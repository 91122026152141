<div *ngIf="IsHiddenLanding == false; else elseBlock"><div class="header" style="background-color: #ffffff!important;height: 80px;border-bottom: 1px solid #ffffff;">
    <div class="logo-container" style="margin-top: 20px;">
        <a routerLink="/dashboard" class="logo">						
            <img src="../../assets/img/logos/logo.png" width="auto" height="40" alt="Canal denuncias" style="margin-left: 35px;margin-top: -25px;width: 250px;height: 75px;object-fit: contain;">					
        </a>	
        <a (click)="goOption()" class="d-md-none" style="background: #ccc;border-radius: 100px;color: #fff;height: 30px;line-height: 30px;position: absolute;right: 95px;text-align: center;top: 14px;width: 30px;" routerlink="/options">
            <i aria-label="Toggle sidebar" class="fas fa-cog"></i>
        </a>
        <a (click)="goProfile()" class="d-md-none" style="background: #ccc;border-radius: 100px;color: #fff;height: 30px;line-height: 30px;position: absolute;right: 55px;text-align: center;top: 14px;width: 30px;" routerlink="/profile">
            <i aria-label="Toggle sidebar" class="fas fa-user"></i>
        </a>				
        <div (click)="toggleSidebar()" class="d-md-none toggle-sidebar-left" data-toggle-class="sidebar-left-opened" data-target="html" data-fire-event="sidebar-left-opened"><i class="fas fa-bars" aria-label="Toggle sidebar"></i></div>
    </div>

    <!-- start: search & user box -->
    <div class="header-right" style="display: -webkit-inline-box;">

        <!--<span class="separator"></span>-->
        <ul class="notifications">
           <!-- <li>
                <a href="#" class="dropdown-toggle notification-icon" data-toggle="dropdown">
                    <i class="fas fa-tasks"></i>
                    <span class="badge">3</span>
                </a>

                <div class="dropdown-menu notification-menu large">
                    <div class="notification-title">
                        <span class="float-right badge badge-default">3</span>
                        Tasks
                    </div>

                    <div class="content">
                        <ul>
                            <li>
                                <p class="clearfix mb-1">
                                    <span class="message float-left">Generating Sales Report</span>
                                    <span class="message float-right text-dark">60%</span>
                                </p>
                                <div class="progress progress-xs light">
                                    <div class="progress-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style="width: 60%;"></div>
                                </div>
                            </li>

                            <li>
                                <p class="clearfix mb-1">
                                    <span class="message float-left">Importing Contacts</span>
                                    <span class="message float-right text-dark">98%</span>
                                </p>
                                <div class="progress progress-xs light">
                                    <div class="progress-bar" role="progressbar" aria-valuenow="98" aria-valuemin="0" aria-valuemax="100" style="width: 98%;"></div>
                                </div>
                            </li>

                            <li>
                                <p class="clearfix mb-1">
                                    <span class="message float-left">Uploading something big</span>
                                    <span class="message float-right text-dark">33%</span>
                                </p>
                                <div class="progress progress-xs light mb-1">
                                    <div class="progress-bar" role="progressbar" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100" style="width: 33%;"></div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>
            <li>
                <a href="#" class="dropdown-toggle notification-icon" data-toggle="dropdown">
                    <i class="fas fa-envelope"></i>
                    <span class="badge">4</span>
                </a>
            </li>-->
            <!--<li>
                <a href="#" class="dropdown-toggle notification-icon" data-toggle="dropdown">
                    <i class="fas fa-bell"></i>
                    <span class="badge">{{numberDenuncias}}</span>
                </a>
            </li>-->
        </ul>

        <!--<span class="separator"></span>-->

        <div id="userbox" class="userbox" style="z-index: 999;display: block !important; ">
            <a href="#" data-toggle="dropdown" aria-expanded="false">
                <figure class="profile-picture">
                    <img style="width:40px !important;height: 40px !important;" src="{{urlPhoto}}" alt="avatar" class="rounded-circle" data-lock-picture="../../assets/img/avatar.png">
                </figure>
                <div class="profile-info" data-lock-name="{{nombreUsuario}}" data-lock-email="{{nombreUsuario}}">
                    <span class="name" style="font-size: 18px;">{{nombreUsuario2}}</span>
                    <hr style="border-top: 0px solid rgb(0 0 0 / 0%);margin: 7px 0;height: 0px;">
                    <span class="name" style="font-size: 12px;">{{nombreUsuario}}</span>
                    <hr style="margin: 6px 0;height: 1px;">
                    <span class="name" style="font-size: 15px;"><b>{{sociedadUsuario}}</b></span>
                    <!--<span class="role">{{roleName}}</span>-->
                </div>

                <i class="fa custom-caret"></i>
            </a>

            <div class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-8px, 35px, 0px);">
                <ul class="list-unstyled mb-2"style=" margin-top: 25px;">
                    <li class="divider"></li>
                    <li class="nav-item d-block">
                        <a style="font-size: 12px;" routerLink="/profile" [routerLinkActive]="['active']"><i class="fas fa-user"></i> {{ 'texto_154' | translate }}</a>
                    </li>
                    <li class="nav-item d-block">
                        <a style="font-size: 12px;" routerLink="/options" [routerLinkActive]="['active']" data-lock-screen="true"><i class="fas fa-lock"></i> {{ 'texto_155' | translate }}</a>
                    </li>
                    <li class="nav-item d-block">
                        <a style="font-size: 12px;" [routerLinkActive]="['active']" (click)="logout()" routerLink="/inicio"><i class="fas fa-power-off"></i> {{ 'texto_75' | translate }}</a>
                    </li>
                </ul>
            </div>
        </div>

    </div>
    <!-- end: search & user box -->
</div></div>   
        <ng-template #elseBlock>
        </ng-template>




<app-admin [menuNavbar]="menuNavbar" [IsHiddenLanding]="IsHiddenLanding"></app-admin>

<script>
    $(document).ready(function() {
        $().ready(function() {
            if ($('.dropdown').hasClass('show-dropdown')) {
                $('.dropdown').addClass('open');
            }
        });
  });
</script>
