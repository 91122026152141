import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Canal } from '../model/canal';
import { ApiService } from '../api.service';
import { DatosDenuncia } from '../model/datosDenuncia';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-terminada-denuncia',
  templateUrl: './terminada-denuncia.component.html',
  styleUrls: ['./terminada-denuncia.component.css']
})
export class TerminadaDenunciaComponent implements OnInit {
  pin_code: string;
  canal: Canal;
  IsAnonimo:boolean = false;
  denuncia: DatosDenuncia[];
  idDenuncia: string = '';

  constructor(private router: Router, private actRoute: ActivatedRoute,private apiService: ApiService, private auth: AuthService) { }

  ngOnInit() {
    if(window.history.state.navigationId == 2){
      window.location.reload();
    }else{
      let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
      let pin = this.actRoute.snapshot.params.id;
      this.pin_code = pin.substring(5, 20);
      this.apiService.searchComplaintByPin(this.pin_code,language).subscribe((denuncia: DatosDenuncia[])=>{
        this.denuncia = denuncia;
        this.idDenuncia = this.denuncia[0].id_denuncia;
        if(this.denuncia[0].denunciante_correo == ""){
          this.IsAnonimo = true;
        }
      });

      this.apiService.searchCanal().subscribe((canal: Canal)=>{
        this.canal = canal;
        if(this.canal[0].valor == "1"  ){
            this.IsAnonimo = true;
        }
      });

    }
  }

  consultar() {
      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.router.navigate(['consultar-denuncia']);
      }); 
        
  }

  inicio(){
    this.auth.logout();
    /*window.location.reload();*/
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/inicio']);
    });
  }

}
