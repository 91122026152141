<div class="main-content">
    <div class="col-md-12">
        <div class="panel panel-primary" style="border-color: #0c55a0;">
            <div class="panel-heading" style="display: block;">
              <h5 class="panel-title">{{ 'texto_129' | translate }}</h5>
              <div style="float: right; margin-top: -30px;"> 
                <i class="glyphicon glyphicon-chevron-down" (click)="cargarFiltros()" ></i>
              </div>
            </div>
            <div class="panel-body" [hidden]="!filtros">
              <form [formGroup]="myForm" >
                <div class="col-md-4" style="font-size: 12px;">
                  <mat-form-field>
                      <mat-label>{{ 'texto_107' | translate }}</mat-label>
                      <input matInput [matDatepicker]="picker" [max]="hoy" [formControl]="fechaInicio"  format="YYYY-MM-DD"  >
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col-md-4" style="font-size: 12px;">
                  <mat-form-field>
                      <mat-label>{{ 'texto_108' | translate }}</mat-label>
                      <input matInput [matDatepicker]="pickerFin" [max]="hoy" [formControl]="fechaFin" format="YYYY-MM-DD"  >
                      <mat-datepicker-toggle matSuffix [for]="pickerFin"></mat-datepicker-toggle>
                      <mat-datepicker #pickerFin></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col-md-4" style="font-size: 12px;">
                  <mat-form-field>
                    <mat-label>{{ 'texto_67' | translate }}</mat-label>
                    <mat-select  [formControl]="empresa"  (click)="cargarEmpresas($event)" >
                      <mat-option [value]="">--</mat-option>
                      <mat-option *ngFor="let emp of empresas" [value]="emp.id_empresa" >
                        {{emp.n_empresa}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-4" style="font-size: 12px;">
                  <mat-form-field>
                    <mat-label>{{ 'texto_130' | translate }}</mat-label>
                    <mat-select [formControl]="tipoDenuncia" (click)="cargarTipoDenuncias($event)" >
                      <mat-option [value]="">--</mat-option>
                      <mat-option *ngFor="let tipo of tipoDenuncias" [value]="tipo.id_tp_denuncia" >
                        {{tipo.nombre}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-4" style="font-size: 12px;">
                  <mat-form-field>
                    <mat-label>{{ 'texto_131' | translate }}</mat-label>
                    <mat-select [formControl]="tipoRelacion" (click)="cargarTipoRelaciones($event)" >
                      <mat-option [value]="">--</mat-option>
                      <mat-option *ngFor="let tipo of tipoRelaciones" [value]="tipo.id_tp_relacion" >
                        {{tipo.nombre}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-4" style="font-size: 12px;">
                  <mat-form-field>
                    <mat-label>{{ 'texto_132' | translate }}</mat-label>
                    <mat-select [formControl]="fase" (click)="cargarFases($event)" >
                      <mat-option [value]="">--</mat-option>
                      <ng-container *ngFor="let fase of fases">
                        <mat-option *ngIf="fase.id_fase == 0 || fase.id_fase == 1 || fase.id_fase == 2 || fase.id_fase == 3 || fase.id_fase == 4 || fase.id_fase == 5" [value]="fase.id_fase" >
                          {{fase.n_fase}}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                  </mat-form-field>
                </div>
                <button (click)="filtrar()" style="float:right;" mdbBtn rounded="true" class="btn-primary mt-1 waves-light" mdbWavesEffect>{{ 'texto_444' | translate }}</button>
                <button (click)="borrarFiltro()" style="float:right;" mdbBtn rounded="true" class="btn-primary mt-1 waves-light" mdbWavesEffect>{{ 'texto_445' | translate }}</button>
            </form>
            </div>
          </div>
    </div>
    <div class="container-fluid">
        <h3>{{ 'texto_368' | translate }}</h3>
        <div class="row">
            
        </div>
        

        <div class="row">
            <div class="col-md-12">
              <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="card card-stats">
                <div class="card-header card-header-danger card-header-icon" style="background:white !important">
                <div class="card-icon">
                            <i class="material-icons">fingerprint</i>
                        </div>
                        <p class="card-category">{{ 'texto_369' | translate }}</p>
                        <h3 class="card-title">{{totalDenuncias}}</h3>
                    </div>
                   
                </div>
            </div>
            </div>
            <div class="col-md-12">
              
                <div class="col-lg-3 col-md-3 col-sm-3">
                    <div class="card card-stats">
                        <div class="card-header card-header-danger card-header-icon" style="background:white !important">
                            <div class="card-icon">
                                <i class="material-icons">done_all</i>
                            </div>
                            <p class="card-category">{{ 'texto_370' | translate }}</p>
                            <h3 class="card-title">{{totalAceptadas}}
                                <small>{{porcentageAceptadas}}%</small>
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3">
                    <div class="card card-stats">
                        <div class="card-header card-header-danger card-header-icon" style="background:white !important">
                            <div class="card-icon">
                                <i class="material-icons">delete_outline</i>
                            </div>
                            <p class="card-category">{{ 'texto_371' | translate }}</p>
                            <h3 class="card-title">{{totalRechazadas}}
                                <small>{{porcentageRechazadas}}%</small>
                            </h3>
                        </div>
    
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3">
                    <div class="card card-stats">
                        <div class="card-header card-header-danger card-header-icon" style="background:white !important">
                            <div class="card-icon">
                                <i class="material-icons">info</i>
                            </div>
                            <p class="card-category">{{ 'texto_372' | translate }}</p>
                            <h3 class="card-title">{{totalConsulta}}
                                <small>{{porcentageConsulta}}%</small>
                            </h3>
                        </div>
                        
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-sm-3">
                  <div class="card card-stats">
                      <div class="card-header card-header-danger card-header-icon" style="background:white !important">
                          <div class="card-icon">
                              <i class="material-icons">thumb_up</i>
                          </div>
                          <p class="card-category">{{ 'texto_373' | translate }}</p>
                          <h3 class="card-title">{{totalAdmision}}
                              <small>{{porcentageAdmision}}%</small>
                          </h3>
                      </div>
                      
                  </div>
              </div>
                
            </div>
            <div class="col-md-12">
                <div class="card card-chart">
                    <div class="card-header card-header-danger" >
                        <h4 class="card-title">{{ 'texto_374' | translate }}</h4>
                        
                    </div>
                    <div class="card-body">                      
                        <div class="ct-chart ct-chart-pie" id="dailySalesChart" style="margin-top: 10px;"></div>
                    </div>
                
                </div>
            </div>
           <!-- <div class="col-md-6">
                <div class="card card-chart">
                    <div class="card-header card-header-danger">
                        <h4 class="card-title">Denuncias por Fase</h4>     
                    </div>
                    <div class="card-body">
                        <div class="ct-chart" id="completedTasksChart"></div>
                    </div>
                    
                </div>
            </div>-->
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="card card-chart">
                    <div class="card-header card-header-danger">             
                        <h4 class="card-title">{{ 'texto_375' | translate }}</h4>
                    </div>
                    <div class="card-body">
                        <div class="ct-chart ct-chart-bar" id="websiteViewsChart"></div>
                    </div>
                
                </div>
            </div>
        </div>
    </div>
  </div>
  