<div class="wrapper">
    <div class="sidebar" data-color="danger" data-background-color="white" data-image="/assets/img/sidebar-1.jpg">
        <app-sidebar></app-sidebar>
        <div class="sidebar-background" style="background-image: url(/assets/img/sidebar-4.jpg)"></div>
    </div>
    <div class="main-panel" style="background-color:white !important;">
        <app-navbar></app-navbar>
        <router-outlet></router-outlet>
    </div>
</div>
