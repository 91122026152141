
  <!-- End Navbar -->
  <div class="main-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">
            <div class="card">
                <div class="card-header card-header-primary" style="height: 60px;">
                  <h4 class="card-title ">{{sociedad}}</h4>
¡                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <th style="width:50px;"></th>
                            <th>ID. {{ 'texto_123' | translate }}</th>
                            <th>N. {{ 'texto_123' | translate }}</th>
                            <th></th>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of sociedades">
                            <td *ngIf="option[0].id_sociedad ==  item.id_sociedad ; else elseBlock"><i class="material-icons">done</i></td>
                            <ng-template #elseBlock><td></td></ng-template>
                            <td>{{ item.id_sociedad }}</td>
                            <td>{{ item.n_sociedad }}</td>                    
                            <td class="td-actions text-right">
                              <button (click)="updateSessionProperties(item.id_sociedad, item.n_sociedad)" type="button" rel="tooltip" title="Edit Task" class="" style="background: none;color:black;border:none;">
                                <i class="material-icons">refresh</i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    
                </div>
              </div>
            </div>
      </div>

      <div class="col-md-6">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header card-header-primary" style="height: 60px;">
                  <h4 class="card-title ">{{ 'texto_153' | translate }} </h4>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <th style="width:50px;"></th>
                            <th></th>
                            <th>{{ 'texto_153' | translate }}</th>
                            <th></th>
                        </thead>
                        <!--bucle-->
                        <tbody>
                          <tr *ngFor="let idioma of idiomas">
                            <td *ngIf="option[0].idioma ==  idioma.id_idioma ; else elseBlockidiomas"><i class="material-icons">done</i></td>
                            <ng-template #elseBlockidiomas><td></td></ng-template>
                            <td><img src="../assets/img/idiomas/{{idioma.bandera}}" alt="{{idioma.n_idioma}}" height="30" width="30"></td>
                            <td>{{idioma.n_idioma}}</td>                    
                            <td class="td-actions text-right">
                              <button (click)="updateLanguageProperties(idioma.id_idioma)" type="button" rel="tooltip" title="Edit Task" class="" style="background: none;color:black;border:none;">
                                <i class="material-icons">refresh</i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                        <!--
                        <tbody>
                          <tr>
                            <td *ngIf="idioma ==  'en'; else elseBlock"><i class="material-icons">done</i></td>
                            <ng-template #elseBlock><td></td></ng-template>
                            <td><img src="../assets/img/idiomas/England.svg" alt="Inglés" height="30" width="30"></td>
                            <td>Inglés</td>                    
                            <td class="td-actions text-right">
                              <button (click)="updateLanguageProperties('en')" type="button" rel="tooltip" title="Edit Task" class="" style="background: none;color:black;border:none;">
                                <i class="material-icons">refresh</i>
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td *ngIf="idioma ==  'es' ; else elseBlock"><i class="material-icons">done</i></td>
                            <ng-template #elseBlock><td></td></ng-template>
                            <td><img src="../assets/img/idiomas/Spain.svg" alt="Español" height="30" width="30"></td>
                            <td>Español</td>                    
                            <td class="td-actions text-right">
                              <button (click)="updateLanguageProperties('es')" type="button" rel="tooltip" title="Edit Task" class="" style="background: none;color:black;border:none;">
                                <i class="material-icons">refresh</i>
                              </button>
                            </td>
                          </tr>
                        </tbody>-->
                      </table>
                </div>
              </div>
            </div>
      </div>
    </div>
  </div>
