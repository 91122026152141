<div class="contenedor">
<div class="contenedor-botones">
    <button *ngFor="let idioma of idiomas" [ngStyle]="{'background': 'url(/assets/img/idiomas/' + idioma.bandera + ') center no-repeat',width: '40px',height: '30px',border: 'none'}" [ngClass]="{'active': activeLang == idioma.id_idioma}" (click)="cambiarLenguaje(idioma.id_idioma)"></button>
                                     
    <!-- <button style="background: url('/assets/img/idiomas/Spain.svg') center no-repeat;width: 40px;height: 30px;border: none;" [ngClass]="{'active': activeLang == 'es'}" (click)="cambiarLenguaje('es')"></button>
    <button style="background: url('/assets/img/idiomas/England.svg') center no-repeat;width: 40px;height: 30px;border: none;" [ngClass]="{'active': activeLang == 'en'}" (click)="cambiarLenguaje('en')"></button>
    <button style="background: url('/assets/img/idiomas/italia.svg') center no-repeat;width: 40px;height: 30px;border: none;" [ngClass]="{'active': activeLang == 'it'}" (click)="cambiarLenguaje('it')"></button>
    <button style="background: url('/assets/img/idiomas/portugal.svg') center no-repeat;width: 40px;height: 30px;border: none;" [ngClass]="{'active': activeLang == 'pt'}" (click)="cambiarLenguaje('pt')"></button>
    -->
</div>
</div>