import { Component, OnInit } from '@angular/core';
import * as Chartist from 'chartist';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';
import { OptionSesion } from '../model/optionSesion';
import { Users } from '../model/users';
import { environment } from "../../environments/environment"
import { Usuarios } from '../model/usuarios';
import { Informe } from '../model/informe';
import * as legend from 'chartist-plugin-legend';
import { DatePipe } from '@angular/common'
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Empresa } from '../model/empresa';
import { Relacion } from '../model/relacion';
import { Denuncia } from '../model/denuncia';
import { Fase } from '../model/fase';
import { DatosDenuncia } from '../model/datosDenuncia';

import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [
    DatePipe,
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class DashboardComponent implements OnInit {
  option: OptionSesion[];
  users: Usuarios[] ;
  informe: Informe[];
  informeType: Informe[];
  informeTypeFilter: Informe[];
  informePhase: Informe[];
  totalDenuncias: number = 0;
  totalAceptadas: number = 0;
  totalRechazadas: number = 0;
  totalConsulta: number = 0;
  totalAdmision: number = 0;
  porcentageAceptadas: number = 0;
  porcentageRechazadas: number = 0;
  porcentageConsulta: number = 0;
  porcentageAdmision: number = 0;
  empresas: Empresa[];
  denuncia: DatosDenuncia[];
  tipoRelaciones: Relacion[];
  tipoDenuncias: Denuncia[];
  fases: Fase[];
  hoy = new Date(); 
  myForm = new FormGroup({
    fechaInicio: new FormControl('',[] ),
    fechaFin: new FormControl('', []),
    empresa: new FormControl('', []),
    tipoDenuncia: new FormControl('', []),
    tipoRelacion: new FormControl('', []),
    fase: new FormControl('', []),
  });

  filtros:boolean = false;

  get fechaInicio() {
    return this.myForm.get('fechaInicio');
  }

  get fechaFin() {
    return this.myForm.get('fechaFin');
  }

  get empresa() {
    return this.myForm.get('empresa');
  }

  get tipoDenuncia() {
    return this.myForm.get('tipoDenuncia');
  }

  get tipoRelacion() {
    return this.myForm.get('tipoRelacion');
  }

  get fase() {
    return this.myForm.get('fase');
  }

  constructor(private apiService: ApiService,private router: Router, private auth: AuthService, private datepipe: DatePipe,private translate: TranslateService) {
    var tester = new legend(); //without this line, you get 'Chartist.plugins undefined'
    this.hoy.setDate(this.hoy.getDate());
   }

  ngOnInit() {
    this.apiService.searchUserById(this.auth.getIdUser()).subscribe((usuarios: Usuarios[])=>{
      this.users = usuarios;
      let photo = environment.baseAvatar + "avatar.png";
      if(usuarios[0].foto != "NULL"){
        this.auth.setPhotoUser(usuarios[0].foto);
      }else{
        this.auth.setPhotoUser(photo);
      }
    });

    
      /* ----------==========     Daily Sales Chart initialization For Documentation    ==========---------- */


      let labelFaseType = [];
      let seriesFaseType = [];
      let total = 0;
      if(this.auth.getSociedad() == null){
        this.apiService.getSessionPropertiesUser(this.auth.getIdUser()).subscribe((option: OptionSesion[])=>{
          this.option = option;
          if(option[0].idioma != ""){ 
           // this.apiService.createSessionPropertiesByUser(this.auth.getIdUser());  
            this.auth.setLanguage(option[0].idioma);    
          }
          if(option[0].id_sociedad != ""){
            this.auth.setSociedad(option[0].id_sociedad);
            this.auth.setNameSociedad(option[0].n_sociedad);
          }
          let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
          this.apiService.searchComplaintsByTypeDashboard(this.auth.getSociedad(),'', '','', '', '', 'TODAS',this.auth.getIdUser(),language).subscribe((informes1: Informe[])=>{
            this.informeType = informes1;
            for(let i=0;i<this.informeType.length;i++){
              labelFaseType.push(this.informeType[i].n_fase + " - " + Number(this.informeType[i].numero));
              seriesFaseType.push(Number(this.informeType[i].numero));
              total = total+Number(this.informeType[i].numero);
            }
            var data = {
              series: seriesFaseType
            };   
              var options = {
              height: 300,
              distributeSeries: true,
              labelInterpolationFnc: function(value) {
                var percentage = Math.round(value / total * 100) + '%';
                return  percentage;
            },
              plugins: [Chartist.plugins.legend({
              legendNames: labelFaseType,
              }),
              
              ]};
              new Chartist.Pie('#dailySalesChart', data, options); 
          });
    
          let labelFase = [];
              let seriesFase = [];
              this.totalDenuncias = 0;
              this.totalAceptadas = 0;
              this.totalRechazadas = 0;
              this.totalConsulta = 0;
              this.totalAdmision = 0;
              this.porcentageAceptadas= 0;
              this.porcentageRechazadas = 0;
              this.porcentageConsulta = 0;
              this.porcentageAdmision = 0;

              this.apiService.searchComplaintsByPhaseDashboard(this.auth.getSociedad(),'', '','', '', '', 'TODAS',this.auth.getIdUser(),language).subscribe((informes2: Informe[])=>{
                this.informe = informes2;
                for(let i=0;i<this.informe.length;i++){
                  let faseDenuncia = Number(this.informe[i].fase);
                  if(faseDenuncia == 0 || faseDenuncia == 11){
                    this.totalAdmision = this.totalAdmision + Number(this.informe[i].numero);
                  }else if (faseDenuncia > 0 && faseDenuncia < 7){
                    this.totalAceptadas = this.totalAceptadas + Number(this.informe[i].numero);
                  }else if(faseDenuncia == 7){
                    this.totalConsulta = this.totalConsulta + Number(this.informe[i].numero);
                    this.totalRechazadas = this.totalRechazadas + Number(this.informe[i].numero);
                  }else if(faseDenuncia == 8){
                    this.totalRechazadas = this.totalRechazadas + Number(this.informe[i].numero);
                  }
                  labelFase.push(this.informe[i].n_fase);
                  seriesFase.push(Number(this.informe[i].numero));
                  this.totalDenuncias = this.totalDenuncias + Number(this.informe[i].numero);
                }
                if(this.totalDenuncias > 0){
                  this.porcentageAceptadas = Math.round(this.totalAceptadas / this.totalDenuncias *100);
                  this.porcentageAdmision = Math.round(this.totalAdmision / this.totalDenuncias *100);
                  this.porcentageConsulta = Math.round(this.totalConsulta / this.totalDenuncias *100);
                  this.porcentageRechazadas = Math.round(this.totalRechazadas / this.totalDenuncias *100);
                }
              
                new Chartist.Bar('#websiteViewsChart', {
                  labels: labelFase,
                  series: seriesFase
                }, {
                  distributeSeries: true,
                  height: 300
                  
                });
              });
        });
      }else{
        let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
        this.apiService.searchComplaintsByTypeDashboard(this.auth.getSociedad(),'', '','', '', '', 'TODAS',this.auth.getIdUser(),language).subscribe((informes1: Informe[])=>{
          this.informeType = informes1;
          for(let i=0;i<this.informeType.length;i++){
            labelFaseType.push(this.informeType[i].n_fase + " - " + Number(this.informeType[i].numero));
            seriesFaseType.push(Number(this.informeType[i].numero));
            total = total+Number(this.informeType[i].numero);
          }
          var data = {
            series: seriesFaseType
          };   
            var options = {
            height: 300,
            distributeSeries: true,
            labelInterpolationFnc: function(value) {
              var percentage = Math.round(value / total * 100) + '%';
              return  percentage;
          },
            plugins: [Chartist.plugins.legend({
            legendNames: labelFaseType,
            })
            ]};
            new Chartist.Pie('#dailySalesChart', data, options); 
        });
  
        let labelFase = [];
            let seriesFase = [];
            this.totalDenuncias = 0;
              this.totalAceptadas = 0;
              this.totalRechazadas = 0;
              this.totalConsulta = 0;
              this.totalAdmision = 0;
              this.porcentageAceptadas= 0;
              this.porcentageRechazadas = 0;
              this.porcentageConsulta = 0;
              this.porcentageAdmision = 0;

            this.apiService.searchComplaintsByPhaseDashboard(this.auth.getSociedad(),'', '','', '', '', 'TODAS',this.auth.getIdUser(),language).subscribe((informes2: Informe[])=>{
              this.informe = informes2;
              for(let i=0;i<this.informe.length;i++){
                let faseDenuncia = Number(this.informe[i].fase);
                if(faseDenuncia == 0 || faseDenuncia == 11){
                  this.totalAdmision = this.totalAdmision + Number(this.informe[i].numero);
                }else if (faseDenuncia > 0 && faseDenuncia < 7){
                  this.totalAceptadas = this.totalAceptadas + Number(this.informe[i].numero);
                }else if(faseDenuncia == 7){
                  this.totalConsulta = this.totalConsulta + Number(this.informe[i].numero);
                  this.totalRechazadas = this.totalRechazadas + Number(this.informe[i].numero);
                }else if(faseDenuncia == 8){
                  this.totalRechazadas = this.totalRechazadas + Number(this.informe[i].numero);
                }
                labelFase.push(this.informe[i].n_fase);
                seriesFase.push(Number(this.informe[i].numero));
                this.totalDenuncias = this.totalDenuncias + Number(this.informe[i].numero);
              }
              if(this.totalDenuncias > 0){
                this.porcentageAceptadas = Math.round(this.totalAceptadas / this.totalDenuncias *100);
                this.porcentageAdmision = Math.round(this.totalAdmision / this.totalDenuncias *100);
                this.porcentageConsulta = Math.round(this.totalConsulta / this.totalDenuncias *100);
                this.porcentageRechazadas = Math.round(this.totalRechazadas / this.totalDenuncias *100);
              }
            
              var options = {
                seriesBarDistance: 10
              };
              
              var responsiveOptions = [
                ['screen and (max-width: 640px)', {
                  seriesBarDistance: 5,
                  axisX: {
                    labelInterpolationFnc: function (value) {
                      return value[0];
                    }
                  }
                }]
              ];

              new Chartist.Bar('#websiteViewsChart', {
                labels: labelFase,
                series: seriesFase
              }, {
                distributeSeries: true,
                  height: 300,
              });
            });
      }
  }


  filtrar(){
    let fecInicio = "";
    if(this.fechaInicio.value != ""){
      fecInicio = this.datepipe.transform(this.fechaInicio.value, 'yyyy-MM-dd');
    }

    let fecFin = "";
    if(this.fechaFin.value != ""){
      fecFin = this.datepipe.transform(this.fechaFin.value, 'yyyy-MM-dd');
    }

    let fechaValida = true;
    if(fecInicio != "" && fecFin != ""){
      if(fecFin < fecInicio){
        fechaValida = false;
      }
    }

    let fase = "-1";
    if(this.fase.value != ""){
       fase = this.fase.value;
    }
    if(fechaValida){
       //console.log(this.auth.getSociedad() + " ---- FI:" + fecInicio +  " ---- FF: " + fecFin +" ---- EMP: " + this.empresa.value + " ---- TD: " +  this.tipoDenuncia.value + " ---- TR: " +  this.tipoRelacion.value + " ---- FASE: " +  this.fase.value);
       /* this.apiService.searchComplaintBySocietyFechaIniHastaEmpresaTipoDenunciaTipoRelacionPhaseUser(this.auth.getSociedad(), fecInicio, fecFin,this.empresa.value, this.tipoDenuncia.value, this.tipoRelacion.value, fase,this.auth.getIdUser()).subscribe((denuncia: DatosDenuncia[])=>{
          this.denuncia = denuncia;
        });*/ 
        let labelFaseType = [];
        let seriesFaseType = [];
        let total = 0;
        let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
        this.apiService.searchComplaintsByTypeDashboard(this.auth.getSociedad(),fecInicio, fecFin,this.empresa.value, this.tipoDenuncia.value, this.tipoRelacion.value, fase,this.auth.getIdUser(),language).subscribe((informes2: Informe[])=>{
          this.informeTypeFilter = informes2;
          for(let i=0;i<this.informeTypeFilter.length;i++){
            labelFaseType.push(this.informeTypeFilter[i].n_fase + " - " + Number(this.informeTypeFilter[i].numero));
            seriesFaseType.push(Number(this.informeTypeFilter[i].numero));
            total = total+Number(this.informeTypeFilter[i].numero);
          }
          var data = {
            series: seriesFaseType
          };   
            var options = {
            height: 300,
            distributeSeries: true,
            labelInterpolationFnc: function(value) {
              var percentage = Math.round(value / total * 100) + '%';
              return  percentage;
          },
            plugins: [Chartist.plugins.legend({
            legendNames: labelFaseType,
            })
            ]};
            new Chartist.Pie('#dailySalesChart', data, options); 
        });



          let labelFase = [];
            let seriesFase = [];
            this.totalDenuncias = 0;
            this.totalAceptadas = 0;
            this.totalRechazadas = 0;
            this.totalConsulta = 0;
            this.totalAdmision = 0;
            this.porcentageAceptadas= 0;
            this.porcentageRechazadas = 0;
            this.porcentageConsulta = 0;
            this.porcentageAdmision = 0;
            this.apiService.searchComplaintsByPhaseDashboard(this.auth.getSociedad(),fecInicio, fecFin,this.empresa.value, this.tipoDenuncia.value, this.tipoRelacion.value, fase,this.auth.getIdUser(),language).subscribe((informes2: Informe[])=>{
              this.informe = informes2;
              for(let i=0;i<this.informe.length;i++){
                let faseDenuncia = Number(this.informe[i].fase);
                if(faseDenuncia == 0  || faseDenuncia == 11){
                  this.totalAdmision = this.totalAdmision  + Number(this.informe[i].numero);
                }else if (faseDenuncia > 0 && faseDenuncia < 7){
                  this.totalAceptadas = this.totalAceptadas + Number(this.informe[i].numero);
                }else if(faseDenuncia == 7){
                  this.totalConsulta = this.totalConsulta + Number(this.informe[i].numero);
                  this.totalRechazadas = this.totalRechazadas + Number(this.informe[i].numero);
                }else if(faseDenuncia == 8){
                  this.totalRechazadas = this.totalRechazadas + Number(this.informe[i].numero);
                }
                labelFase.push(this.informe[i].n_fase);
                seriesFase.push(Number(this.informe[i].numero));
                this.totalDenuncias = this.totalDenuncias + Number(this.informe[i].numero);
              }
              if(this.totalDenuncias > 0){
                this.porcentageAceptadas = Math.round(this.totalAceptadas / this.totalDenuncias *100);
                this.porcentageAdmision = Math.round(this.totalAdmision / this.totalDenuncias *100);
                this.porcentageConsulta = Math.round(this.totalConsulta / this.totalDenuncias *100);
                this.porcentageRechazadas = Math.round(this.totalRechazadas / this.totalDenuncias *100);
              }
            
              var options = {
                seriesBarDistance: 10
              };
              
              var responsiveOptions = [
                ['screen and (max-width: 640px)', {
                  seriesBarDistance: 5,
                  axisX: {
                    labelInterpolationFnc: function (value) {
                      return value[0];
                    }
                  }
                }]
              ];

              new Chartist.Bar('#websiteViewsChart', {
                labels: labelFase,
                series: seriesFase
              }, {
                distributeSeries: true,
                height: 300
              });
            });


    }else{
      alert("Las fechas no son correctas")
    }
  }


  cargarFiltros(){
    this.filtros = !this.filtros;
  }

  cargarEmpresas(e){
    this.apiService.searchCompanyByUser(this.auth.getIdUser(),this.auth.getSociedad()).subscribe((empresas: Empresa[])=>{
      this.empresas = empresas;
    });
  }

  cargarTipoRelaciones(e){
    let language = this.auth.getLanguage();
      if(language == "" || language == null){
        language = 'es';
      }
    this.apiService.searchTypeRelation(this.auth.getSociedad(),true,language).subscribe((tipoRelaciones: Relacion[])=>{
      this.tipoRelaciones = tipoRelaciones;
    });
  }

  cargarTipoDenuncias(e){
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.apiService.searchTypeComplaint(this.auth.getSociedad(),true,language).subscribe((denuncia: Denuncia[])=>{
      this.tipoDenuncias = denuncia;
    });
  }

  cargarFases(e){
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.apiService.getPhases(language).subscribe((fase: Fase[])=>{
      this.fases = fase;
    });
  }

  borrarFiltro(){
    this.myForm.controls['fechaInicio'].setValue(null);
    this.myForm.controls['fechaFin'].setValue(null);
    this.myForm.reset();

     this.empresa.setValue("");
     this.tipoDenuncia.setValue("");
     this.tipoRelacion.setValue("");
     this.fase.setValue("");
     let labelFaseType = [];
        let seriesFaseType = [];
        let total = 0;

        let language = this.auth.getLanguage();
        if(language == "" || language == null){
          language = 'es';
        }
        this.translate.setDefaultLang(language);
     this.apiService.searchComplaintsByTypeDashboard(this.auth.getSociedad(),'', '','', '', '', 'TODAS',this.auth.getIdUser(),language).subscribe((informes1: Informe[])=>{
      this.informeType = informes1;
      for(let i=0;i<this.informeType.length;i++){
        labelFaseType.push(this.informeType[i].n_fase + " - " + Number(this.informeType[i].numero));
        seriesFaseType.push(Number(this.informeType[i].numero));
        total = total+Number(this.informeType[i].numero);
      }
      var data = {
        series: seriesFaseType
      };   
        var options = {
        height: 300,
        distributeSeries: true,
        labelInterpolationFnc: function(value) {
          var percentage = Math.round(value / total * 100) + '%';
          return  percentage;
      },
        plugins: [Chartist.plugins.legend({
        legendNames: labelFaseType,
        })
        ]};
        new Chartist.Pie('#dailySalesChart', data, options); 
    });

    let labelFase = [];
            let seriesFase = [];
            this.totalDenuncias = 0;
              this.totalAceptadas = 0;
              this.totalRechazadas = 0;
              this.totalConsulta = 0;
              this.totalAdmision = 0;
              this.porcentageAceptadas= 0;
              this.porcentageRechazadas = 0;
              this.porcentageConsulta = 0;
              this.porcentageAdmision = 0;
            this.apiService.searchComplaintsByPhaseDashboard(this.auth.getSociedad(),'', '','', '', '', 'TODAS',this.auth.getIdUser(),language).subscribe((informes2: Informe[])=>{
              this.informe = informes2;
              for(let i=0;i<this.informe.length;i++){
                let faseDenuncia = Number(this.informe[i].fase);
                if(faseDenuncia == 0  || faseDenuncia == 11){
                  this.totalAdmision = this.totalAdmision + Number(this.informe[i].numero);
                  this.totalAceptadas = this.totalAceptadas + Number(this.informe[i].numero);
                }else if (faseDenuncia > 0 && faseDenuncia < 7){
                  this.totalAceptadas = this.totalAceptadas+ Number(this.informe[i].numero);
                }else if(faseDenuncia == 7){
                  this.totalConsulta = this.totalConsulta + Number(this.informe[i].numero);
                  this.totalRechazadas = this.totalRechazadas + Number(this.informe[i].numero);
                }else if(faseDenuncia == 8){
                  this.totalRechazadas = this.totalRechazadas + Number(this.informe[i].numero);
                }
                labelFase.push(this.informe[i].n_fase);
                seriesFase.push(Number(this.informe[i].numero));
                this.totalDenuncias = this.totalDenuncias + Number(this.informe[i].numero);
              }
              if(this.totalDenuncias > 0){
                this.porcentageAceptadas = Math.round(this.totalAceptadas / this.totalDenuncias *100);
                this.porcentageAdmision = Math.round(this.totalAdmision / this.totalDenuncias *100);
                this.porcentageConsulta = Math.round(this.totalConsulta / this.totalDenuncias *100);
                this.porcentageRechazadas = Math.round(this.totalRechazadas / this.totalDenuncias *100);
              }
            
              new Chartist.Bar('#websiteViewsChart', {
                labels: labelFase,
                series: seriesFase
              }, {
                distributeSeries: true,
                height: 300
              });
            });
  }

}
