import { Injectable } from '@angular/core';
import { SessionInteruptService } from 'session-expiration-alert';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth.service';
import { SessionTimerService } from 'session-expiration-alert';

@Injectable()
export class AppSessionInteruptService extends SessionInteruptService {
  constructor(private readonly httpClient: HttpClient,private auth: AuthService,public sessionTimer: SessionTimerService) {
    super();
  }
  continueSession() {
    console.log(`I issue an API request to server.`);
  }
  stopSession() {
    this.sessionTimer.stopTimer();
    console.log(`I logout.`);
    this.auth.logout();
    //this.router.navigate(['/inicio']);
    window.location.reload();
  }
}