import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from '../model/datosDenuncia';
import { PlazosDenuncia } from '../model/plazosDenuncia';
import { Historico } from '../model/historico';
import { environment } from "../../environments/environment";
import { DatePipe } from '@angular/common';
import { Receptor } from '../model/receptor';

@Component({
  selector: 'app-enviar-receptor',
  templateUrl: './enviar-receptor.component.html',
  styleUrls: ['./enviar-receptor.component.css'],
  providers:[DatePipe]
})
export class EnviarDenunciaReceptorComponent implements OnInit {
  historico: Historico[];
  validatingForm: FormGroup;
  IsHiddenForm: boolean =  false;
  plazos: PlazosDenuncia[];
  receptor: Receptor[];
  receptor2: Receptor[];
  @Input() denuncia: DatosDenuncia;
  constructor(private apiService: ApiService, private auth: AuthService, private router: Router, private toastr: ToastrService, private translate: TranslateService, private datepipe: DatePipe) { }

  ngOnInit(): void {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.validatingForm = new FormGroup({
      enviarDenunciaReceptor: new FormControl('', [])
    });
  }

  get enviarDenunciaReceptor() {
    return this.validatingForm.get('enviarDenunciaReceptor');
  }

  validar(){
    if(this.enviarDenunciaReceptor.value == true){
    
      this.apiService.searchReceptorByIdDenuncia(this.denuncia[0].id_denuncia, '0').subscribe((receptor: Receptor[])=>{
        this.receptor = receptor;
        if(this.receptor.length > 0 ){  
          let url = environment.base + "/#/editar-denuncia/"+this.denuncia[0].id_denuncia;
            this.apiService.searchInvestigatorByIdDenuncia(this.denuncia[0].id_denuncia, '11').subscribe((receptor2: Receptor[])=>{
              this.receptor2 = receptor2;
              if(this.receptor2.length > 0 ){
                  console.log("res1: " + receptor);
                  let messageReceptor = "Estimado/a " + this.receptor2[0].id_user + " acaba de recibir la denuncia con id "+this.denuncia[0].id_denuncia+". Entre en el canal para gestionarlo.";
                  this.apiService.sendEmail(this.receptor2[0].id_user,'Asignación de denuncia como receptor', messageReceptor , url,false, "Asignación de denuncia como receptor").subscribe(
                    res => {
                      if(res == 200){
                        this.toastr.success(this.translate.instant('texto_532'), this.translate.instant('texto_533'));
                        let role = this.auth.getRole();
                        if(role.includes("RECEPTOR") && this.receptor2[0].id_usuario == this.auth.getIdUser()){
                          /*console.log("REC " + this.plazos[0].id_usuario + '---' + this.auth.getIdUser());*/
                          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                            this.router.navigate(['editar-denuncia/',this.denuncia[0].id_denuncia]);
                          }); 
                        }else{
                          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                              this.router.navigate(['denuncias']);
                          }); 
                        }
                      }else{
                        this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                      }         
                  }); 
                };
              });                                
            }else{
              this.toastr.error(this.translate.instant('texto_531'), this.translate.instant('texto_533'));
              this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                this.router.navigate(['editar-denuncia/',this.denuncia[0].id_denuncia]);
              }); 
            }          
      }); 
    };
  };
};
