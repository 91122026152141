import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from '../model/datosDenuncia';
import { Receptor } from '../model/receptor';
import { environment } from "../../environments/environment";

@Component({
  selector: 'app-enviar-informe',
  templateUrl: './enviar-informe.component.html',
  styleUrls: ['./enviar-informe.component.css']
})
export class EnviarInformeComponent implements OnInit {
  myForm: FormGroup;
  @Input() denuncia: DatosDenuncia;
  textoInforme: string;
  receptor: Receptor[];
  contador = 0

  constructor(private apiService: ApiService,private router: Router, private auth: AuthService, private toastr: ToastrService, private translate: TranslateService, private http: HttpClient) { }

  ngOnInit(): void {
    this.myForm = new FormGroup({
      textoInforme: new FormControl('', []),
    });
    this.textoInforme = this.denuncia[0].informe_investigador;
  }

  get texto() {
    return this.myForm.get('textoInforme');
  }
  enviarInforme(){
    if(this.textoInforme != ""){
      if(this.contador>4000){
        this.toastr.error(this.translate.instant('texto_266'), this.translate.instant('texto_243'));
      }else{
      this.apiService.sendReportByIdDenuncia(this.denuncia[0].id_denuncia,this.textoInforme,'INVESTIGADOR', this.auth.getIdUser()).subscribe(
        res => {
          if(res == 200){
            this.toastr.success('Informe enviado correctamente', 'Enviando informe ...');
            this.apiService.updateComplaintById(this.denuncia[0].id_denuncia, "3", this.auth.getSociedad(), this.auth.getIdUser(),"").subscribe(
              res => {
                if(res == 200){
                  this.apiService.disableUserByIdComplaint(this.denuncia[0].id_denuncia, this.auth.getIdUser()).subscribe(
                    res => {
                      if(res == 200){
                        this.apiService.enableUserByIdComplaint(this.denuncia[0].id_denuncia, this.auth.getIdUser()).subscribe(
                          res => {
                            if(res == 200){
                              this.apiService.searchReceptorByIdComplaint(this.denuncia[0].id_denuncia,true).subscribe((receptor: Receptor[])=>{
                                this.receptor = receptor;

                                let $clau = false;
                                let clausulas_correo = localStorage.getItem("clausulas_correo");
                                if(clausulas_correo == '1'){
                                  $clau = true;
                                }  

                                let url = environment.base + "/#/editar-denuncia/"+this.denuncia[0].id_denuncia;
                                let message = "Estimado/a "  +  this.receptor[0].id_user  + ", le comunicamos que ha sido asignado a la denuncia "  + this.denuncia[0].id_denuncia  + " con el rol de receptor. Entre en el canal para signar un decisor a la denuncia.";
                                  this.apiService.sendEmail(this.receptor[0].mail_user,'Avance de fase en denuncia '+this.denuncia[0].id_denuncia, message,url,$clau, "Asignación de denuncia como receptor").subscribe(
                                    res => {
                                      if(res == 200){
                                        let role = this.auth.getRole();
                                        if(role.includes("RECEPTOR") && this.receptor[0].id_usuario == this.auth.getIdUser()){
                                          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                                            this.router.navigate(['editar-denuncia/',this.denuncia[0].id_denuncia]);
                                          }); 
                                        }else{
                                          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                                              this.router.navigate(['denuncias']);
                                          }); 
                                        }
                                      }else{
                                        this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                                      }         
                                  });                  
                              }); 

                              
                            }else{
                              this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                            }
                          });
                        }else{
                          this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                        }
                      });
                }else{
                  this.toastr.error(this.translate.instant('texto_196'), this.translate.instant('texto_192'));
                }
              });
          }else{
            this.toastr.error('Error al enviar informe', 'Enviando informe ...');
          }
        });
    

      }
    }else{
      this.toastr.error('Es necesario enviar el informe', 'Enviando informe ...');
    }
    
  }

  onKey(event){
    var datos = document.getElementById("textoInformeInv") ;
    for(var i=0; i< 1; i++){
      var div = datos.getElementsByClassName('nw-editor__res')[i];
      this.contador = div.textContent.length
    }
   }
 

}
