<footer class="bg-secondary da-section">
    <div class="container text-white">
        <div class="row">
        <div class="col-md-6">
            <p style="font-size:10px;">&copy; 2021 Becompliance. Todos los derechos reservados.</p>
        </div>
        <div class="col-md-6">
            <p style="font-size:10px;">Política de Cookies</p>
            <a routerLink="/politica-cookies" routerLinkActive="active">Política de Cookies</a>
            </div>
        </div>
    </div>
</footer>