import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from '../model/datosDenuncia';
import { environment } from "../../environments/environment"

@Component({
  selector: 'app-validar-denuncia',
  templateUrl: './validar-denuncia.component.html',
  styleUrls: ['./validar-denuncia.component.css']
})
export class ValidarDenunciaComponent implements OnInit {
  validatingForm: FormGroup;
  IsHiddenForm: boolean =  false;
  @Input() denuncia: DatosDenuncia;
  constructor(private apiService: ApiService, private auth: AuthService, private router: Router, private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit(): void {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.validatingForm = new FormGroup({
      validarDenuncia: new FormControl('', [])
    });
  }

  get validarDenuncia() {
    return this.validatingForm.get('validarDenuncia');
  }

  validar(){
      if(this.validarDenuncia.value == true){
        this.apiService.updateComplaintById(this.denuncia[0].id_denuncia, "1", this.auth.getSociedad(), this.auth.getIdUser(),"").subscribe(
          res => {
            if(res == 200){
              this.toastr.success(this.translate.instant('texto_191'), this.translate.instant('texto_192'));
              if(this.denuncia[0].denunciante_correo != ""){

                let $clau = false;
                let clausulas_correo = localStorage.getItem("clausulas_correo");
                if(clausulas_correo == '1'){
                  $clau = true;
                }  

                let url = environment.base + "/#/consultar-denuncia";
                let message = "Estimado/a "  +  this.denuncia[0].denunciante_nombre + " " +this.denuncia[0].denunciante_apellidos + ", le comunicamos que su denuncia con id "  + this.denuncia[0].id_denuncia  + " ha sido aceptada y procedemos a su tramitación";
                this.apiService.sendEmail(this.denuncia[0].denunciante_correo,'Denuncia '+this.denuncia[0].id_denuncia+ ' validada', message,url,$clau, "Denuncia validada").subscribe(
                  res => {
                    if(res == 200){
                    //  this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                    }else{
                      this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                    }         
                });
              }
            }else{
              this.toastr.error(this.translate.instant('texto_196'), this.translate.instant('texto_192'));
            }
            this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
              this.router.navigate(['editar-denuncia/',this.denuncia[0].id_denuncia]);
            }); 
        });
      }

  }
}
