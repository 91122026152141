import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from '../model/datosDenuncia';
import { environment } from "../../environments/environment";
import { Archivos } from '../data/formData.model';

@Component({
  selector: 'app-anadir-archivo-receptor',
  templateUrl: './anadir-archivo-receptor.component.html',
  styleUrls: ['./anadir-archivo-receptor.component.css']
})
export class AnadirArchivoReceptorComponent implements OnInit {

  PHP_API_SERVER = environment.baseUrl;//"http://127.0.0.1:8080";

  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  myForm = new FormGroup({
    file: new FormControl('',[] ),
    fileSource: new FormControl('', []),
    contactFormModalMessage: new FormControl('', []),
  });
  archivos: Archivos;
  files: any = [];
  @Input() denuncia: DatosDenuncia;
  contador = 0

  idrole: string;

  constructor(private apiService: ApiService,private router: Router, private auth: AuthService, private toastr: ToastrService, private translate: TranslateService, private http: HttpClient) { }

  ngOnInit() {
    
  }

  /*onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myForm.patchValue({
        fileSource: file
      });
     // this.submit();
    }
  }
     
  get contactFormModalMessage() {
    return this.myForm.get('contactFormModalMessage');
  }

  submit(){
    const formData = new FormData();
    formData.append('file', this.myForm.get('fileSource').value);
    formData.append('id_sociedad', this.denuncia[0].id_sociedad);
    formData.append('id_denuncia', this.denuncia[0].id_denuncia);
    formData.append('comentario', this.contactFormModalMessage.value);
    this.http.post(`${this.PHP_API_SERVER}/api/uploadDocument.php`, formData).subscribe(
      res => {
        if(res == 200){
          this.toastr.success('Archivos cargados correctamente', 'Cargando ...');
        }else{
          this.toastr.error('Error al cargar los archivos', 'Cargando ...');
        }
        
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['editar-denuncia/',this.denuncia[0].id_denuncia]);
        });
      })
  }*/
  onKey(event){
    var datos = document.getElementById("addDocument") ;
    for(var i=0; i< 1; i++){
      var div = datos.getElementsByClassName('nw-editor__res')[i];
      this.contador = div.textContent.length
    }
  }


  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myForm.patchValue({
        fileSource: file
      });
     // this.submit();
    }
  }
     
  get contactFormModalMessage() {
    return this.myForm.get('contactFormModalMessage');
  }

  submit(){
    if(this.contador>4000){
      this.toastr.error(this.translate.instant('Superado el máximo número de caracteres'), this.translate.instant('Parámetros incorrectos'));
    }else{

      let comentario = this.contactFormModalMessage.value.replace(/<[^>]*>/g, '');
      comentario = comentario.replace(/'/g, '');

      let role = this.auth.getRole();
      this.idrole = '0';
      if (role.includes("EXTERNO") ){
        this.idrole = '11';
      }         

      const formData = new FormData();
      formData.append('file', this.myForm.get('fileSource').value);
      formData.append('id_sociedad', this.denuncia[0].id_sociedad);
      formData.append('id_denuncia', this.denuncia[0].id_denuncia);
      formData.append('comentario', comentario);
      formData.append('id_usuario', this.auth.getIdUser());
      formData.append('idrole', this.idrole);
      this.http.post(`${this.PHP_API_SERVER}/api/uploadDocument.php`, formData).subscribe(
        res => {
          if(res == 200){
            this.toastr.success('Archivos cargados correctamente', 'Cargando ...');
          }else{
            this.toastr.error('Error al cargar los archivos', 'Cargando ...');
          }
          
          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['editar-denuncia/',this.denuncia[0].id_denuncia]);
          });

        })
    }
  }

  onFileChangeDrag(event) {
    if (event.length > 0) {
      const file = event[0];
      this.myForm.patchValue({
        fileSource: file
      });
      
     //this.submit();
    }
  }

  dragFile(event) {
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
     // const element = event[index];
      //const file = event.target.files[0];

      this.files.push(element.name)
      if(this.files.length > 1){
        this.files.pop(element.name)
        this.toastr.error('Sólo puede subir un archivo, si desea añadir más archivos lo podrá hacer más adelante', 'Cargando ...');
      }else{
        if(element.size/1000000 > 16){
          this.files.pop(element.name)
          alert('Tamaño máximo de archivo es de 16Mb');
        }else{
          this.onFileChangeDrag(event);
        }
      }
    }  
  }

  uploadFile(event) {
    for (let index = 0; index < event.target.files.length; index++) {
      const element = event.target.files[index];
     // const element = event[index];
      //const file = event.target.files[0];

      this.files.push(element.name)
      if(this.files.length > 1){
        this.files.pop(element.name)
        this.toastr.error('Sólo puede subir un archivo, si desea añadir más archivos lo podrá hacer más adelante', 'Cargando ...');
      }else{
        if(element.size/1000000 > 16){
          this.files.pop(element.name)
          alert('Tamaño máximo de archivo es de 16Mb');
        }else{
          this.onFileChange(event);
        }
      }
    }  
  }
  deleteAttachment(index) {
    //this.archivos.nombreArchivo =  "";
    this.files.splice(index, 1)
    const formData = new FormData();
    let file = this.myForm.get('fileSource').value;
  
    formData.append('file', file);
    formData.append('id_sociedad', this.denuncia[0].id_sociedad);
    formData.append('id_denuncia', this.denuncia[0].id_denuncia);
    formData.append('comentario', this.contactFormModalMessage.value);
    formData.append('id_usuario', this.auth.getIdUser());
    this.http.post(`${this.PHP_API_SERVER}/api/deleteDocument.php`, formData).subscribe(
      res => {
        if(res == 200){
          this.toastr.success('Archivos eliminado correctamente', 'Cargando ...');
        }else{
          this.toastr.error('Error al eliminar los archivos', 'Cargando ...');
        }
    })
  }

  

}
