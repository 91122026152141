<section style="position: absolute;width: 100%;z-index: 1;" class="image-container" style="background-image: url(assets/img/wizard/wizard-2.jpg);">
    <div class="container" style="padding: 0;">
        <div class="board" style="width: 100% !important;">
            <button type="button" style="z-index:100;float: right;" class="btn btn-dark"  (click) = "hide();">X</button> 

            <!-- Navigation Area (Circular Tabs) -->
            <msw-navbar></msw-navbar>
            <!-- End Navigation Area (Circular Tabs) -->

            <!-- Content Area -->
            <div class="tab-content">
                <form #denuncianteForm="ngForm" class="editForm" novalidate>
                    <div class="tab-pane fade in active">
                        <h4 class="head text-center">{{title | translate }}</h4>
                        <div class='row'>
                            <div class="grid-container">
                                <div class="relacion">
                                    <div class="form-group">
                                        <label class="control-label" for="relacion">{{ 'texto_342' | translate }}*   </label>  
                                        <mat-form-field style="margin-left: 10px;">
                                            <mat-select id="denuncianteRelacion" name="denuncianteRelacion" [(ngModel)]="relacion.denuncianteRelacion" required>
                                            <mat-option *ngFor="let tipo of tipoDenunciantes" [value]="tipo.id_tp_relacion">
                                                {{tipo.nombre}}
                                            </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>  
                                </div>                                  
                                <div class="datos-denunciante"  [hidden]="IsAnonimo">
                                  <div class="row">
                                      <div class='col-xs-12 col-sm-6'>
                                          <div class="form-group">
                                              <label class="control-label" for="denuncianteFirstName">{{ 'texto_35' | translate }}</label>  
                                              <input class="form-control input-md" #denuncianteFirstName="ngModel" id="denuncianteFirstName" name="denuncianteFirstName" type="text" placeholder="{{ 'texto_35' | translate }}" [(ngModel)]="relacion.denuncianteFirstName">   
                                          </div>
                                      </div>
                                      <div class='col-xs-12 col-sm-6'>
                                          <div class="form-group">
                                              <label class="control-label" for="denuncianteLastName">{{ 'texto_36' | translate }}</label>  
                                              <input class="form-control input-md" #denuncianteLastName="ngModel" id="denuncianteLastName" name="denuncianteLastName" type="text" placeholder="{{ 'texto_36' | translate }}" [(ngModel)]="relacion.denuncianteLastName">
                                          </div>
                                      </div>
                                  </div>                                 
                                  <div class="row">
                                      <div class='col-xs-12 col-sm-6'>
                                          <div class="form-group">
                                              <label class="control-label" for="denuncianteEmail">Email</label>
                                              <input class="form-control input-md" #denuncianteEmail="ngModel" pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$" id="denuncianteEmail" name="denuncianteEmail" type="text" placeholder="Email" [(ngModel)]="relacion.denuncianteEmail">
                                              <div *ngIf="denuncianteEmail.invalid && (denuncianteEmail.dirty || denuncianteEmail.touched)" class="alert alert-danger">
                                                  <div style="font-size:12px">
                                                      {{ 'texto_106' | translate }}
                                                  </div>                                            
                                              </div>
                                          </div>
                                      </div>
                                      <div class='col-xs-12 col-sm-6'>
                                          <div class="form-group">
                                              <label class="control-label" for="denunciantePhone">{{ 'texto_66' | translate }}</label>
                                              <input class="form-control input-md" #denunciantePhone="ngModel" id="denunciantePhone" name="denunciantePhone"  maxlength="15" pattern="([0-9]{9})|([0-9]{13})|([0-9]{15})|([0-9]{10})" type="text" placeholder="{{ 'texto_66' | translate }}" [(ngModel)]="relacion.denunciantePhone">
                                              <div *ngIf="denunciantePhone.invalid && (denunciantePhone.dirty || denunciantePhone.touched)" class="alert alert-danger">
                                                  <div style="font-size:12px">
                                                      {{ 'texto_105' | translate }}
                                                  </div>                                            
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="row">
                                      <div class='col-xs-12 col-sm-6'>
                                          <label class="control-label" for="denuncianteDNI">{{ 'texto_83' | translate }}</label>
                                          <input class="form-control input-md" #denuncianteDNI="ngModel" maxlength="16" pattern="(([X-Z]{1})([-]?)(\d{7})([-]?)([A-Z]{1}))|((\d{8})([-]?)([A-Z]{1}))|((\d{9}))|(([A-Z]{6})(\d{2})([A-Z]{1})(\d{2})([A-Z]{1})(\d{3})([A-Z]{1}))|([0-9]{13})" id="denuncianteDNI" name="denuncianteDNI" type="text" placeholder="{{ 'texto_83' | translate }}" [(ngModel)]="relacion.denuncianteDNI">
                                          <div *ngIf="denuncianteDNI.invalid && (denuncianteDNI.dirty || denuncianteDNI.touched)" class="alert alert-danger">
                                              <div style="font-size:12px">
                                                  {{ 'texto_446' | translate }}
                                              </div>                                            
                                          </div>
                                      </div>
                                  </div>  
                                </div>
                                <div class="comentario" [hidden]="IsHidden">
                                    <div style="font-size: 12px;line-height: 16px;border: 0px solid black;margin-top: 10%;padding: 10px;margin-left: 56px;text-align: justify;">
                                        <p>{{ 'texto_339' | translate }}</p>
                                        <p>{{ 'texto_340' | translate }}</p>
                                    </div>  
                                  </div>                                 
                                <div class="botones">
                                  <div class="form-group text-center" style="margin-top: 30px;">
                                      <button class="btn btn-outline-rounded btn-default" (click)="goToPrevious(denuncianteForm)"> <span style="margin-right:10px;" class="glyphicon glyphicon-arrow-left"></span> {{ 'texto_142' | translate }}</button>
                                      <button class="btn btn-success btn-outline-rounded btn-info" [disabled]="!denuncianteForm.valid && !IsAnonimo" (click)="goToNext(denuncianteForm)"> {{ 'texto_141' | translate }} <span style="margin-left:10px;" class="glyphicon glyphicon-arrow-right"></span></button>
                                  </div>  
                                </div>                              
                              </div>
                        </div>
                        <div class='row'>
                            <div class='col-xs-12  col-sm-12' style="font-size: 10px;line-height: normal;text-align: justify;">
                                <p>A los efectos previstos en la vigente normativa sobre protección de datos personales, le informamos de que los datos aportados por Vd. serán tratados bajo la responsabilidad de Metro de Madrid, S.A, con la finalidad de gestionar las consultas o denuncias comunicadas vía Buzón de Denuncias de Metro de Madrid conforme a lo dispuesto en el protocolo de funcionamiento de dicho buzón y en la normativa vigente al respecto.</p>
                                <p>El tratamiento es necesario para el cumplimiento de obligaciones basadas en la relación contractual de Metro de Madrid con sus empleados, así como para la satisfacción de un interés público, e interés legítimo, de prevenir y actuar frente a infracciones de la legislación aplicable, así como prevenir acciones que contravengan las políticas internas de la Sociedad.</p>
                                <p>No se prevén cesiones de datos, salvo potencialmente a autoridades judiciales, Ministerio Fiscal, y Fuerzas y Cuerpos de Seguridad del Estado (FCSE), y personas o entidades a los que se deba comunicar la información para la exigencia de responsabilidades, en los casos en los que proceda.</p>
                                <p>Puede ejercitar, si lo desea, los derechos contemplados en la normativa vigente que en su caso pudieran corresponder. Dispone de información adicional en el documento “Buzón de Denuncias” accesible aquí:</p>
                                <p><a href="https://www.metromadrid.es/es/contacto/proteccion-de-datos-personales/" target="_blank">https://www.metromadrid.es/es/contacto/proteccion-de-datos-personales</a></p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>