import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from '../model/datosDenuncia';
import { environment } from "../../environments/environment"
import { Historico } from '../model/historico';
import { Usuarios } from '../model/usuarios';
import { DatePipe } from '@angular/common';
import { PlazosDenuncia } from '../model/plazosDenuncia';

@Component({
  selector: 'app-enviar-resolucion',
  templateUrl: './enviar-resolucion.component.html',
  styleUrls: ['./enviar-resolucion.component.css'],
  providers:[DatePipe]
})
export class EnviarResolucionComponent implements OnInit {

  myForm: FormGroup;
  @Input() denuncia: DatosDenuncia;
  textoInforme: string;
  historico: Historico[];
  compliance: Usuarios[];
  contador = 0
  plazos: PlazosDenuncia[];

  constructor(private apiService: ApiService,private router: Router, private auth: AuthService, private toastr: ToastrService, private translate: TranslateService, private http: HttpClient, private datepipe: DatePipe) { }

  ngOnInit(): void {
    this.myForm = new FormGroup({
      textoInforme: new FormControl('', []),
    });
    this.textoInforme = this.denuncia[0].informe_decisor;
  }

  get texto() {
    return this.myForm.get('textoInforme');
  }

  onKey(event){
    var datos = document.getElementById("textoResolucion") ;
    for(var i=0; i< 1; i++){
      var div = datos.getElementsByClassName('nw-editor__res')[i];
      this.contador = div.textContent.length
    }
  }

  enviarInforme(){
    if(this.textoInforme != ""){
      if(this.contador>4000){
        this.toastr.error(this.translate.instant('texto_266'), this.translate.instant('texto_243'));
      }else{
        let roleUsuario = 'COMPLIANCE';
        let idFase = '6';
        let idUser = '5';
        if(this.denuncia[0].id_fase == 4){
          roleUsuario = 'DECISOR';
          idFase = '5';
          idUser = '4';
        }
        this.apiService.sendReportByIdDenuncia(this.denuncia[0].id_denuncia,this.textoInforme,roleUsuario, this.auth.getIdUser()).subscribe(
          res => {
            if(res == 200){
              this.apiService.updateComplaintById(this.denuncia[0].id_denuncia, idFase, this.auth.getSociedad(), this.auth.getIdUser(),"").subscribe(
                res => {
                  if(res == 200){
                    this.apiService.searchUserByIdSocietyRole(this.auth.getSociedad(),idUser,true,"nulo").subscribe((usuarios: Usuarios[])=>{
                      this.compliance = usuarios;
                      this.apiService.disableUserByIdComplaint(this.denuncia[0].id_denuncia, this.auth.getIdUser()).subscribe(
                        res => {
                          if(res == 200){
                            this.apiService.createUserByIdComplaint(this.auth.getSociedad(), this.compliance[0].id_user, this.denuncia[0].id_denuncia, '3', '1',this.auth.getIdUser(), '4', false).subscribe(
                              res => {
                                if(res == 200){
                                  let url = environment.base + "/#/consultar-denuncia";
                                  let message = "";
                                
                                  let $clau = false;
                                  let clausulas_correo = localStorage.getItem("clausulas_correo");
                                  if(clausulas_correo == '1'){
                                    $clau = true;
                                  }  

                                  if(idFase == '5'){
                                    this.apiService.searchInvestigatorByIdDenuncia(this.denuncia[0].id_denuncia, '1').subscribe((investigator: PlazosDenuncia[])=>{
                                      this.plazos = investigator;
                                      let fecha = new Date(); // ó new Date(valor);
                                      let plazo = this.plazos[0].plazo_compliance;
                                      fecha.setDate(fecha.getDate() + parseInt(plazo));
                                      let _date =this.datepipe.transform(fecha, 'dd-MM-yyyy');

                                      let messageC = "Estimado/a "  +  this.compliance[0].id_user  + ", le comunicamos que ha sido asignado a la denuncia "  + this.denuncia[0].id_denuncia  + " con el rol de compliance. Se calcula que la fase actual estará completada para el día "+_date;
                                      let urlC = environment.base + "/#/editar-denuncia/"+this.denuncia[0].id_denuncia;

                                      this.apiService.sendEmail(this.compliance[0].mail_user,'Asignación de denuncia como compliance '+this.denuncia[0].id_denuncia, messageC, urlC,$clau, "Asignación de denuncia como compliance").subscribe(
                                        res => {
                                          if(res == 200){
                                          //  this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                                          }else{
                                            this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                                          }         
                                      });
                                    });                                         
                                  }

                                  if(this.denuncia[0].denunciante_correo != ""){
                                    this.apiService.searchInvestigatorByIdDenuncia(this.denuncia[0].id_denuncia, '1').subscribe((investigator: PlazosDenuncia[])=>{
                                      this.plazos = investigator;
                                      let fecha = new Date(); // ó new Date(valor);
                                      url = environment.base + "/#/consultar-denuncia";
                                      if(idFase == '5'){
                                        let plazo = this.plazos[0].plazo_compliance;
                                        fecha.setDate(fecha.getDate() + parseInt(plazo));
                                        let _date =this.datepipe.transform(fecha, 'dd-MM-yyyy');
                                        message = "Estimado/a "  +  this.denuncia[0].denunciante_nombre + " " + this.denuncia[0].denunciante_apellidos + ", le comunicamos que su denuncia con id "  + this.denuncia[0].id_denuncia  + " ha avanzado a la fase de compliance. Se calcula que la fase actual estará completada para el día "+_date;
                                      }else{
                                        let plazo = this.plazos[0].plazo_decisor;
                                        fecha.setDate(fecha.getDate() + parseInt(plazo));
                                        let _date =this.datepipe.transform(fecha, 'dd-MM-yyyy');
                                        message = "Estimado/a "  +  this.denuncia[0].denunciante_nombre + " " + this.denuncia[0].denunciante_apellidos + ", le comunicamos que su denuncia con id "  + this.denuncia[0].id_denuncia  + " ha avanzado a la fase de compliance. Se calcula que la fase actual estará completada para el día "+_date;
                                      }

                                      this.apiService.sendEmail(this.denuncia[0].denunciante_correo,'Cambio de fase', message,url,$clau, "Cambio de fase").subscribe(
                                        res => {
                                          if(res == 200){
                                          //  this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                                          }else{
                                            this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                                          }         
                                      });
                                    });
                                  }

                                  this.toastr.success(this.translate.instant('texto_267'), this.translate.instant('texto_268'));
                                  let role = this.auth.getRole();
                                  if((role.includes("COMPLIANCE") && idFase=='5') || (role.includes("COMPLIANCE") && idFase=='6')){
                                    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                                      this.router.navigate(['editar-denuncia/',this.denuncia[0].id_denuncia]);
                                    }); 
                                  }else{
                                    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                                        this.router.navigate(['denuncias']);
                                    }); 
                                  }

                                }else{
                                  this.toastr.error(this.translate.instant('texto_269'), this.translate.instant('texto_268'));
                                }
                              });
                          }else{
                            this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                          }
                      });
                      
                    });
                  }else{
                    this.toastr.error(this.translate.instant('texto_196'), this.translate.instant('texto_192'));
                  }
                });
            }else{
              this.toastr.error(this.translate.instant('texto_269'), this.translate.instant('texto_268'));
            }
          });
        }
    }else{
      this.toastr.error(this.translate.instant('texto_272'), this.translate.instant('texto_268'));
    }
    
  }

}
