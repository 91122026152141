
<div [hidden]="!IsHiddenLanding">
    <app-nav-fixed [IsHiddenLanding]="IsHiddenLanding"></app-nav-fixed>
</div>

<header [hidden]="IsHiddenLanding">
    <div class="" style="z-index:90 !important;background-color:white;">
        <nav style="z-index:90 !important;padding-left: 5% !important;padding-right: 5% !important;" class="navbar navbar-expand-lg navbar-dark bg-transparent px-0"><img src="assets/img/logos/logo.png" width="160px" style="margin-left: -10px;">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#da-navbarNav" aria-controls="da-navbarNav" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
        <div class="collapse navbar-collapse text-uppercase" id="da-navbarNav">
            <ul class="navbar-nav ml-auto">
            <li class="nav-item"><a class="nav-link smooth-scroll" style="color: #eb0028!important;" href="#">{{ 'texto_1' | translate }}</a></li>
            <li class="nav-item"><a class="nav-link smooth-scroll" style="color: #eb0028!important;" href="#services">{{ 'texto_2' | translate }}</a></li>
            <li class="nav-item"><a class="nav-link smooth-scroll" style="color: #eb0028!important;" href="#denuncia">{{ 'texto_3' | translate }}</a></li>
            <li class="nav-item"><a class="nav-link smooth-scroll" style="color: #eb0028!important;" href="#support">{{ 'texto_4' | translate }}</a></li>
            </ul>
        </div>

        <app-login></app-login>
        <app-translation></app-translation>
        
        </nav>
    </div>
    <div class="da-home-page-text" data-aos="fade-right" data-aos-duration="1000">
        <div class="container">
            <div class="col-md-10 col-sm-12 px-0 mx-0" style="margin-top: -100px;">
                <h3 class="h5 mt-3" style="color:var(--color-txt-titulo);font-weight: bold;">{{ 'texto_5' | translate }}</h3>
                <h2 class="display-3" style="margin-left:-6px;color:var(--color-txt-titulo);font-weight: bold;">{{ 'texto_6' | translate }}</h2>
                <a class="smooth-scroll btn mt-4"  style="background-color:var(--main-color-bg);color:var(--color-txt-btn);font-weight: bold;" (click)="isOn(false)" routerLink="/tipo" >{{ 'texto_7' | translate }}</a>
                <a class="smooth-scroll btn mt-4" style="background-color:var(--secondary-color-bg);color:var(--color-txt-btn);font-weight: bold;" (click)="isOn(false)" routerLink="/consultar-denuncia" >{{ 'texto_8' | translate }}</a>
            </div>
        </div>
    </div>
</header>

<div [hidden]="IsHiddenLanding">
    <app-informacion-principal></app-informacion-principal>
    <section [hidden]="IsHidden" style="top: 24px;position: absolute;width: 100%;width: 75%;z-index: 1;right: 9%;">
        <div class="container">
            <div class="board" style="width: 100% !important;">
                <!-- Navigation Area (Circular Tabs) -->
                <msw-navbar></msw-navbar>
                <!-- End Navigation Area (Circular Tabs) -->
    
                <!-- Content Area -->
                <div class="tab-content">
                    <!-- Routed view  -->
                    <button type="button" style="z-index:100;float: right;margin-top: -46px;" class="btn btn-dark" data-dismiss="modal" (click) = "hideModal()">X</button> 
                        <router-outlet></router-outlet>
                    
                </div>
                <!-- End Content Area -->
            </div>
    
            <!-- For Debugging: show our formData as it is being typed -->
            
        </div>
    </section>
    
</div>





