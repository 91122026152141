import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ApiService } from '../api.service';
import { Users } from '../model/users';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  users:  Users;
  email: string;
  password: string;
  showModal: boolean = false;
  loginOk: boolean = false;
  loginOkBlock: boolean = false;
  loginNoUser: boolean = false;
  constructor(private apiService: ApiService,private router: Router, private auth: AuthService, private toastr: ToastrService, private translate: TranslateService) { }

  validatingForm: FormGroup;

  ngOnInit() {

  /*  if(window.history.state.navigationId == 3){
      window.location.reload();
    }*/
    this.validatingForm = new FormGroup({
      loginFormModalEmail: new FormControl('', [
        Validators.email,
        Validators.required,
      ]),
      loginFormModalPassword: new FormControl('', [Validators.required])
    });
  }

  
  mostrarLogin(){
    this.showModal= !this.showModal;
  }

  login(){
    this.apiService.login(this.loginFormModalEmail.value, this.loginFormModalPassword.value).subscribe(
      res => {
        if(res[0]){
          console.log("ENTRO"+res[0]); 
          this.auth.sendToken(res[0]["jwtToken"],res[0]["id_user"],res[0]["n_user"],res[0]["ape_user"],res[0]["mail_user"],res[0]["foto"]);
          this.router.navigate(["admin"]);
          location.reload();
          this.loginOk = false;
        }else{
          this.loginOk = true;
        }
        console.log(this.loginOk);
    },
    (err:any) =>{
      if(err === 'Locked'){
        this.loginOkBlock = true;
        this.loginOk = false;
        this.toastr.error(this.translate.instant('texto_251'), this.translate.instant('texto_252'));
      }else if (err === 'Not Found'){
        this.loginOk = false;
        this.loginNoUser = true;
        this.toastr.error(this.translate.instant('texto_534'), this.translate.instant('texto_252'));
      }else{
        this.loginOkBlock = true;
      }
      

    }); 
  }
  get loginFormModalEmail() {
    return this.validatingForm.get('loginFormModalEmail');
  }

  get loginFormModalPassword() {
    return this.validatingForm.get('loginFormModalPassword');
  }

}
