import { Component, OnInit, ViewChild } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.css']
})
export class UpdatePasswordComponent implements OnInit {
  validatingForm: FormGroup;
  urlPhoto: string;
  email: string;
  name: string;
  username: string;
  condition= true;

  constructor(private apiService: ApiService, private auth: AuthService, private router: Router, private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit() {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.condition = true;
    this.username = this.auth.getIdUser();
    this.urlPhoto = this.auth.getPhotoUser();
    this.name = this.auth.getNameUser() + " " + this.auth.getSurnameUser();
    this.validatingForm = new FormGroup({
      oldPassword: new FormControl('', Validators.required),
      password1: new FormControl('', Validators.required),
      password2: new FormControl('', Validators.required)
    });
  }

  get oldPassword() {
    return this.validatingForm.get('oldPassword');
  }

  get password1() {
    return this.validatingForm.get('password1');
  }

  get password2() {
    return this.validatingForm.get('password2');
  }

  updatePassword(){
    if(this.password1.value == this.password2.value){
      this.apiService.updatePasswordUser(this.auth.getIdUser(), this.password1.value, this.oldPassword.value,false).subscribe(
        res => {
          if(res == 200){
            this.toastr.success(this.translate.instant('texto_199'), this.translate.instant('texto_216'));
            this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
              this.router.navigate(['profile']);
            }); 
          }else{
            this.toastr.error(this.translate.instant('texto_221'), this.translate.instant('texto_216'));
          }
         // this.condition = false;
      },
      err =>{
        this.toastr.error(this.translate.instant('texto_215'), this.translate.instant('texto_216'));
      });   
    }else{
      this.toastr.error(this.translate.instant('texto_223'), this.translate.instant('texto_216'));
    }
  }
}
