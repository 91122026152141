export  class  DatosDenunciasExcel {
    id_denuncia: string;
    fec_inicio: string;
    fecha_incidente: string;
    denunciante_correo: string;
    id_tp_relacion: string;
    id_tp_denuncia: string;
    denuncia_desc: string;
    descripcion_tipo_denuncia: string;
    descripcion_relacion: string;
    n_fase: string;
}