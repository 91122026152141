import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { TipoHecho } from '../data/formData.model';
import { Router }              from '@angular/router';
import { ApiService } from '../api.service';
import { FormDataService }     from '../data/formData.service';
import { Empresa } from '../model/empresa';
import { Denuncia } from '../model/denuncia';
import { AuthService } from '../auth.service';
import { OnExecuteData, ReCaptchaV3Service } from 'ng-recaptcha';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tipo',
  templateUrl: './tipo.component.html',
  styleUrls: ['./tipo.component.css']
})

export class TipoComponent implements OnInit {
  title = 'texto_338';
  tipoHecho: TipoHecho;
  form: any;
  empresas: Empresa[];
  denuncias: Denuncia[];
  selectedCompany: string;
  selectedComplaint: string;
  descripcion:string = "";
  reactiveForm: FormGroup;
  private subscription: Subscription;
  @ViewChild('recaptcha', {static: true }) recaptchaElement: ElementRef;

 constructor(private translate: TranslateService, private apiService: ApiService, private router: Router, private formDataService: FormDataService, private auth: AuthService, private recaptchaV3Service: ReCaptchaV3Service) {
 }

  ngOnInit() {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);

    console.log(language);
   /* const body = <HTMLDivElement> document.body;
    var script =document.createElement("script");
    script.async = false;
    script.defer = false;
    script.type = "text/javascript";
    script.innerHTML = "grecaptcha.ready(function () { grecaptcha.execute('6LfoxJcUAAAAAFKjEJ-hBoJ_SpdWdFoG0kNhHpym', { action: 'contact' }).then(function (token) { var recaptchaResponse = document.getElementById('recaptchaResponse'); recaptchaResponse.value = token;});});"; 
    script.src = "https://www.google.com/recaptcha/api.js?render=6LfoxJcUAAAAAFKjEJ-hBoJ_SpdWdFoG0kNhHpym"; //external script
    body.appendChild(script);*/
    this.addRecaptchaScript();
    var iframe = document.getElementsByTagName('iframe')[1];
    if (typeof iframe !== 'undefined') {
      iframe.style.display="block";  
    }
    /*this.subscription = this.recaptchaV3Service.onExecute
      .subscribe((data: OnExecuteData) => {
        console.log("DATA: ", data);
      });*/

    this.apiService.searchCompanies().subscribe((empresa: Empresa[])=>{
      this.empresas = empresa;
     
    });

    this.tipoHecho = this.formDataService.getTipoHecho();
    if(this.tipoHecho.tipoDenuncia != ""){
      this.apiService.searchComplaintByUserSocietyID('',this.tipoHecho.empresa, this.tipoHecho.tipoDenuncia,false,language).subscribe((denuncia: Denuncia[])=>{
        this.denuncias = denuncia;
        this.descripcion = denuncia[0].descripcion;
      });
      //this.descripcion = this.denuncias[tipoDenuncia-1].descripcion;
    }

  }
  onClick(event) {
    this.descripcion = "";
    this.apiService.searchCompanies().subscribe((empresa: Empresa[])=>{
      this.empresas = empresa;
      this.tipoHecho.tipoDenuncia = ""; 
    });
  }
  onChange(event) {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);

    let selectedCompany = event.value.id_empresa;
    let idEmpresa = event.value.id_empresa;
    let idSociedad = event.value.id_sociedad;
    this.descripcion = "";
    
    /*this.apiService.searchCompanyById(selectedCompany,idSociedad).subscribe((empresas: Empresa[])=>{
      this.empresas = empresas;*/
      
     // idEmpresa = this.empresas[0].id_empresa;
      //idSociedad = this.empresas[0].id_sociedad;
      this.tipoHecho.empresa = idSociedad;
      this.tipoHecho.idEmpresa = selectedCompany;
      this.tipoHecho.identificadorEmpresa = idEmpresa;
      this.apiService.searchTypeComplaint(idSociedad,false,language).subscribe((denuncia: Denuncia[])=>{
          this.denuncias = denuncia;
          if(this.tipoHecho.tipoDenuncia == ""){
            this.descripcion = "";
          }else{
           // this.tipoHecho.tipoDenuncia = denuncia[0].id_tp_denuncia;
            this.descripcion = denuncia[0].descripcion;
          } 
      });
 //   });
  }

  onChangeComplaint(event) {
    this.descripcion = "";
    let selectedComplaint = event.value;
    for(var i=0; i<this.denuncias.length; i++){
      if(this.denuncias[i].id_tp_denuncia == selectedComplaint){
        this.descripcion = this.denuncias[i].descripcion;
        break;
      }
    }
    this.formDataService.setReceptorInicialVacio();
    this.formDataService.setReceptorVacio();
  }

  onClickComplaint(event) {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    if(this.tipoHecho.empresa != ""){
      this.apiService.searchTypeComplaint(this.tipoHecho.empresa,false,language).subscribe((denuncia: Denuncia[])=>{
        this.denuncias = denuncia;
    });
    }
    
  }

  save(form: any): boolean {
      if (!form.valid) {
          return false;
      }
          
      this.formDataService.setTipoHecho(this.tipoHecho);
      return true;
  }

  goToNext(form: any) {
      if (this.save(form)) {
        //this.preSubmitForm()
          // Navigate to the work page
          this.router.navigate(['/denunciante']);
      }
  }

  hide(){
    this.auth.logout();
    window.location.reload();
    /*this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/inicio']);
    });*/
  }

  /*public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public preSubmitForm(): void {
    this.recaptchaV3Service.execute('importantAction')
      .subscribe((token) => { 
        console.log("What do I do with this?: ", token)
      });
  }*/


  addRecaptchaScript() {
 
    window['grecaptchaCallback'] = () => {
   
    }
   
    (function(d, s, id, obj){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));
   
  }


  renderReCaptcha() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      'sitekey' : '6Ldku8sZAAAAANZFlrofaO_GHQQbxH99Y2nT_D-C',
      'callback': (response) => {
          console.log(response);
      }
    });
  }
}
