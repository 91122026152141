import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { Abogado } from '../model/abogado';
import { Denuncia } from '../model/denuncia';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-abogado',
  templateUrl: './abogado.component.html',
  styleUrls: ['./abogado.component.css']
})
export class AbogadoComponent implements OnInit {
  validatingForm: FormGroup;
  
  sociedad: string;
  abogados: Abogado[];
  denuncias: Denuncia[];
  nombreDenuncia = new Array();
  constructor(private apiService: ApiService,private router: Router, private auth: AuthService, private toastr: ToastrService, private translate: TranslateService) { }
  IsHiddenForm: boolean =  false;

  id_abogado: string;
  n_abogado: string;
  email:string
  selected: string;
  comentario: string;

  ngOnInit() {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);

    this.validatingForm = new FormGroup({
      signupFormModalName: new FormControl(''),
      signupFormModalId: new FormControl('', []),
      contactFormModalMessage: new FormControl('', []),
      signupFormModalEmail: new FormControl('', [
        Validators.email,
        Validators.required,
      ]),
   //   selectType: new FormControl('', []),
    });

    this.sociedad = this.auth.getNameSociedad();
    this.apiService.searchLayerByUserSociety(this.auth.getIdUser(),this.auth.getSociedad()).subscribe((abogado: Abogado[])=>{
      this.abogados = abogado;
      for (let i = 0; i < this.abogados.length; i++) {
          this.apiService.searchComplaintByUserSocietyID(this.auth.getIdUser(),this.auth.getSociedad(),this.abogados[i].id_tp_denuncia,true,language).subscribe((denuncia: Denuncia[])=>{
            this.denuncias = denuncia;
            this.nombreDenuncia.push(this.denuncias[0].nombre)
          });
      };
    });
  }

  get signupFormModalName() {
    return this.validatingForm.get('signupFormModalName');
  }

  get signupFormModalId() {
    return this.validatingForm.get('signupFormModalId');
  }

  get contactFormModalMessage() {
    return this.validatingForm.get('contactFormModalMessage');
  }

  get signupFormModalEmail() {
    return this.validatingForm.get('signupFormModalEmail');
  }

  get selectType() {
    return this.validatingForm.get('selectType');
  }


  showLayer(layer:Abogado){
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.IsHiddenForm = true;
  
    this.apiService.searchComplaintByUserSociety(this.auth.getIdUser(),this.auth.getSociedad(),language).subscribe((denuncia: Denuncia[])=>{
      this.denuncias = denuncia;
    });

    this.id_abogado = layer.id_abogado;
    this.n_abogado = layer.n_abogado;
    this.email = layer.email;
    this.selected = layer.id_tp_denuncia;
    this.comentario = layer.comentario;
  }

  deleteLayer(id_abogado:string){
    this.apiService.countComplaintByIdAbogados(this.auth.getSociedad(), id_abogado).subscribe(
      res => {
        if(res == 200){
          this.apiService.deleteLayerByUser(this.auth.getIdUser(),this.auth.getSociedad(),id_abogado).subscribe(
            res => {
              if(res == 200){
                this.toastr.success(this.translate.instant('texto_48'), this.translate.instant('texto_50'));
              }else{
                this.toastr.error(this.translate.instant('texto_49'), this.translate.instant('texto_50'));
              }
              this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                this.router.navigate(['abogado']);
            }); 
          });
        }else{
          this.toastr.error(this.translate.instant('texto_49'), this.translate.instant('texto_50'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['abogado']);
        }); 
    },
    err =>{
      this.toastr.error(this.translate.instant('texto_49'), this.translate.instant('texto_50'));
    }); 

    
  }

  hideModal(){
    this.IsHiddenForm = false;
  }

  updateLayer(id_abogado:string){
    let nombreAbogado = this.signupFormModalName.value;
    if(this.signupFormModalName.value == ""){
      nombreAbogado = this.n_abogado;
    }

    this.apiService.updateLayerByUser(this.auth.getSociedad(), id_abogado,/* this.selectType.value*/ '', nombreAbogado, this.contactFormModalMessage.value, this.signupFormModalEmail.value, this.auth.getIdUser()).subscribe(
      res => {
        if(res == 200){
          this.toastr.success(this.translate.instant('texto_52'), this.translate.instant('texto_51'));
        }else{
          this.toastr.error(this.translate.instant('texto_53'), this.translate.instant('texto_51'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['abogado']);
        }); 
    },
    err =>{
      this.toastr.error(this.translate.instant('texto_49'), this.translate.instant('texto_51'));
    }); 
  }


}
