import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { Sociedades } from '../model/sociedades';
import { OptionSesion } from '../model/optionSesion';
import { TranslateService } from '@ngx-translate/core';
import { Idiomas } from '../model/idiomas';

@Component({
  selector: 'app-option-user',
  templateUrl: './option-user.component.html',
  styleUrls: ['./option-user.component.css']
})
export class OptionUserComponent implements OnInit {
  sociedad: string;
  sociedades: Sociedades[];
  option: OptionSesion[];
  idiomas: Idiomas[]; 
  
  constructor(private apiService: ApiService,private router: Router, private auth: AuthService, private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit() {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.sociedad = this.auth.getNameSociedad();
    this.apiService.getSessionPropertiesUser(this.auth.getIdUser()).subscribe((option: OptionSesion[])=>{
      this.option = option;
        });
    this.apiService.searchIdiomas().subscribe((idiomas: Idiomas[])=>{
          this.idiomas = idiomas;
        });
    this.apiService.getSocietiesByIdUser(this.auth.getIdUser()).subscribe((sociedades: Sociedades[])=>{
      this.sociedades = sociedades;
    });
  }

  updateSessionProperties(society:string, n_sociedad: string){
    this.apiService.updateSessionPropertySociety(this.auth.getIdUser(),society).subscribe(
      res => {
        if(res == 200){
          this.toastr.success(this.translate.instant('texto_199'), this.translate.instant('texto_241'));
          this.auth.setSociedad(society);
          this.auth.setNameSociedad(n_sociedad);
          window.location.reload();

        }else{
          this.toastr.error(this.translate.instant('texto_200'), this.translate.instant('texto_241'));
        }

        /*this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {

          this.router.navigate(['options']);
        }); */
    });
  }

  updateLanguageProperties(idioma:string){
    this.apiService.updateSessionPropertyLanguage(this.auth.getIdUser(),idioma).subscribe(
      res => {
        if(res == 200){
          this.toastr.success(this.translate.instant('texto_199'), this.translate.instant('texto_241'));
          this.auth.setLanguage(idioma);
        }else{
          this.toastr.error(this.translate.instant('texto_200'), this.translate.instant('texto_241'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['options']);
        }); 
    });
  }

}
