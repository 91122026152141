<div class="container" id="contenedor" #contenedor>
    <div class="navbar-header">
        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
      </div>

    <div class="col-md-12 container-fluid" style="margin-top: 0px;">
        <div class="panel" >                            
            <div class="panel-body">
                <!--<h3>{{ 'texto_123' | translate }} <span *ngIf="denuncia">{{sociedadUsuario}}</span></h3>-->
                <div class="col-md-4 container-fluid" id="nombreexp">
                    <span class="resalta2">{{ 'texto_76' | translate }}: </span><span *ngIf="denuncia" class="resalta2"> {{denuncia[0].id_denuncia}}</span>
                </div>
                <div id="botonera" class="col-md-7 container-fluid">
                    <app-asignar-receptor [hidden]="!isFaseExterno" [denuncia]="denuncia"></app-asignar-receptor>
                    <app-anadir-archivo-receptor [hidden]="!isFaseExterno" [denuncia]="denuncia"></app-anadir-archivo-receptor >
                    <app-enviar-receptor [hidden]="!isFaseExterno" [denuncia]="denuncia"></app-enviar-receptor>
                    <app-validar-denuncia [hidden]="!isFaseInicial" [denuncia]="denuncia"></app-validar-denuncia>
                    <app-desestimar-denuncia [hidden]="!isFaseInicial" [denuncia]="denuncia"></app-desestimar-denuncia>
                    <app-add-investigator [hidden]="!isFaseInvestigador" [denuncia]="denuncia"></app-add-investigator>
                    <app-asignar-medida-cautelar [hidden]="!isFaseInvestigador" [denuncia]="denuncia"></app-asignar-medida-cautelar>
                    <app-enviar-denuncia-investigador [hidden]="!isFaseInvestigador" [denuncia]="denuncia"></app-enviar-denuncia-investigador>
                    <app-add-witness-admin [hidden]="!isInvestigador" [denuncia]="denuncia"></app-add-witness-admin>
                    <app-anadir-archivo [hidden]="!showDocument" [denuncia]="denuncia"></app-anadir-archivo >
                    <app-enviar-informe [hidden]="!isInvestigador" [denuncia]="denuncia"></app-enviar-informe>
                    <app-asignar-decisor [hidden]="!isFaseDecisor" [denuncia]="denuncia"></app-asignar-decisor>
                    <app-enviar-resolucion [hidden]="!isDecisor" [denuncia]="denuncia"></app-enviar-resolucion>
                    <app-anadir-accion [hidden]="!isFaseCompliance" [denuncia]="denuncia"></app-anadir-accion>
                    <app-enviar-informe-denunciante [hidden]="!isFaseCompliance" [denuncia]="denuncia"></app-enviar-informe-denunciante>
                    <app-archivar [hidden]="!bExisteAccion" [denuncia]="denuncia"></app-archivar>

                    <button  matTooltip="{{'texto_173' | translate }}" style="background-image: url(/../../assets/img/botones/Boton_Descargar\ PDF.png);float:right;"  class="btn btn-cabecera" (click)="exportarDocumento()"></button>
                </div>		
            </div>
        </div>		
    </div>

    <div id="txtfase" class="col-md-12" style="width: 96%;margin-left: 16px;margin-top: 16px;height: 37px;">
        <div id="txtfase" class="col-md-12">
            <span class="resalta2">{{'texto_77' | translate }}: </span><span id="txtfase" class="resalta2">{{denuncia[0].n_fase | translate }}</span>
        </div>				
    </div>

    <div class="col-md-12 progress" style="width: 96%;padding: 0px;height: 37px;border-radius: 4px;">
        <div id="progressbar" class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style="width: {{progressBar}}%;background-color:{{backgroundColor}}" aria-valuenow="{{progressBar}}" aria-valuemin="0" aria-valuemax="100"></div>

    </div>

    <div class="container row" id="content" style=" margin-top: 20px; width: 100%;">
        <article id="main" role="main" class="col-md-12">
            <div class="col-md-6">
                <div class="panel panel-primary" style="min-height:250px;border-color: #000;">
                    <div class="panel-heading" style="background-color: #000;">
                        <h5 class="panel-title">{{ 'texto_78' | translate }} </h5>
                    </div>
                    <div class="panel-body"><span class="resalta">{{ 'texto_79' | translate }}:</span><span> {{denuncia[0].descripcion_relacion}}</span></div>
                    <div *ngIf="idrole != '1' && idrole != '2'" [hidden]="denuncia[0].denunciante_nombre ==''" class="panel-body"><span class="resalta">{{ 'texto_80' | translate }}:</span><span> {{denuncia[0].denunciante_nombre}}</span></div>
                    <div *ngIf="idrole != '1' && idrole != '2'" [hidden]="denuncia[0].denunciante_apellidos ==''" class="panel-body"><span class="resalta">{{ 'texto_81' | translate }}:</span><span> {{denuncia[0].denunciante_apellidos}}</span></div>
                    <div *ngIf="idrole != '1' && idrole != '2'" [hidden]="denuncia[0].denunciante_correo ==''" class="panel-body"><span class="resalta">{{ 'texto_82' | translate }}:</span><span> {{denuncia[0].denunciante_correo}}</span></div>
                    <div *ngIf="idrole != '1' && idrole != '2'" [hidden]="denuncia[0].denunciante_dni ==''" class="panel-body"><span class="resalta">{{ 'texto_83' | translate }}:</span><span> {{denuncia[0].denunciante_dni}}</span></div>
                    <div *ngIf="idrole != '1' && idrole != '2'" [hidden]="denuncia[0].denunciante_telefono ==''" class="panel-body"><span class="resalta">{{ 'texto_66' | translate }}:</span><span> {{denuncia[0].denunciante_telefono}}</span></div>
                </div>
            </div>			
            <div class="col-md-6">
                <div class="panel panel-success" style="border-color:#000">
                    <div class="panel-heading" style="background-color:#000;color:white;">
                        <h5 class="panel-title">{{ 'texto_109' | translate }}</h5>
                    </div>
                    <div class="panel-body"><span class="resalta">{{ 'texto_67' | translate }}:</span><span> {{denuncia[0].nombre_empresa}}</span></div>
                    <div class="panel-body"><span class="resalta">{{ 'texto_71' | translate }}:</span><span> {{denuncia[0].descripcion_tipo_denuncia}}</span></div>
                    <div class="panel-body"><span class="resalta">{{ 'texto_84' | translate }}:</span><span> {{denuncia[0].fecha_incidente | date: 'dd/MM/yyyy'}}</span></div>
                    <div class="panel-body"><span class="resalta">{{ 'texto_85' | translate }}:</span><span> {{denuncia[0].fec_inicio | date: 'dd/MM/yyyy'}}</span></div>	
                    <div class="panel-body"><span class="resalta">{{ 'texto_86' | translate }}:</span><span> {{denuncia[0].fec_fin | date: 'dd/MM/yyyy'}}</span></div>								
				  				
                </div>						
            </div>	
            <div class="col-md-12" >
                <div class="panel panel-success" style="border-color:#000">
                    <div class="panel-heading" style="background-color:#000;color:white;">
                        <h5 class="panel-title">{{ 'texto_87' | translate }}</h5>
                    </div>
                    <div class="panel-body"><span class="resalta">{{ 'texto_63' | translate }}:</span>
                        <p style="word-wrap: break-word" > {{denuncia[0].denuncia_desc}}</p>
                    </div>
                    <div class="panel-body">
                        <span class="resalta">{{ 'texto_88' | translate }}:</span>
                        <p style="word-wrap: break-word" > {{denuncia[0].comentario}}</p>
                    </div>								
                </div>						
            </div>	
            <div class="col-md-12" [style.display]="bExisteAccion ? 'block' : 'none'">
                <div class="panel panel-success">
                    <div class="panel-heading">
                        <h5 class="panel-title">{{ 'texto_94' | translate }}</h5>
                    </div>

                    <div class="panel-body" >
                        <div class="col-md-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <th>{{ 'texto_452' | translate }}</th>
                                        <th>{{ 'texto_453' | translate }}</th>
                                        <th>{{ 'texto_454' | translate }}</th>
                                        <th>{{ 'texto_46' | translate }}</th>
                                        <th>{{ 'texto_92' | translate }}</th>
                                        <th>{{ 'texto_91' | translate }}</th>
                                        <th>{{ 'texto_46' | translate }}</th>
                                        <th>{{ 'texto_344' | translate }}</th>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let item of accion; let i = index">
                                        <td>{{ item.nombre_accion }}</td>
                                        <td>{{ item.responsable }}</td>
                                        <td *ngIf="item.realizada == '0'; else elseBlock">{{ 'texto_45' | translate }}</td>   
                                            <ng-template #elseBlock><td>{{ 'texto_347' | translate }}</td></ng-template>
                                        <td><div [innerHtml]="item.descripcion | safe: 'html'"></div></td> 
                                        <td>{{ item.fec_ult_actualizac  | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                                        <td>{{ item.id_usuario }}</td>    
                                        <td *ngIf="item.realizada == '0'; else elseBlock2"><button (click)="finalizarAccion(item.id_accion)" style="color:#fff" mdbBtn  rounded="true" class="btn-primary mt-1 waves-light" mdbWavesEffect>{{ 'texto_344' | translate }}</button>
                                        </td>   
                                            <ng-template #elseBlock2><td style="text-align: center;color: #3c763d;"><i class="fas fa-check"></i>
                                            </td>
                                        </ng-template>
                                      </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>                           
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="panel panel-warning" style="border-color:#000">
                    <div class="panel-heading" style="background-color:#000;color:white;">
                        <h5 class="panel-title">{{ 'texto_89' | translate }}</h5>
                    </div>
                    <div class="table-responsive" [style.display]="bExisteDocumento ? 'block' : 'none'" style="border: 1px solid #fcf8e3;">
                        <table class="table">
                            <!--Table head-->
                            <thead style="font-size:11px; font-weight:bold;">
                              <tr>
                                <th>#</th>
                                <th>{{ 'texto_90' | translate }}</th>
                                <th>{{ 'texto_46' | translate }}</th>
                                <th>{{ 'texto_91' | translate }}</th>
                                <th>{{ 'texto_92' | translate }}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let item of documento; let i = index" class="table-warning" style="font-size: 10px;">
                                <th scope="row">{{i+1}}</th>
                                <td><a href="{{item.ruta_archivo}}" target="_blank">{{item.n_archivo}}</a></td>
                                <td><span> <div [innerHtml]="item.comentario | safe: 'html'"></div></span></td>
                                <td>{{item.id_usuario }}</td>
                                <td>{{item.fec_ult_actualizac | date: 'dd/MM/yyyy HH:mm:ss'}}</td>  
                            </tr>
                            </tbody>	
                        </table>	
                    </div>	

                    <div class="panel-body" [style.display]="!bExisteDocumento ? 'block' : 'none'">
                        <div class="col-md-12">
                            <span class="resalta">{{ 'texto_110' | translate }}.</span>
                        </div>                           
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="panel panel-info" style="border-color:#000">
                    <div class="panel-heading" style="background-color:#000;color:white;">
                        <h5 class="panel-title">{{ 'texto_93' | translate }}</h5>
                    </div>
                    <div class="table-responsive" [style.display]="bExisteTestigo ? 'block' : 'none'" style="border: 1px solid #d9edf7;">
                        <table class="table">
                            <!--Table head-->
                            <thead style="font-size:11px; font-weight:bold;">
                              <tr>
                                <th>#</th>
                                <th>{{ 'texto_35' | translate }}</th>
                                <th>{{ 'texto_82' | translate }}</th>
                                <th>{{ 'texto_66' | translate }}</th>
                                <th>{{ 'texto_95' | translate }}</th>
                                <th>{{ 'texto_91' | translate }}</th>
                                <th>{{ 'texto_92' | translate }}</th>
                              </tr>
                            </thead>
                            <tbody>
                            <!--Table head-->
                          
                            <!--Table body-->
                            
                              <tr *ngFor="let item of testigo; let i = index" class="table-info" style="font-size: 10px;">
                                <th scope="row">{{i+1}}</th>
                                <td>{{item.n_testigo}}</td>
                                <td>{{item.correo_testigo}}</td>
                                <td>{{item.tlf_testigo}}</td>
                                <td><span> <div [innerHtml]="item.comentario | safe: 'html'"></div></span></td>
                                <td>{{item.id_usuario }}</td>
                                <td>{{item.fec_ult_actualizac | date: 'dd/MM/yyyy HH:mm:ss'}}</td> 
                            </tr>
                            </tbody>	
                        </table>	
                    </div>		
                    					
                    <div class="panel-body" [style.display]="!bExisteTestigo ? 'block' : 'none'" >
                        <div class="col-md-12">
                            <span class="resalta">{{ 'texto_96' | translate }}.</span>
                        </div>      
                    </div>
                </div>
            </div>	

            <div class="col-md-12" [style.display]="denuncia[0].motivo_rechazo!= null ? 'block' : 'none'">
                <div class="panel " style="border-color:teal">
                    <div class="panel-heading" style="background-color: teal;color:white">
                        <h5 class="panel-title">{{ 'Cambio gestor denuncia' | translate }}</h5>
                    </div>

                    <div class="panel-body" >
                        <div class="col-md-12">
                            {{ 'El denunciante ha cambiado el gestor asignado por el sistema y ha argumentado lo siguiente:' | translate }} <span [innerHtml]="denuncia[0].motivo_rechazo | safe: 'html'"></span>
                        </div>                           
                    </div>
                </div>
            </div>            

            <div class="col-md-12" [style.display]="denuncia[0].informe_gestor!= null ? 'block' : 'none'">
                <div class="panel " style="border-color:teal">
                    <div class="panel-heading" style="background-color: teal;color:white">
                        <h5 class="panel-title">{{ 'Informe Gestor' | translate }}</h5>
                    </div>

                    <div class="panel-body" >
                        <div class="col-md-12">
                            <span [innerHtml]="denuncia[0].informe_gestor | safe: 'html'"></span>
                        </div>                           
                    </div>
                </div>
            </div>

            <div class="col-md-12" [style.display]="denuncia[0].informe_investigador!= null ? 'block' : 'none'">
                <div class="panel " style="border-color:teal">
                    <div class="panel-heading" style="background-color: teal;color:white">
                        <h5 class="panel-title">{{ 'texto_270' | translate }}</h5>
                    </div>

                    <div class="panel-body" >
                        <div class="col-md-12">
                            <span [hidden]="(this.denuncia[0].id_fase == '0' && this.idrole == '1') || this.denuncia[0].id_fase == '1' || this.denuncia[0].id_fase == '2' || this.denuncia[0].id_fase == '3'" [innerHtml]="denuncia[0].informe_investigador | safe: 'html'"></span>
                        </div>                           
                    </div>
                </div>
            </div>
            
            <div class="col-md-12" [style.display]="denuncia[0].informe_decisor!= null ? 'block' : 'none'">
                <div class="panel panel-danger" style="border-color:#000">
                    <div class="panel-heading" style="background-color:#000;color:white;">
                        <h5 class="panel-title">{{ 'texto_175' | translate }}</h5>
                    </div>

                    <div class="panel-body" >
                        <div class="col-md-12">
                            <span [hidden]="(this.denuncia[0].id_fase == '0' && this.idrole == '1') || this.denuncia[0].id_fase == '1' || this.denuncia[0].id_fase == '3'" [innerHtml]="denuncia[0].informe_decisor | safe: 'html'"></span>
                        </div>                           
                    </div>
                </div>
            </div>
            <div class="col-md-12" [style.display]="denuncia[0].informe_compliance!= null ? 'block' : 'none'">
                <div class="panel panel-danger" style="border-color:#000">
                    <div class="panel-heading" style="background-color:#000;color:white;">
                        <h5 class="panel-title">{{ 'texto_183' | translate }}</h5>
                    </div>

                    <div class="panel-body" >
                        <div class="col-md-12">
                            <span [hidden]="(this.denuncia[0].id_fase == '0' && this.idrole == '1') || this.denuncia[0].id_fase == '1' || this.denuncia[0].id_fase == '3'" [innerHtml]="denuncia[0].informe_compliance | safe: 'html'"></span>
                        </div>                           
                    </div>
                </div>
            </div>
            <div class="col-md-12" [style.display]="denuncia[0].informe_denunciante!= null ? 'block' : 'none'">
                <div class="panel panel-danger" style="border-color:#000">
                    <div class="panel-heading" style="background-color:#000;color:white;">
                        <h5 class="panel-title">{{ 'texto_208' | translate }}</h5>
                    </div>

                    <div class="panel-body" >
                        <div class="col-md-12">
                            <span [hidden]="(this.denuncia[0].id_fase == '0' && this.idrole == '1') || this.denuncia[0].id_fase == '1' || this.denuncia[0].id_fase == '3'" [innerHtml]="denuncia[0].informe_denunciante | safe: 'html'"></span>
                        </div>                           
                    </div>
                </div>
            </div>            
            <div class="col-md-12">
                <div class="panel panel-danger" style="border-color:#000">
                    <div class="panel-heading" style="background-color:#000;color:white;">
                        <h5 class="panel-title">{{ 'texto_95' | translate }}</h5>
                    </div>
                    <div class="panel-body">
                        <div class="panel-body-chat body-panel" [hidden]="!showChat">
                            <ul class="chat" >
                                <li *ngFor="let item of comentarios; let i = index"  [ngClass]="item.id_usuario == 'Denunciante' ? 'left clearfix' : 'right clearfix'">
                                    <span [ngClass]="item.id_usuario == 'Denunciante' ? 'chat-img pull-left' : 'chat-img pull-right'" >
                                        <img width="40px" src="{{avatar}}" alt="User Avatar" class="img-circle" />
                                    </span>
                                    <div class="chat-body clearfix">
                                        <div *ngIf="item.id_usuario == 'Denunciante' ; else elseBlock">
                                            <strong class="primary-font">{{ 'texto_97' | translate }}</strong>
                                            <small class="pull-right text-muted">
                                                <span class="glyphicon glyphicon-time"></span>{{item.fecha_comentario | date: 'dd/MM/yyyy HH:mm:ss'}}</small>
                                        </div>
                                        <ng-template #elseBlock>
                                            <div> 
                                                <strong style="width: 85%;" class="pull-right primary-font">{{ 'texto_98' | translate }}</strong>
                                                <small class="pull-right text-muted">
                                                    <span class="glyphicon glyphicon-time"></span>{{item.fecha_comentario | date: 'dd/MM/yyyy HH:mm:ss'}}</small>
                                            </div>
                                        </ng-template>

                                        <p *ngIf="item.id_usuario == 'Denunciante' ; else elseBlock3">
                                            {{item.comentario}}
                                        </p>
                                        <ng-template #elseBlock3>
                                            <p style="text-align: right; width: 100%;">
                                                {{item.comentario}}
                                            </p>
                                        </ng-template>
                                        
                                    
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="clearfix" [hidden]="!sendChat">
                            <div class="col-md-12" style="margin-top: 10px;">
                                <input type="hidden" name="usuario_coment" id="usuario_coment" value="denunciante">                                             
                               <textarea maxlength="4000" [formControl]="comentarioDenuncia" class="form-control" rows="3" name="comentarioDenuncia"></textarea>
                                <div class="pull-right">
                                    <button (click)="enviarMensaje(denuncia[0].id_denuncia);" class="btn btn-finish btn-wd" id="btn-chat" name="anyadir_comentario">{{ 'texto_41' | translate }}</button>
                                </div>
                            </div>
                        </div>
                    </div>									
                </div>
            </div>							
        </article><!-- end #main -->
    </div>
</div>