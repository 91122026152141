import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from '../model/datosDenuncia';
import { environment } from "../../environments/environment"
import { Archivos } from '../data/formData.model';

@Component({
  selector: 'app-add-document',
  templateUrl: './add-document.component.html',
  styleUrls: ['./add-document.component.css']
})
export class AddDocumentComponent implements OnInit {
  PHP_API_SERVER = environment.baseUrl;//"http://127.0.0.1:8080";
  //PHP_API_SERVER = "http://portaldedenuncias.azkenservices.com/backend/";
  archivos: Archivos;
  files: any = [];
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  myForm = new FormGroup({
    file: new FormControl('',[] ),
    fileSource: new FormControl('', []),
    contactFormModalMessage: new FormControl('', []),
  });

  @Input() denuncia: DatosDenuncia;
  contador = 0

  constructor(private apiService: ApiService,private router: Router, private auth: AuthService, private toastr: ToastrService, private translate: TranslateService, private http: HttpClient) { }

  ngOnInit() {
    
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myForm.patchValue({
        fileSource: file
      });
     // this.submit();
    }
  }
     
  onKey(event){
    for(var i=0; i< 1; i++){
      var div = document.getElementsByClassName('nw-editor__res')[i];
      this.contador = div.textContent.length
    }
   }

  get contactFormModalMessage() {
    return this.myForm.get('contactFormModalMessage');
  }

  submit(){
    
    if(this.contador>4000){
      this.toastr.error(this.translate.instant('texto_266'), this.translate.instant('texto_243'));
    }else{
      let comentario = this.contactFormModalMessage.value.replace(/<[^>]*>/g, '');
      comentario = comentario.replace(/'/g, '');
      const formData = new FormData();
      formData.append('file', this.myForm.get('fileSource').value);
      formData.append('id_sociedad', this.denuncia[0].id_sociedad);
      formData.append('id_denuncia', this.denuncia[0].id_denuncia);
      formData.append('comentario', comentario);
      /*formData.append('id_usuario', this.denuncia[0].denunciante_correo)*/
      formData.append('id_usuario', 'Denunciante')
      this.http.post(`${this.PHP_API_SERVER}/api/uploadDocument.php`, formData).subscribe(
        res => {
          if(res == 200){
            this.toastr.success(this.translate.instant('texto_202'), this.translate.instant('texto_203'));
            localStorage.setItem("verdenuncia", "");

          }else{
            this.toastr.error(this.translate.instant('texto_204'), this.translate.instant('texto_203'));
          }
          
          let tabs = window.location.href.split("/", 6); 
          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['ver-denuncia/',tabs[5]]);
          }); 

        },
        err =>{
          this.toastr.error(this.translate.instant('texto_204'), this.translate.instant('texto_203'));
        })
    }
  }

  onFileChangeDrag(event) {
    if (event.length > 0) {
      const file = event[0];
      this.myForm.patchValue({
        fileSource: file
      });
      
     //this.submit();
    }
  }

  dragFile(event) {
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
     // const element = event[index];
      //const file = event.target.files[0];

      this.files.push(element.name)
      if(this.files.length > 1){
        this.files.pop(element.name)
        this.toastr.error(this.translate.instant('texto_334'), this.translate.instant('texto_203'));
      }else{
        if(element.size/1000000 > 16){
          this.files.pop(element.name)
          alert(this.translate.instant('texto_333'));
        }else{
          this.onFileChangeDrag(event);
        }
      }
    }  
  }

  uploadFile(event) {
    for (let index = 0; index < event.target.files.length; index++) {
      const element = event.target.files[index];
     // const element = event[index];
      //const file = event.target.files[0];

      this.files.push(element.name)
      if(this.files.length > 1){
        this.files.pop(element.name)
        this.toastr.error(this.translate.instant('texto_334'), this.translate.instant('texto_203'));
      }else{
        if(element.size/1000000 > 16){
          this.files.pop(element.name)
          alert(this.translate.instant('texto_333'));
        }else{
          this.onFileChange(event);
        }
      }
    }  
  }
  deleteAttachment(index) {
    //this.archivos.nombreArchivo =  "";
    this.files.splice(index, 1)
    const formData = new FormData();
    let file = this.myForm.get('fileSource').value;
  
    formData.append('file', file);
    formData.append('id_sociedad', this.denuncia[0].id_sociedad);
    formData.append('id_denuncia', this.denuncia[0].id_denuncia);
    formData.append('comentario', this.contactFormModalMessage.value);
    formData.append('id_usuario', this.denuncia[0].denunciante_correo)
    this.http.post(`${this.PHP_API_SERVER}/api/deleteDocument.php`, formData).subscribe(
      res => {
        if(res == 200){
          this.toastr.success(this.translate.instant('texto_332'), this.translate.instant('texto_203'));
        }else{
          this.toastr.error(this.translate.instant('texto_331'), this.translate.instant('texto_203'));
        }
    },
    err =>{
      this.toastr.error(this.translate.instant('texto_331'), this.translate.instant('texto_203'));
    })
  }

 

}
