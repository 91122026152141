import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-tipo-relacion',
  templateUrl: './add-tipo-relacion.component.html',
  styleUrls: ['./add-tipo-relacion.component.css']
})
export class AddTipoRelacionComponent implements OnInit {

  validatingForm: FormGroup;
  constructor(private apiService: ApiService, private auth: AuthService, private router: Router, private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit() {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    
    this.validatingForm = new FormGroup({
      signupFormModalName: new FormControl('', Validators.required),
      signupFormModalId: new FormControl('', Validators.required),
      contactFormModalMessage: new FormControl('', []),
    });
  }

  get signupFormModalName() {
    return this.validatingForm.get('signupFormModalName');
  }

  get signupFormModalId() {
    return this.validatingForm.get('signupFormModalId');
  }

  get contactFormModalMessage() {
    return this.validatingForm.get('contactFormModalMessage');
  }


  addTipoRelacion(){    
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.apiService.addRelationType(this.auth.getIdUser(), this.auth.getSociedad(), this.signupFormModalId.value, this.signupFormModalName.value, this.contactFormModalMessage.value,language).subscribe(
      res => {
        if(res == 200){
          this.toastr.success(this.translate.instant('texto_314'), this.translate.instant('texto_316'));
        }else{
          this.toastr.error(this.translate.instant('texto_315'), this.translate.instant('texto_316'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['tipo-relacion']);
        }); 
    },
    err =>{
      this.toastr.error(this.translate.instant('texto_315'), this.translate.instant('texto_316'));
    }); 
  }

  public options: Object = {
    charCounterCount: true,
    toolbarButtons: ['bold', 'italic', 'underline', 'fontFamily', 'fontSize',  'html', 'backgroundColor', 'textColor','alignLeft', 'alignCenter', 'alignRight', 'insertLink', '|', 'undo', 'redo' ],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'fontFamily', 'fontSize',  'html', 'backgroundColor', 'textColor','alignLeft', 'alignCenter', 'alignRight', 'insertLink', '|', 'undo', 'redo' ],
    imageAllowedTypes: ['jpeg', 'jpg', 'png'],
    imageMaxSize: 5 * 1024 * 1024,
  };


}
