import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from '../model/datosDenuncia';
import { Testigo } from '../model/testigo';
import { Documento } from '../model/documento';
import { DatePipe } from '@angular/common'
import { Comentario } from '../model/comentario';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { environment } from "../../environments/environment"
import * as jspdf from 'jspdf';  
import html2canvas from 'html2canvas'
import { Acciones } from '../model/acciones';
declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-editar-denuncia',
  templateUrl: './editar-denuncia.component.html',
  styleUrls: ['./editar-denuncia.component.css'],
  providers:[DatePipe]
})

export class EditarDenunciaComponent implements OnInit {
  validatingForm: FormGroup;
  pin_code: string;
  denuncia: DatosDenuncia[];
  testigo: Testigo[];
  documento: Documento[];
  accion: Acciones[];
  bExisteAccion:boolean = false;
  bExisteTestigo = true;
  bExisteDocumento = true;
  id: string;
  comentarios: Comentario[];
  avatar: string;
  showChat: boolean = false;
  sendChat: boolean = false;
  isReceptor:boolean = false;
  isDecisor:boolean = false;
  isInvestigador:boolean = false;
  isFaseInicial:boolean = false;
  isFaseExterno:boolean = false;
  isFaseDecisor:boolean = false;
  isFaseCompliance:boolean = false;
  progressBar:number = 0;
  isFaseInvestigador = false;
  backgroundColor:string = 'red';
  showDocument: boolean = false;
  idrole: string;
  sociedadUsuario: string;
  @ViewChild('contenedor') contenedor:ElementRef;

  constructor(private apiService: ApiService,private router: Router, private auth: AuthService, private toastr: ToastrService, private translate: TranslateService, private actRoute: ActivatedRoute,private datepipe: DatePipe) { }

  ngOnInit() {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }

    this.id = this.actRoute.snapshot.params.id;
    this.validatingForm = new FormGroup({
      comentarioDenuncia: new FormControl('')
    });

    this.sociedadUsuario = this.auth.getNameSociedad();

    this.avatar =  environment.baseAvatar + "social.png";
    this.translate.setDefaultLang(language);
    this.apiService.searchComplaintById(this.id,language).subscribe((denuncia: DatosDenuncia[])=>{
      this.denuncia = denuncia;
      if(denuncia.length == 0){
       // this.toastr.error(this.translate.instant('No existe ninguna denuncia con ese identificador'), this.translate.instant('texto_190'));
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['denuncias']);
        });
      }else{
        let role = this.auth.getRole();
        this.idrole = '0';
        if(this.denuncia[0].id_fase == "0"){
          this.progressBar = 10;
          this.showChat = true;
          this.sendChat = true;          
          if(role.includes("RECEPTOR") ){
            this.isFaseInicial = true;
            this.idrole = '1';
          }
        }else if(this.denuncia[0].id_fase == "11"){
          this.progressBar = 5;
          this.showChat = true;
          this.sendChat = true;           
          if (role.includes("GESTOR") ){
            this.isFaseExterno = true;
            this.idrole = '11';
            this.isFaseInicial = false;
          }
        }else if(this.denuncia[0].id_fase == "1"){
          this.progressBar = 25;
          this.showChat = true;
          this.backgroundColor = 'red';
          this.sendChat = true;
          this.isFaseInvestigador = true;
          this.idrole = '1';
        }else if(this.denuncia[0].id_fase == "2"){
          this.showChat = true;
          this.progressBar = 50;
          this.isFaseInvestigador = false;
          this.idrole = '2';
         // this.isInvestigador = true;
         if( role.includes("INVESTIGADOR")){
          this.isInvestigador = true;
          this.showDocument = true;
          this.idrole = '2';          
        }
          this.backgroundColor = '#F1C453';
         /* if( role.includes("INVESTIGADOR")){
            this.showDocument = true;
          } else{
            this.showDocument = false;
          }   */      

          this.sendChat = true;
        }else if(this.denuncia[0].id_fase == "3"){
          this.progressBar = 65;
          this.idrole = '1';
         
       //   this.isReceptor = true; 
        //  this.isInvestigador = false;
          if(role.includes("RECEPTOR")){
            this.isFaseDecisor = true;
          }else{
            this.isFaseDecisor = false;
          }
          this.isFaseInvestigador = false;
          this.isFaseInicial = false;
          this.isFaseExterno = false;
          this.backgroundColor = '#EFEA5A';
          this.sendChat = true;
        }else if(this.denuncia[0].id_fase == "4"){
      //    this.isDecisor = true;
          if(role.includes("DECISOR")){
            this.isDecisor = true; 
            this.isFaseDecisor = true;
          }
          this.progressBar = 75;
          this.backgroundColor = '#B9E769';
          this.showDocument = true;
          this.sendChat = false;
          this.isFaseDecisor = false;
        }else if(this.denuncia[0].id_fase == "5"){
          this.isFaseDecisor = false;
          this.isFaseCompliance = true;
          this.progressBar = 85;
          this.backgroundColor = '#83E377';
        }else if(this.denuncia[0].id_fase == "6"){
          this.progressBar = 100;
          this.backgroundColor = '#16DB93';
        }

        //console.log("this.isFaseExterno", this.isFaseExterno);

        this.apiService.searchWitnessByComplaint(this.id,this.idrole).subscribe((testigo: Testigo[])=>{
          this.testigo = testigo;
          if(testigo.length == 0){
            this.bExisteTestigo = false;
          }
        });

        this.apiService.searchDocumentsByComplaint(this.id,true,this.idrole).subscribe((documento: Documento[])=>{
          this.documento = documento;
          if(documento.length == 0){
            this.bExisteDocumento = false;
          }
        });
        if(this.isFaseCompliance){
          this.apiService.searchActionsByComplaint(this.id).subscribe((acciones: Acciones[])=>{
            this.accion = acciones;
            if(acciones.length >= 0){
              this.bExisteAccion = true;
            }
          });
        }
      }

      this.apiService.searchCommentsByComplaint(this.id,true).subscribe((comentario: Comentario[])=>{
        this.comentarios = comentario;
        if(this.comentarios.length>0){
          this.showChat = true;
        }else{
          this.showChat = false;
        }
      });
    });
  }

  get comentarioDenuncia() {
    return this.validatingForm.get('comentarioDenuncia'); 
  }

  enviarMensaje(id_denuncia){
    let date = new Date();
    let _date =this.datepipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
    let comentario = this.comentarioDenuncia.value.replace(/<[^>]*>/g, '');
    if (comentario != null && comentario != "") {
      comentario = comentario.replace(/'/g, '');
      this.apiService.sendMessageChat(this.denuncia[0].id_sociedad,id_denuncia,this.auth.getIdUser(), comentario, _date, this.auth.getIdUser(),true).subscribe(
        res => {
          if(res == 200){
            let $clau = false;
            let clausulas_correo = localStorage.getItem("clausulas_correo");
            if(clausulas_correo == '1'){
              $clau = true; 
            }            
            let url = environment.base + "/#/consultar-denuncia";
            let message = "Estimado/a "  +  this.denuncia[0].denunciante_nombre  + " " + this.denuncia[0].denunciante_apellidos + ", le informamos que el equipo gestor del canal ha añadido un nuevo comentario a su denuncia "  + this.denuncia[0].id_denuncia  + ". Entre en el canal para leerlo.";
            this.apiService.sendEmail(this.denuncia[0].denunciante_correo,'Nuevo comentario en la denuncia '+this.denuncia[0].id_denuncia, message,url,$clau, "Nuevo comentario en la denuncia").subscribe(
              res => {
                if(res == 200){
                  this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                }else{
                  this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                }         
            });           
            this.toastr.success(this.translate.instant('texto_363'), this.translate.instant('texto_484'));
          }else{
            this.toastr.error('texto_29', this.translate.instant('texto_484'));
          }
          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['editar-denuncia/', this.id]);
          }); 
      });
    }else{
      this.toastr.error(this.translate.instant('texto_481'), this.translate.instant('texto_484'));
    }    
  }

  exportarDocumento(){
    let ok = false;
    if(this.documento.length > 0){
      if(confirm(this.translate.instant('texto_365'))) {
        ok = true;
      }
    }
    document.getElementById('botonera').style.display = 'none';

    var data = document.getElementById('contenedor');  
    html2canvas(data).then(canvas => {  
      // Few necessary setting options  

      let HTML_Width = canvas.width;
      let HTML_Height = canvas.height;
      let top_left_margin = 15;
      let PDF_Width = HTML_Width + (top_left_margin * 2);
      let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
      let canvas_image_width = HTML_Width;
      let canvas_image_height = HTML_Height;
      let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
      canvas.getContext('2d');
      let imgData = canvas.toDataURL("image/jpeg", 1.0);
      let pdf = new jspdf('p', 'pt', [PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
      for (let i = 1; i <= totalPDFPages; i++) {
        pdf.addPage([PDF_Width, PDF_Height], 'p');
        pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
      }
      let d = new Date().toISOString();
      pdf.save('report-'+d+'.pdf');   

      if(ok){
        for(let j=0;j<this.documento.length;j++){
          let url = this.documento[j].ruta_archivo;
          let name = this.documento[j].n_archivo;
          this.downloadPdf(url,name);
        }  
      }

     /* var imgWidth = 210;   
      var pageHeight = 297;    
      var imgHeight = canvas.height * imgWidth / canvas.width;  
      var heightLeft = imgHeight;  
      const contentDataURL = canvas.toDataURL('image/png')  
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF  
      var position = 0;  
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
     heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;

        pdf.addPage();
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      if(ok){

        for(let j=0;j<this.documento.length;j++){
          let url = this.documento[j].ruta_archivo;
          let name = this.documento[j].n_archivo;
         // this.downloadPdf(url,name);
        }  
      }
      let d = new Date().toISOString();;
      pdf.save('report-'+d+'.pdf');   */
      document.getElementById('botonera').style.display = 'block';
    });  
    this.toastr.success(this.translate.instant('texto_366'), this.translate.instant('texto_367'));
  }

  downloadPdf(pdfUrl: string, pdfName: string ) {
    FileSaver.saveAs(pdfUrl, pdfName);
  }

  finalizarAccion(id_action:string){
    this.apiService.updateActionsById(this.denuncia[0].id_denuncia, id_action, this.auth.getIdUser()).subscribe(
      res => {
        if(res == 200){
          this.toastr.success(this.translate.instant('texto_283'), this.translate.instant('texto_285'));
        }else{
          this.toastr.error(this.translate.instant('texto_284'), this.translate.instant('texto_285'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['editar-denuncia/',this.denuncia[0].id_denuncia]);
        }); 
    });
  }

}
