import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { Relacion } from '../model/relacion';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tipo-relacion',
  templateUrl: './tipo-relacion.component.html',
  styleUrls: ['./tipo-relacion.component.css']
})
export class TipoRelacionComponent implements OnInit {
  sociedad: string;
  relaciones: Relacion[];

  constructor(private apiService: ApiService,private router: Router, private auth: AuthService, private toastr: ToastrService, private translate: TranslateService) { }
  IsHiddenForm: boolean =  false;
  validatingForm: FormGroup;
  idRelacion: string;
  nombre: string;
  comentario: string;

  ngOnInit() {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.sociedad = this.auth.getNameSociedad();
    this.apiService.searchRelationTypeByUserSociety(this.auth.getIdUser(),this.auth.getSociedad(),language).subscribe((relacion: Relacion[])=>{
      this.relaciones = relacion;
    });
    
    this.validatingForm = new FormGroup({
      signupFormModalName: new FormControl(''),
      signupFormModalId: new FormControl(''),
      contactFormModalMessage: new FormControl('', []),
    });
  }

  get signupFormModalName() {
    return this.validatingForm.get('signupFormModalName');
  }

  get signupFormModalId() {
    return this.validatingForm.get('signupFormModalId');
  }

  get contactFormModalMessage() {
    return this.validatingForm.get('contactFormModalMessage');
  }

  deleteRelationType(id_tp_relacion: string){
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.apiService.searchComplaintBySocietyRelation(this.auth.getSociedad(),id_tp_relacion).subscribe(
      res => {
        if(res == 200){
          this.apiService.deleteRelationTypeByUser(this.auth.getIdUser(),this.auth.getSociedad(),id_tp_relacion,language).subscribe(
            res => {
              if(res == 200){
                this.toastr.success(this.translate.instant('texto_225'), this.translate.instant('texto_228'));
              }else{
                this.toastr.error(this.translate.instant('texto_226'), this.translate.instant('texto_228'));
              }
      
              this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                this.router.navigate(['tipo-relacion']);
              }); 
          });
        }else{
          this.toastr.error(this.translate.instant('texto_227'), this.translate.instant('texto_228'));
        }

        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['tipo-relacion']);
        }); 
    });



    
  }

  hideModal(){
    this.IsHiddenForm = false;
  }

  showRelation(relation:Relacion){
    this.IsHiddenForm = true;

    this.idRelacion = relation.id_tp_relacion;
    this.nombre = relation.nombre;
    this.comentario = relation.comentario;
  }

  updateRelation(id_tp_relacion:string){
    let nombreRelacion = this.signupFormModalName.value;
    if(this.signupFormModalName.value == ""){
      nombreRelacion = this.nombre;
    }
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.apiService.updateRelationByUser(this.auth.getSociedad(), id_tp_relacion, nombreRelacion, this.contactFormModalMessage.value,  this.auth.getIdUser(),language).subscribe(
      res => {
        if(res == 200){
          this.toastr.success(this.translate.instant('texto_229'), this.translate.instant('texto_231'));
        }else{
          this.toastr.error(this.translate.instant('texto_230'), this.translate.instant('texto_231'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['tipo-relacion']);
        }); 
    });
  }

  public options: Object = {
    charCounterCount: true,
    toolbarButtons: ['bold', 'italic', 'underline', 'fontFamily', 'fontSize',  'html', 'backgroundColor', 'textColor','alignLeft', 'alignCenter', 'alignRight', 'insertLink', '|', 'undo', 'redo' ],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'fontFamily', 'fontSize',  'html', 'backgroundColor', 'textColor','alignLeft', 'alignCenter', 'alignRight', 'insertLink', '|', 'undo', 'redo' ],
    imageAllowedTypes: ['jpeg', 'jpg', 'png'],
    imageMaxSize: 5 * 1024 * 1024,
  };

}
