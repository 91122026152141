<!-- End Navbar -->
<section class="main-content">					
        <div class="container-fluid">				
              <div class="row">
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-header card-header-primary">
                      <h4 class="card-title">{{ 'texto_30' | translate }}</h4>
                    </div>                    
                    <div class="content">					
                      <form [formGroup]="validatingForm" style="margin-left: 25px; margin-right: 25px;">
                        <div class="row"  style="padding-top:20px" >														
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="bmd-label-floating">{{ 'texto_302' | translate }}</label>
                              <input [formControl]="dominio"  type="text" class="form-control" [(ngModel)]="dominioF" value="{{dominioF}}" readonly>
                            </div>
                          </div>																												
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="bmd-label-floating">{{ 'texto_381' | translate }}</label>
                              <input [formControl]="email"  type="email" class="form-control" [(ngModel)]="emailF" value="{{emailF}}">
                            </div>
                          </div>
                        </div>	
                        <div class="row">															
                          <div class="col-md-4">
                            <div class="form-group">
                              <label class="bmd-label-floating">{{ 'texto_382' | translate }}</label>
                              <input [formControl]="aviso"  type="text" class="form-control" [(ngModel)]="avisoF"  value="{{avisoF}}"> 
                            </div>
                          </div>														
                          <div class="col-md-4">
                            <div class="form-group">
                              <label class="bmd-label-floating">{{ 'texto_383' | translate }}</label>
                              <select [formControl]="anonimo"  class="form-control" [(ngModel)]="selectedTipo" (change)="selectChangeHandler($event)">
                                <option value="1">{{ 'texto_500' | translate }}</option>
                                <option value="0">{{ 'texto_501' | translate }}</option>
                                <option value="2">{{ 'texto_502' | translate }}</option>
                            </select>                    
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label class="bmd-label-floating">{{ 'texto_513' | translate }}</label>
                              <select [formControl]="triaje"  class="form-control" [(ngModel)]="selectedTriaje" (change)="selectChangeHandler8($event)">
                                <option value="1">{{ 'texto_498' | translate }}</option>
                                <option value="0">{{ 'texto_499' | translate }}</option>
                            </select> 
                            </div>
                          </div>	                          													
                        </div>
                        <div class="row">															
                          <div class="col-md-4">
                            <div class="form-group">
                              <label class="bmd-label-floating">{{ 'texto_303' | translate }}</label>
                              <select [formControl]="calusulas"  class="form-control" [(ngModel)]="selectedClausulas" (change)="selectChangeHandler2($event)">
                                <option value="1">Sí</option>
                                <option value="0">No</option>
                            </select> 
                            </div>
                          </div>														
                          <div class="col-md-4">
                            <div class="form-group">
                              <label class="bmd-label-floating">{{ 'texto_324' | translate }}</label>
                              <select [formControl]="conflicto"  class="form-control" [(ngModel)]="selectedConflicto" (change)="selectChangeHandler3($event)">
                                <option value="1">{{ 'texto_498' | translate }}</option>
                                <option value="0">{{ 'texto_499' | translate }}</option>
                            </select>                     
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label class="bmd-label-floating">{{ 'texto_413' | translate }}</label>
                              <input [formControl]="diasverdenuncia"  type="text" class="form-control" [(ngModel)]="diasverdenunciaF"  value="{{diasverdenunciaF}}">  
                            </div>
                          </div>			                          													
                        </div>  
                        <div class="row">															
                          <div class="col-md-4">
                            <div class="form-group">
                              <label class="bmd-label-floating">{{ 'texto_328' | translate }}</label>
                              <input [formControl]="diasinves"  type="text" class="form-control" [(ngModel)]="diasinvesF"  value="{{diasinvesF}}">  
                            </div>
                          </div>														
                          <div class="col-md-4">
                            <div class="form-group">
                              <label class="bmd-label-floating">{{ 'texto_329' | translate }}</label>
                              <input [formControl]="diasdecisor"  type="text" class="form-control" [(ngModel)]="diasdecisorF"  value="{{diasdecisorF}}">                       
                            </div>
                          </div>	
                          <div class="col-md-4">
                            <div class="form-group">
                              <label class="bmd-label-floating">{{ 'texto_330' | translate }}</label>
                              <input [formControl]="diascompliance"  type="text" class="form-control" [(ngModel)]="diascomplianceF"  value="{{diascomplianceF}}">                       
                            </div>
                          </div>	                          												
                        </div>  
                        <div class="row">															
                          <div class="col-md-4">
                            <div class="form-group">
                              <label class="bmd-label-floating">{{ 'texto_335' | translate }}</label><br>
                              <select [formControl]="terminos"  class="form-control" [(ngModel)]="selectedTerminos" (change)="selectChangeHandler4($event)">
                                <option value="1">URL</option>
                                <option value="0">Documento</option>
                            </select>   
                            </div>
                          </div>													
                          <!--<div class="col-md-8" [hidden]="fichero">-->
                          <div class="col-md-8" *ngIf="selectedTerminos == '1' ; else elseBlock">
                            <div class="form-group">
                              <label class="bmd-label-floating">{{ 'texto_341' | translate }}</label>
                              <input [formControl]="ruta"  type="text" class="form-control" [(ngModel)]="rutaF"  value="{{rutaF}}">                       
                            </div>
                          </div>	
                          <ng-template #elseBlock>
                          <!--<div class="col-md-8" [hidden]="!fichero">-->
                          <div class="col-md-8">
                            <div class="form-group">
                              <div class="col-md-12">                              
                                <label class="bmd-label-floating">{{ 'texto_117' | translate }}</label>
                              </div>
                              <div class="col-md-8">
                                <input type="text" [formControl]="doc_terminos" class="form-control" [(ngModel)]="doc_terminosF"  value="{{doc_terminosF}}" readonly>  
                              </div>
                              <div class="col-md-4">
                                <span class="btn btn-primary btn-file">
                                  <span class="glyphicon glyphicon-folder-open"></span> <input  type="file" class="form-control" id="doc_terminos" (change)="onFileChange2($event,'doc_terminos')">                                
                                </span>                                    
                                <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button" (click)="openDoc(doc_terminosF)" title="Ver documento"><span class="glyphicon glyphicon-eye-open"></span></button>
                              </div> 
                            </div>
                          </div>
                          </ng-template>	                         												
                        </div> 
                        
                        <!--El Boton1-->
                        <div class="row">															
                          <div class="col-md-4">
                            <div class="form-group">
                              <label class="bmd-label-floating">{{ 'texto_466' | translate }}</label><br>
                              <select [formControl]="boton1"  class="form-control" [(ngModel)]="selectedBoton1" (change)="selectChangeHandler5($event)">
                                <option value="1">SI</option>
                                <option value="0">NO</option>
                            </select>   
                            </div>
                          </div>													
                          <!--<div class="col-md-8" [hidden]="fichero">-->
                          <div class="col-md-8" *ngIf="selectedBoton1 == '0' ; else elseBlock2"></div>	
                          <ng-template #elseBlock2>
                          <!--<div class="col-md-8" [hidden]="!fichero">-->
                          <div class="col-md-8">
                            <div class="form-group"> 
                              <div class="col-md-12">                              
                                <label class="bmd-label-floating">{{ 'texto_117' | translate }}</label>
                              </div>
                              <div class="col-md-8">
                                <input type="text" [formControl]="doc_boton1" class="form-control" [(ngModel)]="doc_boton1F"  value="{{doc_boton1F}}" readonly>  
                              </div>
                              <div class="col-md-4">
                                <span class="btn btn-primary btn-file">
                                  <span class="glyphicon glyphicon-folder-open"></span> <input  type="file" class="form-control" id="doc_boton1" (change)="onFileChange3($event,'doc_boton1')">                                
                                </span>                                    
                                <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button" (click)="openDoc(doc_boton1F)" title="Ver documento"><span class="glyphicon glyphicon-eye-open"></span></button>
                              </div> 
                            </div>
                          </div>
                          </ng-template>	                         												
                        </div>    
                        <!--El Boton2-->
                        <div class="row">															
                          <div class="col-md-4">
                            <div class="form-group">
                              <label class="bmd-label-floating">{{ 'texto_486' | translate }}</label><br>
                              <select [formControl]="boton2"  class="form-control" [(ngModel)]="selectedBoton2" (change)="selectChangeHandler6($event)">
                                <option value="1">SI</option>
                                <option value="0">NO</option>
                            </select>   
                            </div>
                          </div>													
                          <!--<div class="col-md-8" [hidden]="fichero">-->
                          <div class="col-md-8" *ngIf="selectedBoton2 == '0' ; else elseBlock22"></div>	
                          <ng-template #elseBlock22>
                          <!--<div class="col-md-8" [hidden]="!fichero">-->
                          <div class="col-md-8">
                            <div class="form-group"> 
                              <div class="col-md-12">                              
                                <label class="bmd-label-floating">{{ 'texto_117' | translate }}</label>
                              </div>
                              <div class="col-md-8">
                                <input type="text" [formControl]="doc_boton2" class="form-control" [(ngModel)]="doc_boton2F"  value="{{doc_boton2F}}" readonly>  
                              </div>
                              <div class="col-md-4">
                                <span class="btn btn-primary btn-file">
                                  <span class="glyphicon glyphicon-folder-open"></span> <input  type="file" class="form-control" id="doc_boton2" (change)="onFileChange3($event,'doc_boton2')">                                
                                </span>                                    
                                <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button" (click)="openDoc(doc_boton2F)" title="Ver documento"><span class="glyphicon glyphicon-eye-open"></span></button>
                              </div> 
                            </div>
                          </div>
                          </ng-template>	                         												
                        </div> 
                        <!--El Boton3-->
                        <div class="row">															
                          <div class="col-md-4">
                            <div class="form-group">
                              <label class="bmd-label-floating">{{ 'texto_488' | translate }}</label><br>
                              <select [formControl]="boton3"  class="form-control" [(ngModel)]="selectedBoton3" (change)="selectChangeHandler7($event)">
                                <option value="1">SI</option>
                                <option value="0">NO</option>
                            </select>   
                            </div>
                          </div>													
                          <!--<div class="col-md-8" [hidden]="fichero">-->
                          <div class="col-md-8" *ngIf="selectedBoton3 == '0' ; else elseBlock23"></div>	
                          <ng-template #elseBlock23>
                          <!--<div class="col-md-8" [hidden]="!fichero">-->
                          <div class="col-md-8">
                            <div class="form-group"> 
                              <div class="col-md-12">                              
                                <label class="bmd-label-floating">{{ 'texto_117' | translate }}</label>
                              </div>
                              <div class="col-md-8">
                                <input type="text" [formControl]="doc_boton3" class="form-control" [(ngModel)]="doc_boton3F"  value="{{doc_boton3F}}" readonly>  
                              </div>
                              <div class="col-md-4">
                                <span class="btn btn-primary btn-file">
                                  <span class="glyphicon glyphicon-folder-open"></span> <input  type="file" class="form-control" id="doc_boton3" (change)="onFileChange3($event,'doc_boton3')">                                
                                </span>                                    
                                <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button" (click)="openDoc(doc_boton3F)" title="Ver documento"><span class="glyphicon glyphicon-eye-open"></span></button>
                              </div> 
                            </div>
                          </div>
                          </ng-template>	                         												
                        </div>                                                                                 
                        <button (click)="guardarDatos()" type = "submit"  class="btn btn-primary pull-right">{{ 'texto_384' | translate }}</button>
                        <div class="clearfix"></div>
                        
                        
                      </form>
                      
                    </div>
                  </div>
                </div>


                <div class="col-md-6">
                  <div class="card">
                    <div class="card-header card-header-primary">
                      <h4 class="card-title">{{ 'texto_118' | translate }}</h4>
                    </div>                    
                    <div class="content">					
                      <form [formGroup]="validatingFormMail" style="margin-left: 25px; margin-right: 25px;">
                        <div class="row"  style="padding-top:20px" >														
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="bmd-label-floating">{{ 'texto_220' | translate }}</label>
                              <input [formControl]="nremitente"  type="text" class="form-control" [(ngModel)]="nremitenteF" value="{{nremitenteF}}">
                            </div>
                          </div>                          
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="bmd-label-floating">{{ 'texto_222' | translate }}</label>
                              <input [formControl]="remitente"  type="email" class="form-control" [(ngModel)]="remitenteF" value="{{remitenteF}}" >
                            </div>
                          </div>																												
                        </div>	
                        <div class="row">															
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="bmd-label-floating">{{ 'texto_224' | translate }}</label>
                              <input [formControl]="srv_mail"  type="text" class="form-control" [(ngModel)]="srv_mailF"  value="{{srv_mailF}}"> 
                            </div>
                          </div>														
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="bmd-label-floating">{{ 'texto_385' | translate }}</label>
                              <input [formControl]="puerto"  type="text" class="form-control" [(ngModel)]="puertoF"  value="{{puertoF}}"> 
                            </div>
                          </div>														
                        </div>
                        <div class="row">															
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="bmd-label-floating">{{ 'texto_386' | translate }}</label>
                              <input [formControl]="usr_mail"  type="text" class="form-control" [(ngModel)]="usr_mailF"  value="{{usr_mailF}}"> 
                            </div>
                          </div>														
                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="bmd-label-floating">{{ 'texto_387' | translate }}</label>
                              <input [formControl]="pass_mail"  type="password" class="form-control" [(ngModel)]="pass_mailF"  value="{{pass_mailF}}"> 
                            </div>
                          </div>														
                        </div>      
                        <div class="row">															
                          <div class="col-md-4">
                            <div class="form-group">
                              <mat-checkbox [checked]="auth_mail" [(ngModel)]="auth_mailF" [formControl]="auth_mail" class="example-margin">{{ 'texto_393' | translate }}</mat-checkbox>
                            </div>
                          </div>														
                          <div class="col-md-3">
                            <div class="form-group">
                              <mat-checkbox [checked]="ssl" [(ngModel)]="sslF" [formControl]="ssl" class="example-margin">{{ 'texto_394' | translate }}</mat-checkbox>
                            </div>
                          </div>	
                          <div class="col-md-5">
                            <div class="form-group">
                              <app-prueba-correo></app-prueba-correo>
                            </div>                        													
                          </div>
                        </div>                                             
                        <button (click)="guardarDatosCorreo()" type = "submit"  class="btn btn-primary pull-right">{{ 'texto_384' | translate }}</button>
                        <div class="clearfix"></div>
                      </form>
                    </div>
                  </div>
                </div>                
              </div>
              
              <div class="row">
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header card-header-primary">
                      <h4 class="card-title">{{ 'texto_395' | translate }}</h4>
                    </div>                    
                    <div class="content">					
                      <form [formGroup]="validatingFormColores" style="margin-left: 25px; margin-right: 25px;">
                        <div class="row"  style="padding-top:20px" >																										
                          <div class="col-md-3">
                            <div class="form-group">
                              <!--<div id="cp" class="input-group colorpicker-component">-->
                                <label class="bmd-label-floating">{{ 'texto_414' | translate }}</label>
                                <input [formControl]="colorprincipal" id="colorprincipal" type="text" class="form-control" [(ngModel)]="colorprincipalF"  value="{{colorprincipalF}}" >
                                <!--<span class="input-group-addon"><i></i></span>
                              </div>-->
                            </div>
                          </div>														
                          <div class="col-md-3">
                            <div class="form-group">
                                <label class="bmd-label-floating">{{ 'texto_415' | translate }}</label>
                                <input [formControl]="colorsecundario" id="colorsecundario" type="text" class="form-control" [(ngModel)]="colorsecundarioF"  value="{{colorsecundarioF}}" >
                            </div>
                          </div>	
                          <div class="col-md-3">
                            <div class="form-group">
                              <label class="bmd-label-floating">{{ 'texto_416' | translate }}</label>
                              <input [formControl]="colortxttitulo" id="colortxttitulo" type="text" class="form-control" [(ngModel)]="colortxttituloF"  value="{{colortxttituloF}}" >
                            </div>
                          </div>													
                          <div class="col-md-3">
                            <div class="form-group">
                                <label class="bmd-label-floating">{{ 'texto_417' | translate }}</label>
                                <input [formControl]="colortxtbtn" id="colortxtbtn" type="text" class="form-control" [(ngModel)]="colortxtbtnF"  value="{{colortxtbtnF}}" >
                            </div>
                          </div>	                          												
                        </div>
                        <div class="row" >		
                          <div class="col-md-3">
                            <div class="form-group">
                                <label class="bmd-label-floating">{{ 'texto_418' | translate }}</label>
                                <input [formControl]="colorbgseccion1" id="colorbgseccion1" type="text" class="form-control" [(ngModel)]="colorbgseccion1F"  value="{{colorbgseccion1F}}" >
                            </div>
                          </div>	                          																								
                          <div class="col-md-3">
                            <div class="form-group">
                                <label class="bmd-label-floating">{{ 'texto_419' | translate }}</label>
                                <input [formControl]="colortxtseccion1" id="colortxtseccion1" type="text" class="form-control" [(ngModel)]="colortxtseccion1F"  value="{{colortxtseccion1F}}" >
                            </div>
                          </div>														
                          <div class="col-md-3">
                            <div class="form-group">
                                <label class="bmd-label-floating">{{ 'texto_420' | translate }}</label>
                                <input [formControl]="colorbgseccion2" id="colorbgseccion2" type="text" class="form-control" [(ngModel)]="colorbgseccion2F"  value="{{colorbgseccion2F}}" >
                            </div>
                          </div>	
                          <div class="col-md-3">
                            <div class="form-group">  
                              <label class="bmd-label-floating">{{ 'texto_421' | translate }}</label>
                              <input [formControl]="colortxtseccion2" id="colortxtseccion2" type="text" class="form-control" [(ngModel)]="colortxtseccion2F"  value="{{colortxtseccion2F}}" >
                            </div>
                          </div>													                          												
                        </div>
                        <div class="row"  style="padding-top:20px" >																										
                          <div class="col-md-3">
                            <div class="form-group">
                                <label class="bmd-label-floating">{{ 'texto_422' | translate }}</label>
                                <input [formControl]="colorbgseccion3" id="colorbgseccion3" type="text" class="form-control" [(ngModel)]="colorbgseccion3F"  value="{{colorbgseccion3F}}" >
                            </div>
                          </div>	                          
                          <div class="col-md-3">
                            <div class="form-group">
                                <label class="bmd-label-floating">{{ 'texto_423' | translate }}</label>
                                <input [formControl]="colortxtseccion3" id="colortxtseccion3" type="text" class="form-control" [(ngModel)]="colortxtseccion3F"  value="{{colortxtseccion3F}}" >
                            </div>
                          </div>		
                          <div class="col-md-3">
                            <div class="form-group">
                                <label class="bmd-label-floating">{{ 'texto_424' | translate }}</label>
                                <input [formControl]="colormenulateral" id="colormenulateral" type="text" class="form-control" [(ngModel)]="colormenulateralF"  value="{{colormenulateralF}}" >
                            </div>
                          </div>	                          
                          <div class="col-md-3">
                            <div class="form-group">
                                <label class="bmd-label-floating">{{ 'texto_425' | translate }}</label>
                                <input [formControl]="colortxtcabeceras" id="colortxtcabeceras" type="text" class="form-control" [(ngModel)]="colortxtcabecerasF"  value="{{colortxtcabecerasF}}" >
                            </div>
                          </div>	                          												                         												
                        </div>
                        <div class="row"  style="padding-top:20px" >																										
                          <div class="col-md-3">
                            <div class="form-group">
                                <label class="bmd-label-floating">{{ 'texto_426' | translate }}</label>
                                <input [formControl]="colortxtbotones" id="colortxtbotones" type="text" class="form-control" [(ngModel)]="colortxtbotonesF"  value="{{colortxtbotonesF}}" >
                            </div>
                          </div>	                           	                          												                         												
                        </div>                        
                        <button (click)="guardarColores()" type = "submit"  class="btn btn-primary pull-right">{{ 'texto_384' | translate }}</button>
                        <div class="clearfix"></div>
                      </form>
                    </div>
                  </div>
                </div>                
              </div>     
              
              <div class="row">
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-header card-header-primary">
                      <h4 class="card-title">{{ 'texto_396' | translate }}</h4>
                    </div>                    
                    <div class="content">	                      
                     <p style="display: block;margin-inline-start: 20px;font-family: 'Montserrat', sans-serif;font-size: 2em !important;">{{ 'texto_289' | translate }}<a href="https://convertimage.es/" target="_blank"> Convertimage</a></p>
                      <form [formGroup]="validatingFormImages" style="margin-left: 25px; margin-right: 25px;">													
                          <div class="row">	
                            <div class="col-md-6">
                              <div class="form-group">
                                <div class="row">
                                  <div class="col-md-12"><label>{{ 'texto_294' | translate }}</label></div>
                                </div>
                                <div class="row">
                                  <div class="col-md-9">
                                    <input type="text" [formControl]="logo" class="form-control" [(ngModel)]="logoF"  value="{{logoF}}" readonly>  
                                  </div>
                                  <div class="col-md-3">
                                    <span class="btn btn-primary btn-file">
                                      <span class="glyphicon glyphicon-folder-open"></span> <input  type="file" class="form-control" id="logo" (change)="onFileChange($event,'logo')">                                
                                    </span>                                    
                                    <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button" (click)="openImg(logoF)" title="Ver imagen"><span class="glyphicon glyphicon-eye-open"></span></button>
                                  </div> 
                                </div>                                
                              </div>                              
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <div class="row">
                                  <div class="col-md-12"><label>{{ 'texto_427' | translate }}</label></div>
                                </div>
                                <div class="row">
                                  <div class="col-md-9">
                                    <input type="text" [formControl]="inicio1" class="form-control" [(ngModel)]="inicio1F"  value="{{inicio1F}}" readonly>  
                                  </div>
                                  <div class="col-md-3">
                                    <span class="btn btn-primary btn-file">
                                      <span class="glyphicon glyphicon-folder-open"></span> <input  type="file" class="form-control" id="inicio1" (change)="onFileChange($event,'inicio1')">                                
                                    </span>                                    
                                    <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button" (click)="openImg(inicio1F)" title="Ver imagen"><span class="glyphicon glyphicon-eye-open"></span></button>
                                  </div> 
                                </div>                                  
                              </div>                              
                            </div>                        
                          </div>
                          <div class="row">	
                            <div class="col-md-6">
                              <div class="form-group">
                                <div class="row">
                                  <div class="col-md-12"><label>{{ 'texto_428' | translate }}</label></div>
                                </div>
                                <div class="row">
                                  <div class="col-md-9">
                                    <input type="text" class="form-control" [formControl]="inicio2" [(ngModel)]="inicio2F"  value="{{inicio2F}}" readonly>  
                                  </div>
                                  <div class="col-md-3">
                                    <span class="btn btn-primary btn-file">
                                      <span class="glyphicon glyphicon-folder-open"></span> <input  type="file" class="form-control" id="inicio2" (change)="onFileChange($event,'inicio2')">                                
                                    </span>                                    
                                    <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button" (click)="openImg(inicio2F)" title="Ver imagen"><span class="glyphicon glyphicon-eye-open"></span></button>
                                  </div> 
                                </div>                                  
                              </div>                              
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <div class="row">
                                  <div class="col-md-12"><label>{{ 'texto_429' | translate }}</label></div>
                                </div>
                                <div class="row">
                                  <div class="col-md-9">
                                    <input type="text" class="form-control" [formControl]="inicio3" [(ngModel)]="inicio3F"  value="{{inicio3F}}" readonly>  
                                  </div>
                                  <div class="col-md-3">
                                    <span class="btn btn-primary btn-file">
                                      <span class="glyphicon glyphicon-folder-open"></span> <input  type="file" class="form-control" id="inicio3" (change)="onFileChange($event,'inicio3')">                                
                                    </span>                                    
                                    <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button" (click)="openImg(inicio3F)" title="Ver imagen"><span class="glyphicon glyphicon-eye-open"></span></button>
                                  </div> 
                                </div>                                 
                              </div>                              
                            </div>                        
                          </div>  
                          <div class="row">	
                            <div class="col-md-6">
                              <div class="form-group">
                                <div class="row">
                                  <div class="col-md-12"><label>{{ 'texto_430' | translate }}</label></div>
                                </div>
                                <div class="row">
                                  <div class="col-md-9">
                                    <input type="text" class="form-control" [formControl]="fondo_denunciante"  [(ngModel)]="fondo_denuncianteF"  value="{{fondo_denuncianteF}}" readonly>  
                                  </div>
                                  <div class="col-md-3">
                                    <span class="btn btn-primary btn-file">
                                      <span class="glyphicon glyphicon-folder-open"></span> <input type="file" class="form-control" id="fondo_denunciante" (change)="onFileChange($event,'fondo_denunciante')">                                
                                    </span>                                    
                                    <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button" (click)="openImg(fondo_denuncianteF)" title="Ver Wizard"><span class="glyphicon glyphicon-eye-open"></span></button>
                                  </div> 
                                </div>                                
                              </div>                              
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <div class="row">
                                  <div class="col-md-12"><label>{{ 'texto_431' | translate }}</label></div>
                                </div>
                                <div class="row">
                                  <div class="col-md-9">
                                    <input type="text" class="form-control"  [formControl]="wizard1" [(ngModel)]="wizard1F"  value="{{wizard1F}}" readonly>  
                                  </div>
                                  <div class="col-md-3">
                                    <span class="btn btn-primary btn-file">
                                      <span class="glyphicon glyphicon-folder-open"></span> <input type="file" class="form-control" id="wizard1" (change)="onFileChange($event,'wizard1')">                                
                                    </span>                                    
                                    <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button" (click)="openImg(wizard1F)" title="Ver imagen"><span class="glyphicon glyphicon-eye-open"></span></button>
                                  </div> 
                                </div>                                  
                              </div>                              
                            </div>                        
                          </div>
                          <div class="row">	
                            <div class="col-md-6">
                              <div class="form-group">
                                <div class="row">
                                  <div class="col-md-12"><label>{{ 'texto_432' | translate }}</label></div>
                                </div>
                                <div class="row">
                                  <div class="col-md-9">
                                    <input type="text" class="form-control"  [formControl]="wizard2" [(ngModel)]="wizard2F"  value="{{wizard2F}}" readonly>  
                                  </div>
                                  <div class="col-md-3">
                                    <span class="btn btn-primary btn-file">
                                      <span class="glyphicon glyphicon-folder-open"></span> <input type="file" class="form-control" id="wizard2" (change)="onFileChange($event,'wizard2')">                                
                                    </span>                                    
                                    <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button" (click)="openImg(wizard2F)" title="Ver Wizard"><span class="glyphicon glyphicon-eye-open"></span></button>
                                  </div> 
                                </div>                                  
                              </div>                              
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <div class="row">
                                  <div class="col-md-12"><label>{{ 'texto_433' | translate }}</label></div>
                                </div>
                                <div class="row">
                                  <div class="col-md-9">
                                    <input type="text" class="form-control" [(ngModel)]="wizard3F" [formControl]="wizard3"  value="{{wizard3F}}" readonly>  
                                  </div>
                                  <div class="col-md-3">
                                    <span class="btn btn-primary btn-file">
                                      <span class="glyphicon glyphicon-folder-open"></span> <input type="file" class="form-control" id="wizard3" (change)="onFileChange($event,'wizard3')">                                
                                    </span>                                    
                                    <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button" (click)="openImg(wizard3F)" title="Ver imagen"><span class="glyphicon glyphicon-eye-open"></span></button>
                                  </div> 
                                </div>                                 
                              </div>                              
                            </div>                        
                          </div>                                                  
                          <div class="row">	
                            <div class="col-md-6">
                              <div class="form-group">
                                <div class="row">
                                  <div class="col-md-12"><label>{{ 'texto_434' | translate }}</label></div>
                                </div>
                                <div class="row">
                                  <div class="col-md-9">
                                    <input type="text" class="form-control" [formControl]="wizard4" [(ngModel)]="wizard4F"  value="{{wizard4F}}" readonly>  
                                  </div>
                                  <div class="col-md-3">
                                    <span class="btn btn-primary btn-file">
                                      <span class="glyphicon glyphicon-folder-open"></span> <input  type="file" class="form-control" id="wizard4" (change)="onFileChange($event,'wizard4')">                                
                                    </span>                                    
                                    <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button" (click)="openImg(wizard4F)" title="Ver Wizard"><span class="glyphicon glyphicon-eye-open"></span></button>
                                  </div> 
                                </div>                                  
                              </div>                              
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <div class="row">
                                  <div class="col-md-12"><label>{{ 'texto_435' | translate }}</label></div>
                                </div>
                                <div class="row">
                                  <div class="col-md-9">
                                    <input type="text" class="form-control" [(ngModel)]="wizard5F" [formControl]="wizard5"  value="{{wizard5F}}" readonly>  
                                  </div>
                                  <div class="col-md-3">
                                    <span class="btn btn-primary btn-file">
                                      <span class="glyphicon glyphicon-folder-open"></span> <input type="file" class="form-control" id="wizard5" (change)="onFileChange($event,'wizard5')">                                
                                    </span>                                    
                                    <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button" (click)="openImg(wizard5F)" title="Ver imagen"><span class="glyphicon glyphicon-eye-open"></span></button>
                                  </div> 
                                </div>                                 
                              </div>                              
                            </div>                        
                          </div>
                          <div class="row">	
                            <div class="col-md-6">
                              <div class="form-group">
                                <div class="row">
                                  <div class="col-md-12"><label>{{ 'texto_436' | translate }}</label></div>
                                </div>
                                <div class="row">
                                  <div class="col-md-9">
                                    <input type="text" class="form-control" [(ngModel)]="wizard6F" [formControl]="wizard6" value="{{wizard6F}}" readonly>  
                                  </div>
                                  <div class="col-md-3">
                                    <span class="btn btn-primary btn-file">
                                      <span class="glyphicon glyphicon-folder-open"></span> <input  type="file" class="form-control" id="wizard6" (change)="onFileChange($event,'wizard6')">                                
                                    </span>                                    
                                    <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button" (click)="openImg(wizard6F)" title="Ver Wizard"><span class="glyphicon glyphicon-eye-open"></span></button>
                                  </div> 
                                </div>                                  
                              </div>                              
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <div class="row">
                                  <div class="col-md-12"><label>{{ 'texto_437' | translate }}</label></div>
                                </div>
                                <div class="row">
                                  <div class="col-md-9">
                                    <input type="text" class="form-control" [(ngModel)]="wizard7F" [formControl]="wizard7"  value="{{wizard7F}}" readonly>  
                                  </div>
                                  <div class="col-md-3">
                                    <span class="btn btn-primary btn-file">
                                      <span class="glyphicon glyphicon-folder-open"></span> <input type="file" class="form-control" id="wizard7" (change)="onFileChange($event,'wizard7')">                                
                                    </span>                                    
                                    <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button" (click)="openImg(wizard7F)" title="Ver imagen"><span class="glyphicon glyphicon-eye-open"></span></button>
                                  </div> 
                                </div>                                 
                              </div>                              
                            </div>                        
                          </div>
                          <div class="row">	
                            <div class="col-md-6">
                              <div class="form-group">
                                <div class="row">
                                  <div class="col-md-12"><label>{{ 'texto_438' | translate }}</label></div>
                                </div>
                                <div class="row">
                                  <div class="col-md-9">
                                    <input type="text" class="form-control" [(ngModel)]="logindenuncianteF" [formControl]="logindenunciante" value="{{logindenuncianteF}}" readonly>  
                                  </div>
                                  <div class="col-md-3">
                                    <span class="btn btn-primary btn-file">
                                      <span class="glyphicon glyphicon-folder-open"></span> <input  type="file" class="form-control" id="logindenunciante" (change)="onFileChange($event,'logindenunciante')">                                
                                    </span>                                    
                                    <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button" (click)="openImg(logindenuncianteF)" title="Ver Wizard"><span class="glyphicon glyphicon-eye-open"></span></button>
                                  </div> 
                                </div>                                  
                              </div>                              
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <div class="row">
                                  <div class="col-md-12"><label>{{ 'texto_439' | translate }}</label></div>
                                </div>
                                <div class="row">
                                  <div class="col-md-9">
                                    <input type="text" class="form-control" [(ngModel)]="cabeceramailF" [formControl]="cabeceramail" value="{{cabeceramailF}}" readonly>  
                                  </div>
                                  <div class="col-md-3">
                                    <span class="btn btn-primary btn-file">
                                      <span class="glyphicon glyphicon-folder-open"></span> <input  type="file" class="form-control" id="cabeceramail" (change)="onFileChange($event,'cabeceramail')">                                
                                    </span>                                    
                                    <button name="editar_doc" formtarget="_self" class="btn btn-primary btn-xs" type="button" (click)="openImg(cabeceramailF)" title="Ver imagen"><span class="glyphicon glyphicon-eye-open"></span></button>
                                  </div> 
                                </div>                                 
                              </div>                              
                            </div>                        
                          </div>                                                                             
                          <!--<button (click)="subirImagenes()" type = "submit"  class="btn btn-primary pull-right">{{ 'texto_384' | translate }}</button>-->
                          <div class="clearfix"></div>                          
                      </form>											
                    </div>
                  </div>								
                </div>
              </div>	
              
              <div class="row">
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-header card-header-primary">
                      <h4 class="card-title">{{ 'texto_397' | translate }}</h4>
                    </div>                    
                    <div class="content">					
                      <form  [formGroup]="validatingFormTextos" style="margin-left: 25px; margin-right: 25px;">
                        <div class="row"  style="padding-top:20px" >																										
                          <div class="col-md-12">
                            <div class="form-group">
                                <label class="bmd-label-floating">{{ 'texto_440' | translate }}</label>
                            </div>
                             <div class="form-group">
                                <mat-form-field>
                                  <mat-label>{{ 'texto_140' | translate }}</mat-label>
                                  <mat-select   [formControl]="id_texto"  id="id_texto"  [(ngModel)]="textos.id_texto" required (selectionChange)="onChangeTexto($event)" (click)="onClickTexto($event)">
                                    <mat-option matTooltip="{{txt.id_texto}}" *ngFor="let txt of textos2" [value]="txt.id_texto">
                                      {{txt.id_texto}}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                                <label class="bmd-label-floating">{{ 'texto_441' | translate }}</label>
                                <textarea class="form-control" [formControl]="texto_value" id="texto_value" [(ngModel)]="textoF" vcols="40" rows="5"  value="{{textoF}}"></textarea>
                            </div>
                          </div>	                          													                          												
                        </div>                        
                        <button (click)="guardarTextos()" type = "submit"  class="btn btn-primary pull-right">{{ 'texto_384' | translate }}</button>
                        <div class="clearfix"></div>
                      </form>
                    </div>
                  </div>
                </div>                

                <div class="col-md-6">
                  <div class="card">
                    <div class="card-header card-header-primary">
                      <h4 class="card-title">{{ 'texto_398' | translate }}</h4>
                    </div>                  
                    <div class="content">					
                      <form  [formGroup]="validatingFormClausulas" style="margin-left: 25px; margin-right: 25px;">
                        <div class="row"  style="padding-top:20px" >																										
                          <div class="col-md-12">
                            <div class="form-group">
                                <label class="bmd-label-floating">{{ 'texto_442' | translate }}</label>
                            </div>
                             <div class="form-group">
                                <mat-form-field>
                                  <mat-label>{{ 'texto_140' | translate }}</mat-label>
                                  <mat-select   [formControl]="id_clausula"  id="id_clausula"  [(ngModel)]="clausulas.id_clausula" required (selectionChange)="onChangeClausula($event)" (click)="onClickClausula($event)">
                                    <mat-option matTooltip="{{txt.id_clausula}}" *ngFor="let txt of clausulas2" [value]="txt.id_clausula">
                                      {{txt.id_clausula}}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                                <label class="bmd-label-floating">{{ 'texto_443' | translate }}</label>
                                <textarea class="form-control" [formControl]="clausula_value" id="clausula_value" [(ngModel)]="clausulaF" vcols="40" rows="5"  value="{{clausulaF}}"></textarea>
                            </div>
                          </div>	                          													                          												
                        </div>                        
                        <button (click)="guardarClausulas()" type = "submit"  class="btn btn-primary pull-right">{{ 'texto_384' | translate }}</button>
                        <div class="clearfix"></div>
                      </form>
                    </div>
                  </div>
                </div>                
              </div>               

        </div>
</section>	 






