
<section style="position: absolute;width: 100%;z-index: 1;" class="image-container" style="background-image: url(assets/img/denunciante/login-denunciante.jpg);">
    <div class="container">
        <!--<a routerLink="/" class="logo">-->
        <a (click) = "inicio();" class="logo">     
            <img src="assets/img/logos/logo.png" style="height: 75px;">
        </a>
        <div class="row form-box" style="margin-top: 6%;float: right;">
          <div class="col-10 m-5">
                <h1 class="title-form">{{ 'texto_360' | translate }}</h1>
				<p class="description-form">{{ 'texto_361' | translate }}</p>
                <div class="new-form">
                    <label class="label-form" for="formId">* {{ 'texto_362' | translate }}:</label>
                    <input type="text" id="formId" class="form-control input-form" [formControl]="formId"
                        mdbInput mdbValidate>
                    <mdb-error style="top:126px !important;margin-left:0" *ngIf="formId.invalid && (formId.dirty || formId.touched)">
                        {{ 'texto_61' | translate }}
                    </mdb-error>
                    <mdb-success style="top:126px !important;margin-left:0" *ngIf="formId.valid && (formId.dirty || formId.touched)">
                        {{ 'texto_62' | translate }}
                    </mdb-success>
                    <label class="label-form" for="formPin">* {{ 'Pin' | translate }}:</label>
                    <input type="password" id="formPin" class="form-control input-form" [formControl]="formPin"
                        mdbInput mdbValidate>
                    <mdb-error style="top:200px !important;margin-left:0" *ngIf="formPin.invalid && (formPin.dirty || formPin.touched)">
                        {{ 'texto_61' | translate }}
                    </mdb-error>
                    <mdb-success style="top:200px !important;margin-left:0" *ngIf="formPin.valid && (formPin.dirty || formPin.touched)">
                        {{ 'texto_62' | translate }}
                    </mdb-success>              
                </div>
                
                <div class="text-center mt-4">
                    <button (click)="enviar()" style="color:#fff" mdbBtn rounded="true" class=" new-btn" mdbWavesEffect>{{ 'texto_41' | translate }}
                    </button>
                  </div>
            </div>
        </div>
    </div>
</section>




