<section style="position: absolute;width: 100%;z-index: 1;" class="image-container" style="background-image: url(assets/img/wizard/fondo-1.jpg);">
    <div class="row">
      <div class="col m-5" >
        <a (click) = "inicio();" class="logo"><img src="assets/img/logos/logo.png" style="height: 75px;"></a>
      </div>
    </div>    
    <div class="container" style="margin-left: 15%;">
        <div class="row new-box" style="margin-top: 50px;">
          <div class="col m-5" >
            <h1 class="title">{{ 'texto_143' | translate }}.</h1>
            <p class="text-description" [hidden]="IsAnonimo"><strong>{{ 'texto_144' | translate }}.</strong></p>             
            <p class="text-description"><strong>{{ 'texto_145' | translate }}: {{pin_code}} con el siguiente identificador {{idDenuncia}} {{ 'texto_146' | translate }}.</strong></p>
            <p><a class="new-btn"routerLink="/consultar-denuncia" role="button">{{ 'texto_147' | translate }}</a></p>
          </div>
        </div>
    </div>
</section>