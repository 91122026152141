<button type="button" mdbBtn color="default" rounded="true" data-toggle="modal" data-target="#login" (click)="frame.show()"
  mdbWavesEffect><i class="fa fa-user"></i></button>

  <div mdbModal #frame="mdbModal" class="modal fade left" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
<div class="modal-dialog" role="document">
 <div class="modal-content">
    <div class="modal-header text-center">
      <img src="assets/img/logos/logo.png" width="160px" style="width: 250px;height: 75px;object-fit: contain;">
       <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame.hide()">
         <span aria-hidden="true">&times;</span>
       </button>
     </div>
   <div class="modal-body mx-3">
     <div class="md-form mb-5">
       <mdb-icon fas icon="envelope" class="prefix grey-text" style="display: contents;"></mdb-icon>
       <input type="email" id="defaultForm-email" [formControl]="loginFormModalEmail" class="form-control"
              mdbInput mdbValidate>
       <label for="defaultForm-email">Email</label>
       <mdb-error style="margin-left: 80%;top:-12px !important;"
         *ngIf="loginFormModalEmail.invalid && (loginFormModalEmail.dirty || loginFormModalEmail.touched)">
         {{ 'texto_61' | translate }}
       </mdb-error>
       <mdb-success style="margin-left: 80%;top:-12px !important;"
         *ngIf="loginFormModalEmail.valid && (loginFormModalEmail.dirty || loginFormModalEmail.touched)">{{ 'texto_62' | translate }}
       </mdb-success>
     </div>

     <div class="md-form mb-4">
       <mdb-icon fas icon="lock" class="prefix grey-text" style="display: contents;"></mdb-icon>
       <input type="password" id="defaultForm-pass" [formControl]="loginFormModalPassword" class="form-control"
              mdbInput mdbValidate>
       <label for="defaultForm-pass">Password</label>
       <mdb-error style="margin-left: 80%;top:-12px !important;"
         *ngIf="loginFormModalPassword.invalid && (loginFormModalPassword.dirty || loginFormModalPassword.touched)">
         {{ 'texto_61' | translate }}
       </mdb-error>
       <mdb-success style="margin-left: 80%;top:-12px !important;"
         *ngIf="loginFormModalPassword.valid && (loginFormModalPassword.dirty || loginFormModalPassword.touched)">
         {{ 'texto_62' | translate }}
       </mdb-success>
       <div [hidden]="!loginOk" class="alert alert-danger" role="alert" style="text-align: center !important;">
         <span style="font-size: 10px;"><b>Error: </b>{{ 'texto_522' | translate }}</span>
      </div>
      <div [hidden]="!loginOkBlock" class="alert alert-danger" role="alert" style="text-align: center !important;">
        <span style="font-size: 10px;"><b>Error: </b>{{ 'texto_523' | translate }}</span>
     </div>
     <div [hidden]="!loginNoUser" class="alert alert-danger" role="alert" style="text-align: center !important;">
      <span style="font-size: 10px;"><b>Error: </b>{{ 'texto_524' | translate }}</span>
     </div>     
    </div>
 
   </div>
   <div class="modal-footer d-flex justify-content-center">
     <button (click)="login()" mdbBtn color="default" class="waves-light" mdbWavesEffect>Login</button>
   </div>
 </div>
</div>
</div>
