<style>
    #status-buttons div.active span.round-tabs.one {
        background: rgb(34, 194, 34);
        color: #fff;
    }

    #status-buttons div.active span.round-tabs.three {
        background: #3e5e9a;
        color: #fff;
    }

    #status-buttons div.active span.round-tabs.four {
        background: #f1685e;
        color: #fff;
    }
    .nav-tabs > li div {
        width: 70px;
        height: 70px;
        margin: 20px auto;
        border-radius: 100%;
        padding: 0;
    }
    .nav-tabs>li>div {
        line-height: 1.42857143;
        border: 1px solid transparent;
    }

    .nav>li>div {
        position: relative;
        display: block;
    }
</style>

<div class="board-inner" id="status-buttons">
    <ul class="nav nav-tabs" id="myTab">
        <div class="liner"></div>
        <li>
            <div [className]="isActiveTipo ? 'active' : ''"  >
                <span class="round-tabs one">
                    <i class="glyphicon glyphicon-tasks"></i>
                </span>
            </div>
        </li>
        <li>
            <div [className]="isActiveDenunciante ? 'active' : ''">
                <span class="round-tabs one">
                    <i class="glyphicon glyphicon-user"></i>
                </span>
            </div>
        </li> 
        <li>
            <div [className]="isActiveReceptor ? 'active' : ''" >
                <span class="round-tabs one">
                    <i class="glyphicon glyphicon-screenshot"></i>
                </span>
            </div>
        </li>   
        <li>
            <div [className]="isActiveDatosDenuncia ? 'active' : ''">
                <span class="round-tabs one">
                    <i class="glyphicon glyphicon-pencil"></i>
                </span>
            </div>
        </li>    
        <!-- circular user icon -->
        <li>
            <div [className]="isActiveTestigo ? 'active' : ''">
                <span class="round-tabs three">
                    <i class="glyphicon glyphicon-eye-open"></i>
                </span>
            </div>
        </li>

        <!-- circular tasks icon -->
         <li>
            <div [className]="isActiveArchivos ? 'active' : ''">
                <span class="round-tabs three">
                    <i class="glyphicon glyphicon-paperclip"></i>
                </span> 
            </div>
        </li>

        <!-- circular home icon -->
        <li>
            <div [className]="isActiveFinalizarDenuncia ? 'active' : ''">
                <span class="round-tabs four">
                    <i class="glyphicon glyphicon-ok"></i>
                </span>
            </div>
        </li>

        <!-- circular ok icon -->
        
                
    </ul>
    <div class="clearfix"></div>
</div>

<!-- Close the Splash screen -->
<script src="assets/js/loading-bars.js"></script>