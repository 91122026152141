import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from '../model/datosDenuncia';
import { Testigo } from '../model/testigo';
import { Documento } from '../model/documento';
import { DatePipe } from '@angular/common'
import { Comentario } from '../model/comentario';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { environment } from "../../environments/environment"
import { HttpClient  } from '@angular/common/http';
import { Usuarios } from '../model/usuarios';

@Component({
  selector: 'app-ver-denuncia',
  templateUrl: './ver-denuncia.component.html',
  styleUrls: ['./ver-denuncia.component.css'],
  providers:[DatePipe]

})
export class VerDenunciaComponent implements OnInit {
  validatingForm: FormGroup;
  pin_code: string;
  denuncia: DatosDenuncia[];
  testigo: Testigo[];
  documento: Documento[];
  bExisteTestigo = true;
  bExisteDocumento = true;
  id: string;
  comentarios: Comentario[];
  avatar: string;
  showChat: boolean = false;
  show: boolean = true;
  progressBar:number = 0;
  backgroundColor:string = 'red';
  ipAddress = '';
  sendChat: boolean = false;
  usuario_denuncia: Usuarios[];

  constructor(private apiService: ApiService,private router: Router, private auth: AuthService, private toastr: ToastrService, private translate: TranslateService, private actRoute: ActivatedRoute,private datepipe: DatePipe,private http:HttpClient) { }

  ngOnInit() {
    
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.id = this.actRoute.snapshot.params.id;

    this.validatingForm = new FormGroup({
      comentarioDenuncia: new FormControl('')
    });
    this.avatar =  environment.baseAvatar + "social.png";
    this.translate.setDefaultLang(language);
    this.pin_code = this.actRoute.snapshot.params.id;
    this.apiService.searchComplaintById(this.pin_code,language).subscribe((denuncia: DatosDenuncia[])=>{
      this.denuncia = denuncia;

      if(denuncia.length == 0){
       // this.getIPAddress();
        this.toastr.error(this.translate.instant('texto_189'), this.translate.instant('texto_190'));
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['consultar-denuncia']);
        });
      }else{
        let indice = localStorage.getItem("verdenuncia");
        if(indice == "1"){
            this.router.navigate(['consultar-denuncia']);
        }else{
          localStorage.setItem("verdenuncia", "1");
        }
        if(this.denuncia[0].id_fase == "0"){
          this.progressBar = 10;
          this.sendChat = true;
        }else if(this.denuncia[0].id_fase == "1"){
          this.progressBar = 25;
          this.showChat = true;
          this.backgroundColor = 'red';
          this.sendChat = true;
        }else if(this.denuncia[0].id_fase == "2"){
          this.progressBar = 50;
          this.showChat = true;
          this.backgroundColor = '#F1C453';
          this.sendChat = true;
        }else if(this.denuncia[0].id_fase == "3"){
          this.showChat = true;
          this.progressBar = 65;
          this.backgroundColor = '#EFEA5A';
          this.sendChat = true;
        }else if(this.denuncia[0].id_fase == "4"){
          this.progressBar = 75;
          this.backgroundColor = '#B9E769';
          this.show = false;
        }else if(this.denuncia[0].id_fase == "5"){
          this.progressBar = 85;
          this.backgroundColor = '#83E377';
          this.show = false;
        }else if(this.denuncia[0].id_fase == "6"){
          this.progressBar = 100;
          this.backgroundColor = '#16DB93';
          this.show = false;
        }

        let denunciante = this.denuncia[0].denunciante_correo;
        if(denunciante == ""){
          denunciante = "Denunciante";
        }
        this.apiService.searchWitnessByUserComplaint(this.denuncia[0].id_denuncia, denunciante).subscribe((testigo: Testigo[])=>{
          this.testigo = testigo;
          if(testigo.length == 0){
            this.bExisteTestigo = false;
          }
        });

        this.apiService.searchDocumentsByUserComplaint(this.denuncia[0].id_denuncia,denunciante).subscribe((documento: Documento[])=>{
          this.documento = documento;
          if(documento.length == 0){
            this.bExisteDocumento = false;
          }
        });
      }

      this.apiService.searchCommentsByComplaint(this.denuncia[0].id_denuncia,false).subscribe((comentario: Comentario[])=>{
        this.comentarios = comentario;
        if(this.comentarios.length>0){
          this.showChat = true;
        }
      });
    });
  }

  get comentarioDenuncia() {
    return this.validatingForm.get('comentarioDenuncia');
  }


  enviarMensaje(id_denuncia){
    let date = new Date();
    let _date =this.datepipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
    let nombre = "";
    if(this.denuncia[0].denunciante_nombre != ""){
      nombre = this.denuncia[0].denunciante_nombre;
    }else{
        nombre = this.denuncia[0].id_usuario;
    }
    let comentario = this.comentarioDenuncia.value.replace(/<[^>]*>/g, ''); 
    if (comentario != null && comentario != ""){
      comentario = comentario.replace(/'/g, '');
      this.apiService.sendMessageChat(this.denuncia[0].id_sociedad,id_denuncia,nombre, comentario, _date, 'Denunciante',false).subscribe(
        res => {
          if(res == 200){ 
            this.apiService.buscarUsuarioDenuncia(id_denuncia,false).subscribe((usuarios: Usuarios[])=>{
              this.usuario_denuncia = usuarios; 
              if(usuarios.length > 0){
                let $clau = false;
                let clausulas_correo = localStorage.getItem("clausulas_correo");
                if(clausulas_correo == '1'){
                  $clau = true; 
                }            
                let url = environment.base + "/#/editar-denuncia/"+this.denuncia[0].id_denuncia;
                let message = "Estimado/a "  +  this.usuario_denuncia[0].id_user  + ", le informamos que se ha realizado un comentario en la denuncia "  + this.denuncia[0].id_denuncia  + ". Entre en el canal para leerlo.";
                this.apiService.sendEmail(this.usuario_denuncia[0].mail_user,'Nuevo comentario en la denuncia '+this.denuncia[0].id_denuncia, message,url,$clau, "Nuevo comentario en la denuncia").subscribe(
                res => {
                  if(res == 200){
                    this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));          
                  }else{
                    this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                  }         
                }); 
              }
              this.toastr.success(this.translate.instant('texto_363'), this.translate.instant('texto_364'));
              localStorage.setItem("verdenuncia", "");
            });
          }else{
            this.toastr.error('texto_29', 'texto_364');
          }
          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['ver-denuncia/', this.id]);
        }); 
      });
    }else{
      this.toastr.error(this.translate.instant('texto_481'), this.translate.instant('texto_484'));
    }
  }


  getIPAddress(){
    this.http.get("http://api.ipify.org/?format=json").subscribe((res:any)=>{
      this.ipAddress = res.ip;
    });
  }

  logout(){
    this.auth.logout();
    window.location.reload();
  }
}
