
<section style="position: absolute;width: 100%;z-index: 1;" class="image-container" style="background-image: url(assets/img/wizard/wizard-1.jpg);">  
    <div class="container" style="padding: 0;">
        <div class="board" style="width: 100% !important;">
          <button type="button" style="z-index:100;float: right;" class="btn btn-dark"  (click) = "hide();">X</button> 

            <!-- Navigation Area (Circular Tabs) -->
            <msw-navbar></msw-navbar>
            <!-- End Navigation Area (Circular Tabs) -->

            <!-- Content Area -->
            <div class="tab-content">
              <form #tipoForm="ngForm" class="editForm" novalidate>
                  <div class="tab-pane fade in active">
                      <h4 class="head text-center"> {{ title | translate }}</h4>
                      <div class='row'>
                          <div class='col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8'>
                              <div class="row">
                                  <div class='col-xs-12 col-sm-6'>
                                      <div class="form-group">
                                          <label class="control-label" for="tipoHecho">{{ 'texto_67' | translate }}</label> <br> 
                                            <mat-form-field>
                                              <mat-label>{{ 'texto_140' | translate }}</mat-label>
                                              <mat-select #empresa="ngModel" id="empresa" name="empresa" (selectionChange)="onChange($event)" [(ngModel)]="selectedCompany" (click)="onClick($event)" required>
                                                <mat-option *ngFor="let emp of empresas" [value]="emp" >
                                                  {{emp.n_empresa}}
                                                </mat-option>
                                              </mat-select>
                                            </mat-form-field>

                                      </div>
                                  </div>
                                  <div class='col-xs-12 col-sm-6'>
                                      <div class="form-group">
                                          <label class="control-label" for="tipoHecho">{{ 'texto_71' | translate }}</label> <br> 
                                            <mat-form-field>
                                              <mat-label>{{ 'texto_140' | translate }}</mat-label>
                                              <mat-select  #tipoDenuncia="ngModel" id="tipoDenuncia" name="tipoDenuncia" [(ngModel)]="tipoHecho.tipoDenuncia" required (selectionChange)="onChangeComplaint($event)" (click)="onClickComplaint($event)">
                                                <mat-option matTooltip="{{denuncia.nombre}}" *ngFor="let denuncia of denuncias" [value]="denuncia.id_tp_denuncia">
                                                  {{denuncia.nombre}}
                                                </mat-option>
                                              </mat-select>
                                            </mat-form-field>
                                      </div>
                                  </div>
                                  <div style="font-size: 15px;line-height: 16px;" [innerHtml]="descripcion | safe: 'html'"></div>
                              </div>
                              
                                  
                              <div class="form-group text-center" style="margin-top: 30px;">
                                  <button class="btn btn-success btn-outline-rounded btn-info" [disabled]="!tipoForm.valid" (click)="goToNext(tipoForm)">{{ 'texto_141' | translate }}  <span style="margin-left:10px;" class="glyphicon glyphicon-arrow-right"></span></button>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div #recapthca></div>
              </form>
            </div>
        </div>
      </div>
    </section>
