import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../api.service';
import { Rol } from '../model/rol';
import { OptionSesion } from '../model/optionSesion';
import { ToastrService } from 'ngx-toastr';
import { DatosDenuncia } from '../model/datosDenuncia';


@Component({
  selector: 'app-nav-fixed',
  templateUrl: './nav-fixed.component.html',
  styleUrls: ['./nav-fixed.component.css']
})
export class NavFixedComponent implements OnInit {
  roles: Rol[];
  roleName: string = "";
  nombreUsuario: string = "";
  nombreUsuario2: string = "";
  sociedadUsuario: string = "";
  option: OptionSesion[];
  menuNavbar: boolean = false;
  @Input() IsHiddenLanding: boolean;
  IsAuth = false;
  denuncia: DatosDenuncia[];
  numberDenuncias:number = 0;
  urlPhoto: string;

  constructor(private router: Router, private auth: AuthService,private apiService: ApiService, private translate: TranslateService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.IsAuth = this.auth.isLoggedIn();
    this.urlPhoto = this.auth.getPhotoUser();
    if(this.IsAuth){
      this.IsHiddenLanding = false;
    }else{
      this.IsHiddenLanding = true;
    }

    this.nombreUsuario = this.auth.getIdUser();
      if(this.auth.getIdUser() != null){
        this.apiService.getSessionPropertiesUser(this.auth.getIdUser()).subscribe((option: OptionSesion[])=>{
          this.option = option;
          if(option[0].id_sociedad != ""){ 
            this.auth.setLanguage(option[0].idioma);
            this.auth.setSociedad(option[0].id_sociedad);
            this.auth.setNameSociedad(option[0].n_sociedad);
            if(this.auth.getLanguage()=="en"){
              this.translate.setDefaultLang('en');
            }

            this.sociedadUsuario = this.auth.getNameSociedad();
            this.nombreUsuario2 = this.auth.getNameUser() + " " + this.auth.getSurnameUser();
            
            
            this.apiService.searchRoleByUserSociety(this.auth.getIdUser(),this.auth.getSociedad(),'1').subscribe((roles: Rol[])=>{
              this.roles = roles;
              for(let i=0;i<this.roles.length;i++){
                this.roleName = this.roleName + ","+ this.roles[i].n_rol;
              }   
              
             // let fases = "TODAS";
              let fases = "";
              let delimiter = ",";
                  if(this.roleName.includes("RECEPTOR") ){
                    fases = "0,1,3"; 
                  }
                  if(this.roleName.includes("DECISOR")){
                    if(fases != ""){
                      fases  = fases + delimiter;
                    }
                    fases = fases + "4"; 
                  }
                  if(this.roleName.includes("INVESTIGADOR")){
                    if(fases != ""){
                      fases  = fases + delimiter;
                    }
                    fases = fases + "2,3"; 
                  }
                  if(this.roleName.includes("COMPLIANCE")){
                    if(fases != ""){
                      fases  = fases + delimiter;
                    }
                    fases = fases + "5"; 
                  }
                  let language = this.auth.getLanguage();
                  if(language == "" || language == null){
                    language = 'es';
                  }
                  this.translate.setDefaultLang(language);
              //let fases = "0,1,2,3,4,5"
              this.apiService.searchComplaintBySocietyPhase(this.auth.getSociedad(),fases,this.auth.getIdUser(),language).subscribe((denuncia: DatosDenuncia[])=>{
                this.denuncia = denuncia;
                this.numberDenuncias = this.denuncia.length;
              });
            });
          }else{
            this.toastr.error(this.translate.instant('texto_242'), this.translate.instant('texto_243"'));
          }
        });
      }  else{
        this.apiService.searchRoleByUserSociety(this.auth.getIdUser(),this.auth.getSociedad(),'1').subscribe((roles: Rol[])=>{
          this.roles = roles;
          for(let i=0;i<this.roles.length;i++){
            this.roleName = this.roles[i].n_rol;
            break;           
          }   
          let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
          let fases = "TODAS";
          //let fases = "0,1,2,3,4,5"
          this.apiService.searchComplaintBySocietyPhase(this.auth.getSociedad(),fases,this.auth.getIdUser(),language).subscribe((denuncia: DatosDenuncia[])=>{
            this.denuncia = denuncia;
            this.numberDenuncias = this.denuncia.length;
          });
        });
           
      }
  }

  logout(){
    this.auth.logout();
    window.location.reload();
  }
  
  toggleSidebar(){
    this.menuNavbar = !this.menuNavbar;
  }

  goProfile(){
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(['profile']);
    }); 
  }
  goOption(){
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(['options']);
    }); 
  }
}
