
<body >
  <div *ngIf="IsHiddenLanding == false; else elseBlock">
  <session-expiration-alert [alertAt]="10" [startTimer]="true"></session-expiration-alert>

    <div class="wrapper" [ngStyle]="{'background-color': !isVisible ? 'white' : 'inherit' }" style="top:60px;z-index: 10;" >
      <button id="btnAgrupado" type="button" (click)="showMenu()" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation" style="background-color: rgba(0, 0, 0, 0); border: none; width: 40px; height: 40px; z-index: 9999; position: fixed; left: 200px; margin-top: 46px;"><i class="fa fa-bars" aria-hidden="true"></i></button>
      <div id="sidebar" class="sidebar" data-color="purple" data-background-color="white" data-image="../assets/img/sidebar-1.jpg" [hidden]="!isVisible || menuNavbar" style="top: 110px;background-color: {{color}};" >
        
        <div class="sidebar-wrapper" >
          <ul class="nav">
            <div [hidden]="bloque1" style="margin-bottom: 15px;">
              <li>
                <div [hidden]="isAgrupado" class="" style="border-bottom: 1px solid;">
                  <a  class="simple-text logo-normal" style="font-weight: bold;font-weight: bold;" (click)="changeMenu1()"  >
                    {{ 'texto_98' | translate }} <!--<i style="float:right;font-size: 12px;margin-top: 5px;" class="fa fa-angle-down" aria-hidden="true"></i>-->
                  </a>
                </div>
              </li>
              
              <li class="nav-item " routerLink="/dashboard" [routerLinkActive]="['active']" [hidden]="seeMenu1" >
                <a class="nav-link" >
                  <i class="material-icons">dashboard</i>
                  <p>Dashboard</p>
                </a>
              </li>
              <li class="nav-item " routerLink="/denuncias" [routerLinkActive]="['active']" [hidden]="!hasPermitDenuncia || seeMenu1">
                <a class="nav-link" >
                  <i class="material-icons">assignment</i>
                  <p>{{ 'texto_349' | translate }}</p>
                </a>
              </li>
              <li class="nav-item " routerLink="/todas-denuncias" [routerLinkActive]="['active']" [hidden]="!hasPermitAllDenuncias || seeMenu1">
                <a class="nav-link" >
                  <i class="material-icons">assessment</i>
                  <p>{{ 'texto_137' | translate }}</p>
                </a>
              </li>
          </div>
          <div [hidden]="!bloque3">
            <li>
              <div [hidden]="isAgrupado" class="" style="border-bottom: 1px solid;">
                <a  class="simple-text logo-normal" style="font-weight: bold;font-weight: bold;" (click)="changeMenu3()"  >
                  {{ 'texto_468' | translate }} <!--<i style="float:right;font-size: 12px;margin-top: 5px;" class="fa fa-angle-down" aria-hidden="true"></i>-->
                </a>
              </div>
            </li>
            
            <li class="nav-item " routerLink="/consultas" [routerLinkActive]="['active']" [hidden]="seeMenu3">
              <a class="nav-link" >
                <i class="material-icons  ">query_stats</i>
                <p>{{ 'texto_469' | translate }}</p>
              </a>
            </li>
            
        </div>
          <div [hidden]="!bloque2">
            <li>
              <div class="" style="border-top: 1px solid;border-bottom: 1px solid;">
                <a [hidden]="isAgrupado"  class="simple-text logo-normal" style="font-weight: bold;font-weight: bold;" (click)="changeMenu2()" >
                  {{ 'texto_350' | translate }} <!--<i style="float:right;font-size: 12px;margin-top: 5px;" class="fa fa-angle-down" aria-hidden="true"></i>-->
                </a>
              </div>
            </li>
            <li class="nav-item " routerLink="/empresas" [routerLinkActive]="['active']" [hidden]="!hasPermit" [hidden]="!seeMenu2">
              <a class="nav-link"  >
                <i class="material-icons">account_balance</i>
                <p>{{ 'texto_351' | translate }}</p>
              </a>
            </li>
            <li class="nav-item " routerLink="/usuarios" [routerLinkActive]="['active']" [hidden]="!hasPermit" [hidden]="!seeMenu2">
              <a class="nav-link" >
                <i class="material-icons">people</i>
                <p>{{ 'texto_352' | translate }}</p>
              </a>
            </li>
            <li class="nav-item "  routerLink="/tipo-denuncia" [routerLinkActive]="['active']" [hidden]="!hasPermit" [hidden]="!seeMenu2">
              <a class="nav-link">
                <i class="material-icons">gavel</i>
                <p>{{ 'texto_353' | translate }}</p>
              </a>
            </li>
            <li class="nav-item " routerLink="/medida-cautelar" [routerLinkActive]="['active']" [hidden]="!hasPermit" [hidden]="!seeMenu2">
              <a class="nav-link">
                <i class="material-icons">security</i>
                <p>{{ 'texto_156' | translate }}</p>
              </a>
            </li>
            <li class="nav-item " routerLink="/tipo-relacion" [routerLinkActive]="['active']" [hidden]="!hasPermit" [hidden]="!seeMenu2">
              <a class="nav-link" >
                <i class="material-icons">share</i>
                <p>{{ 'texto_354' | translate }}</p>
              </a>
            </li>
            <li class="nav-item "  routerLink="/abogado" [routerLinkActive]="['active']" [hidden]="!hasPermit" [hidden]="!seeMenu2">
              <a class="nav-link" >
                <i class="material-icons">layers</i>
                <p>{{ 'texto_57' | translate }}</p>
              </a>
            </li>
            <li class="nav-item "  routerLink="/advanced-setting" [routerLinkActive]="['active']" [hidden]="!hasPermit" [hidden]="!seeMenu2">
              <a class="nav-link" >
                <i class="material-icons">settings</i>
                <p>{{ 'texto_356' | translate }}</p>
              </a>
            </li>
          </div>
            <li class="nav-item " (click)="logout()" [routerLinkActive]="['active']" >
              <a class="nav-link" >
                <i class="material-icons">power_settings_new</i>
                <p>{{ 'texto_75' | translate }}</p>
              </a>
            </li>
            
          </ul>
        </div>
      </div>
      <div id="main-panel" class="main-panel" style="background-color: white;" [ngStyle]="{'width': !isVisible ? '100%' : 'calc(100% - 260px)' }"  [ngStyle]="{'width': menuNavbar ? '100%' : 'calc(100% - 260px)' }">
        <!-- Navbar -->
        <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top " style="position: relative;display: initial;" [ngStyle]="{'display': IsAuth ? 'block' : 'none' }">
          <div class="container-fluid" [ngStyle]="{'display': !isVisible ? 'block' : 'contents' }" >
          
            <button class="navbar-toggler" id="btntogle" type="button" (click)="showMenu()" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
              <span class="sr-only">Toggle navigation</span>
              <span class="navbar-toggler-icon icon-bar"></span>
              <span class="navbar-toggler-icon icon-bar"></span>
              <span class="navbar-toggler-icon icon-bar"></span>
            </button>
            
          </div>
        </nav>
        <!-- End Navbar -->
        <router-outlet></router-outlet>
    
        
      </div>
    </div>

  </div>   
  <ng-template #elseBlock>

    <div class="wrapper" [ngStyle]="{'background-color': !isVisible ? 'white' : 'inherit' }" style="top:0px;z-index: 10;" >
      <div id="sidebar" class="sidebar" data-color="purple" data-background-color="white" data-image="../assets/img/sidebar-1.jpg" [hidden]="!isVisible" style="top: 0px;background-color: {{color}};" >
        
        <div class="sidebar-wrapper" >
          <ul class="nav">
            <div [hidden]="bloque1">
            <li>
              <div class="" style="border-top: 1px solid;border-bottom: 1px solid;">
                <a class="simple-text logo-normal" style="font-weight: bold;font-weight: bold;" (click)="changeMenu1()" >
                  {{ 'texto_98' | translate }} <!--<i style="float:right;font-size: 12px;margin-top: 5px;" class="fa fa-angle-down" aria-hidden="true"></i>-->
                </a>
              </div>
            </li>
            
            <li class="nav-item " routerLink="/dashboard" [routerLinkActive]="['active']" [hidden]="seeMenu1" >
              <a class="nav-link" >
                <i class="material-icons">dashboard</i>
                <p>Dashboard</p>
              </a>
            </li>
            <li class="nav-item " routerLink="/todas-denuncias" [routerLinkActive]="['active']" [hidden]="!hasPermitAllDenuncias" [hidden]="seeMenu1" >
              <a class="nav-link" >
                <i class="material-icons">pie_chart</i>
                <p>{{ 'texto_348' | translate }}</p>
              </a>
            </li>
            <li class="nav-item " routerLink="/denuncias" [routerLinkActive]="['active']" [hidden]="!hasPermitDenuncia || seeMenu1">
              <a class="nav-link" >
                <i class="material-icons">assignment</i>
                <p>{{ 'texto_349' | translate }}</p>
              </a>
            </li>
            <li class="nav-item " routerLink="/todas-denuncias" [routerLinkActive]="['active']" [hidden]="!hasPermitAllDenuncias || seeMenu1">
              <a class="nav-link" >
                <i class="material-icons">assessment</i>
                <p>{{ 'texto_137' | translate }}</p>
              </a>
            </li>
          </div>
          <div [hidden]="!bloque3" style="margin-bottom: 15px;">
            <li>
              <div class="" style="border-top: 1px solid;border-bottom: 1px solid;">
                <a class="simple-text logo-normal" style="font-weight: bold;font-weight: bold;" (click)="changeMenu3()" >
                  {{ 'texto_467' | translate }} <!--<i style="float:right;font-size: 12px;margin-top: 5px;" class="fa fa-angle-down" aria-hidden="true"></i>-->
                </a>
              </div>
            </li>
            
            <li class="nav-item " routerLink="/consultas" [routerLinkActive]="['active']" [hidden]="!hasPermitDenuncia || seeMenu3">
              <a class="nav-link" >
                <i class="material-icons  ">query_stats</i>
                <p>{{ 'texto_468' | translate }}</p>
              </a>
            </li>
            
        </div>
            <div [hidden]="!bloque2">
              <li>
                <div class="" style="border-top: 1px solid;border-bottom: 1px solid;">
                  <a  class="simple-text logo-normal" style="font-weight: bold;font-weight: bold;" (click)="changeMenu2()" >
                    {{ 'texto_350' | translate }} <!--<i style="float:right;font-size: 12px;margin-top: 5px;" class="fa fa-angle-down" aria-hidden="true"></i>-->
                  </a>
                </div>
              </li>
              <li class="nav-item " routerLink="/empresas" [routerLinkActive]="['active']" [hidden]="!hasPermit" [hidden]="!seeMenu2">
                <a class="nav-link"  >
                  <i class="material-icons">account_balance</i>
                  <p>{{ 'texto_351' | translate }}</p>
                </a>
              </li>
              <li class="nav-item " routerLink="/usuarios" [routerLinkActive]="['active']" [hidden]="!hasPermit" [hidden]="!seeMenu2">
                <a class="nav-link" >
                  <i class="material-icons">people</i>
                  <p>{{ 'texto_352' | translate }}</p>
                </a>
              </li>
              <li class="nav-item "  routerLink="/tipo-denuncia" [routerLinkActive]="['active']" [hidden]="!hasPermit" [hidden]="!seeMenu2">
                <a class="nav-link">
                  <i class="material-icons">gavel</i>
                  <p>{{ 'texto_353' | translate }}</p>
                </a>
              </li>
              <li class="nav-item " routerLink="/medida-cautelar" [routerLinkActive]="['active']" [hidden]="!hasPermit" [hidden]="!seeMenu2">
                <a class="nav-link">
                  <i class="material-icons">security</i>
                  <p>{{ 'texto_156' | translate }}</p>
                </a>
              </li>
              <li class="nav-item " routerLink="/tipo-relacion" [routerLinkActive]="['active']" [hidden]="!hasPermit" [hidden]="!seeMenu2">
                <a class="nav-link" >
                  <i class="material-icons">share</i>
                  <p>{{ 'texto_354' | translate }}</p>
                </a>
              </li>
              <li class="nav-item "  routerLink="/abogado" [routerLinkActive]="['active']" [hidden]="!hasPermit" [hidden]="!seeMenu2">
                <a class="nav-link" >
                  <i class="material-icons">layers</i>
                  <p>{{ 'texto_355' | translate }}</p>
                </a>
              </li>
              <li class="nav-item "  routerLink="/advanced-setting" [routerLinkActive]="['active']" [hidden]="!hasPermit" [hidden]="!seeMenu2">
                <a class="nav-link" >
                  <i class="material-icons">settings</i>
                  <p>{{ 'texto_356' | translate }}</p>
                </a>
              </li>
            </div>
            <li class="nav-item " (click)="logout()" [routerLinkActive]="['active']" >
              <a class="nav-link" >
                <i class="material-icons">power_settings_new</i>
                <p>{{ 'texto_75' | translate }}</p>
              </a>
            </li>
            
          </ul>
        </div>
      </div>
      <div id="main-panel" class="main-panel" style="background-color: white;" [ngStyle]="{'width': !isVisible ? '100%' : 'calc(100% - 260px)' }" >
        <!-- Navbar -->
        <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top " style="position: relative;display: initial;" [ngStyle]="{'display': IsAuth ? 'block' : 'none' }">
          <div class="container-fluid" [ngStyle]="{'display': !isVisible ? 'block' : 'contents' }" >
          
            <button class="navbar-toggler" type="button" (click)="showMenu()" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
              <span class="sr-only">Toggle navigation</span>
              <span class="navbar-toggler-icon icon-bar"></span>
              <span class="navbar-toggler-icon icon-bar"></span>
              <span class="navbar-toggler-icon icon-bar"></span>
            </button>
            
          </div>
        </nav>
        <!-- End Navbar -->
        <router-outlet></router-outlet>
    
        
      </div>
    </div>

  </ng-template>


<!--   Core JS Files   -->
<script src="../assets/js/core/jquery.min.js"></script>
<script src="../assets/js/core/popper.min.js"></script>
<script src="../assets/js/core/bootstrap-material-design.min.js"></script>
<script src="../assets/js/plugins/perfect-scrollbar.jquery.min.js"></script>
<!-- Plugin for the momentJs  -->
<script src="../assets/js/plugins/moment.min.js"></script>
<!--  Plugin for Sweet Alert -->
<script src="../assets/js/plugins/sweetalert2.js"></script>
<!-- Forms Validations Plugin -->
<script src="../assets/js/plugins/jquery.validate.min.js"></script>
<!-- Plugin for the Wizard, full documentation here: https://github.com/VinceG/twitter-bootstrap-wizard -->
<script src="../assets/js/plugins/jquery.bootstrap-wizard.js"></script>
<!--	Plugin for Select, full documentation here: http://silviomoreto.github.io/bootstrap-select -->
<script src="../assets/js/plugins/bootstrap-selectpicker.js"></script>
<!--  Plugin for the DateTimePicker, full documentation here: https://eonasdan.github.io/bootstrap-datetimepicker/ -->
<script src="../assets/js/plugins/bootstrap-datetimepicker.min.js"></script>
<!--  DataTables.net Plugin, full documentation here: https://datatables.net/  -->
<script src="../assets/js/plugins/jquery.dataTables.min.js"></script>
<!--	Plugin for Tags, full documentation here: https://github.com/bootstrap-tagsinput/bootstrap-tagsinputs  -->
<script src="../assets/js/plugins/bootstrap-tagsinput.js"></script>
<!-- Plugin for Fileupload, full documentation here: http://www.jasny.net/bootstrap/javascript/#fileinput -->
<script src="../assets/js/plugins/jasny-bootstrap.min.js"></script>
<!--  Full Calendar Plugin, full documentation here: https://github.com/fullcalendar/fullcalendar    -->
<script src="../assets/js/plugins/fullcalendar.min.js"></script>
<!-- Vector Map plugin, full documentation here: http://jvectormap.com/documentation/ -->
<script src="../assets/js/plugins/jquery-jvectormap.js"></script>
<!--  Plugin for the Sliders, full documentation here: http://refreshless.com/nouislider/ -->
<script src="../assets/js/plugins/nouislider.min.js"></script>
<!-- Include a polyfill for ES6 Promises (optional) for IE11, UC Browser and Android browser support SweetAlert -->
<script src="https://cdnjs.cloudflare.com/ajax/libs/core-js/2.4.1/core.js"></script>
<!-- Library for adding dinamically elements -->
<script src="../assets/js/plugins/arrive.min.js"></script>
<!--  Google Maps Plugin    -->
<!-- Chartist JS -->
<script src="../assets/js/plugins/chartist.min.js"></script>
<!--  Notifications Plugin    -->
<script src="../assets/js/plugins/bootstrap-notify.js"></script>
<!-- Control Center for Material Dashboard: parallax effects, scripts for the example pages etc -->
<script src="../assets/js/material-dashboard.js?v=2.1.1" type="text/javascript"></script>
<!-- Material Dashboard DEMO methods, don't include it in your project! -->
<script src="../assets/demo/demo.js"></script>



<script>
  $(document).ready(function() {
    $().ready(function() {
      $sidebar = $('.sidebar');

      $sidebar_img_container = $sidebar.find('.sidebar-background');

      $full_page = $('.full-page');

      $sidebar_responsive = $('body > .navbar-collapse');

      window_width = $(window).width();

      fixed_plugin_open = $('.sidebar .sidebar-wrapper .nav li.active a p').html();

      if (window_width > 767 && fixed_plugin_open == 'Dashboard') {
        if ($('.fixed-plugin .dropdown').hasClass('show-dropdown')) {
          $('.fixed-plugin .dropdown').addClass('open');
        }

      }

      $('.fixed-plugin a').click(function(event) {
        // Alex if we click on switch, stop propagation of the event, so the dropdown will not be hide, otherwise we set the  section active
        if ($(this).hasClass('switch-trigger')) {
          if (event.stopPropagation) {
            event.stopPropagation();
          } else if (window.event) {
            window.event.cancelBubble = true;
          }
        }
      });

      $('.fixed-plugin .active-color span').click(function() {
        $full_page_background = $('.full-page-background');

        $(this).siblings().removeClass('active');
        $(this).addClass('active');

        var new_color = $(this).data('color');

        if ($sidebar.length != 0) {
          $sidebar.attr('data-color', new_color);
        }

        if ($full_page.length != 0) {
          $full_page.attr('filter-color', new_color);
        }

        if ($sidebar_responsive.length != 0) {
          $sidebar_responsive.attr('data-color', new_color);
        }
      });

      $('.fixed-plugin .background-color .badge').click(function() {
        $(this).siblings().removeClass('active');
        $(this).addClass('active');

        var new_color = $(this).data('background-color');

        if ($sidebar.length != 0) {
          $sidebar.attr('data-background-color', new_color);
        }
      });

      $('.fixed-plugin .img-holder').click(function() {
        $full_page_background = $('.full-page-background');

        $(this).parent('li').siblings().removeClass('active');
        $(this).parent('li').addClass('active');


        var new_image = $(this).find("img").attr('src');

        if ($sidebar_img_container.length != 0 && $('.switch-sidebar-image input:checked').length != 0) {
          $sidebar_img_container.fadeOut('fast', function() {
            $sidebar_img_container.css('background-image', 'url("' + new_image + '")');
            $sidebar_img_container.fadeIn('fast');
          });
        }

        if ($full_page_background.length != 0 && $('.switch-sidebar-image input:checked').length != 0) {
          var new_image_full_page = $('.fixed-plugin li.active .img-holder').find('img').data('src');

          $full_page_background.fadeOut('fast', function() {
            $full_page_background.css('background-image', 'url("' + new_image_full_page + '")');
            $full_page_background.fadeIn('fast');
          });
        }

        if ($('.switch-sidebar-image input:checked').length == 0) {
          var new_image = $('.fixed-plugin li.active .img-holder').find("img").attr('src');
          var new_image_full_page = $('.fixed-plugin li.active .img-holder').find('img').data('src');

          $sidebar_img_container.css('background-image', 'url("' + new_image + '")');
          $full_page_background.css('background-image', 'url("' + new_image_full_page + '")');
        }

        if ($sidebar_responsive.length != 0) {
          $sidebar_responsive.css('background-image', 'url("' + new_image + '")');
        }
      });

      $('.switch-sidebar-image input').change(function() {
        $full_page_background = $('.full-page-background');

        $input = $(this);

        if ($input.is(':checked')) {
          if ($sidebar_img_container.length != 0) {
            $sidebar_img_container.fadeIn('fast');
            $sidebar.attr('data-image', '#');
          }

          if ($full_page_background.length != 0) {
            $full_page_background.fadeIn('fast');
            $full_page.attr('data-image', '#');
          }

          background_image = true;
        } else {
          if ($sidebar_img_container.length != 0) {
            $sidebar.removeAttr('data-image');
            $sidebar_img_container.fadeOut('fast');
          }

          if ($full_page_background.length != 0) {
            $full_page.removeAttr('data-image', '#');
            $full_page_background.fadeOut('fast');
          }

          background_image = false;
        }
      });

      $('.switch-sidebar-mini input').change(function() {
        $body = $('body');

        $input = $(this);

        if (md.misc.sidebar_mini_active == true) {
          $('body').removeClass('sidebar-mini');
          md.misc.sidebar_mini_active = false;

          $('.sidebar .sidebar-wrapper, .main-panel').perfectScrollbar();

        } else {

          $('.sidebar .sidebar-wrapper, .main-panel').perfectScrollbar('destroy');

          setTimeout(function() {
            $('body').addClass('sidebar-mini');

            md.misc.sidebar_mini_active = true;
          }, 300);
        }

        // we simulate the window Resize so the charts will get updated in realtime.
        var simulateWindowResize = setInterval(function() {
          window.dispatchEvent(new Event('resize'));
        }, 180);

        // we stop the simulation of Window Resize after the animations are completed
        setTimeout(function() {
          clearInterval(simulateWindowResize);
        }, 1000);

      });
    });
  });
</script>
<script>
  $(document).ready(function() {
    // Javascript method's body can be found in assets/js/demos.js
    md.initDashboardPageCharts();

  });
</script>
