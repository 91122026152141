<section style="position: absolute;width: 100%;z-index: 1;" class="image-container" style="background-image: url(assets/img/wizard/wizard-5.jpg);">
    <div class="container" style="padding: 0;">
        <div class="board" style="width: 100% !important;">
            <button type="button" style="z-index:100;float: right;" class="btn btn-dark"  (click) = "hide();">X</button> 

            <!-- Navigation Area (Circular Tabs) -->
            <msw-navbar></msw-navbar>
            <!-- End Navigation Area (Circular Tabs) -->

            <!-- Content Area -->
            <div class="tab-content">
                <form #personalForm="ngForm" class="editForm" novalidate>
                    <div class="tab-pane fade in active">
                        <h4 class="head text-center">{{title | translate }}</h4>
                        <div class='row'>
                            <div class='col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8'>
                                <div class="row">
                                    <div class='col-xs-12 col-sm-6'>
                                        <div class="form-group">
                                            <label class="control-label" for="firstname">{{ 'texto_35' | translate }}</label>  
                                            <input class="form-control input-md" #firstname="ngModel" id="firstname" name="firstname" type="text" placeholder="{{ 'texto_35' | translate }}" [(ngModel)]="personal.firstName">   
                                        </div>
                                    </div>
                                    <div class='col-xs-12 col-sm-6'>
                                        <div class="form-group">
                                            <label class="control-label" for="lastname">{{ 'texto_36' | translate }}</label>  
                                            <input class="form-control input-md" #lastname="ngModel" id="lastname" name="lastname" type="text" placeholder="{{ 'texto_36' | translate }}" [(ngModel)]="personal.lastName">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class='col-xs-12 col-sm-6'>
                                        <div class="form-group">
                                            <label class="control-label" for="email">Email</label>
                                            <input class="form-control input-md" #email="ngModel" pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$" id="email" name="email" type="text" placeholder="Email" [(ngModel)]="personal.email">
                                            <div *ngIf="email.invalid " class="alert alert-danger">
                                                <div style="font-size:12px">
                                                    {{ 'texto_106' | translate }}
                                                </div>                                            
                                            </div>
                                        </div>
                                    </div>
                                    <div class='col-xs-12 col-sm-6'>
                                        <div class="form-group">
                                            <label class="control-label" for="phone">{{ 'texto_66' | translate }}</label>
                                            <input class="form-control input-md" #phone="ngModel" id="phone" name="phone"  maxlength="9" pattern="[0-9]{9}" type="text" placeholder="{{ 'texto_66' | translate }}" [(ngModel)]="personal.phone">
                                            <div *ngIf="phone.invalid "  class="alert alert-danger">
                                                <div style="font-size:12px">
                                                    {{ 'texto_105' | translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class='col-xs-12 col-sm-12'>
                                        <label class="control-label" for="description">{{ 'texto_63' | translate }}</label>
                                        <textarea maxlength="4000" class="form-control input-md" #description="ngModel" id="description" name="description" type="text" placeholder="{{ 'texto_63' | translate }}" [(ngModel)]="personal.description" rows="6"></textarea>
                                        <p><small style="font-size: 10px;">*{{ 'texto_152' | translate }}</small></p>
                                    </div>
                                </div>
                                <div class="form-group text-center"  style="margin-top: 30px;">
                                    <button class="btn btn-outline-rounded btn-default" (click)="goToPrevious(personalForm)"> <span style="margin-right:10px;" class="glyphicon glyphicon-arrow-left"></span> {{ 'texto_142' | translate }}</button>
                                    <button class="btn btn-success btn-outline-rounded btn-info" [disabled]="!personalForm.valid" (click)="goToNext(personalForm)"> {{ 'texto_141' | translate }} <span style="margin-left:10px;" class="glyphicon glyphicon-arrow-right"></span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>