import { Component, OnInit, Input, ElementRef, ViewChild  } from '@angular/core';
import { Router }              from '@angular/router';
import { ApiService } from '../api.service';
import { FormDataService }     from '../data/formData.service';
import { FinalizarDenuncia } from '../data/formData.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth.service';
import { Pin } from '../model/pin';
import { environment } from "../../environments/environment"
import { Denuncia } from '../model/denuncia';
import { Abogado } from '../model/abogado';
import { DatosDenuncia } from '../model/datosDenuncia';
import { Receptor } from '../model/receptor';
import { Relacion } from '../model/relacion';
import { Empresa } from '../model/empresa';

@Component({
  selector: 'app-finalizar-denuncia',
  templateUrl: './finalizar-denuncia.component.html',
  styleUrls: ['./finalizar-denuncia.component.css']
})
export class FinalizarDenunciaComponent implements OnInit {

    title = 'texto_359';
    finalizar: FinalizarDenuncia;
    pin: Pin;
    pin_code: string;
    form: any;
    @Input() formData;
    denuncias: Denuncia[];
    isExterna:boolean = false;
    abogados: Abogado[];
    denuncia: DatosDenuncia[];
    receptor: Receptor[];
    relaciones: Relacion[];
    empresas: Empresa[];
    finalizada: boolean = false;
    
    terminos2: string;
    url: string;
    documento: string;

    fase_inicial: string;

    triaje: string = "";

    @ViewChild('recaptcha', {static: true }) recaptchaElement: ElementRef;

    constructor(private router: Router, private auth: AuthService, private formDataService: FormDataService, private apiService: ApiService, private toastr: ToastrService, private translate: TranslateService) {
    }

    ngOnInit() {
        this.finalizar = this.formDataService.getFinalizar();
        this.terminos2 = localStorage.getItem("terminos"); 
        this.url = localStorage.getItem("url");
        this.documento = localStorage.getItem("documento"); 

        //console.log(this.terminos2);
        //console.log(this.url);
        //console.log(this.documento);
    }

    save(form: any): boolean {
        if (!form.valid) {
            return false;
        }
            
        this.formDataService.setFinalizar(this.finalizar);
        return true;
    }

    goToPrevious(form: any) {
      //if (this.save(form)) {
          // Navigate to the personal page
          this.formDataService.setFinalizar(this.finalizar);

          this.router.navigate(['/archivos']);
     // }
    }
    goToNext(form: any) {
      if (this.save(form) && this.finalizada === false) {
        this.finalizada = true;
        for(var i=0; i< document.getElementsByClassName('grecaptcha-badge').length; i++){
            var div = document.getElementsByClassName('grecaptcha-badge')[i]
            div.setAttribute("style","display:none");
        }
        // Navigate to the work page
        this.formData = this.formDataService.getFormData();
        let nombreArchivo = this.formData.nombreArchivo;
        /*if(this.formData.nombreArchivo != ""){
          let nombrePartes = this.formData.nombreArchivo;
          let nPartes = nombrePartes.split("\\",3);
          nombreArchivo = nPartes[2];
        }*/

       /* let idEmpresa = ""
        let idSociedad = ""
        this.apiService.searchCompanyById(this.formData.empresa).subscribe((empresas: Empresa[])=>{
          this.empresas = empresas;
          idEmpresa = this.empresas[0].id_empresa;
          idEmpresa = this.empresas[0].id_empresa;
        });*/
        let language = this.auth.getLanguage();
        if(language == "" || language == null){
          language = 'es';
        }
        this.translate.setDefaultLang(language);
        let generatepin = "";
        this.apiService.searchComplaintByUserSocietyID(this.auth.getIdUser(),this.formData.empresa,this.formData.tipoDenuncia,false,language).subscribe((denuncia: Denuncia[])=>{
          this.denuncias = denuncia;

          if(this.denuncias[0].tipo_gestion  == '2'){
            this.isExterna = true;
          }
          let finalizadaDenuncia = (<HTMLInputElement>document.getElementById('finaliced')).value;

          let $clau = false;
          let clausulas_correo = localStorage.getItem("clausulas_correo");
          if(clausulas_correo == '1'){
            $clau = true;
          }  

          if(this.isExterna){
            
            this.apiService.createComplaint(this.formData.empresa, this.formData.tipoDenuncia, this.formData.denuncianteFirstName, this.formData.denuncianteLastName, this.formData.denuncianteDNI, this.formData.denuncianteEmail, this.formData.denunciantePhone, 
              this.formData.fechaDatosDenuncia, this.formData.denuncianteRelacion, this.formData.descripcionDatosDenuncia, '6', this.formData.identificadorEmpresa,this.formData.comentario, this.formData.firstName, this.formData.lastName, this.formData.email, this.formData.phone, this.formData.description, nombreArchivo,this.formData.receptor, this.formData.motivo,this.formData.receptorInicial,finalizadaDenuncia).subscribe((pin: Pin)=>{
                this.pin = pin;
                if(this.pin.pin_code != "0"){
                  this.toastr.success(this.translate.instant('texto_262'), this.translate.instant('texto_263'));
                  this.pin_code = this.pin.pin_code;
                  generatepin = this.makeid(5)+this.pin_code+this.makeid(5);
                  this.apiService.updateParamComplaintById(this.pin.id_denuncia).subscribe(
                    res => {
                      if(res == 200){
                        if(this.formData.denuncianteEmail != ""){
                          let url = environment.base + "/#/consultar-denuncia";
                          let message = "Estimado/a "  +  this.formData.denuncianteFirstName + " "+this.formData.denuncianteLastName  + ", le comunicamos que su denuncia con id " + this.pin.id_denuncia + " y pin " + this.pin.pin_code + " ha sido aceptada y procedemos a su tramitación. La gestión de la denuncia va a llevarse a cabo por una persona externa a la organización.";
                          this.apiService.sendEmail(this.formData.denuncianteEmail,'Denuncia registrada ', message, url,$clau, "Denuncia recibida").subscribe(
                            res => {
                              if(res == 200){
                                //this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                              }else{
                                this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                              }         
                          });
                        }
                      }
                    });

                  this.apiService.searchLayerByIdSociety(this.denuncias[0].id_gestor,this.formData.empresa).subscribe((abogado: Abogado[])=>{
                    this.abogados = abogado;   
                    let message = "Estimado/a "+this.abogados[0].n_abogado+", le comunicamos que hemos recibido una denuncia y le ha sido asignada para su gestión.";
                    let message2 = "Los datos de la denuncia son:<br>";
                    if(this.formData.denuncianteEmail != ""){
                        message2 = message2 + " Nombre denunciante: "+this.formData.denuncianteFirstName+" "+this.formData.denuncianteLastName+"<br>"+
                        "DNI: " + this.formData.denuncianteDNI + "<br>"+
                       "Correo denunciante: " + this.formData.denuncianteEmail + "<br>"+
                       "Teléfono denunciante: " + this.formData.denunciantePhone + "<br>";
                       
                    }
                    let language = this.auth.getLanguage();
                    if(language == "" || language == null){
                      language = 'es';
                    }
                    this.translate.setDefaultLang(language);
                    let tipoDenuncia = "";
                    this.apiService.searchComplaintByUserSocietyID('',this.formData.empresa, this.formData.tipoDenuncia,false,language).subscribe((denuncia: Denuncia[])=>{
                      this.denuncias = denuncia;
                      tipoDenuncia = denuncia[0].nombre;
                      let tipoRelacion = "";
                      this.apiService.searchRelationTypeById(this.formData.denuncianteRelacion, this.formData.empresa,language).subscribe((relacion: Relacion[])=>{
                        this.relaciones = relacion;
                        tipoRelacion = relacion[0].nombre;
                        let message3 = "Fecha incidente: " + this.formData.fechaDatosDenuncia.format('DD/MM/YYYY') +"<br>"+
                      "Relacción del denunciante: " + tipoRelacion +"<br>"+
                      "Tipo de denuncia: " + tipoDenuncia +"<br>"+
                      "Descripción:"+this.formData.descripcionDatosDenuncia+"<br>"+
                      "Comentario adicional: " + this.formData.comentario +"<br>";

                      this.apiService.sendEmail(this.abogados[0].email,'Asignación de denuncia ', message + "<br>" +message2+ "<br>" +message3, '',$clau, "Asignación de denuncia").subscribe(
                        res => {
                          if(res == 200){
                            //this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                          }else{
                            this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                          }         
                      });
                      });                
                    });
                    
                  });

                  this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                    this.router.navigate(['finalizada/', generatepin]);
                  }); 
                }else{
                  this.toastr.error(this.translate.instant('texto_264'), this.translate.instant('texto_263'));
                    this.auth.logout();
                    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                      this.router.navigate(['login']);
                    }); 
                }
            },
              (err:any) =>{
                this.toastr.error(this.translate.instant('texto_264'), this.translate.instant('texto_263'));
              }
            );

          }else{
            this.fase_inicial = '0'
            this.triaje = localStorage.getItem("triaje");
            if (this.triaje == '1'){
              this.fase_inicial = '11'
            }

            this.apiService.createComplaint(this.formData.empresa, this.formData.tipoDenuncia, this.formData.denuncianteFirstName, this.formData.denuncianteLastName, this.formData.denuncianteDNI, this.formData.denuncianteEmail, this.formData.denunciantePhone, 
              this.formData.fechaDatosDenuncia, this.formData.denuncianteRelacion, this.formData.descripcionDatosDenuncia, this.fase_inicial, this.formData.identificadorEmpresa,this.formData.comentario, this.formData.firstName, this.formData.lastName, this.formData.email, this.formData.phone, this.formData.description, nombreArchivo, this.formData.receptor,this.formData.motivo,this.formData.receptorInicial,finalizadaDenuncia).subscribe((pin: Pin)=>{
                this.pin = pin;
                if(this.pin.pin_code != "0"){
                  this.toastr.success(this.translate.instant('texto_262'), this.translate.instant('texto_263'));
                  this.pin_code = this.pin.pin_code;
                  generatepin = this.makeid(5)+this.pin_code+this.makeid(5);
                  if(this.formData.denuncianteEmail != ""){
                    let url = environment.base + "/#/consultar-denuncia";
                    let message = "Estimado/a "  +  this.formData.denuncianteFirstName  + " "+this.formData.denuncianteLastName+", le comunicamos que su denuncia ha sido recibida correctamente. Para poder acceder a la información le solicitaremos el siguiente pin: " + this.pin.pin_code + " con el identificador " + this.pin.id_denuncia + ". Guárdelo en un lugar seguro para conocer el avance de la denuncia.";
                    this.apiService.sendEmail(this.formData.denuncianteEmail,'Denuncia registrada ', message, url,$clau, "Denuncia recibida").subscribe(
                      res => {
                        if(res == 200){
                          this.apiService.searchComplaintByPin(this.pin_code,language).subscribe((denuncia: DatosDenuncia[])=>{
                            this.denuncia = denuncia;
                            let url2 = environment.base + "/#/editar-denuncia/"+this.denuncia[0].id_denuncia;
                            this.apiService.searchReceptorByIdComplaint(this.denuncia[0].id_denuncia,false).subscribe((receptor: Receptor[])=>{
                              this.receptor = receptor;
                                let messageReceptor = "Estimado/a " + this.receptor[0].id_user + " acaba de recibir la denuncia con id "+this.denuncia[0].id_denuncia+". Entre en el canal para gestionarlo.";
                                let asunto ="Asignación de denuncia como receptor";
                                if (this.triaje == '1'){
                                  asunto = "Asignación de denuncia como gestor";
                                }                                
                                this.apiService.sendEmail(this.receptor[0].mail_user,asunto, messageReceptor , url2,$clau, asunto).subscribe(
                                  res => {
                                    if(res == 200){

                                      //this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                                    }else{
                                      this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                                    }         
                                });                                
                            });
                          });
                        }else{
                          this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                        }         
                    });
                  }else{
                    let language = this.auth.getLanguage();
                    if(language == "" || language == null){
                      language = 'es';
                    }
                    this.apiService.searchComplaintByPin(this.pin_code,language).subscribe((denuncia: DatosDenuncia[])=>{
                      this.denuncia = denuncia;
                      this.apiService.searchReceptorByIdComplaint(this.denuncia[0].id_denuncia,false).subscribe((receptor: Receptor[])=>{
                        this.receptor = receptor;
                        let url2 = environment.base + "/#/editar-denuncia/"+this.denuncia[0].id_denuncia;
                        let asunto ="Asignación de denuncia como receptor";
                        if (this.triaje == '1'){
                          asunto = "Asignación de denuncia como gestor";
                        }  
                          let messageReceptor = "Estimado/a "+this.receptor[0].id_user+", le comunicamos que se le ha asignado una nueva denuncia con id "+this.denuncia[0].id_denuncia+". Por favor, entre en el Canal de Denuncias para iniciar el trámite.";
                          this.apiService.sendEmail(this.receptor[0].mail_user,asunto, messageReceptor , url2,$clau, asunto).subscribe(
                            res => {
                              if(res == 200){
                                //this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                              }else{
                                this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                              }         
                          });                           
                      });                      
                    });
                  }
    
                  this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                    this.router.navigate(['finalizada/', generatepin]);
                  }); 
                }else{
                  this.toastr.error(this.translate.instant('texto_264'), this.translate.instant('texto_263'));
                    this.auth.logout();
                    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                      this.router.navigate(['login']);
                    }); 
                }
            },
            (err:any) =>{
              this.toastr.error(this.translate.instant('texto_264'), this.translate.instant('texto_263'));
            });
            }

           /* this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
              this.router.navigate(['finalizada/', generatepin]);
            });*/ 
          });

      }
  }
  
  hide(){
    this.auth.logout();
    //window.location.reload();
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/inicio']);
    });
  }

   private makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }
}
