<div class="main-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-primary" style="border-color: #0c55a0;">
            <div class="panel-heading" style="display: block;">
              <h5 class="panel-title">{{ 'texto_129' | translate }}</h5>
              <div style="float: right; margin-top: -30px;"> 
                <i class="glyphicon glyphicon-chevron-down" (click)="cargarFiltros()" ></i>
              </div>
            </div>
            <div class="panel-body" [hidden]="!filtros">
              <form [formGroup]="myForm" >
                <div class="col-md-4" style="font-size: 12px;">
                  <mat-form-field>
                      <mat-label>{{ 'texto_107' | translate }}</mat-label>
                      <input matInput [matDatepicker]="picker" [max]="hoy" [formControl]="fechaInicio"  format="YYYY-MM-DD"  >
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col-md-4" style="font-size: 12px;">
                  <mat-form-field>
                      <mat-label>{{ 'texto_108' | translate }}</mat-label>
                      <input matInput [matDatepicker]="pickerFin" [max]="hoy" [formControl]="fechaFin" format="YYYY-MM-DD"  >
                      <mat-datepicker-toggle matSuffix [for]="pickerFin"></mat-datepicker-toggle>
                      <mat-datepicker #pickerFin></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col-md-4" style="font-size: 12px;">
                  <mat-form-field>
                    <mat-label>{{ 'texto_67' | translate }}</mat-label>
                    <mat-select  [formControl]="empresa"  (click)="cargarEmpresas($event)" >
                      <mat-option [value]="">--</mat-option>
                      <mat-option *ngFor="let emp of empresas" [value]="emp.id_empresa" >
                        {{emp.n_empresa}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-4" style="font-size: 12px;">
                  <mat-form-field>
                    <mat-label>{{ 'texto_130' | translate }}</mat-label>
                    <mat-select [formControl]="tipoDenuncia" (click)="cargarTipoDenuncias($event)" >
                      <mat-option [value]="">--</mat-option>
                      <mat-option *ngFor="let tipo of tipoDenuncias" [value]="tipo.id_tp_denuncia" >
                        {{tipo.nombre}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-4" style="font-size: 12px;">
                  <mat-form-field>
                    <mat-label>{{ 'texto_131' | translate }}</mat-label>
                    <mat-select [formControl]="tipoRelacion" (click)="cargarTipoRelaciones($event)" >
                      <mat-option [value]="">--</mat-option>
                      <mat-option *ngFor="let tipo of tipoRelaciones" [value]="tipo.id_tp_relacion" >
                        {{tipo.nombre}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-4" style="font-size: 12px;">
                  <mat-form-field>
                    <mat-label>{{ 'texto_132' | translate }}</mat-label>
                    <mat-select [formControl]="fase" (click)="cargarFases($event)" >
                      <mat-option [value]="">--</mat-option>
                      <ng-container *ngFor="let fase of fases">
                        <mat-option *ngIf="fase.id_fase == 0 || fase.id_fase == 1 || fase.id_fase == 2 || fase.id_fase == 3 || fase.id_fase == 4 || fase.id_fase == 5" [value]="fase.id_fase" >
                          {{fase.n_fase}}
                        </mat-option> 
                      </ng-container>
                      
                    </mat-select>
                  </mat-form-field>
                </div>
                <button (click)="filtrar()" style="float:right;color:#fff" mdbBtn rounded="true" class="btn-primary mt-1 waves-light" mdbWavesEffect>{{ 'Filtrar' | translate }}</button>
                <button (click)="borrarFiltro()" style="color:#fff;float:right;" mdbBtn rounded="true" class="btn-primary mt-1 waves-light" mdbWavesEffect>{{ 'Borrar' | translate }}</button>
            </form>
            </div>
          </div>
          


            <div class="card">
              
                <div class="card-header card-header-primary" style="height: 60px;">
                  <h4 class="card-title ">{{ 'texto_137' | translate }}</h4>
                </div>
                <div class="card-body">
                  <div class="table-responsive" *ngIf="denuncia">
                    <i matTooltip="{{'texto_485' | translate }}" class="fa fa-4x fa-file-excel-o" (click)="exportExcel()" style="float: left;margin-top: 3px;margin-right: 10px;"></i>          
                    <mat-paginator [length]="denuncia.length"  [pageSize]="page_size" [pageSizeOptions]="pageSizeOptions" (page)="handlePage($event)"> </mat-paginator>

                    <!--<i matTooltip="{{'texto_485' | translate }}" class="fa fa-4x fa-file-excel-o" (click)="exportExcel()" style="float:right;"></i>-->
                    <table class="table" id="excel-denuncia-table">
					<!--<table class="table">-->
                        <thead>
                            <th >{{ 'texto_133' | translate }}</th>
                            <th>{{ 'texto_92' | translate }}</th>
                            <th>{{ 'texto_97' | translate }}</th>
                            <th>{{ 'texto_132' | translate }}</th>
                            <th>{{ 'texto_134' | translate }}</th>
                            <th>{{ 'texto_135' | translate }}</th>
                            <th></th>
                        </thead>
                        <tbody>

                          <tr *ngFor="let item of denuncia | paginate:page_size:page_number">
                            <td>{{item.id_denuncia}}</td>   
                            <td>{{item.fec_inicio | date: 'dd/MM/yyyy'}}</td>  
                            <td *ngIf="item.denunciante_nombre != '' && (item.id_fase != '0' && item.id_fase != '1' && item.id_fase != '2' && item.id_fase != '3'); else elseBlock">
                              {{item.denunciante_nombre}} {{item.denunciante_apellidos}}</td>   
                            <ng-template #elseBlock>
                              <td *ngIf="(item.id_fase != '0' && item.id_fase != '1' && item.id_fase != '2' && item.id_fase != '3'); else elseBlock2">
                                {{ 'texto_136' | translate }}</td>
                              <ng-template #elseBlock2>
                                <td>&nbsp;</td>
                              </ng-template>							
                            </ng-template>
                            <td>{{item.n_fase}}</td>
                            <td>{{item.nombre_empresa}}</td>   
                            <td>{{item.descripcion_tipo_denuncia}}</td>  
                            <td><button (click)="editarDenuncia(item.id_denuncia)" type="button" rel="tooltip" title="Edit Task" class="" style="background: none;color:black;border:none;">
                                <i class="material-icons">edit</i>
                              </button></td>
                          </tr>
                      
                        </tbody>
                      </table>
                      <!--<app-add-user></app-add-user>-->
                  </div>
                </div>
              </div>
            </div>
      </div>
    </div>
  </div>
