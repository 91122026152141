import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { Denuncia } from '../model/denuncia';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from '@ngx-translate/core';
import { Abogado } from '../model/abogado';
import { ReceptorObligatorio } from '../model/receptorobligaorio';
import { Receptor } from '../model/receptor';

@Component({
  selector: 'app-tipo-denuncia',
  templateUrl: './tipo-denuncia.component.html',
  styleUrls: ['./tipo-denuncia.component.css']
})
export class TipoDenunciaComponent implements OnInit {
  sociedad: string;
  denuncias: Denuncia[];
  IsHiddenForm: boolean =  false;
  idDenuncia: string;
  nombre: string;
  comentario: string;
  validatingForm: FormGroup;
  checkAbogado: boolean;
  abogados: Abogado[];
  abogadoSelected: string;
  receptoreso: ReceptorObligatorio[];
  selectAdabogado: string;  
  modeSelect: string;   


  constructor(private apiService: ApiService,private router: Router, private auth: AuthService, private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit() {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);

    this.sociedad = this.auth.getNameSociedad();
    this.apiService.searchComplaintByUserSociety(this.auth.getIdUser(),this.auth.getSociedad(),language).subscribe((denuncia: Denuncia[])=>{
      this.denuncias = denuncia;
    });

    this.apiService.searchLayerByUserSociety(this.auth.getIdUser(),this.auth.getSociedad()).subscribe((abogado: Abogado[])=>{
      this.abogados = abogado;   
    });

    this.apiService.searchReceptorObligatoryByUserSociety(this.auth.getIdUser(),this.auth.getSociedad()).subscribe((users: ReceptorObligatorio[])=>{
      this.receptoreso = users;   
    });

    this.validatingForm = new FormGroup({
      signupFormModalName: new FormControl(''),
      signupFormModalId: new FormControl(''),
      contactFormModalMessage: new FormControl('', Validators.required),
      abogado: new FormControl('', [
      ]), 
    });

    //this.modeSelect = this.selectAdabogado;
    //this.selectAdabogado =this.selectAdabogado;

    //console.log("this.modeSelect: ", this.modeSelect);
    //console.log("this.selectAdabogado: ", this.selectAdabogado);
  }

  get signupFormModalName() {
    return this.validatingForm.get('signupFormModalName');
  }

  get signupFormModalId() {
    return this.validatingForm.get('signupFormModalId');
  }

  get contactFormModalMessage() {
    return this.validatingForm.get('contactFormModalMessage');
  }

  get abogado() {
    return this.validatingForm.get('abogado');
  }

  deleteComplaint(id_tp_denuncia: string){
    this.apiService.searchComplaintBySocietyType(this.auth.getSociedad(),id_tp_denuncia).subscribe(
      res => {
        if(res == 200){
          this.apiService.deleteComplaintByUser(this.auth.getIdUser(),this.auth.getSociedad(),id_tp_denuncia).subscribe(
            res => {
              if(res == 200){
                this.toastr.success(this.translate.instant('texto_232'), this.translate.instant('texto_235'));
              }else{
                this.toastr.error(this.translate.instant('texto_233'), this.translate.instant('texto_235'));
              }
              this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                this.router.navigate(['tipo-denuncia']);
              }); 
          });
        }else{
          this.toastr.error(this.translate.instant('texto_234'), this.translate.instant('texto_235'));
        }

        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['tipo-denuncia']);
        }); 
    });



    
  }

  hideModal(){
    this.IsHiddenForm = false;
  }

  showComplaint(complaint:Denuncia){
    this.IsHiddenForm = true;
  
    this.idDenuncia = complaint.id_tp_denuncia;
    this.nombre = complaint.nombre;
    this.comentario = complaint.descripcion;

    const toSelect = complaint.id_gestor;
    console.log("toSelect ", toSelect);
    //this.abogado[0].value = toSelect;
    //console.log("this.abogado[0].value ", this.abogado[0].value);
    //this.validatingForm.get('abogado').setValue(toSelect);
    //console.log("this.validatingForm.get('abogado') ", this.validatingForm.get('abogado').value);
    
    this.abogado.patchValue({
      selectAdabogado:toSelect
    });    

  }

  updateComplaint(id_tp_denuncia:string){
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    let nombreRelacion = this.signupFormModalName.value;
    if(this.signupFormModalName.value == ""){
      nombreRelacion = this.nombre;
    }

    let tipo_receptor = this.abogado.value.tipo_receptor;
    if(tipo_receptor == "" || tipo_receptor == null){
      tipo_receptor = "0";
    }
    //console.log(tipo_receptor);    

    let gestor_denuncia = this.abogado.value.id;
    if(gestor_denuncia == "" || gestor_denuncia == null){
      gestor_denuncia = "";
    }
    //console.log(gestor_denuncia);    

    this.apiService.updateComplaintByUser(this.auth.getSociedad(), id_tp_denuncia, nombreRelacion, this.comentario,this.auth.getIdUser(),this.abogado.value.tipo_receptor,gestor_denuncia,language).subscribe(
      res => {
        if(res == 200){
          this.toastr.success(this.translate.instant('texto_236'), this.translate.instant('texto_238'));
        }else{
          this.toastr.error(this.translate.instant('texto_237'), this.translate.instant('texto_238'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['tipo-denuncia']);
        }); 
    });
  }

  selectabogado(event) {
    let selectedIdAbogado = event.value;
    console.log("selectedIdAbogado: ", selectedIdAbogado.id);
    /*for(var i=0; i<this.abogados.length; i++){
      if(this.abogados[i].id == selectedIdAbogado){
        this.abogado = this.abogados[i].n_abogado;
        //console.log(this.textoF);
        break;
      }
    }*/
    this.selectAdabogado = event.value;
    //this.modeSelect = event.value;
    //this.selectAdabogado = selectedIdAbogado.id
    //this.modeSelect = selectedIdAbogado.id
    console.log("this.selectAdabogado: ", this.selectAdabogado);
    console.log("this.modeSelect: ", this.modeSelect);
  }    

}
