import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from '../model/datosDenuncia';
import { PlazosDenuncia } from '../model/plazosDenuncia';
import { Historico } from '../model/historico';
import { environment } from "../../environments/environment";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-enviar-denuncia-investigador',
  templateUrl: './enviar-denuncia-investigador.component.html',
  styleUrls: ['./enviar-denuncia-investigador.component.css'],
  providers:[DatePipe]
})
export class EnviarDenunciaInvestigadorComponent implements OnInit {
  historico: Historico[];
  validatingForm: FormGroup;
  IsHiddenForm: boolean =  false;
  plazos: PlazosDenuncia[];
  @Input() denuncia: DatosDenuncia;
  constructor(private apiService: ApiService, private auth: AuthService, private router: Router, private toastr: ToastrService, private translate: TranslateService, private datepipe: DatePipe) { }

  ngOnInit(): void {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.validatingForm = new FormGroup({
      enviarDenuncia: new FormControl('', [])
    });
  }

  get enviarDenuncia() {
    return this.validatingForm.get('enviarDenuncia');
  }

  validar(){
    if(this.enviarDenuncia.value == true){
    
      this.apiService.searchInvestigatorByIdDenuncia(this.denuncia[0].id_denuncia, '2').subscribe((investigator: PlazosDenuncia[])=>{
        this.plazos = investigator;
        if(this.plazos.length > 0 ){
          /*this.apiService.disableUserByIdComplaint(this.denuncia[0].id_denuncia).subscribe(
          res => {
            if(res == 200){*/
              this.apiService.updateComplaintById(this.denuncia[0].id_denuncia, "2", this.auth.getSociedad(), this.auth.getIdUser(),"").subscribe(
                res => {
                  if(res == 200){
                    for(let i=0;i<this.plazos.length;i++){
                     /* this.apiService.createHistoryComplaintById(this.denuncia[0].id_denuncia,"2",this.auth.getSociedad(),this.plazos[i].id_usuario).subscribe(
                        res => {
                          if(res == 200){*/
                            this.apiService.createUserByIdComplaint(this.auth.getSociedad(), this.plazos[i].id_usuario, this.denuncia[0].id_denuncia,"2", '1',this.auth.getIdUser(),'1', false).subscribe(
                              res => {
                                if(res == 200){
                                  //this.toastr.success(this.translate.instant('texto_273'), this.translate.instant('texto_274'));
                                }else{
                                 // this.toastr.error(this.translate.instant('Error al asignado el investigador'), this.translate.instant('texto_274'));
                                }
                              });

                              let $clau = false;
                              let clausulas_correo = localStorage.getItem("clausulas_correo");
                              if(clausulas_correo == '1'){
                                $clau = true;
                              }  

                              let fecha = new Date(); // ó new Date(valor);
                              let plazo = this.plazos[0].plazo_investigacion;
                              fecha.setDate(fecha.getDate() + parseInt(plazo));
                              let _date =this.datepipe.transform(fecha, 'dd-MM-yyyy');
                              let url = environment.base + "/#/editar-denuncia/"+this.denuncia[0].id_denuncia;
                              let message = "Estimado/a "  +  this.plazos[i].id_usuario  + ", le comunicamos que ha sido asignado a la denuncia "  + this.denuncia[0].id_denuncia  + " con el rol de investigador. La fase de investigación debe finalizar antes del día "+_date;
                              this.apiService.sendEmail(this.plazos[i].mail_user,'Avance de fase en denuncia '+this.denuncia[0].id_denuncia, message,url,$clau, "Asignación de denuncia como investigador").subscribe(
                                res => {
                                  if(res == 200){
                                    this.toastr.success(this.translate.instant('texto_273'), this.translate.instant('texto_274'));
                                  }else{
                                    this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                                  }         
                              }); 

                          
                              if(i == 0){
                                  if(this.denuncia[0].denunciante_correo != ""){
                                    let fecha = new Date(); // ó new Date(valor);
                                    let plazo = this.plazos[0].plazo_investigacion;
                                    fecha.setDate(fecha.getDate() + parseInt(plazo));
                                    let _date =this.datepipe.transform(fecha, 'dd-MM-yyyy');
                                    let url = environment.base + "/#/consultar-denuncia/";
                                    let message = "Estimado/a "  +  this.denuncia[0].denunciante_nombre  + " "+this.denuncia[0].denunciante_apellidos + ", le comunicamos que su denuncia con id "  + this.denuncia[0].id_denuncia  + " ha avanzado a la fase de investigación. Se calcula que la fase actual estará completada para el día "+_date;
                                    this.apiService.sendEmail(this.denuncia[0].denunciante_correo,'Avance de fase en denuncia '+this.denuncia[0].id_denuncia, message,url,$clau, "Cambio de fase").subscribe(
                                      res => {
                                        if(res == 200){
                                          this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                                        }else{
                                          this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                                        }         
                                    });
                                  }else{
                                    //this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                                  }
                                }  
                         /* }else{
                           // this.toastr.error(this.translate.instant('Error al asignar el investigador'), this.translate.instant('texto_274'));
                          }
                        },
                        err =>{
                          this.toastr.error(this.translate.instant('Error al asignar el investigador'), this.translate.instant('texto_274'));
                        });*/
  
                        }
                       }
                    });
                    let role = this.auth.getRole();
                    if(role.includes("INVESTIGADOR") && this.plazos[0].id_usuario == this.auth.getIdUser()){
                      //console.log("INV " + this.plazos[0].id_usuario + '---' + this.auth.getIdUser());
                      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                        this.router.navigate(['editar-denuncia/',this.denuncia[0].id_denuncia]);
                      }); 
                    }else{
                      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                          this.router.navigate(['denuncias']);
                      }); 
                    }

        /*    }else{
              this.toastr.error(this.translate.instant('texto_196'), this.translate.instant('texto_192'));
            }
          });*/
            
        }else{
          this.toastr.error(this.translate.instant('texto_275'), this.translate.instant('texto_274'));
          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['editar-denuncia/',this.denuncia[0].id_denuncia]);
          }); 
        }
      });

      
    }

}

}
