import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatSliderModule } from '@angular/material/slider';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PoliticaCookiesComponent } from './politica-cookies/politica-cookies.component';
import { InformacionPrincipalComponent } from './informacion-principal/informacion-principal.component';
import { TranslationComponent } from './translation/translation.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { WorkflowService }    from './workflow/workflow.service';

/* Feature Components */
import { PersonalComponent }  from './personal/personal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormDataService }    from './data/formData.service';
import { NavbarComponent }    from './navbar/navbar.component';
import { TipoComponent } from './tipo/tipo.component';
import { DenuncianteComponent } from './denunciante/denunciante.component';
import { ReceptorComponent } from './receptor/receptor.component';
import { DatosDenunciaComponent } from './datos-denuncia/datos-denuncia.component';
import { ArchivosComponent } from './archivos/archivos.component';
import { FinalizarDenunciaComponent } from './finalizar-denuncia/finalizar-denuncia.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatTabsModule} from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { AdminComponent } from './admin/admin.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { TableListComponent } from './table-list/table-list.component';
import { TypographyComponent } from './typography/typography.component';
import { IconsComponent } from './icons/icons.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { UpgradeComponent } from './upgrade/upgrade.component';
import { ToastrModule } from 'ngx-toastr';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { PruebaCorreoComponent } from './prueba-correo/prueba-correo.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfiguracionAvanzadaComponent } from './configuracion-avanzada/configuracion-avanzada.component';
import { EmpresasComponent } from './empresas/empresas.component';
import { GestionUsuariosComponent } from './gestion-usuarios/gestion-usuarios.component';
import { MatTableModule } from  '@angular/material/table';
import { AddCompanyComponent } from './add-company/add-company.component';
import { DataTablesModule } from 'angular-datatables';
import { AddUserComponent } from './add-user/add-user.component';
import { UpdateUserComponent } from './update-user/update-user.component';
import { OptionUserComponent } from './option-user/option-user.component';
import { TipoDenunciaComponent } from './tipo-denuncia/tipo-denuncia.component';
import { AddTipoDenunciaComponent } from './add-tipo-denuncia/add-tipo-denuncia.component';
import { SafePipe } from './safe.pipe';
import { MedidaCautelarComponent } from './medida-cautelar/medida-cautelar.component';
import { AddMedidaCautelarComponent } from './add-medida-cautelar/add-medida-cautelar.component';
import { TipoRelacionComponent } from './tipo-relacion/tipo-relacion.component';
import { AddTipoRelacionComponent } from './add-tipo-relacion/add-tipo-relacion.component';
import { AbogadoComponent } from './abogado/abogado.component';
import { AddAbogadoComponent } from './add-abogado/add-abogado.component';
import { AddUserRoleComponent } from './add-user-role/add-user-role.component';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { FormularioDenunciaComponent } from './formulario-denuncia/formulario-denuncia.component';
import { TerminadaDenunciaComponent } from './terminada-denuncia/terminada-denuncia.component';
import { ConsultarDenunciaComponent } from './consultar-denuncia/consultar-denuncia.component';
import { VerDenunciaComponent } from './ver-denuncia/ver-denuncia.component';
import { AddWitnessComponent } from './add-witness/add-witness.component';
import { AddDocumentComponent } from './add-document/add-document.component';
import { AnadirArchivoReceptorComponent } from './anadir-archivo-receptor/anadir-archivo-receptor.component';
import { DenunciasComponent } from './denuncias/denuncias.component';
import { EditarDenunciaComponent } from './editar-denuncia/editar-denuncia.component';
import { AddInvestigatorComponent } from './add-investigator/add-investigator.component';
import { DesestimarDenunciaComponent } from './desestimar-denuncia/desestimar-denuncia.component';
import { NgxWigModule } from 'ngx-wig';
import { ValidarDenunciaComponent } from './validar-denuncia/validar-denuncia.component';
import { EnviarDenunciaReceptorComponent } from './enviar-receptor/enviar-receptor.component'; 
import { AsignarReceptorComponent } from './asignar-receptor/asignar-receptor.component';
import { EnviarDenunciaInvestigadorComponent } from './enviar-denuncia-investigador/enviar-denuncia-investigador.component';
import { AsignarMedidaCautelarComponent } from './asignar-medida-cautelar/asignar-medida-cautelar.component';
import { EnviarInformeComponent } from './enviar-informe/enviar-informe.component';
import { AsignarDecisorComponent } from './asignar-decisor/asignar-decisor.component';
import { EnviarResolucionComponent } from './enviar-resolucion/enviar-resolucion.component';
import { AnadirAccionComponent } from './anadir-accion/anadir-accion.component';
import { EnviarinformeDenuncianteComponent } from './enviar-informe-denunciante/enviar-informe-denunciante.component';
import { ArchivarComponent } from './archivar/archivar.component';
import { TodasDenunciasComponent } from './todas-denuncias/todas-denuncias.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AnadirArchivoComponent } from './anadir-archivo/anadir-archivo.component';
import { NavFixedComponent } from './nav-fixed/nav-fixed.component';
import { UserIdleModule } from 'angular-user-idle';
import { SessionExpirationAlert, SessionInteruptService } from 'session-expiration-alert';
import { AppSessionInteruptService } from './services/app-session-interupt.service';
import { DragDropDirective } from './drag-drop.directive';
import { AddWitnessAdminComponent } from './add-witness-admin/add-witness-admin.component';
import { EditarTodasDenunciasComponent } from './editar-todas-denuncias/editar-todas-denuncias.component';
import { PaginatePipe } from './pipes/paginate.pipe';
import { CustomMatPaginatorIntl } from './paginator-es';
import { ErrorInterceptor } from './auth/error.interceptor';
import { JwtInterceptor } from './auth/jwt.interceptor';
import { appInitializer } from './auth/app.initializer';
import { AuthService } from './auth.service';
import { fakeBackendProvider } from './auth/fake-backend';
import { JwtModule } from '@auth0/angular-jwt';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { HtmltoplaintextPipe } from './pipes/htmltoplaintext.pipe';
import { ConsultasComponent } from './consultas/consultas.component';

@NgModule({
  declarations: [
    AppComponent,
    PoliticaCookiesComponent,
    InformacionPrincipalComponent,
    TranslationComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    PersonalComponent,
    NavbarComponent,
    TipoComponent,
    DenuncianteComponent,
    ReceptorComponent,
    DatosDenunciaComponent,
    ArchivosComponent,
    FinalizarDenunciaComponent,
    AdminComponent,
    AdminLayoutComponent,
    DashboardComponent,
    UserProfileComponent,
    TableListComponent,
    TypographyComponent,
    IconsComponent,
    NotificationsComponent,
    UpgradeComponent,
    UpdatePasswordComponent,
    PruebaCorreoComponent,
    ConfiguracionAvanzadaComponent,
    EmpresasComponent,
    GestionUsuariosComponent,
    AddCompanyComponent,
    AddUserComponent,
    UpdateUserComponent,
    OptionUserComponent,
    TipoDenunciaComponent,
    AddTipoDenunciaComponent,
    SafePipe,
    MedidaCautelarComponent,
    AddMedidaCautelarComponent,
    TipoRelacionComponent,
    AddTipoRelacionComponent,
    AbogadoComponent,
    AddAbogadoComponent,
    AddUserRoleComponent,
    FormularioDenunciaComponent,
    TerminadaDenunciaComponent,
    ConsultarDenunciaComponent,
    VerDenunciaComponent,
    AddWitnessComponent,
    AddDocumentComponent,
    AnadirArchivoReceptorComponent,
    DenunciasComponent,
    EditarDenunciaComponent,
    AddInvestigatorComponent,
    DesestimarDenunciaComponent,
    ValidarDenunciaComponent,
    EnviarDenunciaInvestigadorComponent,
    EnviarDenunciaReceptorComponent,
    AsignarReceptorComponent,
    AsignarMedidaCautelarComponent,
    EnviarInformeComponent,
    AsignarDecisorComponent,
    EnviarResolucionComponent,
    AnadirAccionComponent,
    EnviarinformeDenuncianteComponent,
    ArchivarComponent,
    TodasDenunciasComponent,
    AnadirArchivoComponent,
    NavFixedComponent,
    DragDropDirective,
    AddWitnessAdminComponent,
    EditarTodasDenunciasComponent,
    PaginatePipe,
    HtmltoplaintextPipe,
    ConsultasComponent,
  ],
  imports: [
    NgbModule,
    MatDatepickerModule,
    MatInputModule, 
    MatButtonModule, 
    MatCardModule, 
    MatFormFieldModule, 
    MatTableModule,
    MatCheckboxModule, 
    MatDatepickerModule, 
    MatRadioModule, 
    MatSelectModule,
    MatSliderModule,
    MatTooltipModule,
    MatPaginatorModule,
    BrowserModule,
    DataTablesModule,
    FormsModule,
    NgxWigModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    RecaptchaV3Module, 
    SessionExpirationAlert.forRoot({ totalMinutes: 30 }),   // UserIdleModule.forRoot({idle: 1, timeout: 30, ping: 20}),
    MDBBootstrapModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [ HttpClient ]
      }
    }),
    BrowserAnimationsModule,
    MatTabsModule,
    ToastrModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: function  tokenGetter() { return  localStorage.getItem('access_token'); },
        //allowedDomains: ['localhost:3000'],
        //disallowedRoutes: ['http://localhost:3000/auth/login']
      }
    })
  ],
  exports: [
    MatMomentDateModule,
  ],
  schemas: [ NO_ERRORS_SCHEMA ],
  providers: [    MatDatepickerModule,  
    { provide: FormDataService, useClass: FormDataService },
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },
    { provide: WorkflowService, useClass: WorkflowService },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: SessionInteruptService, useClass: AppSessionInteruptService },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6Ldku8sZAAAAANZFlrofaO_GHQQbxH99Y2nT_D-C' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
