import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../api.service';
import { Rol } from '../model/rol';
import { OptionSesion } from '../model/optionSesion';
import { ToastrService } from 'ngx-toastr';
import { SessionTimerService } from 'session-expiration-alert';
import { Parametros } from '../model/parametros';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  color:string = "black";
  option: OptionSesion[];
  isVisible = true;
  IsAuth = false;
  roles: Rol[];
  hasPermit: boolean = false;
  hasPermitDenuncia: boolean = false;
  hasPermitAllDenuncias: boolean = false;
  seeMenu1: boolean = false;
  seeMenu2: boolean = true;
  seeMenu3: boolean = false;
  @Input() menuNavbar: boolean;
  @Input() IsHiddenLanding: boolean;
  parametros: Parametros;
  bloque1: boolean = false;
  bloque2: boolean = false;
  bloque3: boolean = false;
  isAgrupado = false;

  constructor(private router: Router, private auth: AuthService,private apiService: ApiService, private translate: TranslateService, private toastr: ToastrService, public sessionTimer: SessionTimerService) { }


  ngOnInit() {
  /*  this.userIdle.startWatching();
    this.userIdle.onTimerStart().subscribe(count => console.log(count));
    this.userIdle.onTimeout().subscribe(() => 
    {
      this.onTimeout();
      console.log('Time is up!');
    });*/

    this.IsAuth = this.auth.isLoggedIn();
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    if(this.IsAuth){
      this.IsHiddenLanding = false;
      this.apiService.searchAdvancedParam().subscribe((parametros: Parametros)=>{
        this.parametros = parametros;
        if(this.parametros[0].valor == "1"){
          this.auth.setAnonimo("1");
        }else{
          this.auth.setAnonimo("");
        }
        this.auth.setRemitente(this.parametros[1].valor);
        this.auth.setConsultas(this.parametros[2].valor);
        this.auth.setAviso(this.parametros[3].valor);
      });
    }else{
      this.IsHiddenLanding = true;
    }
    this.translate.setDefaultLang(language);
    if(this.auth.getIdUser()==null){
      this.isVisible = false;
    }else{
      let role = "";
      if(this.auth.getSociedad() == null){
        this.apiService.getSessionPropertiesUser(this.auth.getIdUser()).subscribe((option: OptionSesion[])=>{
          this.option = option;
          if(option[0].id_sociedad != ""){ 
            this.auth.setLanguage(option[0].idioma);
            this.auth.setSociedad(option[0].id_sociedad);
            this.auth.setNameSociedad(option[0].n_sociedad);
            this.apiService.searchRoleByUserSociety(this.auth.getIdUser(),this.auth.getSociedad(),'1').subscribe((roles: Rol[])=>{
              this.roles = roles;
              for(let i=0;i<this.roles.length;i++){
                role = role + "," +this.roles[i].n_rol;
              }
              //role = this.roles[0].n_rol;
              if(role != ""){
                this.auth.setRole(role);
                if(role.includes("ADMIN")  || role.includes("SUPERADMIN")){
                  this.hasPermit = true;
                  this.bloque1 = true;
                  this.bloque2 = true;
                 // this.hasPermitAllDenuncias = true;
                  if(role.includes("SUPERADMIN")){
                    this.bloque1 = false;
                    this.bloque3 = true;
                    this.hasPermitDenuncia = false; 
                  }
                }
                 if(role.includes("DECISOR")  || role.includes("RECEPTOR")  || role.includes("INVESTIGADOR")  || role.includes("COMPLIANCE") ){
                  this.hasPermitDenuncia = true; 
                  this.bloque1 = false;
                  if(role.includes("COMPLIANCE")){
                    this.hasPermitAllDenuncias = true;
                    this.bloque3 = true;
                  }
                }
              }
            });
          }else{
            this.toastr.error(this.translate.instant('texto_242'), this.translate.instant('texto_243"'));
          }
        });
      }else{
        this.apiService.searchRoleByUserSociety(this.auth.getIdUser(),this.auth.getSociedad(),'1').subscribe((roles: Rol[])=>{
          this.roles = roles;
          for(let i=0;i<this.roles.length;i++){
            role = role + "," +this.roles[i].n_rol;
          }
          //role = this.roles[0].n_rol;
          if(role != ""){
            this.auth.setRole(role);
            if(role.includes("ADMIN")  || role.includes("SUPERADMIN")){
              this.hasPermit = true;
              this.bloque1 = true;
              this.bloque2 = true;
              this.bloque3 = true;
              //this.hasPermitAllDenuncias = true;
              if(role.includes("SUPERADMIN")){
                this.bloque1 = false;
                this.hasPermitDenuncia = false;
              }
            }
             if(role.includes("DECISOR")  || role.includes("RECEPTOR")  || role.includes("GESTOR") || role.includes("INVESTIGADOR")  || role.includes("COMPLIANCE") ){
              this.hasPermitDenuncia = true; 
              this.bloque1 = false;
              if(role.includes("COMPLIANCE")){
                this.hasPermitAllDenuncias = true;
              }
            }
          }
        });
      }
    }
  }

 
  logout(){
    this.auth.logout();
    window.location.reload();
  }

  showMenu(){
    this.isAgrupado = !this.isAgrupado;
    document.getElementById("main-panel").style.zIndex = "999";
    if(this.isAgrupado){
      document.getElementById("main-panel").style.width = "calc(100% - 60px)";
      for(var i=0; i< document.getElementsByClassName('nav-link').length; i++){
        var div = document.getElementsByClassName('nav-link')[i]
        div.setAttribute("style","margin-left:0px");
      }  
      
      setTimeout(function(){ document.getElementById("btnAgrupado").style.left = "10px"; }, 150);
    }else{
      document.getElementById("main-panel").style.width = "calc(100% - 260px)";
      for(var i=0; i< document.getElementsByClassName('nav-link').length; i++){
        var div = document.getElementsByClassName('nav-link')[i]
        div.setAttribute("style","margin-left: 14px;");
      }  
      setTimeout(function(){ document.getElementById("btnAgrupado").style.left = "200px"; }, 150);
      
    }
    //this.isVisible =  !this.isVisible;
  }

  hidePanel(){
    if(this.isVisible){
      this.isVisible =  false;
    }
  }

  changeMenu1(){
    this.seeMenu1 = !this.seeMenu1;
  }

  changeMenu2(){
    this.seeMenu2 = !this.seeMenu2;
  }

  changeMenu3(){
    this.seeMenu3 = !this.seeMenu3;
  }
}
