<button class="btn btn-primary btn-round" type="button" mdbBtn rounded="true" data-toggle="modal" data-target="#basicExample"
        (click)="frame.show()" mdbWavesEffect>{{ 'texto_125' | translate }}
</button>

<div mdbModal #frame="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true" [hidden]="!condition">
  <div class="modal-dialog cascading-modal modal-avatar modal-sm" role="document">
    <!--Content-->
    <div class="modal-content">

      <!--Header-->
      <div class="modal-header">
        <img src="{{urlPhoto}}" alt="avatar"  class="rounded-circle img-responsive" style="margin-top: 20px;">
      </div>
      <!--Body-->
      <div class="modal-body mb-1">

        <h5 class="mt-1 mb-2 text-center">{{name}}</h5>

        <div class="md-form ml-0 mr-0">
          <input type="password" id="oldPassword" class="form-control form-control-sm ml-0"
                 mdbInput mdbValidate [formControl]="oldPassword">
          <label for="oldPassword" class="ml-0">{{ 'texto_126' | translate }}</label>
          <mdb-error
            *ngIf="oldPassword.invalid && (oldPassword.dirty || oldPassword.touched)">
            {{ 'texto_61' | translate }}
          </mdb-error>
          <mdb-success
            *ngIf="oldPassword.valid && (oldPassword.dirty || oldPassword.touched)">
            {{ 'texto_62' | translate }}
          </mdb-success>
        </div>

        <div class="md-form ml-0 mr-0">
          <input type="password" id="password1" class="form-control form-control-sm ml-0"
                 mdbInput mdbValidate [formControl]="password1">
          <label for="password1" class="ml-0">{{ 'texto_127' | translate }}</label>
          <mdb-error
            *ngIf="password1.invalid && (password1.dirty || password1.touched)">
            {{ 'texto_61' | translate }}
          </mdb-error>
          <mdb-success
            *ngIf="password1.valid && (password1.dirty || password1.touched)">
            {{ 'texto_62' | translate }}
          </mdb-success>
        </div>
        
        <div class="md-form ml-0 mr-0">
          <input type="password" id="password2" class="form-control form-control-sm ml-0"
                 mdbInput mdbValidate [formControl]="password2">
          <label for="password2" class="ml-0">{{ 'texto_128' | translate }}</label>
          <mdb-error
            *ngIf="password2.invalid && (password2.dirty || password2.touched)">
            {{ 'texto_61' | translate }}
          </mdb-error>
          <mdb-success
            *ngIf="password2.valid && (password2.dirty || password2.touched)">
            {{ 'texto_62' | translate }}
          </mdb-success>
        </div>

        <div class="text-center mt-4">
          <button (click)="updatePassword()" style="color:#fff" mdbBtn rounded="true" class="btn-primary mt-1 waves-light" mdbWavesEffect>{{ 'texto_59' | translate }}
          </button>
        </div>
      </div>

    </div>
    <!--/.Content-->
  </div>
</div>