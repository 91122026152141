import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from '../model/datosDenuncia';

@Component({
  selector: 'app-add-witness',
  templateUrl: './add-witness.component.html',
  styleUrls: ['./add-witness.component.css']
})
export class AddWitnessComponent implements OnInit {
  validatingForm: FormGroup;
  IsHiddenForm: boolean =  false;
  @Input() denuncia: DatosDenuncia;
  constructor(private apiService: ApiService, private auth: AuthService, private router: Router, private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit() {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);

    this.validatingForm = new FormGroup({
      signupFormModalTelefono: new FormControl('', []),
      signupFormModalEmail: new FormControl('', [
        Validators.email,
        Validators.required,
      ]),
      signupFormModalName: new FormControl('', Validators.required),
      contactFormModalMessage: new FormControl('', [Validators.required]),
    });
  }

  get signupFormModalName() {
    return this.validatingForm.get('signupFormModalName');
  }

  get signupFormModalTelefono() {
    return this.validatingForm.get('signupFormModalTelefono');
  }

  get contactFormModalMessage() {
    return this.validatingForm.get('contactFormModalMessage');
  }

  get signupFormModalEmail() {
    return this.validatingForm.get('signupFormModalEmail');
  }


  addWitness(){
    /*this.apiService.addWitness(this.denuncia[0].id_sociedad, this.denuncia[0].denunciante_correo,this.denuncia[0].id_denuncia, this.signupFormModalName.value, this.signupFormModalTelefono.value, this.signupFormModalEmail.value, this.contactFormModalMessage.value, '1',false).subscribe(*/
    this.apiService.addWitness(this.denuncia[0].id_sociedad, 'Denunciante',this.denuncia[0].id_denuncia, this.signupFormModalName.value, this.signupFormModalTelefono.value, this.signupFormModalEmail.value, this.contactFormModalMessage.value, '1',false).subscribe(  
      res => {
        if(res == 200){
          this.toastr.success(this.translate.instant('texto_304'), this.translate.instant('texto_306'));
          localStorage.setItem("verdenuncia", "");
        }else{
          this.toastr.error(this.translate.instant('texto_305'), this.translate.instant('texto_306'));
        }
        let tabs = window.location.href.split("/", 6); 
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['ver-denuncia/',tabs[5]]);
        }); 
      },
      err =>{
        this.toastr.error(this.translate.instant('texto_305'), this.translate.instant('texto_306'));
      }); 
  }

  public options: Object = {
    charCounterCount: true,
    toolbarButtons: ['bold', 'italic', 'underline', 'fontFamily', 'fontSize',  'html', 'backgroundColor', 'textColor','alignLeft', 'alignCenter', 'alignRight', 'insertLink', '|', 'undo', 'redo' ],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'fontFamily', 'fontSize',  'html', 'backgroundColor', 'textColor','alignLeft', 'alignCenter', 'alignRight', 'insertLink', '|', 'undo', 'redo' ],
    imageAllowedTypes: ['jpeg', 'jpg', 'png'],
    imageMaxSize: 5 * 1024 * 1024,
  };
}
