import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Users } from '../model/users';
import { environment } from "../../environments/environment"
import { Usuarios } from '../model/usuarios';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  PHP_API_SERVER = environment.baseUrl;//"http://127.0.0.1:8080";
 // PHP_API_SERVER = "http://portaldedenuncias.azkenservices.com/backend/";

  email: string;
  username: string;
  name: string;
  surname: string;
  urlPhoto: string;
  validatingForm2: FormGroup;
  emailForm: string;
  nameForm: string;
  surnameForm: string;
  fileData: File = null;
  previewUrl:any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  users: Usuarios[] ;
  myForm = new FormGroup({
    file: new FormControl('',[] ),
    fileSource: new FormControl('', [])
  });

  constructor(private apiService: ApiService,private router: Router, private auth: AuthService, private toastr: ToastrService,private http: HttpClient,private translate: TranslateService) { }

  ngOnInit() {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.validatingForm2 = new FormGroup({
      email: new FormControl('', [Validators.email, Validators.required,]),
      username: new FormControl('', [Validators.required,]),
      name: new FormControl('', [Validators.required,]),
      surname: new FormControl('', [Validators.required,]),
    });

   // this.email = this.auth.getEmailUser();
    this.username = this.auth.getIdUser();
   // this.name = this.auth.getNameUser();
   // this.surname = this.auth.getSurnameUser();
    //this.urlPhoto = this.auth.getPhotoUser();
  //  if(this.urlPhoto == "" || this.urlPhoto == "null"){
      this.apiService.searchUserById(this.auth.getIdUser()).subscribe((usuarios: Usuarios[])=>{
        this.users = usuarios;
        this.name = usuarios[0].n_user;
        this.surname = usuarios[0].ape_user;
        this.email = usuarios[0].mail_user;
        let photo = environment.baseAvatar + "avatar.png";
        if(usuarios[0].foto != "NULL" && usuarios[0].foto != ""){
          photo = usuarios[0].foto;
          this.urlPhoto = photo;
          this.auth.setPhotoUser(photo);
        }else{
          this.urlPhoto = photo;
        }
      });
  //  } 
  }


  get modalFormAvatarPassword() {
    return this.validatingForm2.get('modalFormAvatarPassword');
  }


  updateDataUser(){
    let error = false;
    this.email = (document.getElementById("email") as HTMLInputElement).value;
    if(this.email == "" || !this.isMail(this.email)){
      error = true;
    }

    this.name = (document.getElementById("name") as HTMLInputElement).value;
    if(this.name == ""){
      error = true;
    }

    this.surname = (document.getElementById("surname") as HTMLInputElement).value;
    if(this.surname == ""){
      error = true;
    }
    
    if(error){
      this.toastr.error(this.translate.instant('texto_197'), this.translate.instant('texto_198'));
    }else{
      this.apiService.updateDataUser(this.auth.getIdUser(), this.email, this.name, this.surname).subscribe(
        res => {
          if(res == 200){
            this.auth.setEmailUser(this.email);
            this.auth.setNameUser(this.name);
            this.auth.setSurnameUser(this.surname)
            this.toastr.success(this.translate.instant('texto_199'), this.translate.instant('texto_201'));
          }else{
            this.toastr.error(this.translate.instant('texto_200'), this.translate.instant('texto_201'));
          }
          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['profile']);
          }); 
      });
    }
  }

  get f(){
    return this.myForm.controls;
  }
  
  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myForm.patchValue({
        fileSource: file
      });
      this.submit();
    }
  }
     
  submit(){
    const formData = new FormData();
    formData.append('file', this.myForm.get('fileSource').value);
    formData.append('id_user', this.auth.getIdUser());

    this.http.post(`${this.PHP_API_SERVER}/api/upload.php`, formData).subscribe(
      res => {
        if(res !=""){
          this.toastr.success(this.translate.instant('texto_202'), this.translate.instant('texto_203'));
          let photo = environment.baseAvatar + res;
          
          this.auth.setPhotoUser(photo);
          window.location.reload();
        }else{
          this.toastr.error(this.translate.instant('texto_204'), this.translate.instant('texto_203'));
        }
        
      },
      err =>{
        this.toastr.error(this.translate.instant('texto_204'), this.translate.instant('texto_203'));
      })
  }
  isMail(str){
      return typeof str==='string' && /^[\w+\d+._]+\@[\w+\d+_+]+\.[\w+\d+._]{2,8}$/.test(str);
  }
 
}
