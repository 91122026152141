import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PoliticaCookiesComponent } from './politica-cookies/politica-cookies.component';
import { PersonalComponent } from './personal/personal.component';
import { TipoComponent } from './tipo/tipo.component';
import { DenuncianteComponent } from './denunciante/denunciante.component';
import { ReceptorComponent } from './receptor/receptor.component';
import { DatosDenunciaComponent } from './datos-denuncia/datos-denuncia.component';
import { FinalizarDenunciaComponent } from './finalizar-denuncia/finalizar-denuncia.component';
import { ArchivosComponent } from './archivos/archivos.component';
import { WorkflowGuard }        from './workflow/workflow-guard.service';
import { WorkflowService }      from './workflow/workflow.service';
import { AdminComponent } from './admin/admin.component';
import { AuthGuard } from './auth/auth.guard';
import { AuthService } from './auth.service';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ConfiguracionAvanzadaComponent } from './configuracion-avanzada/configuracion-avanzada.component';
import { EmpresasComponent } from './empresas/empresas.component';
import { GestionUsuariosComponent } from './gestion-usuarios/gestion-usuarios.component';
import { UpdateUserComponent } from './update-user/update-user.component';
import { OptionUserComponent } from './option-user/option-user.component';
import { TipoDenunciaComponent } from './tipo-denuncia/tipo-denuncia.component';
import { MedidaCautelarComponent } from './medida-cautelar/medida-cautelar.component';
import { TipoRelacionComponent } from './tipo-relacion/tipo-relacion.component';
import { AbogadoComponent } from './abogado/abogado.component';
import { TerminadaDenunciaComponent } from './terminada-denuncia/terminada-denuncia.component';
import { ConsultarDenunciaComponent } from './consultar-denuncia/consultar-denuncia.component';
import { VerDenunciaComponent } from './ver-denuncia/ver-denuncia.component';
import { DenunciasComponent } from './denuncias/denuncias.component';
import { AppComponent } from './app.component';
import { EditarDenunciaComponent } from './editar-denuncia/editar-denuncia.component';
import { TodasDenunciasComponent } from './todas-denuncias/todas-denuncias.component';
import { EditarTodasDenunciasComponent } from './editar-todas-denuncias/editar-todas-denuncias.component';
import { ConsultasComponent } from './consultas/consultas.component';


const routes: Routes = [
  { path: 'layout',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [{
      path: '',
      loadChildren: () => import('./admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
    }]
  },
  { path: 'admin', component: AdminComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'profile', component: UserProfileComponent, canActivate: [AuthGuard] },
  { path: 'advanced-setting', component: ConfiguracionAvanzadaComponent, canActivate: [AuthGuard] },
  { path: 'empresas', component: EmpresasComponent, canActivate: [AuthGuard] },
  { path: 'usuarios', component: GestionUsuariosComponent, canActivate: [AuthGuard] },
  { path: 'user/:id', component: UpdateUserComponent, canActivate: [AuthGuard] },
  { path: 'tipo-denuncia', component: TipoDenunciaComponent, canActivate: [AuthGuard] },
  { path: 'tipo-relacion', component: TipoRelacionComponent, canActivate: [AuthGuard] },
  { path: 'medida-cautelar', component: MedidaCautelarComponent, canActivate: [AuthGuard] },
  { path: 'abogado', component: AbogadoComponent, canActivate: [AuthGuard] },
  { path: 'login',  component: LoginComponent },
  { path: 'finalizada/:id', component: TerminadaDenunciaComponent },
  { path: 'consultar-denuncia', component: ConsultarDenunciaComponent },
  { path: 'ver-denuncia/:id', component: VerDenunciaComponent },
  { path: 'todas-denuncias', component: TodasDenunciasComponent, canActivate: [AuthGuard] },
  { path: 'editar-denuncia/:id', component: EditarDenunciaComponent, canActivate: [AuthGuard] },
  { path: 'editar-todas-denuncias/:id', component: EditarTodasDenunciasComponent, canActivate: [AuthGuard] },
  { path: 'options', component: OptionUserComponent, canActivate: [AuthGuard] },
  { path: 'tipo', component: TipoComponent },
  { path: 'denuncias', component: DenunciasComponent },
  { path: 'denunciante', component: DenuncianteComponent },
  { path: 'receptor', component: ReceptorComponent },
  { path: 'datos-denuncia', component: DatosDenunciaComponent },
  { path: 'archivos', component: ArchivosComponent  },
  { path: 'finalizar-denuncia', component: FinalizarDenunciaComponent },
  { path: 'testigo', component: PersonalComponent },
  { path: 'politica-cookies', component: PoliticaCookiesComponent },
  { path: 'inicio', component: AppComponent },
  { path: '',  redirectTo: '/login', pathMatch: 'full' },
  { path: 'consultas', component: ConsultasComponent },
  
  // 6th Route
  { path: '**', component: LoginComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true} )],
  exports: [RouterModule],
  providers: [WorkflowGuard,AuthService,AuthGuard]
})
export class AppRoutingModule { }
