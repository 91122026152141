import { Component, OnInit } from '@angular/core';
import { Receptor } from '../data/formData.model';
import { ReceptorInicial } from '../data/formData.model';
import { Router }              from '@angular/router';
import { FormDataService }     from '../data/formData.service';
import { RolReceptor } from '../model/rolReceptor';
import { ApiService } from '../api.service';
import { TipoHecho } from '../data/formData.model';
import { ToastrService } from 'ngx-toastr';

export interface Receptores {
  id: string;
  viewValue: string;
}

@Component({
  selector: 'app-receptor',
  templateUrl: './receptor.component.html',
  styleUrls: ['./receptor.component.css']
})
export class ReceptorComponent implements OnInit {
    tipoHecho: TipoHecho;
    title = 'texto_343';
    inicial: boolean = false;
    atras:boolean = false;
    receptor: Receptor;
    receptorInicial: ReceptorInicial;
    receptorPropuesto: RolReceptor;
    //asignarReceptor = Receptor;
    form: any;
    index:number = 0;
    indexA:number = 0;
    receptores: RolReceptor[];
    contador = 0;

    gestionar_conflicto: string = "";
    clausulas_correo: string = "";


    constructor(private apiService: ApiService, private router: Router, private formDataService: FormDataService, private toastr: ToastrService) { }

    ngOnInit() {
      this.tipoHecho = this.formDataService.getTipoHecho();
      let empresa = this.tipoHecho.empresa;
      this.receptor = this.formDataService.getReceptor();
      this.receptorInicial = this.formDataService.getReceptorInicial();
      if(this.receptor.motivo == "" && this.receptor.receptor != ""){
        this.inicial = false;
        this.apiService.searchUsersReceptors(empresa).subscribe((receptores: RolReceptor[])=>{
            this.receptores = receptores;
            for(var i=0; i<this.receptores.length; i++){
              if(this.receptores[i].id_user == this.receptorInicial.receptorInicial){
                this.indexA = i;
                this.receptorInicial.receptorInicial = receptores[i].id_user;
                this.receptorInicial.motivoInicial = "";
                this.formDataService.setReceptorInicial(this.receptorInicial);
                break;
              }
            }
          });
      }else{
        if(this.receptor.receptor == "" && empresa != ""){ 
          this.apiService.searchUsersReceptorsRamdom(empresa,this.tipoHecho.tipoDenuncia).subscribe((receptores: RolReceptor[])=>{
            this.receptores = receptores;
            this.indexA = 0;
            this.receptorInicial.receptorInicial = receptores[0].id_user;
            this.receptorInicial.motivoInicial = "";
            this.formDataService.setReceptorInicial(this.receptorInicial);
          });
       }else{
          this.inicial = true;
          this.apiService.searchUsersReceptors(empresa).subscribe((receptores: RolReceptor[])=>{
            this.receptores = receptores;
          });
        }
      }

     this.gestionar_conflicto = localStorage.getItem("gestionar_conflicto");
     this.clausulas_correo = localStorage.getItem("clausulas_correo");
     //console.log(this.gestionar_conflicto);
     //console.log(this.clausulas_correo);

    }

    save(form: any): boolean {
        if (!form.valid) {
            return false;
        }
        if(!this.inicial){
          this.receptor.receptor = this.receptores[this.index].id_user;
          this.receptor.motivo = "";
          this.formDataService.setReceptor(this.receptor);
        } else{
          if(this.receptor.motivo == ""){
            this.toastr.error('Es necesario añadir la descripción', 'Parámetros incorrectos');
            return false;
          }else{
            this.formDataService.setReceptor(this.receptor);
          
          }
        }   
        
        return true;
    }

    goToPrevious(form: any) {
      if (this.save(form)) {
          // Navigate to the personal page
          this.router.navigate(['/denunciante']);
      }
  }

    goToNext(form: any) {
        if (this.save(form)) {
            // Navigate to the work page
            this.router.navigate(['/datos-denuncia']);
        }
    }

    buscarReceptores(){
      this.inicial = true;
      this.tipoHecho = this.formDataService.getTipoHecho();
      let empresa = this.tipoHecho.empresa;
      this.apiService.searchUsersReceptors(empresa).subscribe((receptores: RolReceptor[])=>{
        this.receptores = receptores;
      });
        this.receptor = this.formDataService.getReceptor();
    }

    hide(){
      window.location.reload();
     /* this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/inicio']);
      });*/
    }

    deshacer(){
      let empresa = this.tipoHecho.empresa;
      this.receptor.receptor= this.formDataService.getReceptorInicial().receptorInicial;
      this.apiService.searchUsersSocietyReceptors(empresa,this.receptor.receptor).subscribe((receptores: RolReceptor[])=>{
        this.receptores = receptores;
      });
      this.inicial = false;
    }

    onKey(event){
        var datos = (document.getElementById("motivo") as HTMLInputElement).value ;
        this.contador = datos.length
     }
}
