import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from '../model/datosDenuncia';
import { PageEvent } from '@angular/material/paginator';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Empresa } from '../model/empresa';
import { Relacion } from '../model/relacion';
import { Denuncia } from '../model/denuncia';
import { Fase } from '../model/fase';
import { DatePipe } from '@angular/common'
import * as XLSX from 'xlsx'; 

import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { ExportService } from '../services/export.service';
import { DatosDenunciasExcel } from '../model/datosDenunciaExcel';

@Component({
  selector: 'app-todas-denuncias',
  templateUrl: './todas-denuncias.component.html',
  styleUrls: ['./todas-denuncias.component.css'],
  providers: [
    DatePipe,
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class TodasDenunciasComponent implements OnInit {
  empresas: Empresa[];
  denuncia: DatosDenuncia[];
  denunciaExcel: DatosDenunciasExcel[] = [];
  tipoRelaciones: Relacion[];
  tipoDenuncias: Denuncia[];
  fases: Fase[];
  page_size: number = 25;
  page_number = 1;
  pageSizeOptions = [5,10,25,50];
  hoy = new Date(); 
  idrole: string;

  myForm = new FormGroup({
    fechaInicio: new FormControl('',[] ),
    fechaFin: new FormControl('', []),
    empresa: new FormControl('', []),
    tipoDenuncia: new FormControl('', []),
    tipoRelacion: new FormControl('', []),
    fase: new FormControl('', []),
  });

  filtros:boolean = false;

  constructor(private exportService: ExportService, private apiService: ApiService,private router: Router, private auth: AuthService, private toastr: ToastrService, private translate: TranslateService, private _adapter: DateAdapter<any>, private datepipe: DatePipe) {
    this.hoy.setDate(this.hoy.getDate());
   }
  
  get fechaInicio() {
    return this.myForm.get('fechaInicio');
  }

  get fechaFin() {
    return this.myForm.get('fechaFin');
  }

  get empresa() {
    return this.myForm.get('empresa');
  }

  get tipoDenuncia() {
    return this.myForm.get('tipoDenuncia');
  }

  get tipoRelacion() {
    return this.myForm.get('tipoRelacion');
  }

  get fase() {
    return this.myForm.get('fase');
  }

  ngOnInit() {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    let fases = "TODAS";
    //let fases = "0,1,2,3,4,5"
    this.apiService.searchComplaintBySocietyPhase(this.auth.getSociedad(),fases,this.auth.getIdUser(),language).subscribe((denuncia: DatosDenuncia[])=>{
      this.denuncia = denuncia;
    });
  }

  editarDenuncia(id_denuncia:string){
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(['editar-todas-denuncias/', id_denuncia]);
    }); 
  }

  handlePage(e: PageEvent){
    this.page_size = e.pageSize;
    this.page_number = e.pageIndex+1;
  }

  cargarFiltros(){
    this.filtros = !this.filtros;
  }

  cargarEmpresas(e){
    this.apiService.searchCompanyByUser(this.auth.getIdUser(),this.auth.getSociedad()).subscribe((empresas: Empresa[])=>{
      this.empresas = empresas;
    });
  }

  cargarTipoRelaciones(e){
    let language = this.auth.getLanguage();
      if(language == "" || language == null){
        language = 'es';
      }
    this.apiService.searchTypeRelation(this.auth.getSociedad(),true,language).subscribe((tipoRelaciones: Relacion[])=>{
      this.tipoRelaciones = tipoRelaciones;
    });
  }

  cargarTipoDenuncias(e){
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.apiService.searchTypeComplaint(this.auth.getSociedad(),true,language).subscribe((denuncia: Denuncia[])=>{
      this.tipoDenuncias = denuncia;
    });
  }

  cargarFases(e){
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.apiService.getPhases(language).subscribe((fase: Fase[])=>{
      this.fases = fase;
    });
  }

  filtrar(){
    let fecInicio = "";
    if(this.fechaInicio.value != ""){
      fecInicio = this.datepipe.transform(this.fechaInicio.value, 'yyyy-MM-dd');
    }

    let fecFin = "";
    if(this.fechaFin.value != ""){
      fecFin = this.datepipe.transform(this.fechaFin.value, 'yyyy-MM-dd');
    }
    let fechaValida = true;
    if(fecInicio != "" && fecFin != ""){
      if(fecFin < fecInicio){
        fechaValida = false;
      }
    }

    let fase = "-1";
    if(this.fase.value != ""){
       fase = this.fase.value;
    }
    if(fechaValida){
      let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
      //console.log("FEC INI: "+fecInicio + "FEC FIN: "+fecFin + "EMPRESA: "+ this.empresa.value + "TIPO: "+ this.tipoDenuncia.value + "REL: "+ this.tipoRelacion.value  + "FASE: "+ fase)  
      this.apiService.searchComplaintBySocietyFechaIniHastaEmpresaTipoDenunciaTipoRelacionPhase(this.auth.getSociedad(), fecInicio, fecFin,this.empresa.value, this.tipoDenuncia.value, this.tipoRelacion.value, fase,language).subscribe((denuncia: DatosDenuncia[])=>{
        this.denuncia = denuncia;
      }); 
    }else{
      alert("Las fechas no son correctas")
    }
    
  }

  borrarFiltro(){
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.myForm.controls['fechaInicio'].setValue(null);
    this.myForm.controls['fechaFin'].setValue(null);
    this.myForm.reset();

   /* this.empresas =  [];;
    this.denuncia = [];;
    this.tipoRelaciones = [];;
    this.tipoDenuncias = [];
    this.fases = [];*/
    this.empresa.setValue("");
    this.tipoDenuncia.setValue("");
    this.tipoRelacion.setValue("");
    this.fase.setValue("");
    
    let fases = "TODAS";
    //let fases = "0,1,2,3,4,5";
    this.apiService.searchComplaintBySocietyPhase(this.auth.getSociedad(),fases,this.auth.getIdUser(),language).subscribe((denuncia: DatosDenuncia[])=>{
      this.denuncia = denuncia;
    });
  }
  
  exportExcel(): void  {
    let d = new Date().toISOString();
    let fileName = 'denuncias_' + d;  
    for(let item of this.denuncia){
      let itemDenuncia:DatosDenunciasExcel = new DatosDenunciasExcel();
      itemDenuncia.id_denuncia = item.id_denuncia;
      itemDenuncia.fec_inicio = item.fec_inicio;
      itemDenuncia.denunciante_correo = (item.denunciante_correo == "") ? "An�nimo" : item.denunciante_correo;
      itemDenuncia.n_fase = item.n_fase;
      itemDenuncia.descripcion_relacion = item.descripcion_relacion;
      itemDenuncia.descripcion_tipo_denuncia = item.descripcion_tipo_denuncia;
      this.denunciaExcel.push(itemDenuncia);
    }
    this.exportService.exportExcel(this.denunciaExcel, fileName);
    this.denunciaExcel = [];
 }
  
}