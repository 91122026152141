import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DEFAULT_LANGUAGE, TranslateService } from '@ngx-translate/core';
import { Abogado } from '../model/abogado';
import { ReceptorObligatorio } from '../model/receptorobligaorio';

@Component({
  selector: 'app-add-tipo-denuncia',
  templateUrl: './add-tipo-denuncia.component.html',
  styleUrls: ['./add-tipo-denuncia.component.css']
})
export class AddTipoDenunciaComponent implements OnInit {
  validatingForm: FormGroup;
  abogados: Abogado[];
  receptoreso: ReceptorObligatorio[];
  constructor(private apiService: ApiService, private auth: AuthService, private router: Router, private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit() {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);

    this.validatingForm = new FormGroup({
      signupFormModalName: new FormControl('', Validators.required),
      signupFormModalId: new FormControl('', Validators.required),
      contactFormModalMessage: new FormControl('', []),
      abogado: new FormControl('', [
      ]),
    });

    this.apiService.searchLayerByUserSociety(this.auth.getIdUser(),this.auth.getSociedad()).subscribe((abogado: Abogado[])=>{
      this.abogados = abogado;   
    });

    this.apiService.searchReceptorObligatoryByUserSociety(this.auth.getIdUser(),this.auth.getSociedad()).subscribe((users: ReceptorObligatorio[])=>{
      this.receptoreso = users;   
    });
  }

  get signupFormModalName() {
    return this.validatingForm.get('signupFormModalName');
  }

  get signupFormModalId() {
    return this.validatingForm.get('signupFormModalId');
  }

  get contactFormModalMessage() {
    return this.validatingForm.get('contactFormModalMessage');
  }

  get abogado() {
    return this.validatingForm.get('abogado');
  }

  addTipoDenuncia(){  
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    
    this.apiService.addTypeComplaint(this.auth.getIdUser(), this.auth.getSociedad(), this.signupFormModalId.value, this.signupFormModalName.value, this.contactFormModalMessage.value, this.abogado.value.tipo_receptor,this.abogado.value.id,this.abogado.value.nombre,language).subscribe(
      res => {
        if(res == 200){
          this.toastr.success(this.translate.instant('texto_317'), this.translate.instant('texto_319'));
        }else{
          this.toastr.error(this.translate.instant('texto_318'), this.translate.instant('texto_319'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['tipo-denuncia']);
        }); 
    },
    err =>{
      this.toastr.error(this.translate.instant('texto_318'), this.translate.instant('texto_319'));
    }); 

  }


  public options: Object = {
    charCounterCount: true,
    toolbarButtons: ['bold', 'italic', 'underline', 'fontFamily', 'fontSize',  'html', 'backgroundColor', 'textColor','alignLeft', 'alignCenter', 'alignRight', 'insertLink', '|', 'undo', 'redo' ],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'fontFamily', 'fontSize',  'html', 'backgroundColor', 'textColor','alignLeft', 'alignCenter', 'alignRight', 'insertLink', '|', 'undo', 'redo' ],
    imageAllowedTypes: ['jpeg', 'jpg', 'png'],
    imageMaxSize: 5 * 1024 * 1024,
  };
}
