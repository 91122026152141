<section style="position: absolute;width: 100%;z-index: 1;" class="image-container" style="background-image: url(assets/img/wizard/wizard-6.jpg);">
    <div class="container" style="padding: 0;">
        <div class="board" style="width: 100% !important;">
            <button type="button" style="z-index:100;float: right;" class="btn btn-dark"  (click) = "hide();">X</button> 

            <!-- Navigation Area (Circular Tabs) -->
            <msw-navbar></msw-navbar>
            <!-- End Navigation Area (Circular Tabs) -->

            <!-- Content Area -->
            <div class="tab-content">
                <form #archivoForm="ngForm" class="editForm" novalidate>
                    <div class="tab-pane fade in active">
                        <h4 class="head text-center">{{title | translate }}</h4>
                        <div class='row'>
                            <div class='col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8'>
                                <div class="row" style="margin-top: -30px;">
                                    <div class="col-md-12">
                                        <form [formGroup]="myForm" style="width:100%;display:contens;color:white">
                                            <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="dragFile($event)">
                                                <input [(ngModel)]="archivos.nombreArchivo"
                                                formControlName="file"
                                                accept=".pptx,.pptx,.pptm,.potx,.potm,.ppam,.ppsx,.ppsm,.xlsx,.xlsm,.xltx,.xltm,.xlam,image/gif,image/jpeg,image/jpg,image/png,video/*,audio/*,.pdf,.txt,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                id="file" hidden type="file" #fileInput (change)="uploadFile($event)">
                                              </div>
                                              <div  class="files-list" *ngFor="let file of files;let i= index">
                                                  <p> {{ file }} </p>
                                                  <button class="delete-file" (click)="deleteAttachment(i)">
                                                    <img src="../../assets/img/borrar-fichero.png">
                                                  </button>
                                               </div>
                                        </form>


                                        
                                    </div>

                                    <div class='col-xs-12 col-sm-12'>
                                        <label class="control-label" for="descripcion">{{ 'texto_63' | translate }}</label>
                                        <div class="md-form">
                                            <textarea maxlength="4000" class="form-control input-md" [formControl]="descripcion"  id="descripcion" name="descripcion" type="text" placeholder="{{ 'texto_63' | translate }}" [(ngModel)]="archivos.descripcionArchivo" rows="4"></textarea>
                                        </div>
                                        <p><small style="font-size: 10px;">*{{ 'texto_334' | translate }}</small></p>
                                    </div>
                                        
                                </div>

                                
                                <div class="form-group text-center" style="margin-top: 30px;">
                                    <button class="btn btn-outline-rounded btn-default" (click)="goToPrevious(archivoForm)"> <span style="margin-right:10px;" class="glyphicon glyphicon-arrow-left"></span> {{ 'texto_142' | translate }}</button>
                                    <button class="btn btn-success btn-outline-rounded btn-info" [disabled]="!archivoForm.valid" (click)="goToNext(archivoForm)"> {{ 'texto_141' | translate }} <span style="margin-left:10px;" class="glyphicon glyphicon-arrow-right"></span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>