import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router }              from '@angular/router';
import { FormDataService }     from '../data/formData.service';
import { Archivos } from '../data/formData.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { AuthService } from '../auth.service';
import { environment } from "../../environments/environment"
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../api.service';
import { Documento } from '../model/documento';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-archivos',
  templateUrl: './archivos.component.html',
  styleUrls: ['./archivos.component.css'],
  animations: []
})
export class ArchivosComponent implements OnInit {
  PHP_API_SERVER = environment.baseUrl;//"http://127.0.0.1:8080";
  title = 'texto_358';
  lastModified:string = "0";
    archivos: Archivos;
    myForm = new FormGroup({
      file: new FormControl('',[] ),
      fileSource: new FormControl('', []),
      descripcion: new FormControl('', []),
    });
    files: any = [];
    documento: Documento[];

    
    constructor(private apiService: ApiService, private translate: TranslateService, private router: Router, private formDataService: FormDataService, private _http: HttpClient, private auth: AuthService, private http: HttpClient, private toastr: ToastrService,) {
    }

    ngOnInit() {
        this.archivos = this.formDataService.getArchivos();
        if(this.archivos.nombreArchivo!=""){
          this.apiService.searchDocumentsByComplaint(this.archivos.nombreArchivo,false,'0').subscribe((documento: Documento[])=>{
            this.documento = documento;
            this.lastModified = this.documento[0].id_denuncia;
            this.files.push(this.documento[0].n_archivo);
          });
        }
    }

  save(form: any): boolean {
    if (!form.valid) {
        return false;
    }
    
    this.formDataService.setArchivos(this.archivos);
    return true;
  }

  goToPrevious(form: any) {
    if (this.save(form)) {
        this.router.navigate(['/testigo']);
    }
  }

  goToNext(form: any) {
    
    this.submit();

      if (this.save(form)) {
          this.router.navigate(['/finalizar-denuncia']);
          
      }
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.archivos.nombreArchivo = "";
        this.myForm.patchValue({
          fileSource: file
        });
      
     //this.submit();
    }
  }

  onFileChangeDrag(event) {
    if (event.length > 0) {
      const file = event[0];
        this.myForm.patchValue({
          fileSource: file
        });

    // this.submit();
    }
  }
   
get descripcion() {
  return this.myForm.get('descripcion');
}

submit(){
  if(this.archivos.nombreArchivo == ""){
    const formData = new FormData();
    let descripcion = this.descripcion.value.replace(/<[^>]*>/g, '');
    descripcion = descripcion.replace(/'/g, '');

    formData.append('file', this.myForm.get('fileSource').value);
    formData.append('id_sociedad', this.formDataService.getTipoHecho().empresa);
    formData.append('id_denuncia', this.lastModified);
    formData.append('comentario', descripcion);
    //formData.append('id_usuario', this.formDataService.getDenunciante().denuncianteEmail);
    formData.append('id_usuario', 'Denunciante');
    this.archivos.nombreArchivo = this.myForm.get('fileSource').value.lastModified;
    if(this.myForm.get('fileSource').value != ""){
      this.http.post(`${this.PHP_API_SERVER}/api/uploadDocument.php`, formData).subscribe(
        res => {
          if(res == 200){
            this.archivos.nombreArchivo =  this.lastModified;
          // this.toastr.success('Archivos cargados correctamente', 'Cargando ...');
          }else{
            this.toastr.error(this.translate.instant('texto_202'), this.translate.instant('texto_203'));
          }
        },
        err =>{
          this.toastr.error(this.translate.instant('texto_204'), this.translate.instant('texto_203'));
        })
      }
    }
  }
  
  
  hide(){
    this.auth.logout();
    window.location.reload();
    /*this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/inicio']);
    });*/
  }

  dragFile(event) {
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
     // const element = event[index];
      //const file = event.target.files[0];

      this.files.push(element.name)
      if(this.files.length > 1){
        this.files.pop(element.name)
        this.toastr.error('Sólo puede subir un archivo, si desea añadir más archivos lo podrá hacer más adelante', 'Cargando ...');
      }else{
        if(element.size/1000000 > 16){
          this.files.pop(element.name)
          alert('Tamaño máximo de archivo es de 16Mb');
        }else{
          this.lastModified = element.lastModified
          this.onFileChangeDrag(event);
        }
        
      }
    }  
  }

  uploadFile(event) {
    for (let index = 0; index < event.target.files.length; index++) {
      const element = event.target.files[index];
     // const element = event[index];
      //const file = event.target.files[0];

      this.files.push(element.name)
      if(this.files.length > 1){
        this.files.pop(element.name)
        this.toastr.error('Sólo puede subir un archivo, si desea añadir más archivos lo podrá hacer más adelante', 'Cargando ...');
      }else{
        if(element.size/1000000 > 16){
          this.files.pop(element.name)
          alert('Tamaño máximo de archivo es de 16Mb');
        }else{
            this.lastModified = element.lastModified
            this.onFileChange(event);
        }
      }
    }  
  }
  deleteAttachment(index) {
    this.archivos.nombreArchivo =  "";
    this.files.splice(index, 1)
    const formData = new FormData();
    let file = this.myForm.get('fileSource').value;
    if(this.myForm.get('fileSource').value == ""){
      formData.append('delete_file', this.documento[0].n_archivo);
    }
    formData.append('file', file);
    formData.append('id_sociedad', this.formDataService.getTipoHecho().empresa);
    formData.append('id_denuncia', this.lastModified);
    formData.append('comentario', this.descripcion.value);
    formData.append('id_usuario', this.formDataService.getDenunciante().denuncianteEmail);
    this.http.post(`${this.PHP_API_SERVER}/api/deleteDocument.php`, formData).subscribe(
      res => {
        if(res == 200){
          this.toastr.success('Archivos eliminado correctamente', 'Cargando ...');
        }else{
          this.toastr.error('Error al eliminar los archivos', 'Cargando ...');
        }
    })
  }


}
