import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatosDenuncia } from '../model/datosDenuncia';

@Component({
  selector: 'app-anadir-accion',
  templateUrl: './anadir-accion.component.html',
  styleUrls: ['./anadir-accion.component.css']
})
export class AnadirAccionComponent implements OnInit {

  validatingForm: FormGroup;
  IsHiddenForm: boolean =  false;
  @Input() denuncia: DatosDenuncia;
  contador = 0

  constructor(private apiService: ApiService, private auth: AuthService, private router: Router, private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit(): void {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.validatingForm = new FormGroup({
      nombreAccion: new FormControl('', [Validators.required]),
      nombreContacto: new FormControl('', [Validators.required]),
      responsable: new FormControl('', [Validators.required, Validators.email]),
      textoAccion: new FormControl('', [])
    });
  }

  onKey(event){
    var datos = document.getElementById("txtAccion") ;
    for(var i=0; i< 1; i++){
      var div = datos.getElementsByClassName('nw-editor__res')[i];
      this.contador = div.textContent.length
    }
  }

  get nombreAccion() {
    return this.validatingForm.get('nombreAccion');
  }

  get nombreContacto() {
    return this.validatingForm.get('nombreContacto');
  }

  get responsable() {
    return this.validatingForm.get('responsable');
  }

  get textoAccion() {
    return this.validatingForm.get('textoAccion');
  }

  asignarAccion(){
    if(this.contador>4000){
      this.toastr.error(this.translate.instant('texto_266'), this.translate.instant('texto_243'));
    }else{
      
      let $clau = false;
      let clausulas_correo = localStorage.getItem("clausulas_correo");
      if(clausulas_correo == '1'){
        $clau = true;
      }

      this.apiService.createActionComplaint(this.auth.getSociedad(), this.denuncia[0].id_denuncia, this.nombreAccion.value, this.responsable.value, this.nombreContacto.value, this.textoAccion.value,this.auth.getIdUser()).subscribe(
        res => {
          if(res == 200){
            this.toastr.success(this.translate.instant('texto_299'), this.translate.instant('texto_300'));
            if(this.responsable.value != ""){
              let message = "Estimado/a "  +  this.nombreContacto.value + ", con relaccion a la denuncia con id "  + this.denuncia[0].id_denuncia  + " le comunicamos que, a petición del Compliance Officer, debe realizar la siguiente acción: "+ this.nombreAccion.value+ ". Consistente en: "+ this.textoAccion.value;
              this.apiService.sendEmail(this.responsable.value,'Plan de Accion para denuncia '+this.denuncia[0].id_denuncia, message,'',$clau, "Asignación de llevar a cabo una acción").subscribe(
                res => {
                  if(res == 200){
                  //  this.toastr.success(this.translate.instant('texto_194'), this.translate.instant('texto_195'));
                  }else{
                    this.toastr.error(this.translate.instant('texto_193'), this.translate.instant('texto_195'));
                  }         
              });
            }
          }else{
            this.toastr.error(this.translate.instant('texto_301'), this.translate.instant('texto_300'));
          }
          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['editar-denuncia/',this.denuncia[0].id_denuncia]);
          }); 
      });
  }
  }
}
