import { Component, OnInit } from '@angular/core';
import { Router }              from '@angular/router';
import { FormDataService }     from '../data/formData.service';
import { Datos } from '../data/formData.model';
import {
    MAT_MOMENT_DATE_FORMATS,
    MomentDateAdapter,
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  } from '@angular/material-moment-adapter';

import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-datos-denuncia',
  templateUrl: './datos-denuncia.component.html',
  styleUrls: ['./datos-denuncia.component.css'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})

export class DatosDenunciaComponent implements OnInit {
  contador = 0;
  title = 'texto_109';
    datos: Datos;
    form: any;
    hoy = new Date(); 
    constructor(private router: Router, private formDataService: FormDataService, private _adapter: DateAdapter<any>, private toastr: ToastrService, private translate: TranslateService) {
      this.hoy.setDate(this.hoy.getDate());
    }

    ngOnInit() {
        this._adapter.setLocale('es');
        this.datos = this.formDataService.getDatos();
       // let datosDescripcion = (document.getElementById("descripcionDatosDenuncia") as HTMLInputElement).value;
        this.contador = this.datos.descripcionDatosDenuncia.length;
    }

    save(form: any): boolean {
        if (!form.valid) {
            return false;
        }
            
      //  this.datos.fechaDatosDenuncia = "2015-02-02"
      if(this.contador>4000){
        this.toastr.error(this.translate.instant('texto_266'), this.translate.instant('texto_243'));
      }else{

        this.formDataService.setDatos(this.datos);
        return true;
      }
    }

    goToPrevious(form: any) {
      if (this.save(form)) {
          // Navigate to the personal page
          this.router.navigate(['/receptor']);
      }
  }

    goToNext(form: any) {
        if (this.save(form)) {
            // Navigate to the work page
            this.router.navigate(['/testigo']);
        }
    }

    hide(){
      window.location.reload();
     /* this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/inicio']);
      });*/
    }

    onKey(event){
      let datos = (document.getElementById("descripcionDatosDenuncia") as HTMLInputElement).value;
      this.contador = datos.length;
    }
}
