
  <!-- End Navbar -->
  <div class="main-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-8">
          <div class="card">
            <div class="card-header card-header-primary">
              <h4 class="card-title">{{ 'texto_115' | translate }}</h4>
              <p class="card-category" style="color: rgb(0 0 0 / 100%);">{{ 'texto_116' | translate }}</p>
            </div>
            <div class="card-body">
              <form [formGroup]="validatingForm2">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="bmd-label-floating">{{ 'texto_91' | translate }}</label>
                      <input formControlName="username" id="username" readonly type="text" class="form-control" name="username" [(ngModel)]="username">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="bmd-label-floating">{{ 'texto_38' | translate }}</label>
                      <input formControlName="email" type="email" id="email" class="form-control" name="email" [(ngModel)]="email" mdbInput mdbValidate>

                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="bmd-label-floating">{{ 'texto_35' | translate }}</label>
                      <input formControlName="name" type="text" id="name" class="form-control" name="name" [(ngModel)]="name"  mdbInput mdbValidate>

                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="bmd-label-floating">{{ 'texto_36' | translate }}</label>
                      <input formControlName="surname" type="text" id="surname"  class="form-control" name="surname" [(ngModel)]="surname" mdbInput mdbValidate> 

                    </div>
                  </div>
                </div>                      
                <button (click)="updateDataUser()" type = "submit"  class="btn btn-primary pull-right">{{ 'texto_59' | translate }}</button>
                <div class="clearfix"></div>
              </form>
            </div>
          </div>
        </div>
      
        <div class="col-md-4">
          
          <div class="card card-profile">
            <div class="card-avatar">
              <form [formGroup]="myForm" (ngSubmit)="submit()">

                <div class="form-group">
                    <label for="file" style="padding: 40px;"></label>
                    <input 
                        formControlName="file"
                        id="file" 
                        accept=".pptx,.pptx,.pptm,.potx,.potm,.ppam,.ppsx,.ppsm,.xlsx,.xlsm,.xltx,.xltm,.xlam,image/gif,image/jpeg,image/jpg,image/png,video/*,audio/*,.pdf,.txt,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        type="file" 
                        class="form-control"
                        (change)="onFileChange($event)">
                    <div *ngIf="f.file.touched && f.file.invalid" class="alert alert-danger">
                        <div *ngIf="f.file.errors.required">File is required.</div>
                    </div>
                </div>
                    
            </form>

              <span>
                <img class="img" style="margin-top:-166px;" src="{{urlPhoto}}" />
              </span>
              
            
            </div>
            <div class="card-body">
              <h6 class="card-category text-gray">{{email}}</h6>
              <h4 class="card-title">{{name}} {{surname}}</h4>           
              <app-update-password></app-update-password>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



  