import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../api.service';
import { AuthService } from '../auth.service';
import { Idiomas } from '../model/idiomas';

@Component({
  selector: 'app-translation',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.css']
})
export class TranslationComponent implements OnInit {

  public activeLang = 'es';
  idiomas: Idiomas[];
  constructor( private auth: AuthService,private apiService: ApiService,private translate: TranslateService) {
    this.translate.setDefaultLang(this.activeLang);
  }
  ngOnInit() {
    this.apiService.searchIdiomas().subscribe((idiomas: Idiomas[])=>{
      this.idiomas = idiomas;
    });


  }
  public cambiarLenguaje(lang) {
    this.auth.setLanguage(lang);
    this.activeLang = lang;
    this.translate.use(lang); 
  }

}
  