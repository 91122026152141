import { Component, OnInit, Input } from '@angular/core';
import { Router }              from '@angular/router';
import { FormDataService }     from '../data/formData.service';
import { DatosDenunciante } from '../data/formData.model';
import { ApiService } from '../api.service';
import { Relacion } from '../model/relacion';
import { Canal } from '../model/canal';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-denunciante',
  templateUrl: './denunciante.component.html',
  styleUrls: ['./denunciante.component.css']
})
export class DenuncianteComponent implements OnInit {
  @Input() formData;
  title = 'texto_78';
    relacion: DatosDenunciante;
    tipoRelacion: string;
    form: any;
    tipoDenunciantes: Relacion;
    canal: Canal;
    IsAnonimo:boolean = false;
    IsHidden= true;
    constructor( private auth: AuthService,private apiService: ApiService, private router: Router, private formDataService: FormDataService, private toastr: ToastrService) {}

    ngOnInit() {
      let language = this.auth.getLanguage();
      if(language == "" || language == null){
        language = 'es';
      }

        this.relacion = this.formDataService.getDenunciante();
        this.formData = this.formDataService.getFormData();

        this.apiService.searchTypeRelation(this.formData.empresa,false,language).subscribe((tipoDenunciantes: Relacion)=>{
          this.tipoDenunciantes = tipoDenunciantes;
        });

        this.apiService.searchCanal().subscribe((canal: Canal)=>{
            this.canal = canal;
            if(this.canal[0].valor == "1"){
                this.IsAnonimo = true;
            }
            if(this.canal[0].valor == "2"){
              this.IsHidden = false;
            }            
          });          
    }

    save(form: any): boolean {
        if(this.IsAnonimo != true){
            if (!form.valid) { 
                return false;
            }
            if(this.canal[0].valor == "0"){
              if(this.relacion.denuncianteFirstName == "" || this.relacion.denuncianteLastName == "" || this.relacion.denuncianteEmail == "" || this.relacion.denunciantePhone == "" || this.relacion.denuncianteDNI == ""){
                this.toastr.error('Es obligatorio dejar los datos personales', 'Par�metros incorrectos');
                return false;
              }
            }
        }
        
        
        this.formDataService.setDenunciante(this.relacion);
        return true;
    }

    goToPrevious(form: any) {
       // if (this.save(form)) {
            this.router.navigate(['/tipo']);
      //  }
    }

    goToNext(form: any) {
        if (this.save(form)) {
            this.router.navigate(['/receptor']);
        }
    }

    hide(){
        window.location.reload();
       /* this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/inicio']);
        });*/
    }
}
