import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Parametros } from '../model/parametros';
import { environment } from "../../environments/environment";
import { FormDataService }     from '../data/formData.service';
import { Textos } from '../data/formData.model';
import { Textos2 } from '../model/textos2';
import { Clausulas } from '../data/formData.model';
import { Clausulas2 } from '../model/clausulas2';

@Component({
  selector: 'app-configuracion-avanzada',
  templateUrl: './configuracion-avanzada.component.html',
  styleUrls: ['./configuracion-avanzada.component.css']
})
export class ConfiguracionAvanzadaComponent implements OnInit {
  PHP_API_SERVER = environment.baseUrl;

  validatingForm: FormGroup;
  validatingFormImages: FormGroup;
  validatingFormMail: FormGroup;
  validatingFormColores: FormGroup;
  validatingFormTextos: FormGroup;
  validatingFormClausulas: FormGroup;

  emailF:string = "";
  dominioF:string = "";
  avisoF:string = "";
  //anonimoF: boolean = false;
  anonimoF:string = "";
  clausulasF:string = "";
  conflictoF:string = "";
  diasinvesF:string = "";
  diasdecisorF:string = "";
  diascomplianceF:string = "";
  diasverdenunciaF:string = "";  

  logoF:string = "";
  inicio1F:string = "";
  inicio2F:string = "";
  inicio3F:string = "";
  fondo_denuncianteF:string = "";
  wizard1F:string = "";  
  wizard2F:string = "";  
  wizard3F:string = "";
  wizard4F:string = "";  
  wizard5F:string = "";   
  wizard6F:string = ""; 
  wizard7F:string = "";  
  logindenuncianteF:string = "";
  cabeceramailF:string = "";

  nremitenteF:string = "";
  remitenteF:string = "";
  srv_mailF:string = "";
  puertoF:string = "";
  usr_mailF:string = "";
  pass_mailF:string = "";
  auth_mailF: boolean = false;
  sslF: boolean = false;

  colorbgseccion1F: string = "";
  colorbgseccion2F: string = "";
  colorbgseccion3F: string = "";
  colorprincipalF: string = "";
  colorsecundarioF: string = "";  
  colortxtbtnF: string = "";
  colortxtseccion1F: string = "";
  colortxtseccion2F: string = "";  
  colortxtseccion3F: string = "";
  colortxttituloF: string = "";
  colormenulateralF: string = "";  
  colortxtcabecerasF: string = "";
  colortxtbotonesF: string = "";

  parametros: Parametros;
  
  fileData: File = null;
  previewUrl:any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;    
  
  color: string;

  selectedTipo: string = '';
  selectedClausulas: string = '';
  selectedConflicto: string = '';
  selectedTriaje: string = '';

  textos: Textos;
  textos2: Textos2[];
  textoF:string = "";
  selectedIdTexto: string;

  clausulas: Clausulas;
  clausulas2: Clausulas2[];
  clausulaF:string = "";
  selectedIdClausula: string;

  rutaF: string = ""
  doc_terminosF: string = "";

  //fichero: boolean;
  selectedTerminos:string;   

  //fichero: boolean;
  selectedBoton1:string;  
  doc_boton1F: string = "";

  selectedBoton2:string;  
  doc_boton2F: string = "";

  selectedBoton3:string;  
  doc_boton3F: string = "";



  //event handler for the select element's change event
  selectChangeHandler (event: any) {
    //update the ui
    this.selectedTipo = event.target.value;
  }  

  //event handler for the select element's change event
  selectChangeHandler2 (event2: any) {
    //update the ui
    this.selectedClausulas = event2.target.value;
  }  

  //event handler for the select element's change event
  selectChangeHandler3 (event3: any) {
  //update the ui
  this.selectedConflicto = event3.target.value;
  }  
  
    //event handler for the select element's change event
    selectChangeHandler8 (event8: any) {
      //update the ui
      this.selectedTriaje = event8.target.value;
    }  

  //event handler for the select element's change event
  selectChangeHandler4 (event4: any) {
    //update the ui
    this.selectedTerminos = event4.target.value;
    /*console.log(this.selectedTerminos);
    if(this.selectedTerminos == "0"){
      this.fichero = true;
    }else{
      this.fichero = false;
    }*/
  } 
  selectChangeHandler5 (event5: any) {
    //update the ui
    this.selectedBoton1 = event5.target.value;
    //console.log(this.selectedBoton1);
    /*if(this.selectedTerminos == "0"){
      this.fichero = true;
    }else{
      this.fichero = false;
    }*/
  } 

  selectChangeHandler6 (event6: any) {
    //update the ui
    this.selectedBoton2 = event6.target.value;
    //console.log(this.selectedBoton1);
    /*if(this.selectedTerminos == "0"){
      this.fichero = true;
    }else{
      this.fichero = false;
    }*/
  } 

  selectChangeHandler7 (event6: any) {
    //update the ui
    this.selectedBoton3 = event6.target.value;
    //console.log(this.selectedBoton1);
    /*if(this.selectedTerminos == "0"){
      this.fichero = true;
    }else{
      this.fichero = false;
    }*/
  } 


  constructor(private apiService: ApiService, private formDataService: FormDataService, private auth: AuthService, private toastr: ToastrService,  private translate: TranslateService, private router: Router,private http: HttpClient) { }


  ngOnInit() {

    let idioma = this.auth.getLanguage();
    if(idioma == "" || idioma == null){
      idioma = 'es';
    }    
    
    this.apiService.searchAdvancedParam().subscribe((parametros: Parametros)=>{
      this.parametros = parametros;
      this.selectedTipo = this.parametros[0].valor;
      //this.anonimoF = this.parametros[0].valor;
      if(this.parametros[1].valor == "1"){
        this.auth_mailF = true;
      }else{
        this.auth_mailF = false;
      } 
      this.cabeceramailF = this.parametros[2].valor;
      this.selectedClausulas = this.parametros[3].valor;
      this.colorbgseccion1F = this.parametros[4].valor;
      this.colorbgseccion2F = this.parametros[5].valor;
      this.colorbgseccion3F = this.parametros[6].valor;
      this.colorprincipalF = this.parametros[7].valor;
      this.colorsecundarioF = this.parametros[8].valor;
      this.colortxtbtnF = this.parametros[9].valor;
      this.colortxtseccion1F = this.parametros[10].valor;
      this.colortxtseccion2F = this.parametros[11].valor;
      this.colortxtseccion3F = this.parametros[12].valor;
      this.colortxttituloF = this.parametros[13].valor;
      this.selectedConflicto = this.parametros[14].valor;
      this.remitenteF = this.parametros[15].valor;
      //this.destination_file = this.parametros[16].valor;
      this.diascomplianceF = this.parametros[17].valor;
      this.diasdecisorF = this.parametros[18].valor;
      this.diasinvesF = this.parametros[19].valor;
      this.dominioF = this.parametros[20].valor;
      this.fondo_denuncianteF = this.parametros[21].valor;
      this.inicio1F = this.parametros[22].valor;
      this.inicio2F = this.parametros[23].valor;
      this.inicio3F = this.parametros[24].valor;
      this.logindenuncianteF = this.parametros[25].valor;
      this.logoF = this.parametros[26].valor;
      this.emailF = this.parametros[27].valor;
      this.nremitenteF = this.parametros[28].valor;
      this.pass_mailF = this.parametros[29].valor;
      this.avisoF = this.parametros[30].valor;
      this.puertoF = this.parametros[31].valor;
      this.srv_mailF = this.parametros[32].valor;
      if(this.parametros[33].valor == "1"){
        this.sslF = true;
      }else{
        this.sslF = false;
      }
      this.usr_mailF = this.parametros[34].valor;
      this.wizard1F = this.parametros[35].valor;
      this.wizard2F = this.parametros[36].valor;
      this.wizard3F = this.parametros[37].valor;
      this.wizard4F = this.parametros[38].valor;
      this.wizard5F = this.parametros[39].valor;
      this.wizard6F = this.parametros[40].valor;
      this.wizard7F = this.parametros[41].valor;
      this.selectedTerminos = this.parametros[42].valor;
      this.doc_terminosF = this.parametros[43].valor;
      this.rutaF = this.parametros[44].valor;
      this.diasverdenunciaF = this.parametros[45].valor;
      this.colormenulateralF = this.parametros[46].valor;
      this.colortxtcabecerasF = this.parametros[47].valor;
      this.colortxtbotonesF = this.parametros[48].valor;
      this.doc_boton1F = this.parametros[50].valor;
      this.selectedBoton1 = this.parametros[51].valor;
      this.doc_boton2F = this.parametros[52].valor;
      this.selectedBoton2 = this.parametros[53].valor;
      this.doc_boton3F = this.parametros[54].valor;
      this.selectedBoton3 = this.parametros[55].valor;
      this.selectedTriaje = this.parametros[56].valor;
      /*if(this.selectedTerminos == "0"){
        this.fichero = true;
      }else{
        this.fichero = false;
      }*/
          

    });


    this.textos = this.formDataService.getTexto();
    if(this.textos.id_texto != ""){
      this.apiService.searchTextos(this.textos.id_texto, idioma).subscribe((textos2: Textos2[])=>{
        this.textos2 = textos2;
        this.textoF = textos2[0].texto;
      });
    }   
    
    
    this.clausulas = this.formDataService.getClausula();
    if(this.clausulas.id_clausula != ""){
      this.apiService.searchClausulas(this.clausulas.id_clausula).subscribe((clausulas2: Clausulas2[])=>{
        this.clausulas2 = clausulas2;
        this.clausulaF = clausulas2[0].clausula;
      });
    }     


    this.validatingForm = new FormGroup({
      email: new FormControl('', [
        Validators.email,
      ]),
      dominio: new FormControl('', [
      ]),
      aviso: new FormControl('', [
      ]),
      anonimo: new FormControl('', [
      ]),
      calusulas: new FormControl('', [
      ]),
      triaje: new FormControl('', [
      ]),      
      conflicto: new FormControl('', [
      ]),
      diasinves: new FormControl('', [
      ]),
      diasdecisor: new FormControl('', [
      ]),
      diascompliance: new FormControl('', [
      ]),
      doc_terminos: new FormControl('', [
      ]),
      doc_boton1: new FormControl('', [
      ]),
      boton1: new FormControl('', [
      ]),
      doc_boton2: new FormControl('', [
      ]),
      boton2: new FormControl('', [
      ]),
      doc_boton3: new FormControl('', [
      ]),
      boton3: new FormControl('', [
      ]),
      ruta: new FormControl('', [
      ]),    
      terminos: new FormControl('', [
      ]), 
      file: new FormControl('',[
      ] ),
      fileSource: new FormControl('', [
      ]),
      fich: new FormControl('', [
      ]),    
      diasverdenuncia: new FormControl('', [
      ]),     
    }); 

    this.validatingFormColores = new FormGroup({
      colorprincipal: new FormControl('', [
      ]),
      colorsecundario: new FormControl('', [
      ]),
      colorbgseccion1: new FormControl('', [
      ]),
      colorbgseccion2: new FormControl('', [
      ]),
      colorbgseccion3: new FormControl('', [
      ]),
      colortxtbtn: new FormControl('', [
      ]),
      colortxtseccion1: new FormControl('', [
      ]),
      colortxtseccion2: new FormControl('', [
      ]),
      colortxtseccion3: new FormControl('', [
      ]),
      colortxttitulo: new FormControl('', [
      ]),      
      colormenulateral: new FormControl('', [
      ]), 
      colortxtcabeceras: new FormControl('', [
      ]), 
      colortxtbotones: new FormControl('', [
      ]), 
    });     

    this.validatingFormImages = new FormGroup({
      file: new FormControl('',[] ),
      fileSource: new FormControl('', []),
      fich: new FormControl('', []),      
      logo: new FormControl('', []),
      inicio1: new FormControl('', []),
      inicio2: new FormControl('', []),
      inicio3: new FormControl('', []),
      fondo_denunciante: new FormControl('', []),
      wizard1: new FormControl('', []),
      wizard2: new FormControl('', []),
      wizard3: new FormControl('', []),
      wizard4: new FormControl('', []),
      wizard5: new FormControl('', []),
      wizard6: new FormControl('', []),
      wizard7: new FormControl('', []),
      logindenunciante: new FormControl('', []),
      cabeceramail: new FormControl('', [])      
    }); 

    this.validatingFormMail = new FormGroup({
      nremitente: new FormControl('',[] ),
      remitente: new FormControl('', []),
      srv_mail: new FormControl('', []),      
      puerto: new FormControl('', []),
      usr_mail: new FormControl('', []),
      pass_mail: new FormControl('', []),
      auth_mail: new FormControl('', []),
      ssl: new FormControl('', [])     
    });   

    this.validatingFormTextos = new FormGroup({
      id_texto: new FormControl('',[] ),
      texto_value: new FormControl('',[] )    
    });      

    this.validatingFormClausulas = new FormGroup({
      id_clausula: new FormControl('',[] ),
      clausula_value: new FormControl('',[] )    
    });      
    
  }


  onChangeTexto(event) {
    this.textoF = "";
    let selectedIdTexto = event.value;
    //console.log(selectedIdTexto);
    for(var i=0; i<this.textos2.length; i++){
      if(this.textos2[i].id_texto == selectedIdTexto){
        this.textoF = this.textos2[i].texto;
        //console.log(this.textoF);
        break;
      }
    }
    
  }

  onClickTexto(event) {
      let idioma = this.auth.getLanguage();
      if(idioma == "" || idioma == null){
        idioma = 'es';
      }        
      this.apiService.searchTextos2(this.textos.id_texto,false,idioma).subscribe((textos2: Textos2[])=>{
      this.textos2 = textos2;
    });
  }

  onChangeClausula(event) {
    this.clausulaF = "";
    let selectedIdClausula = event.value;
    //console.log(selectedIdTexto);
    for(var i=0; i<this.clausulas2.length; i++){
      if(this.clausulas2[i].id_clausula == selectedIdClausula){
        this.clausulaF = this.clausulas2[i].clausula;
        //console.log(this.textoF);
        break;
      }
    }
    
  }
  
  onClickClausula(event) {
      this.apiService.searchClausulas2(this.clausulas.id_clausula,false).subscribe((clausulas2: Clausulas2[])=>{
      this.clausulas2 = clausulas2;
    });    
  }

  get dominio() {
    return this.validatingForm.get('dominio');
  }

  get email() {
    return this.validatingForm.get('email');
  }

  get aviso() {
    return this.validatingForm.get('aviso');
  }

  get anonimo() {
    return this.validatingForm.get('anonimo');
  }

  get calusulas() {
    return this.validatingForm.get('calusulas');
  }

  get triaje() {
    return this.validatingForm.get('triaje');
  }  

  get conflicto() {
    return this.validatingForm.get('conflicto');
  }

  get diasinves() {
    return this.validatingForm.get('diasinves');
  }

  get diasdecisor() {
    return this.validatingForm.get('diasdecisor');
  }

  get diascompliance() {
    return this.validatingForm.get('diascompliance');
  }

  get ruta() {
    return this.validatingForm.get('ruta');
  }

  get doc_terminos() {
    return this.validatingForm.get('doc_terminos');
  }
  get doc_boton1() {
    return this.validatingForm.get('doc_boton1');
  }

  get boton1() {
    return this.validatingForm.get('boton1');
  }

  get doc_boton2() {
    return this.validatingForm.get('doc_boton2');
  }

  get boton2() {
    return this.validatingForm.get('boton2');
  }

  get doc_boton3() {
    return this.validatingForm.get('doc_boton3');
  }

  get boton3() {
    return this.validatingForm.get('boton3');
  }

  get terminos() {
    return this.validatingForm.get('terminos');
  }


  get logo() {
    return this.validatingFormImages.get('logo');
  }  

  get inicio1() {
    return this.validatingFormImages.get('inicio1');
  } 

  get inicio2() {
    return this.validatingFormImages.get('inicio2');
  } 

  get inicio3() {
    return this.validatingFormImages.get('inicio3');
  } 

  get fondo_denunciante() {
    return this.validatingFormImages.get('fondo_denunciante');
  } 

  get wizard1() {
    return this.validatingFormImages.get('wizard1');
  } 

  get wizard2() {
    return this.validatingFormImages.get('wizard2');
  } 

  get wizard3() {
    return this.validatingFormImages.get('wizard3');
  } 

  get wizard4() {
    return this.validatingFormImages.get('wizard4');
  } 

  get wizard5() {
    return this.validatingFormImages.get('wizard5');
  } 

  get wizard6() {
    return this.validatingFormImages.get('wizard6');
  } 

  get wizard7() {
    return this.validatingFormImages.get('wizard7');
  } 

  get logindenunciante() {
    return this.validatingFormImages.get('logindenunciante');
  } 

  get cabeceramail() {
    return this.validatingFormImages.get('cabeceramail');
  } 

  get nremitente() {
    return this.validatingFormMail.get('nremitente');
  }

  get remitente() {
    return this.validatingFormMail.get('remitente');
  }

  get srv_mail() {
    return this.validatingFormMail.get('srv_mail');
  }

  get puerto() {
    return this.validatingFormMail.get('puerto');
  }

  get usr_mail() {
    return this.validatingFormMail.get('usr_mail');
  }

  get pass_mail() {
    return this.validatingFormMail.get('pass_mail');
  }

  get auth_mail() {
    return this.validatingFormMail.get('auth_mail');
  }

  get ssl() {
    return this.validatingFormMail.get('ssl');
  }

  get colorprincipal() {
    return this.validatingFormColores.get('colorprincipal');
  }

  get colorsecundario() {
    return this.validatingFormColores.get('colorsecundario');
  }

  get colorbgseccion1() {
    return this.validatingFormColores.get('colorbgseccion1');
  }

  get colormenulateral() {
    return this.validatingFormColores.get('colormenulateral');
  }

  get colortxtcabeceras() {
    return this.validatingFormColores.get('colortxtcabeceras');
  }

  get colortxtbotones() {
    return this.validatingFormColores.get('colortxtbotones');
  }

  get colorbgseccion2() {
    return this.validatingFormColores.get('colorbgseccion2');
  }

  get colorbgseccion3() {
    return this.validatingFormColores.get('colorbgseccion3');
  }

  get colortxtbtn() {
    return this.validatingFormColores.get('colortxtbtn');
  }

  get colortxtseccion1() {
    return this.validatingFormColores.get('colortxtseccion1');
  }

  get colortxtseccion2() {
    return this.validatingFormColores.get('colortxtseccion2');
  }

  get colortxtseccion3() {
    return this.validatingFormColores.get('colortxtseccion3');
  }

  get colortxttitulo() {
    return this.validatingFormColores.get('colortxttitulo');
  }

  get texto_value() {
    return this.validatingFormTextos.get('texto_value');
  }

  get id_texto() {
    return this.validatingFormTextos.get('id_texto');
  }
  
  get clausula_value() {
    return this.validatingFormClausulas.get('clausula_value');
  }

  get id_clausula() {
    return this.validatingFormClausulas.get('id_clausula');
  }

  get diasverdenuncia() {
    return this.validatingForm.get('diasverdenuncia');
  }
  
  guardarDatos(){
    /*let valor1 = "0";
    if(this.anonimo.value == true){
      valor1 = "1";
    }*/
    let valor1 = "0";
    valor1 = this.selectedTipo;   

    let valor2 = "";
    if(this.dominio.value != ""){
      valor2 = this.dominio.value;
    }

    let valor3 = "";
    if(this.email.value != ""){
      valor3 = this.email.value;
    }

    let valor4 = "";
    if(this.aviso.value != ""){
      valor4 = this.aviso.value;
    }

    let valor5 = false;
    let valor51 = "0";
    if (this.selectedClausulas == "1"){
      valor5 = true;
      valor51 = "1";
    }  

    let valor6 = "0";
    valor6 = this.selectedConflicto;

    let valor7 = "";
    if(this.diasinves.value != ""){
      valor7 = this.diasinves.value;
    }

    let valor8 = "";
    if(this.diasdecisor.value != ""){
      valor8 = this.diasdecisor.value;
    }

    let valor9 = "";
    if(this.diascompliance.value != ""){
      valor9 = this.diascompliance.value;
    }

    let valor10 = "0";
    valor10 = this.selectedTerminos;    

    let valor11 = "";
    if(this.doc_terminos.value != ""){
      valor11 = this.doc_terminos.value;
    }  
    
    let valor12 = "";
    if(this.ruta.value != ""){
      valor12 = this.ruta.value;
    }     
    
    let valor13 = "";
    if(this.diasverdenuncia.value != ""){
      valor13 = this.diasverdenuncia.value;
    }     

    let valor14 = "0";
    valor14 = this.selectedBoton1; 

    let valor15 = "0";
    valor15 = this.selectedBoton2; 

    let valor16 = "0";
    valor16 = this.selectedBoton3; 

    let valor17 = false;
    let valor171 = "0";
    if (this.selectedTriaje == "1"){
      valor17 = true;
      valor171 = "1";
    }      
    
    let reload = "0";
    if(this.boton1.value != valor14){
      reload = "1";
    }
    if(this.boton2.value != valor15){
      reload = "1";
    }

    if(this.boton3.value != valor16){
      reload = "1";
    }


    this.apiService.updateAdvancedParam(valor1,valor2,valor3,valor4,valor51,valor6,valor7,valor8,valor9,valor10,valor11,valor12,valor13,valor14,valor15,valor16,valor171).subscribe(
      res => {
        if(res == 200){
          if(valor10=="0"){
            this.subirDoc();
          }
          
          this.toastr.success(this.translate.instant('texto_290'), this.translate.instant('texto_292'));
          this.auth.setAnonimo(valor1);
          this.auth.setRemitente(valor2);
          this.auth.setConsultas(valor3);
          this.auth.setAviso(valor4);
          this.auth.setClausulas(valor5);
          this.auth.setConflicto(valor6);
          this.auth.setDiasInves(valor7);
          this.auth.setDiasDecisor(valor8);
          this.auth.setDiasCompliance(valor9);
          this.auth.setTerminos(valor10);
          this.auth.setDocTerminos(valor11);
          this.auth.setRuta(valor12);
          this.auth.setDiasverdenuncia(valor13);
          this.auth.setBoton1(valor14);
          this.auth.setBoton2(valor15);
          this.auth.setBoton3(valor16);
          this.auth.setTriaje(valor17);
          if(reload == "1"){
            window.location.reload();
          }
          
         }else{
          this.toastr.error(this.translate.instant('texto_291'), this.translate.instant('texto_292'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['advanced-setting']);
        });          
    });
  }

  guardarColores(){
    let valor1 = "";
    if(this.colorprincipal.value != ""){
      valor1 = this.colorprincipal.value;
    }
    let valor2 = "";
    if(this.colorsecundario.value != ""){
      valor2 = this.colorsecundario.value;
    }
    let valor3 = "";
    if(this.colorbgseccion2.value != ""){
      valor3 = this.colorbgseccion2.value;
    }
    let valor4 = "";
    if(this.colorbgseccion3.value != ""){
      valor4 = this.colorbgseccion3.value;
    }
    let valor5 = "";
    if(this.colortxtbtn.value != ""){
      valor5 = this.colortxtbtn.value;
    }
    let valor6 = "";
    if(this.colortxtseccion1.value != ""){
      valor6 = this.colortxtseccion1.value;
    }
    let valor7 = "";
    if(this.colortxtseccion2.value != ""){
      valor7 = this.colortxtseccion2.value;
    }
    let valor8 = "";
    if(this.colortxtseccion3.value != ""){
      valor8 = this.colortxtseccion3.value;
    }
    let valor9 = "";
    if(this.colortxttitulo.value != ""){
      valor9 = this.colortxttitulo.value;
    }
    let valor10 = "";
    if(this.colorbgseccion1.value != ""){
      valor10 = this.colorbgseccion1.value;
    }    
    let valor11 = "";
    if(this.colormenulateral.value != ""){
      valor11 = this.colormenulateral.value;
    }   
    let valor12 = "";
    if(this.colortxtcabeceras.value != ""){
      valor12 = this.colortxtcabeceras.value;
    }   
    let valor13 = "";
    if(this.colortxtbotones.value != ""){
      valor13 = this.colortxtbotones.value;
    }   
    this.apiService.updateAdvancedParam3(valor1,valor2,valor3,valor4,valor5,valor6,valor7,valor8,valor9,valor10,valor11,valor12,valor13).subscribe(
      res => {
        if(res == 200){
          this.toastr.success(this.translate.instant('texto_290'), this.translate.instant('texto_292'));
          this.auth.setColorprincipal(valor1);
          this.auth.setColorsecundario(valor2);
          this.auth.setColorbgseccion2(valor3);
          this.auth.setColorbgseccion3(valor4);
          this.auth.setColortxtbtn(valor5);
          this.auth.setColortxtseccion1(valor6);
          this.auth.setColortxtseccion2(valor7);
          this.auth.setColortxtseccion3(valor8);
          this.auth.setColortxttitulo(valor9);
          this.auth.setColorbgseccion1(valor10);
          this.auth.setColormenulateral(valor11);
          this.auth.setColortxtcabeceras(valor12);
          this.auth.setColortxtbotones(valor13);
          window.location.reload();
        }else{
          this.toastr.error(this.translate.instant('texto_291'), this.translate.instant('texto_292')); 
          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['advanced-setting']); 
          }); 
      }
        
    });
  }


  guardarDatosCorreo(){  
    let valor1 = "";
    if(this.nremitente.value != ""){
      valor1 = this.nremitente.value;
    }
    let valor2 = "";
    if(this.remitente.value != ""){
      valor2 = this.remitente.value;
    }
    let valor3= "";
    if(this.srv_mail.value != ""){
      valor3 = this.srv_mail.value;
    }
    let valor4 = "";
    if(this.puerto.value != ""){
      valor4 = this.puerto.value;
    }
    let valor5 = "";
    if(this.usr_mail.value != ""){
      valor5 = this.usr_mail.value;
    }
    let valor6 = "";
    if(this.pass_mail.value != ""){
      valor6 = this.pass_mail.value;
    }
    let valor7 = "0";
    if(this.ssl.value == true){
      valor7 = "1";
    } 
    let valor8 = "0";
    if(this.auth_mail.value == true){
      valor8 = "1";
    }     
    this.apiService.updateAdvancedParam2(valor1,valor2,valor3,valor4,valor5,valor6,valor7,valor8).subscribe(
      res => {
        if(res == 200){
          this.toastr.success(this.translate.instant('texto_290'), this.translate.instant('texto_292'));
          this.auth.setNremitente(valor1);
          this.auth.setRemitente(valor2);
          this.auth.setSrv_mail(valor3);
          this.auth.setPuerto(valor4);
          this.auth.setUsr_mail(valor1);
          this.auth.setPass_mail(valor2);
          this.auth.setSsl(valor3);
          this.auth.setAuth_mail(valor4);          
        }else{
          this.toastr.error(this.translate.instant('texto_291'), this.translate.instant('texto_292'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['advanced-setting']);
        }); 
    });
  }


  guardarTextos(){  
    let idioma = this.auth.getLanguage();
    if(idioma == "" || idioma == null){
      idioma = 'es';
    }  
    let valor1 = "";
    let valor2 = "";
    //console.log(this.texto_value.value);
    //console.log(this.id_texto.value);
    if(this.texto_value.value != ""){
      valor1 = this.id_texto.value;
      valor2 = this.texto_value.value;
    }    
    this.apiService.updateTextos(valor1,valor2,idioma).subscribe(
      res => {
        if(res == 200){
          this.apiService.generarFicheroTextos(idioma).subscribe(
            res => {
              if(res == 200){
                this.toastr.success(this.translate.instant('texto_525'), this.translate.instant('texto_527'));
                this.auth.setId_texto(valor1);
                this.auth.setTexto(valor2);
                this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                  this.router.navigate(['advanced-setting']);
                });                
              }else{
                this.toastr.error(this.translate.instant('texto_526'), this.translate.instant('texto_527'));
              } 
          })
        }else{
          this.toastr.error(this.translate.instant('texto_526'), this.translate.instant('texto_527'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['advanced-setting']);
        }); 
    });
  }

  guardarClausulas(){  
    let idioma = this.auth.getLanguage();
    if(idioma == "" || idioma == null){
      idioma = 'es';
    }  
    let valor1 = "";
    let valor2 = "";
    //console.log(this.texto_value.value);
    //console.log(this.id_texto.value);
    if(this.clausula_value.value != ""){
      valor1 = this.id_clausula.value;
      valor2 = this.clausula_value.value;
    }    
    this.apiService.updateClausulas(valor1,valor2).subscribe(
      res => {
        if(res == 200){
          this.toastr.success(this.translate.instant('texto_528'), this.translate.instant('texto_530'));
          this.auth.setId_clausula(valor1);
          this.auth.setClausula(valor2);
        }else{
          this.toastr.error(this.translate.instant('texto_529'), this.translate.instant('texto_530'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['advanced-setting']);
        }); 
    });
  }

  get f(){
    return this.validatingFormImages.controls;
  }

  onFileChange(event,fichero) {
    if (event.target.files.length > 0) {
      //console.log(" ---- fichero:" + fichero);
      const file = event.target.files[0];
      this.validatingFormImages.patchValue({
        fileSource: file,
        fich: fichero
      });
      this.subirImagenes();
    }
  }

  openImg(url: string) {
    window.open(url, "_blank");
  }

  openDoc(url: string) {
    window.open(url, "_blank");
  }  

  onFileChange2(event,fichero) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.validatingForm.patchValue({
        fileSource: file,
        fich: fichero
      });
      //this.subirDoc();
    }
  }
    onFileChange3(event,fichero) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.validatingForm.patchValue({
          fileSource: file,
          fich: fichero
        });
        this.subirDoc();
      }

    
  }

  subirImagenes(){
    const formData = new FormData();
    formData.append('file', this.validatingFormImages.get('fileSource').value);
    formData.append('id_user', this.auth.getIdUser());
    formData.append('fichero', this.validatingFormImages.get('fich').value);
    //console.log(this.auth.getSociedad() + " ---- fich:" + this.validatingFormImages.get('fich').value);
    this.http.post(`${this.PHP_API_SERVER}/api/upload_images.php`, formData).subscribe(
      res => {
        if(res == 200){
          this.toastr.success(this.translate.instant('texto_202'), this.translate.instant('texto_203'));
          /*this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['advanced-setting']);
          });*/
          window.location.reload();
          //console.log("res:" + res);
        }else{
          this.toastr.error(this.translate.instant('texto_204'), this.translate.instant('texto_203'));
          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['advanced-setting']);
          });
          //console.log("res:" + res);
        }
        
      },
      err =>{
        this.toastr.error(this.translate.instant('texto_204'), this.translate.instant('texto_203'));
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['advanced-setting']);
        });
      })
  }  


  subirDoc(){
    const formData = new FormData();
    formData.append('file', this.validatingForm.get('fileSource').value);
    formData.append('id_user', this.auth.getIdUser());
    formData.append('fichero', this.validatingForm.get('fich').value);
    formData.append('nomboton1',this.translate.instant('texto_467'));
    formData.append('nomboton2',this.translate.instant('texto_487'));
    formData.append('nomboton3',this.translate.instant('texto_489'));
    formData.append('nempresa',this.translate.instant('texto_6'));
    //console.log(this.auth.getSociedad() + " ---- fich:" + this.validatingFormImages.get('fich').value);
    this.http.post(`${this.PHP_API_SERVER}/api/upload_images.php`, formData).subscribe(
      res => {
        if(res == 200){
          this.toastr.success(this.translate.instant('texto_202'), this.translate.instant('texto_203'));
          /*this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['advanced-setting']);
          });*/
          //window.location.reload();
          this.guardarDatos();
          //console.log("res:" + res);
        }else{
          this.toastr.error(this.translate.instant('texto_204'), this.translate.instant('texto_203'));
          this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['advanced-setting']);
          });
          //console.log("res:" + res);
        }
       
        
      },
      err =>{
        this.toastr.error(this.translate.instant('texto_204'), this.translate.instant('texto_203'));
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['advanced-setting']);
        });
      })
  }  



}




  

