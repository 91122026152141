import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from "../environments/environment"

import {Users} from './model/users'
import { throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { identifierModuleUrl } from '@angular/compiler';
import { ReceptorInicial } from './data/formData.model';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
 
  PHP_API_SERVER = environment.baseUrl;//"http://127.0.0.1:8080";
 // PHP_API_SERVER = "http://portaldedenuncias.azkenservices.com/backend/";
  constructor(private httpClient: HttpClient, private auth: AuthService) {}

  /*login2(): Observable<Users[]>{
    return this.httpClient.get<Users[]>(`${this.PHP_API_SERVER}/api/login.php`);
  }*/

  login(username:string, password:string) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/api/login.php`, {
      email: username,
      password: password,    
    });     
  }

  protected(token:string) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/api/protected.php`, {
      token: token,
    });     
  }

  updateDataUser(username:string, email:string, name:string, surname:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };
    
    return this.httpClient.post(`${this.PHP_API_SERVER}/api/updateUser.php`, {
      username: username,
      email: email,
      name: name,
      surname: surname,    
    },  options);     
  }

  updatePasswordUser(username:string, password:string, passwordOld:string, reenviar:boolean) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/updatePassword.php`, {
      username: username,
      password: password,
      passwordOld: passwordOld,
      reenviar: reenviar
    },  options);     
  }

  addCompany(id_sociedad:string, id_empresa:string, n_empresa:string, comentario:string, id_usuario:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearEmpresa.php`, {
      id_sociedad: id_sociedad,
      id_empresa: id_empresa,
      n_empresa: n_empresa,
      comentario: comentario,
      id_usuario: id_usuario,
    },  options); 
  }


  searchCompanyByUser(id_usuario:string, id_sociedad:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarEmpresaUsuario.php`, {
      id_usuario: id_usuario,
      id_sociedad: id_sociedad,    
    },  options);     
  }


  searchIdiomas() {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarIdiomas.php`, {   
    },  options);     
  }


  searchCompanyUserById(id_usuario:string, id_sociedad:string, id_empresa:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarEmpresaUsuariobyId.php`, {
      id_usuario: id_usuario,
      id_sociedad: id_sociedad, 
      id_empresa: id_empresa,   
    },  options);     
  }

  searchCompanyById( id: string, id_sociedad: string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarEmpresaById.php`, {
      id: id,   
      id_sociedad: id_sociedad,
    },  options);     
  }

  deleteCompanyByUser(id_usuario:string, id_sociedad:string, id_empresa:string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/eliminarEmpresa.php`, {
      id_usuario: id_usuario,
      id_sociedad: id_sociedad,   
      id_empresa: id_empresa, 
    },  options);  
  }

  deleteInvesSelect(id_sociedad:string, id_denuncia:string, id_usuario:string, rol:string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/eliminarInvestigador.php`, {
      id_sociedad: id_sociedad,
      id_denuncia: id_denuncia,         
      id_usuario: id_usuario, 
      rol: rol
    },  options);  
  }

  updateCompanyByUser(id_sociedad:string, id_empresa:string, n_empresa:string, comentario:string, id_usuario:string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/modificarEmpresa.php`, {
      id_sociedad: id_sociedad,  
      id_empresa: id_empresa,  
      n_empresa: n_empresa, 
      comentario: comentario,   
      id_usuario: id_usuario,
    },  options);
  }

  searchUserByIdSociety(id_sociedad:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarUsuariosByIdSociedad.php`, {
      id_sociedad: id_sociedad, 
    },  options);     
  }

  searchAllUsers() {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarTodosUsuarios.php`, {
    },  options);     
  }


  searchUserByIdSocietyRole(id_sociedad:string, id_role:string,ramdom:boolean, id_denuncia:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarUsuariosByIdSociedadRole.php`, {
      id_sociedad: id_sociedad, 
      id_role: id_role,
      ramdom: ramdom,
      id_denuncia: id_denuncia
    },  options);     
  }

  searchInvesSelect(id_sociedad:string, id_denuncia:string, id_role:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarInvestigadorSeleccionado.php`, {
      id_sociedad: id_sociedad,
      id_denuncia: id_denuncia, 
      id_role: id_role
    },  options);     
  }

  searchInformes(id_sociedad:string, id_denuncia:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarInforme.php`, {
      id_sociedad: id_sociedad,
      id_denuncia: id_denuncia
    },  options);     
  }

  searchreceptorrechazadoByDenucia(id_sociedad:string, id_denuncia:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarreceptorRechazado.php`, {
      id_sociedad: id_sociedad,
      id_denuncia: id_denuncia
    },  options);     
  }

  deleteUser(id_usuario:string, id_sociedad:string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/eliminarUsuario.php`, {
      id_usuario: id_usuario,
      id_sociedad: id_sociedad,  
    },  options);
  }

  addUser(id_user:string, mail_user:string, n_user:string, ape_user:string, id_sociedad:string, key:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearUsuario.php`, {
      id_user: id_user,
      mail_user: mail_user,
      n_user: n_user,
      ape_user: ape_user,
      id_sociedad: id_sociedad,
      key: key
    },  options); 
  }

  searchUserById(id_user:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarUsuarioId.php`, {
      id_user: id_user
    },  options);     
  }

  updateUser(id_user:string, mail_user:string, n_user:string, ape_user:string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/modificarUsuario.php`, {
      id_user: id_user,  
      mail_user: mail_user,  
      n_user: n_user, 
      ape_user: ape_user,   
    },  options);
  }

  blockUser(id_user:string, state:string, id_usuario: string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/bloquearUsuario.php`, {
      id_user: id_user,  
      state: state,
      id_usuario: id_usuario
    },  options);
  }

  sendEmail(id_user:string, subject:string, message: string, boton: string, clausulas: boolean, titulo: string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/enviarEmail.php`, {
      id_user: id_user,  
      subject: subject,
      message: message,
      boton: boton,
      clausulas: clausulas,
      titulo: titulo,
    });
  }  

  sendEmailSupport(firstName:string, lastName:string, subject:string, replyTo:string, message: string){
    return this.httpClient.post(`${this.PHP_API_SERVER}/api/enviarEmailSoporte.php`, {
      firstName: firstName,  
      lastName: lastName,
      subject: subject,
      replyTo: replyTo,
      message: message
    });
  }

  getSocieties() {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.get(`${this.PHP_API_SERVER}/api/getSociedades.php`, {
    } );     
  }

  getSessionPropertiesUser(id_user:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/getSessionPropertiesUser.php`, {
      id_user: id_user, 
    },  options );     
  }

  getSocietiesByIdUser(id_user:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/getSociedadesByIdUser.php`, {
      id_user: id_user, 
    }, options );     
  }

  updateSessionPropertySociety(id_user:string, society:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/updateSessionPropertySociety.php`, {
      id_user: id_user, 
      society: society,
    }, options);     
  }

  createSessionPropertiesByUser(id_usuario:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearUserPropertiesByIdUser.php`, {
      id_usuario: id_usuario, 
    }, options);     
  }

  updateSessionPropertyLanguage(id_user:string, idioma:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/updateSessionPropertyIdioma.php`, {
      id_user: id_user, 
      idioma: idioma
    }, options);     
  }


  addTypeComplaint(id_usuario:string, id_sociedad:string, id_tp_denuncia:string, nombre:string, descripcion:string, tipo_receptor:string,id:string,nombre_receptor: string,idioma: string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearTipoDenuncia.php`, {
      id_usuario: id_usuario,
      id_sociedad: id_sociedad,
      id_tp_denuncia: id_tp_denuncia,
      nombre: nombre,
      descripcion: descripcion,
      tipo_receptor: tipo_receptor,
      id: id,
      nombre_receptor: nombre_receptor,
      idioma: idioma,
    }); 
  }

  updateComplaintByUser(id_sociedad:string, id_tp_denuncia:string, nombre:string, descripcion:string, id_usuario:string,tipo_receptor:string,id:string,idioma: string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/modificarTipoDenuncia.php`, {
      id_sociedad: id_sociedad,  
      id_tp_denuncia: id_tp_denuncia,
      nombre: nombre, 
      descripcion: descripcion,   
      id_usuario: id_usuario,
      tipo_receptor: tipo_receptor,
      id: id,
      idioma: idioma,
    }, options);
  }

  searchComplaintByUserSociety(id_usuario:string, id_sociedad:string,idioma:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarTipoDenuncia.php`, {
      id_usuario: id_usuario,
      id_sociedad: id_sociedad, 
      idioma: idioma,
    }, options);     
  }

  searchComplaintByUserSocietyID(id_usuario:string, id_sociedad:string, id_tp_denuncia:string,seguro:boolean,idioma: string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarTipoDenunciabyId.php`, {
      id_usuario: id_usuario,
      id_sociedad: id_sociedad, 
      id_tp_denuncia: id_tp_denuncia,
      seguro: seguro,
      idioma: idioma,
    }, options);     
  }

  deleteComplaintByUser(id_usuario:string, id_sociedad:string, id_tp_denuncia:string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/eliminarTipoDenuncia.php`, {
      id_usuario: id_usuario,
      id_sociedad: id_sociedad,  
      id_tp_denuncia: id_tp_denuncia
    });
  }


  addCaution(id_usuario:string, id_sociedad:string, id_mcu:string, nombre:string, comentario:string,idioma: string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearMedidaCautelar.php`, {
      id_usuario: id_usuario,
      id_sociedad: id_sociedad,
      id_mcu: id_mcu,
      nombre: nombre,
      comentario: comentario,
      idioma: idioma
    }, options); 
  }

  searchCautionByUserSociety(id_usuario:string, id_sociedad:string,idioma: string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarMedidaCautelar.php`, {
      id_usuario: id_usuario,
      id_sociedad: id_sociedad, 
      idioma: idioma
    }, options);     
  }


  searchCautionBySociety(id_sociedad:string,idioma: string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarMedidaCautelarBySociety.php`, {
      id_sociedad: id_sociedad, 
      idioma: idioma
    });     
  }

  deleteCautionByUser(id_usuario:string, id_sociedad:string, id_mcu:string, idioma: string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/eliminarMedidaCautelar.php`, {
      id_usuario: id_usuario,
      id_sociedad: id_sociedad,  
      id_mcu: id_mcu,
      idioma: idioma
    }, options);
  }

  updateCautionByUser(id_sociedad:string, id_mcu:string, nombre:string, comentario:string,  id_usuario:string,idioma: string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/modificarMedidaCautelar.php`, {
      id_sociedad: id_sociedad,  
      id_mcu: id_mcu,
      nombre: nombre, 
      comentario: comentario,   
      id_usuario: id_usuario,
      idioma: idioma
    }, options);
  }


  addRelationType(id_usuario:string, id_sociedad:string, id_tp_relacion:string, nombre:string, comentario:string,idioma: string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearTipoRelacion.php`, {
      id_usuario: id_usuario,
      id_sociedad: id_sociedad,
      id_tp_relacion: id_tp_relacion,
      nombre: nombre,
      comentario: comentario,
      idioma: idioma
    }, options); 
  }

  searchRelationTypeByUserSociety(id_usuario:string, id_sociedad:string,idioma: string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarTipoRelacion.php`, {
      id_usuario: id_usuario,
      id_sociedad: id_sociedad, 
      idioma: idioma
    }, options);     
  }

  searchRelationTypeById(id_tp_relacion:string, id_sociedad:string,idioma: string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarTipoRelacionById.php`, {
      id_tp_relacion: id_tp_relacion,
      id_sociedad: id_sociedad, 
      idioma: idioma
    }, options);     
  }

  deleteRelationTypeByUser(id_usuario:string, id_sociedad:string, id_tp_relacion:string,idioma: string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/eliminarTipoRelacion.php`, {
      id_usuario: id_usuario,
      id_sociedad: id_sociedad,  
      id_tp_relacion: id_tp_relacion,
      idioma: idioma
    }, options);
  }

  updateRelationByUser(id_sociedad:string, id_tp_relacion:string, nombre:string, comentario:string, id_usuario:string,idioma: string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/modificarTipoRelacion.php`, {
      id_sociedad: id_sociedad,  
      id_tp_relacion: id_tp_relacion,
      nombre: nombre, 
      comentario: comentario,   
      id_usuario: id_usuario,
      idioma: idioma
    }, options);
  }

  addLayer(id_usuario:string, id_sociedad:string, id_abogado:string, id_tp_denuncia:string, n_abogado:string, comentario:string, email:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearAbogado.php`, {
      id_usuario: id_usuario,
      id_sociedad: id_sociedad,
      id_abogado: id_abogado,
      id_tp_denuncia: id_tp_denuncia,
      n_abogado: n_abogado,
      comentario: comentario,
      email: email,
    }, options); 
  }

  searchLayerByUserSociety(id_usuario:string, id_sociedad:string,) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarAbogado.php`, {
      id_usuario: id_usuario,
      id_sociedad: id_sociedad, 
    }, options);     
  }

  searchReceptorObligatoryByUserSociety(id_usuario:string, id_sociedad:string,) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarReceptorObligatorio.php`, {
      id_usuario: id_usuario,
      id_sociedad: id_sociedad, 
    }, options);     
  }

  searchLayerByIdSociety(id_abogado:string, id_sociedad:string,) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarAbogadoById.php`, {
      id_abogado: id_abogado,
      id_sociedad: id_sociedad, 
    }, options);     
  }
  deleteLayerByUser(id_usuario:string, id_sociedad:string, id_abogado:string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/eliminarAbogado.php`, {
      id_usuario: id_usuario,
      id_sociedad: id_sociedad,  
      id_abogado: id_abogado
    }, options);
  }

  updateLayerByUser(id_sociedad:string, id_abogado:string, id_tp_denuncia:string, n_abogado:string, comentario:string, email:string, id_usuario:string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/modificarAbogado.php`, {
      id_sociedad: id_sociedad,  
      id_abogado: id_abogado,
      id_tp_denuncia: id_tp_denuncia,  
      n_abogado: n_abogado, 
      comentario: comentario,   
      email:email,
      id_usuario: id_usuario,
    }, options);
  }


  searchRoleByUserSociety(id_user:string, id_sociedad:string, menu:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarRolbyUserSociety.php`, {
      id_user: id_user,
      id_sociedad: id_sociedad,
      menu: menu,
    }, options);     
  }

  updateRolUser(id_sociedad:string, id_user:string, id_rol:string, fec_inicio:string, fec_fin:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers }; 

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/actualizarRolbyUserSociety.php`, {
      id_user: id_user,
      id_sociedad: id_sociedad,
      id_rol: id_rol,
      fec_inicio: fec_inicio,
      fec_fin: fec_fin,
    }, options);     
  }  

  deleteRoleByUserSociety(id_user:string, id_sociedad:string, id_rol:string, fec_inicio:string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/eliminarRolbyUserSociety.php`, {
      id_user: id_user,
      id_sociedad: id_sociedad,  
      id_rol: id_rol,
      fec_inicio: fec_inicio,
    }, options);
  }

  addRoleByIdUser(id_user:string, id_sociedad:string, id_rol:string, id_usuario:string,fec_inicio:string, fec_fin:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearRolbyUserSociety.php`, {
      id_user: id_user,
      id_sociedad: id_sociedad,
      id_rol: id_rol,
      id_usuario: id_usuario,
      fec_inicio: fec_inicio,
      fec_fin: fec_fin,
    }, options); 
  }

  searchRole() {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/getRoles.php`, {
    }, options);     
  }

  searchCompanies(){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/getCompanies.php`, {
    }, options);  
  }

  searchCompaniesbyid(idempresa:string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/getCompaniesbyid.php`, {
      idempresa: idempresa,
    }, options);  
  }  

  searchTypeComplaint(id_sociedad:string, seguro:boolean,idioma: string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/getTypeComplaint.php`, {
      id_sociedad: id_sociedad,
      seguro: seguro,
      idioma: idioma,

    }, options); 
  }

  searchTextos2(id_texto:string, seguro:boolean, idioma:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/getTextos.php`, {
      id_texto: id_texto,
      seguro: seguro,
      idioma: idioma,
    }, options); 
  }


  searchClausulas2(id_clausula:string, seguro:boolean) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/getClausulas.php`, {
      id_clausula: id_clausula,
      seguro: seguro,
    }, options); 
  }

  searchComplaintBySocietyFechaIniHastaEmpresaTipoDenunciaTipoRelacionPhase(id_sociedad:string, fec_inicio:string, fec_fin:string, id_empresa:string, id_tp_denuncia:string, id_tp_relacion:string, id_fase:string,idioma: string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDenunciasByIdSociedadFechaTiposFase.php`, {
      id_sociedad: id_sociedad,
      fec_inicio: fec_inicio,
      fec_fin: fec_fin,
      id_empresa: id_empresa,
      id_tp_denuncia: id_tp_denuncia,
      id_tp_relacion: id_tp_relacion,
      id_fase: id_fase,
      idioma: idioma
    }, options); 
  }

  searchComplaintBySocietyFechaIniHastaEmpresaTipoDenunciaTipoRelacionPhaseUser(id_sociedad:string, fec_inicio:string, fec_fin:string, id_empresa:string, id_tp_denuncia:string, id_tp_relacion:string, id_fase:string, receptor:string,idioma: string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDenunciasByIdSociedadFechaTiposFaseUsuario.php`, {
      id_sociedad: id_sociedad,
      fec_inicio: fec_inicio,
      fec_fin: fec_fin,
      id_empresa: id_empresa,
      id_tp_denuncia: id_tp_denuncia,
      id_tp_relacion: id_tp_relacion,
      id_fase: id_fase,
      receptor: receptor,
      idioma: idioma
    }, options); 
  }


  ejecutarQuery(query:string, campo_1: string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/ejecutarQuery.php`, {
      query: query,
      campo_1: campo_1
    }, options); 
  }

  searchTablas(){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };
    return this.httpClient.post(`${this.PHP_API_SERVER}/api/gettablas.php`, {
      
    }, options);
  }

  searchAtributos(n_tabla:string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };
    return this.httpClient.post(`${this.PHP_API_SERVER}/api/getatributos.php`, {
    n_tabla: n_tabla
    }, options);
  }

  searchUsersReceptors(id_sociedad:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };
    
    return this.httpClient.post(`${this.PHP_API_SERVER}/api/getUserReceptorBySociety.php`, {
      id_sociedad: id_sociedad,
    }, options); 
  }

  searchUsersSocietyReceptors(id_sociedad:string, id_user:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/getUserReceptorByUserSociety.php`, {
      id_sociedad: id_sociedad,
      id_user: id_user
    }, options); 
  }

  searchUsersReceptorsRamdom(id_sociedad:string,id_tp_denuncia:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/getUserReceptorRamdonBySociety.php`, {
      id_sociedad: id_sociedad,
      id_tp_denuncia: id_tp_denuncia,
    }, options); 
  }

  searchTypeRelation(id_sociedad:string, seguro: boolean,idioma: string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/getTypeRelation.php`, {
      id_sociedad: id_sociedad,
      seguro: seguro,
      idioma: idioma
    }, options); 
  }

  createComplaint(id_sociedad:string, id_tp_denuncia:string, denunciante_nombre:string, denunciante_apellidos:string, 
    denunciante_dni:string,     denunciante_correo:string, denunciante_telefono:string,
    fecha_incidente:string, id_tp_relacion:string, denuncia_desc:string, id_fase:string, id_empresa:string,  comentario:string,
    nombre_testigo: string, apellido_testigo: string, email_testigo: string, phone_testigo: string, descripcion_testigo: string, 
    file_name: string, receptor: string,motivo: string,receptorinicial: string, finalizada: string) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearDenuncia.php`, {
      id_sociedad: id_sociedad,
      id_tp_denuncia: id_tp_denuncia,
      denunciante_nombre: denunciante_nombre,
      denunciante_apellidos: denunciante_apellidos,
      denunciante_dni: denunciante_dni,
      denunciante_correo: denunciante_correo,
      denunciante_telefono: denunciante_telefono,
      fecha_incidente: fecha_incidente,
      id_tp_relacion: id_tp_relacion,
      denuncia_desc: denuncia_desc,
      id_fase: id_fase,
      id_empresa: id_empresa,
      comentario: comentario,
      nombre_testigo: nombre_testigo,
      apellido_testigo: apellido_testigo,
      email_testigo: email_testigo,
      phone_testigo: phone_testigo,
      descripcion_testigo: descripcion_testigo,
      file_name: file_name,
      receptor: receptor,
      motivo: motivo,
      receptorinicial: receptorinicial,
      finalizada: finalizada
    }); 
  }

  searchComplaintByPin(pin_code:string,idioma: string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDenunciaByPin.php`, {
      pin_code: pin_code,
      idioma: idioma
    }, options); 
  }

  searchComplaintByPinId(pin_code:string,id_complaint:string, idioma: string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDenunciaByPinId.php`, {
      pin_code: pin_code,
      id_complaint: id_complaint,
      idioma: idioma
    }, options); 
  }

  searchComplaintBySocietyPhase(id_sociedad:string,id_fase:string, receptor:string, idioma: string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDenunciasByIdSociedadFase.php`, {
      id_sociedad: id_sociedad,
      id_fase: id_fase,
      receptor: receptor,
      idioma: idioma
    }, options); 
  }

  searchComplaintByIdUser(id_sociedad:string,id_denuncia:string, receptor:string, idioma: string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDenunciasByIdUser.php`, {
      id_sociedad: id_sociedad,
      id_denuncia: id_denuncia,
      receptor: receptor,
      idioma: idioma
    }, options); 
  }

  searchReceptorByIdComplaint(id_denuncia:string,seguro:boolean) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarReceptorByIdDenuncia.php`, {
      id_denuncia: id_denuncia,
      seguro: seguro,
    }, options); 
  }

  searchComplaintBySocietyRelation(id_sociedad:string,id_tp_relacion:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDenunciasPorTipoRelacionSociedad.php`, {
      id_sociedad: id_sociedad,
      id_tp_relacion: id_tp_relacion,
      
    }, options); 
  }

  searchComplaintBySocietyType(id_sociedad:string,id_tp_denuncia:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDenunciasPorTipoDenunciaSociedad.php`, {
      id_sociedad: id_sociedad,
      id_tp_denuncia: id_tp_denuncia
    }, options); 
  }

  searchCountComplaintBySocietyUser(id_sociedad:string,id_user:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDenunciasPorUsuarioSociedad.php`, {
      id_sociedad: id_sociedad,
      id_user: id_user
    }, options); 
  }

  searchComplaintById(id_denuncia:string, idioma: string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDenunciasById.php`, {
      id_denuncia: id_denuncia,
      idioma: idioma
    }, options); 
  }

  addWitness(id_sociedad:string, id_usuario:string, id_denuncia:string, n_testigo:string, tlf_testigo:string, correo_testigo:string, comentario:string, subido_por_denunciante:string,seguro:boolean) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearTestigo.php`, {
      id_sociedad: id_sociedad,
      id_usuario: id_usuario,
      id_denuncia: id_denuncia,
      n_testigo: n_testigo,
      tlf_testigo: tlf_testigo,
      correo_testigo: correo_testigo,
      comentario: comentario,
      subido_por_denunciante: subido_por_denunciante,
      seguro: seguro
    }, options); 
  }

  searchWitnessByComplaint(id_denuncia:string, rol:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarTestigoByIdDenuncia.php`, {
      id_denuncia: id_denuncia,
      rol: rol
    }, options); 
  }

  searchWitnessByUserComplaint(id_denuncia:string, id_usuario:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarTestigoByIdDenunciaUsuario.php`, {
      id_denuncia: id_denuncia,
      id_usuario: id_usuario
    }, options); 
  }

  searchDocumentsByComplaint(id_denuncia:string, seguro:boolean, rol:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };
    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDocumentosByIdDenuncia.php`, {
      id_denuncia: id_denuncia,
      seguro: seguro,
      rol: rol
    }); 
  }

  searchDocumentsByUserComplaint(id_denuncia:string, id_usuario:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDocumentosByIdDenunciaUsuario.php`, {
      id_denuncia: id_denuncia,
      id_usuario: id_usuario
    }, options); 
  }

  searchTextos(id_texto: string, idioma: string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarTextos.php`, {
      id_texto: id_texto,
      idioma: idioma,
    }, options);     
  }

  searchClausulas(id_clausula: string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarClausulas.php`, {
      id_clausula: id_clausula,
    }, options);     
  }

  searchAdvancedParam() {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarConfiguracionParametros.php`, {
    }, options);     
  }

  searchParamCliente() {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarParametrosCliente.php`, {
    }, options);     
  }

  updateAdvancedParam(anonimo:string, dominio:string, consultas:string, plazo:string, clausulas:string, conflicto:string, diasinves:string, diasdecisor:string, diascompliance:string, terminos:string, doc_terminos:string, ruta:string, diasverdenuncia:string, boton1: string, boton2: string, boton3: string, triaje: string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearConfiguracionParametros.php`, {
      anonimo: anonimo,
      dominio: dominio,
      consultas: consultas,
      plazo: plazo,
      clausulas: clausulas,
      conflicto: conflicto,
      diasinves: diasinves,
      diasdecisor: diasdecisor,
      diascompliance: diascompliance,
      terminos: terminos,
      doc_terminos: doc_terminos,
      ruta: ruta,
      diasverdenuncia: diasverdenuncia,
      boton1: boton1,
      boton2: boton2,
      boton3: boton3,
      triaje: triaje,
    }); 
  }

updateAdvancedParam2(nremitente:string, remitente:string, srv_mail:string, puerto:string, usr_mail:string, pass_mail:string, ssl:string, auth_mail:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearConfiguracionParametros2.php`, {
      nremitente: nremitente,
      remitente: remitente,
      srv_mail: srv_mail,
      puerto: puerto,
      usr_mail: usr_mail,
      pass_mail: pass_mail,
      ssl: ssl,
      auth_mail: auth_mail,
    }); 
  }

  updateTextos(id_texto:string, texto:string, idioma:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearTextos.php`, {
      id_texto: id_texto,
      texto: texto,
      idioma: idioma,
    }); 
  }  


  updateClausulas(id_clausula:string, clausula:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearClausulas.php`, {
      id_clausula: id_clausula,
      clausula: clausula,
    }); 
  } 

  

  generarFicheroTextos(idioma:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/generarFicheroTextos.php`, {
      idioma: idioma,
    }); 
  }  

  updateAdvancedParam3(colorprincipal:string, colorsecundario:string, colorbgseccion2:string, colorbgseccion3:string, colortxtbtn:string, colortxtseccion1:string, colortxtseccion2:string, colortxtseccion3:string, colortxttitulo:string, colorbgseccion1:string, colormenulateral:string, colortxtcabeceras:string, colortxtbotones:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearConfiguracionParametros3.php`, {
      colorprincipal: colorprincipal,
      colorsecundario: colorsecundario,
      colorbgseccion2: colorbgseccion2,
      colorbgseccion3: colorbgseccion3,
      colortxtbtn: colortxtbtn,
      colortxtseccion1: colortxtseccion1,
      colortxtseccion2: colortxtseccion2,
      colortxtseccion3: colortxtseccion3,
      colortxttitulo: colortxttitulo,     
      colorbgseccion1: colorbgseccion1, 
      colormenulateral: colormenulateral,
      colortxtcabeceras: colortxtcabeceras,
      colortxtbotones: colortxtbotones, 
    }); 
  }

  searchCanal() {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarCanal.php`, {
    }, options);     
  }


  sendMessageChat(id_sociedad:string, id_denuncia:string, id_user:string, comentario:string, fecha_comentario:string, id_usuario: string, seguro:boolean){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/enviarMensajeChat.php`, {
      id_sociedad: id_sociedad,  
      id_denuncia: id_denuncia,
      id_user: id_user,
      comentario: comentario,
      fecha_comentario: fecha_comentario,
      id_usuario: id_usuario,
      seguro: seguro,
    }, options);
  }

  searchCommentsByComplaint(id_denuncia:string, seguro:boolean) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarComentariosByDenuncia.php`, {
      id_denuncia: id_denuncia,
      seguro: seguro,
    }, options); 
  }

  buscarUsuarioDenuncia(id_denuncia:string, seguro:boolean) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarUsuarioByDenuncia.php`, {
      id_denuncia: id_denuncia,
      seguro: seguro,
    }, options); 
  }

  searchHistoricoUsuariosBySociedadFase(id_sociedad:string, id_fase:string, id_denuncia:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarUsuariosByIdSociedadFaseDenuncia.php`, {
      id_sociedad: id_sociedad,
      id_fase: id_fase,
      id_denuncia: id_denuncia,
    }, options); 
  }

  updateComplaintById(id_denuncia:string, id_fase:string, id_sociedad:string, id_usuario:string, informe_denunciante:string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/modificarDenunciaById.php`, {
      id_denuncia: id_denuncia,  
      id_fase: id_fase,  
      id_sociedad: id_sociedad, 
      id_usuario: id_usuario, 
      informe_denunciante: informe_denunciante,  
    }, options);
  }

  updateParamComplaintById(id_denuncia:string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/modificarCampoDenunciaByIdDenuncia.php`, {
      id_denuncia: id_denuncia,  
    }, options);
  }

  createMCUByIdComplaint(id_sociedad:string, id_mcu:string, id_denuncia:string, nombre_mcu:string, descripcion:string, contacto_ejecutor:string, realizado:string, id_usuario:string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearMedidaCautelarByIdDenuncia.php`, {
      id_sociedad: id_sociedad,
      id_mcu: id_mcu,  
      id_denuncia: id_denuncia,  
      nombre_mcu: nombre_mcu, 
      descripcion: descripcion,
      contacto_ejecutor: contacto_ejecutor,
      realizado: realizado,
      id_usuario: id_usuario,   
    }, options);
  }


  createDeadlinesByIdComplaint(id_sociedad:string, id_denuncia:string, plazo_investigacion:string, plazo_decisor:string, plazo_compliance:string, id_usuario:string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearPlazosDenunciaById.php`, {
      id_sociedad: id_sociedad,
      id_denuncia: id_denuncia,  
      plazo_investigacion: plazo_investigacion, 
      plazo_decisor: plazo_decisor,
      plazo_compliance: plazo_compliance,
      id_usuario: id_usuario
    }, options);
  }

  createHistoryComplaintById(id_denuncia:string, id_fase:string, id_sociedad:string, id_usuario:string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearHistoricoByIdDenuncia.php`, {
      id_denuncia: id_denuncia,  
      id_fase: id_fase,  
      id_sociedad: id_sociedad, 
      id_usuario: id_usuario,   
    }, options);
  }

  searchComplaintsByPhaseDashboard(id_sociedad:string, fec_inicio:string, fec_fin:string, id_empresa:string, id_tp_denuncia:string, id_tp_relacion:string, id_fase:string, receptor:string,idioma: string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDenunciasPorFaseDashboard.php`, {
      id_sociedad: id_sociedad,
      fec_inicio: fec_inicio,
      fec_fin: fec_fin,
      id_empresa: id_empresa,
      id_tp_denuncia: id_tp_denuncia,
      id_tp_relacion: id_tp_relacion,
      id_fase: id_fase,
      receptor: receptor,
      idioma:idioma
    }, options); 
  }

  searchComplaintsByTypeDashboard(id_sociedad:string, fec_inicio:string, fec_fin:string, id_empresa:string, id_tp_denuncia:string, id_tp_relacion:string, id_fase:string, receptor:string,idioma: string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarDenunciasPorTipoDashboard.php`, {
      id_sociedad: id_sociedad,
      fec_inicio: fec_inicio,
      fec_fin: fec_fin,
      id_empresa: id_empresa,
      id_tp_denuncia: id_tp_denuncia,
      id_tp_relacion: id_tp_relacion,
      id_fase: id_fase,
      receptor: receptor,
      idioma: idioma
    }, options); 
  }

  disableUserByIdComplaint(id_denuncia:string, id_usuario: string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };
    
    return this.httpClient.post(`${this.PHP_API_SERVER}/api/deshabilitarUsuarioByIdDenuncia.php`, {
      id_denuncia: id_denuncia,
      id_usuario: id_usuario
    }, options); 
  }

  enableUserByIdComplaint(id_denuncia:string, id_usuario: string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/habilitarUsuarioByIdDenuncia.php`, {
      id_denuncia: id_denuncia,
      id_usuario: id_usuario
    }, options); 
  }

  createUserByIdComplaint(id_sociedad:string, id_user:string, id_denuncia:string, id_rol:string, habilitado:string, id_usuario:string, id_fase:string, modal: boolean){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearUsuarioByDenuncia.php`, {
      id_sociedad: id_sociedad,
      id_user: id_user,
      id_denuncia: id_denuncia,  
      id_rol: id_rol, 
      habilitado: habilitado,
      id_usuario: id_usuario,
      id_fase: id_fase,
      modal: modal
    }, options);
  }

  createActionComplaint(id_sociedad:string,  id_denuncia:string, nombre_accion:string, responsable:string, contacto:string, descripcion:string, id_usuario:string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/crearAccionDenuncia.php`, {
      id_sociedad: id_sociedad,
      id_denuncia: id_denuncia,  
      nombre_accion: nombre_accion, 
      responsable: responsable,
      contacto: contacto,
      descripcion: descripcion,
      id_usuario: id_usuario
    }, options);
  }

  searchInvestigatorByIdDenuncia(id_denuncia:string, id_fase:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };
    
    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarUsuarioInvestigadorByDenuncia.php`, {
      id_denuncia: id_denuncia,
      id_fase: id_fase
    }, options);     
  }

  searchReceptorByIdDenuncia(id_denuncia:string, id_fase:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };
    
    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarUsuarioReceptorByDenuncia.php`, {
      id_denuncia: id_denuncia,
      id_fase: id_fase
    }, options);     
  }
  sendReportByIdDenuncia(id_denuncia:string, informe:string, estado:string, id_usuario: string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/enviarInformeByDenuncia.php`, {
      id_denuncia: id_denuncia,  
      informe: informe,  
      estado: estado, 
      id_usuario: id_usuario
    }, options);
  }

  searchActionsByComplaint(id_denuncia:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/buscarAccionesByDenuncia.php`, {
      id_denuncia: id_denuncia
    }, options); 
  }

  updateActionsById(id_denuncia:string,id_accion:string, id_usuario: string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/actualizarAccionById.php`, {
      id_denuncia: id_denuncia,
      id_accion: id_accion,
      id_usuario: id_usuario
    }, options); 
  }


  countActionsByIdComplaint(id_sociedad:string,id_denuncia:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/contarAccionesSinFinalizarByIdDenuncia.php`, {
      id_sociedad: id_sociedad,
      id_denuncia: id_denuncia
    }, options); 
  }

  countComplaintByIdCompany(id_sociedad:string,id_empresa:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/contarDenunciasPorEmpresas.php`, {
      id_sociedad: id_sociedad,
      id_empresa: id_empresa
    }, options); 
  }

  countInvesSelect(id_sociedad:string, id_denuncia:string, id_usuario:string, rol:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/contarInvestigadoresSeleccionados.php`, {
      id_sociedad: id_sociedad,
      id_denuncia: id_denuncia,
      id_usuario: id_usuario,
      rol: rol
    }, options); 
  }

  countComplaintByIdAbogados(id_sociedad:string,id_abogado:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/contarDenunciasPorAbogados.php`, {
      id_sociedad: id_sociedad,
      id_abogado: id_abogado
    }, options); 
  }

  counMedidaCautelarByIdCompany(id_sociedad:string,id_mcu:string) {
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/contarMedidaCautelarById.php`, {
      id_sociedad: id_sociedad,
      id_mcu: id_mcu
    }, options); 
  }

  getPhases(idioma: string){
    const headers = { 'Authorization': 'Bearer '+this.auth.getToken() }
    let options = { headers: headers };

    return this.httpClient.post(`${this.PHP_API_SERVER}/api/getPhases.php`, {
      idioma: idioma
    }, options); 
  }
  
}
