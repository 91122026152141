import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'app-informacion-principal',
  templateUrl: './informacion-principal.component.html',
  styleUrls: ['./informacion-principal.component.css']
})
export class InformacionPrincipalComponent implements OnInit {
  validatingForm: FormGroup;
  version: string;
  boton1: string;
  docboton1: string;
  boton2: string;
  docboton2: string;
  boton3: string;
  docboton3: string;

  constructor(private apiService: ApiService, private router: Router, private translate: TranslateService, private toastr: ToastrService) { }

  ngOnInit() {
    this.version = localStorage.getItem("version");
    this.boton1 = localStorage.getItem("boton1");
    this.docboton1 = localStorage.getItem("docboton1"); 
    this.boton2 = localStorage.getItem("boton2");
    this.docboton2 = localStorage.getItem("docboton2"); 
    this.boton3 = localStorage.getItem("boton3");
    this.docboton3 = localStorage.getItem("docboton3"); 
    //console.log(this.boton1);
    //console.log(this.docboton1);

    this.validatingForm = new FormGroup({
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      subject: new FormControl('', []),
      replyTo: new FormControl('', []),
      message: new FormControl('', [ Validators.required]),
    }); 
  }

  get firstName() {
    return this.validatingForm.get('firstName');
  }

  get lastName() {
    return this.validatingForm.get('lastName');
  }

  get subject() {
    return this.validatingForm.get('subject');
  }

  get replyTo() {
    return this.validatingForm.get('replyTo');
  }

  get message() {
    return this.validatingForm.get('message');
  }

  enviarEmail(){
    if(this.message.value == ""){
      this.toastr.error(this.translate.instant('texto_253'), this.translate.instant('texto_243'));
    }else{
      this.apiService.sendEmailSupport(this.firstName.value, this.lastName.value, this.subject.value, this.replyTo.value, this.message.value).subscribe(
        res => {
          if(res == 200){
            this.toastr.success(this.translate.instant('texto_255'), this.translate.instant('texto_257'));
          }else{
            this.toastr.error(this.translate.instant('texto_256'), this.translate.instant('texto_257'));
          }
          window.location.reload();
      });
    }
    
  }
}
