import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Cautelar } from '../model/cautelar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-medida-cautelar',
  templateUrl: './medida-cautelar.component.html',
  styleUrls: ['./medida-cautelar.component.css']
})
export class MedidaCautelarComponent implements OnInit {
  sociedad: string;
  cautelares: Cautelar[];
  IsHiddenForm: boolean =  false;
  constructor(private apiService: ApiService,private router: Router, private auth: AuthService, private toastr: ToastrService, private translate: TranslateService) { }
  validatingForm: FormGroup;

  idMedida: string;
  nombreCautelar: string;
  comentario: string;

  ngOnInit() {  
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.sociedad = this.auth.getNameSociedad();
    this.apiService.searchCautionByUserSociety(this.auth.getIdUser(),this.auth.getSociedad(),language).subscribe((cautelar: Cautelar[])=>{
      this.cautelares = cautelar;
    });

    this.validatingForm = new FormGroup({
      signupFormModalName: new FormControl(''),
      signupFormModalId: new FormControl(''),
      contactFormModalMessage: new FormControl('', []),
    });
  }

  get signupFormModalName() {
    return this.validatingForm.get('signupFormModalName');
  }

  get signupFormModalId() {
    return this.validatingForm.get('signupFormModalId');
  }

  get contactFormModalMessage() {
    return this.validatingForm.get('contactFormModalMessage');
  }

  deleteCaution(id_mcu: string){
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.apiService.counMedidaCautelarByIdCompany(this.auth.getSociedad(), id_mcu).subscribe(
      res => {
        if(res == 200){
          this.apiService.deleteCautionByUser(this.auth.getIdUser(),this.auth.getSociedad(),id_mcu,language).subscribe(
            res => {
              if(res == 200){
                this.toastr.success(this.translate.instant('texto_244'), this.translate.instant('texto_247'));
              }else{
                this.toastr.error(this.translate.instant('texto_245'), this.translate.instant('texto_247'));
              }
      
              this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                this.router.navigate(['medida-cautelar']);
              }); 
          });
        }else{
          this.toastr.error(this.translate.instant('texto_246'), this.translate.instant('texto_247'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['medida-cautelar']);
        }); 
    });
  }

  hideModal(){
    this.IsHiddenForm = false;
  }
  
  updateCaution(id_mcu:string){
    let nombreMedidaCautelar = this.signupFormModalName.value;
    if(this.signupFormModalName.value == ""){
      nombreMedidaCautelar = this.nombreCautelar;
    }
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);
    this.apiService.updateCautionByUser(this.auth.getSociedad(), id_mcu, nombreMedidaCautelar, this.contactFormModalMessage.value,  this.auth.getIdUser(),language).subscribe(
      res => {
        if(res == 200){
          this.toastr.success(this.translate.instant('texto_248'), this.translate.instant('texto_250'));
        }else{
          this.toastr.error(this.translate.instant('texto_249'), this.translate.instant('texto_250'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['medida-cautelar']);
        }); 
    });
  }

  showCaution(caution:Cautelar){
    this.IsHiddenForm = true;

    this.idMedida = caution.id_mcu;
    this.nombreCautelar = caution.nombre;
    this.comentario = caution.comentario;
  }



}
