
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { PageEvent } from '@angular/material/paginator';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import * as XLSX from 'xlsx'; 

import { Tabla } from '../model/tabla';
import { Atributo } from '../model/atributo'
import { DatosQuery } from '../model/datosQuery';

import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { ExportService } from '../services/export.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


@Component({
  selector: 'app-consultas',
  templateUrl: './consultas.component.html',
  styleUrls: ['./consultas.component.css'],
  providers: [
    DatePipe,
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})

export class ConsultasComponent implements OnInit {
 atributos: Atributo[];
 tablas: Tabla[];
 datosQuery: DatosQuery[];
 tratado: DatosQuery[];


 selectedValuetabla: string;
 selectedValueatributo: string;
 selectedValueatributo2: string; 
 selectedValueatributo3: string;
 selectedValueatributo4: string;
 selectedValueoperador: string;
 selectedValueoperador3: string;
 selectedValuetipo: string;
 selectedTotal: string;
 selectedAlias: string;
 datosconsulta: string = "";
 n_atributoF:string = "";
 n_tablaF:string = "";

 n_atributoF2:string = "";

 valor_filtroF:string = "";
 valor_tipo: string = "";

 aliasF: string = "";

 myForm = new FormGroup({
    tabla: new FormControl('',[]),
    atributo: new FormControl('', []),
    n_atributo_value: new FormControl('', []),
    n_tabla_value: new FormControl('', []),
 });

 myForm2 = new FormGroup({
  atributo2: new FormControl('', []),
  n_atributo_value2: new FormControl('', []),
  n_tabla_value2: new FormControl({value: '', disabled: true}, []),
  operador2: new FormControl('', []),
  valor_filtro2: new FormControl('', []),
  tipo: new FormControl('', []),
});

myForm3 = new FormGroup({
  atributo3: new FormControl('', []),
  n_atributo_value3: new FormControl('', []),
  n_tabla_value3: new FormControl({value: '', disabled: true}, []),
  operador3: new FormControl('', []),
});

myForm4 = new FormGroup({
  atributo4: new FormControl('', []),
  n_atributo_value4: new FormControl('', []),
  n_tabla_value4: new FormControl({value: '', disabled: true}, []),
  operador4: new FormControl('', []),
});

myForm5 = new FormGroup({
  alias: new FormControl('',[]),
});

myForm6 = new FormGroup({
  total: new FormControl('', []),
});

 page_size: number = 25;
 page_number = 1;
 pageSizeOptions = [5,10,25,50];

 columnas_qry: Array<{valor1: string, valor2: string, valor3: string}> =[];
 columnas_where: Array<{valor1: string, valor2: string, valor3: string}> = [];
 columnas_order: Array<{valor1: string, valor2: string}> = [];
 columnas_group: Array<string> = [];
 columnas_rdo: Array<string> = [];


 campos: string="";
 campos_where: string="";
 campos_order: string= "";
 campos_group: string= "";

 sentencia_where: string="";


  constructor(private exportService: ExportService, private apiService: ApiService,private router: Router, private auth: AuthService, private toastr: ToastrService, private translate: TranslateService, private _adapter: DateAdapter<any>, private datepipe: DatePipe) {
   
  }

  ngOnInit() {    
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);

  }

  cargarTablas(e){
    this.apiService.searchTablas().subscribe((tablas: Tabla[])=>{
      this.tablas = tablas;
    });
  }

  cargarAtributos(selectedValue:string){
      this.apiService.searchAtributos(this.tablas[selectedValue].n_tabla).subscribe((atributos: Atributo[])=>{
        this.atributos = atributos;
      });
  }

  Ejecutar(){   
   this.apiService.ejecutarQuery(this.datosconsulta, this.campos).subscribe(
     (datosQuery: DatosQuery[])=>{
      this.datosQuery = datosQuery;
      //console.log(this.datosQuery);
      this.toastr.success(this.translate.instant('texto_470'),this.translate.instant('texto_469'));
     },
     
   (err:any) =>{
    this.toastr.error(this.translate.instant('texto_471'),this.translate.instant('texto_469'));
  }); 
  }  
  
  Anadir(){
    if(this.n_atributoF!=null && this.selectedValueatributo != null){
      if(this.selectedValueatributo == "*"){
        this.columnas_qry.push({valor1: this.selectedValueatributo, valor2: null, valor3: null});
        this.buildQry();
      }else{
        if(this.atributos.find(x=> x.n_atributo == this.n_atributoF).funcion == null ){
          this.columnas_qry.push({valor1: this.atributos.find(x=> x.n_atributo == this.n_atributoF).id_campo, valor2: null, valor3: null});
          this.buildQry();
        }else{
          this.columnas_qry.push({valor1: (this.atributos.find(x=> x.n_atributo == this.n_atributoF).funcion+"("+this.atributos.find(x=> x.n_atributo == this.n_atributoF).id_campo+",'#####')"), valor2: null, valor3: null});
          this.buildQry();
        }
       
        //this.toastr.success('Campo añadido correctamente', 'Consultas');
      }
    }else{
      this.toastr.error(this.translate.instant('texto_472'),this.translate.instant('texto_469'));
    } 
    
  }

  Anadir2(valor){
    if(valor != null){
      if(this.atributos.some(x=> x.n_atributo == valor)){
        if(this.atributos.find(x=> x.n_atributo == valor).funcion == null ){
          this.sentencia_where = this.sentencia_where + " " + this.atributos.find(x=> x.n_atributo == valor).id_campo;
           this.buildQry();
        }else{
          this.sentencia_where = this.sentencia_where + " " + (this.atributos.find(x=> x.n_atributo == valor).funcion+"("+this.atributos.find(x=> x.n_atributo == valor).id_campo+",'#####')");
          this.buildQry();
        }
      }else{
        this.sentencia_where = this.sentencia_where + " " + valor;
        this.buildQry();  
      }
              
    /*  if(this.selectedValuetipo == "Texto"){
        this.columnas_where.push({valor1: this.selectedValueatributo2,valor2: this.selectedValueoperador,valor3: ( "\'"+this.valor_filtroF+"\'")});
        this.buildQry();
        this.toastr.success('Campo añadido correctamente', 'Consultas');      
      }else{
        this.columnas_where.push({valor1: this.selectedValueatributo2,valor2: this.selectedValueoperador,valor3: this.valor_filtroF});
        this.buildQry();
        this.toastr.success('Campo añadido correctamente', 'Consultas');
      }*/       
    }else{
      this.toastr.error(this.translate.instant('texto_473'),this.translate.instant('texto_469'));
    } 
      
  }

  Anadir3(){
    if(this.selectedValueatributo3 !=null && this.selectedValueoperador3 != null){
        this.columnas_order.push({valor1: this.atributos.find(x=> x.n_atributo == this.selectedValueatributo3).id_campo,valor2: this.selectedValueoperador3});
        this.buildQry();  
        //this.toastr.success('Campo añadido correctamente', 'Consultas');
    }else{
      this.toastr.error(this.translate.instant('texto_474'),this.translate.instant('texto_469'));
    } 
    
  }

  Anadir4(){
    if(this.selectedValueatributo4 !=null){
        this.columnas_group.push(this.atributos.find(x=> x.n_atributo == this.selectedValueatributo4).id_campo);
        this.buildQry();  
        //this.toastr.success('Campo añadido correctamente', 'Consultas');
    }else{
      this.toastr.error(this.translate.instant('texto_475'),this.translate.instant('texto_469'));
    } 
    
  }

  buildQry(){ 
    this.campos = "";
    this.campos_where = "";
    this.campos_order = "";
    this.campos_group = "";
    this.columnas_rdo = [];

    for (var i = 0; i < this.columnas_qry.length; i++) {
      if(this.columnas_qry[i].valor2!="" && this.columnas_qry[i].valor2!=null){
        if(this.columnas_qry[i].valor3!="" && this.columnas_qry[i].valor3!=null){
          this.campos = this.campos + this.columnas_qry[i].valor2 + "(" + this.columnas_qry[i].valor1 + ") AS '" + this.columnas_qry[i].valor3 + "',";
          this.columnas_rdo.push(this.columnas_qry[i].valor3);
        }else{
          this.campos = this.campos + this.columnas_qry[i].valor2 + "(" + this.columnas_qry[i].valor1 + '),';
          this.columnas_rdo.push(this.columnas_qry[i].valor2 + "(" + this.columnas_qry[i].valor1 + ')');
        }
      }else{
        if(this.columnas_qry[i].valor3!="" && this.columnas_qry[i].valor3!=null){
          this.campos = this.campos + this.columnas_qry[i].valor1 + " AS '" + this.columnas_qry[i].valor3 + "',";
          this.columnas_rdo.push(this.columnas_qry[i].valor3);
        }else{        
          this.campos = this.campos + this.columnas_qry[i].valor1 + ',';
          this.columnas_rdo.push(this.columnas_qry[i].valor1);
        }
      }
    }  

    /*for (var j = 0; j < this.columnas_where.length; j++) {
    this.campos_where = this.campos_where + this.columnas_where[j].valor1 + this.columnas_where[j].valor2 + this.columnas_where[j].valor3+ ' AND ';
    }*/
    this.campos_where = this.sentencia_where;

    for (var k = 0; k < this.columnas_order.length; k++) {
      this.campos_order = this.campos_order + this.columnas_order[k].valor1 + ' ' + this.columnas_order[k].valor2 + ',';
    }  
    for (var t = 0; t < this.columnas_group.length; t++) {
      this.campos_group = this.campos_group + this.columnas_group[t] + ',';
    } 

    this.campos = this.campos.substring(0, this.campos.length - 1);
    //this.campos_where = this.campos_where.substring(0, this.campos_where.length - 4);
    this.campos_group = this.campos_group.substring(0, this.campos_group.length - 1);
    this.campos_order = this.campos_order.substring(0, this.campos_order.length - 1);

    this.datosconsulta = "SELECT " + this.campos + " FROM " + this.tablas.find(x=> x.n_tabla == this.n_tablaF).id_tabla;  
    let tiene_sociedad = this.tablas.find(x=> x.n_tabla == this.n_tablaF).societaria;
    console.log("this.n_tablaF: ", this.n_tablaF);
    console.log("tiene_sociedad: ", tiene_sociedad);    
    if (this.campos_where != ""){
      if (tiene_sociedad == "1"){
        this.datosconsulta = this.datosconsulta + " WHERE " + this.campos_where + " and id_sociedad = '" + this.auth.getSociedad() + "'";
      }else{
        this.datosconsulta = this.datosconsulta + " WHERE " + this.campos_where;
      }
    }else{
      if (tiene_sociedad == "1"){      
        this.datosconsulta = this.datosconsulta + " WHERE id_sociedad = '" + this.auth.getSociedad() + "'";
      }        
    }

    if (this.campos_group != ""){
      this.datosconsulta = this.datosconsulta + " GROUP BY " + this.campos_group;   
    }

    if (this.campos_order != ""){
      this.datosconsulta = this.datosconsulta + " ORDER BY " +this.campos_order;
    }
  }  
  
  handlePage(e: PageEvent){
    this.page_size = e.pageSize;
    this.page_number = e.pageIndex+1;
  }

  onChangeAtributo(event) {
    this.n_atributoF = "";
    let selectedIdAtributo = event.value;
    for(var i=0; i<this.atributos.length; i++){
      if(this.atributos[i].id_atributo == selectedIdAtributo){
        this.n_atributoF = this.atributos[i].n_atributo;
        break;
      }
    }
  }  

  onChangeTabla(event) {
    this.n_tablaF = "";
    this.campos = "";
    this.columnas_qry = [];
    this.datosconsulta = "";
    this.selectedValueatributo = "";
    this.selectedValueatributo2 = "";
    this.selectedValueatributo3 = "";
    this.selectedValueatributo4 = "";
    this.selectedValueoperador = "";
    this.selectedValueoperador3 = "";
    
    this.columnas_group = [];
    this.columnas_order = [];
    this.sentencia_where = "";
    this.columnas_rdo = [];
    this.datosQuery = [];    

    let selectedIdtabla = event.value;
    for(var i=0; i<this.tablas.length; i++){
      if(this.tablas[i].id == selectedIdtabla){
        this.n_tablaF = this.tablas[i].n_tabla;
        break;
      }
    }
  }    

  onChangeTotal(event,campo) {
    let encontrado:boolean = false;
    this.selectedTotal = event.target.value;
    var i = 0; 
    while(!encontrado && i < this.columnas_qry.length){
      if(this.columnas_qry[i].valor1 == campo){  
        encontrado = true;  
        if (this.selectedTotal != ""){
          this.columnas_qry[i].valor2 = this.selectedTotal;        
        }else{
          this.columnas_qry[i].valor2 = null;
        }
      }
      i++;
    }
    this.buildQry();
  }  

  onChangeOrden(campo) {
    let encontrado:boolean = false;    
    var i = 0; 
    while(!encontrado && i < this.columnas_order.length){
      if(this.columnas_order[i].valor1 == campo){  
        encontrado = true;  
        if (this.selectedValueoperador3 != ""){
          this.columnas_order[i].valor2 = this.selectedValueoperador3;        
        }else{
          this.columnas_order[i].valor2 = null;
        }
      }
      i++;
    }
    this.buildQry();
  } 

  onChangeAlias(event,campo) {
    
    let encontrado:boolean = false;
    this.selectedAlias = event.target.value;
    var i = 0; 
    while(!encontrado && i < this.columnas_qry.length){
      if(this.columnas_qry[i].valor1 == campo){  
        encontrado = true;  
        if (this.selectedAlias != ""){
          this.columnas_qry[i].valor3 = this.selectedAlias;        
        }else{
          this.columnas_qry[i].valor3 = null;
        }
      }
      i++;
    }
    this.buildQry();     
  }   

  deletecampo_select(campo){
    let encontrado:boolean = false;
    var i = 0; 
    while(!encontrado && i < this.columnas_qry.length){
      if(this.columnas_qry[i].valor1 == campo){  
        encontrado = true;  
        if(i == this.columnas_qry.length-1){
          this.columnas_qry.pop();
        }else if(i==0){
          this.columnas_qry.shift();
        }else{
          for(var j = i; j<this.columnas_qry.length-1;j++){
            this.columnas_qry[j]=this.columnas_qry[j+1];
          }
          this.columnas_qry.pop();          
        }
      }
      i++;
    }
    this.buildQry();
    this.toastr.success(this.translate.instant('texto_476'),this.translate.instant('texto_469'));
  }

  deletecampo_where(){
    this.sentencia_where = "";
    this.buildQry();
  }

  deletecampo_order(campo){
    let encontrado:boolean = false;
    var i = 0; 
    while(!encontrado && i < this.columnas_order.length){
      if(this.columnas_order[i] == campo){  
        encontrado = true;  
        if(i == this.columnas_order.length-1){
          this.columnas_order.pop();
        }else if(i==0){
          this.columnas_order.shift();
        }else{
          for(var j = i; j<this.columnas_order.length-1;j++){
            this.columnas_order[j]=this.columnas_order[j+1];
          }
          this.columnas_order.pop();          
        }
      }
      i++;
  }
  this.buildQry();
  this.toastr.success(this.translate.instant('texto_476'),this.translate.instant('texto_469'));
  }

  deletecampo_group(campo){
    let encontrado:boolean = false;
    var i = 0; 
    while(!encontrado && i < this.columnas_group.length){
      if(this.columnas_group[i] == campo){  
        encontrado = true;  
        if(i == this.columnas_group.length-1){
          this.columnas_group.pop();
        }else if(i==0){
          this.columnas_group.shift();
        }else{
          for(var j = i; j<this.columnas_group.length-1;j++){
            this.columnas_group[j]=this.columnas_group[j+1];
          }
          this.columnas_group.pop();          
        }
      }
      i++;
  }
  this.buildQry();
  this.toastr.success(this.translate.instant('texto_476'),this.translate.instant('texto_469'));
  }

  abrirConsulta(){
    this.toastr.success(this.translate.instant('texto_477'),this.translate.instant('texto_479'));
  }
  guardarConsulta(){
    this.toastr.success(this.translate.instant('texto_478'),this.translate.instant('texto_479'));
  }

  get tabla() {
    return this.myForm.get('tabla');
  }

  get atributo() {
    return this.myForm.get('atributo');
  }  

  get atributo2() {
    return this.myForm2.get('atributo2');
  }  

  get atributo3() {
    return this.myForm3.get('atributo3');
  }  

  get atributo4() {
    return this.myForm4.get('atributo4');
  }  

  get n_atributo_value() {
    return this.myForm.get('n_atributo_value');
  } 

  get n_atributo_value2() {
    return this.myForm2.get('n_atributo_value2');
  } 

  get n_atributo_value3() {
    return this.myForm3.get('n_atributo_value3');
  } 

  get n_atributo_value4() {
    return this.myForm4.get('n_atributo_value4');
  } 


  get n_tabla_value() {
    return this.myForm.get('n_tabla_value');
  }  
  
  get n_tabla_value2() {
    return this.myForm2.get('n_tabla_value2');
  }  

  get n_tabla_value3() {
    return this.myForm3.get('n_tabla_value3');
  }  

  get n_tabla_value4() {
    return this.myForm4.get('n_tabla_value4');
  }  

  get operador2() {
    return this.myForm2.get('operador2');
  }   

  get operador3() {
    return this.myForm3.get('operador3');
  }   

  get operador4() {
    return this.myForm4.get('operador4');
  }   


  get tipo() {
    return this.myForm2.get('tipo');
  }    
  
  get valor_filtro2() {
    return this.myForm2.get('valor_filtro2');
  }      

  get alias() {
    return this.myForm5.get('alias');
  } 
  
  get total() {
    return this.myForm6.get('total');
  }   
  
  exportExcel(): void  {
    let d = new Date().toISOString();
    let fileName = 'consulta_xls_' + d;  
    this.exportService.exportExcel(this.datosQuery, fileName);
  }  

  exportCsv(data: any) {
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    const csv = data.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    let d = new Date().toISOString();
    let fileName = 'consulta_csv_' + d; 

    a.href = url;
    a.download = fileName + '.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}
