<section style="position: absolute;width: 100%;z-index: 1;" class="image-container" style="background-image: url(assets/img/wizard/wizard-4.jpg);">
    <div class="container" style="padding: 0;">
        <div class="board" style="width: 100% !important;">
            <button type="button" style="z-index:100;float: right;" class="btn btn-dark"  (click) = "hide();">X</button> 

            <!-- Navigation Area (Circular Tabs) -->
            <msw-navbar></msw-navbar>
            <!-- End Navigation Area (Circular Tabs) -->

            <!-- Content Area -->
            <div class="tab-content">
                <form #datosDenunciaForm="ngForm" class="editForm" novalidate>
                    <div class="tab-pane fade in active">
                        <h4 class="head text-center">{{title | translate }}</h4>
                        <div class='row'>
                            <div class="grid-container">
                                <div class="fecha">
                                  <div class="form-group">
                                      <label class="control-label" for="receptor">{{ 'texto_345'| translate }}</label> <br> 
                                      <mat-form-field>
                                          <input matInput required [matDatepicker]="fechaDatosDenuncia" [max]="hoy" placeholder="{{ 'texto_92'| translate }}" id="fechaDatosDenuncia" name="fechaDatosDenuncia" [(ngModel)]="datos.fechaDatosDenuncia">
                                          <mat-datepicker-toggle matSuffix [for]="fechaDatosDenuncia"></mat-datepicker-toggle>
                                          <mat-datepicker #fechaDatosDenuncia></mat-datepicker>
                                      </mat-form-field>
                                  </div>  
                                </div>
                                <div class="comentario">
                                  <div>
                                      <span style="font-size: 12px;display:block;line-height: normal;">{{ 'texto_346'| translate }}</span>
                                  </div>  
                                </div>
                                <div class="descripcion">
                                  <div>
                                      <label class="control-label" for="descripcionDatosDenuncia">{{ 'texto_63' | translate }}</label>
                                      <textarea (keyup)="onKey($event)" maxlength="4000" required class="form-control input-md" #descripcionDatosDenuncia="ngModel" id="descripcionDatosDenuncia" name="descripcionDatosDenuncia" type="text" placeholder="{{ 'texto_63' | translate }}" [(ngModel)]="datos.descripcionDatosDenuncia" rows="8"></textarea>
                                      <span style="float: left;font-weight: bold;font-size:12px">{{contador}} {{ 'texto_69' | translate }} 4000</span>
                                  </div>  
                                </div>
                                <div class="botones">
                                  <div class="form-group text-center">
                                      <button class="btn btn-outline-rounded btn-default" (click)="goToPrevious(datosDenunciaForm)"> <span style="margin-right:10px;" class="glyphicon glyphicon-arrow-left"></span> {{ 'texto_142' | translate }}</button>
                                      <button class="btn btn-success btn-outline-rounded btn-info" [disabled]="!datosDenunciaForm.valid" (click)="goToNext(datosDenunciaForm)"> {{ 'texto_141' | translate }} <span style="margin-left:10px;" class="glyphicon glyphicon-arrow-right"></span></button>
                                  </div>  
                                </div>
                            </div>                            
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>