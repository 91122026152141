import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { ApiService } from '../api.service';
import { Empresa } from '../model/empresa';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-empresas',
  templateUrl: './empresas.component.html',
  styleUrls: ['./empresas.component.css']
})
export class EmpresasComponent implements OnInit {
  sociedad: string;
  empresas: Empresa[];
  validatingForm: FormGroup;
  constructor(private apiService: ApiService,private router: Router, private auth: AuthService, private toastr: ToastrService, private changeDetectorRefs: ChangeDetectorRef,private translate: TranslateService) { }
  IsHiddenForm: boolean =  false;

  id_empresa: string;
  n_empresa: string;
  comentario: string;
  
  ngOnInit() {
    let language = this.auth.getLanguage();
    if(language == "" || language == null){
      language = 'es';
    }
    this.translate.setDefaultLang(language);

    this.sociedad = this.auth.getNameSociedad();
    this.apiService.searchCompanyByUser(this.auth.getIdUser(),this.auth.getSociedad()).subscribe((empresas: Empresa[])=>{
      this.empresas = empresas;
    });
    this.validatingForm = new FormGroup({
      signupFormModalName: new FormControl(''),
      signupFormModalId: new FormControl(''),
      contactFormModalMessage: new FormControl('', []),
    });
  }

  get signupFormModalName() {
    return this.validatingForm.get('signupFormModalName');
  }

  get signupFormModalId() {
    return this.validatingForm.get('signupFormModalId');
  }

  get contactFormModalMessage() {
    return this.validatingForm.get('contactFormModalMessage');
  }


  deleteCompany(id_empresa:string){
    this.apiService.countComplaintByIdCompany(this.auth.getSociedad(), id_empresa).subscribe(
      res => {
        if(res == 200){
          this.apiService.deleteCompanyByUser(this.auth.getIdUser(),this.auth.getSociedad(),id_empresa).subscribe(
            res => {
              if(res == 200){
                this.toastr.success(this.translate.instant('texto_276'), this.translate.instant('texto_279'));
              }else{
                this.toastr.error(this.translate.instant('texto_277'), this.translate.instant('texto_279'));
              }
              this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
                this.router.navigate(['empresas']);
            }); 
          });
        }else{
          this.toastr.error(this.translate.instant('texto_278'), this.translate.instant('texto_279'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['empresas']);
        }); 
    });

   
  }

  showCompany(empresa:Empresa){
    this.IsHiddenForm = true;
    this.id_empresa = empresa.id_empresa; 
    this.n_empresa = empresa.n_empresa;
    this.comentario = empresa.comentario;
  }

  hideModal(){
    this.IsHiddenForm = false;
  }

  updateCompany(id_empresa:string){
    this.apiService.updateCompanyByUser(this.auth.getSociedad(), id_empresa, this.signupFormModalName.value, this.contactFormModalMessage.value, this.auth.getIdUser()).subscribe(
      res => {
        if(res == 200){
          this.toastr.success(this.translate.instant('texto_280'), this.translate.instant('texto_282'));
        }else{
          this.toastr.error(this.translate.instant('teato_281'), this.translate.instant('texto_282'));
        }
        this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['empresas']);
        }); 
    });
  }

}
