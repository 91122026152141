import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from "../../environments/environment"
import { AuthService } from '../auth.service';
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to the api url
        //const user = this.authenticationService.getIdUser;
        const isLoggedIn = this.authenticationService.isLoggedIn();
        const isApiUrl = true;//request.url.startsWith(environment.baseUrl);
        if (isLoggedIn && isApiUrl) {
            var token = this.authenticationService.getToken();
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }

        return next.handle(request);
    }
}